import { FC } from 'react';
import { Range as Base, getTrackBackground } from 'react-range';

import { RangeHolder } from './Range.Holder';

export const Range: FC<{
  values: number[];
  min: number;
  max: number;
  step: number;
  onChange: (values: any) => void;
}> = ({ values: currentValues, onChange, min, max, step }) => {
  return (
    <>
      <Base
        step={step}
        min={min}
        max={max}
        values={currentValues}
        onChange={(values) => onChange(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '28px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '4px',
                width: '100%',
                borderRadius: '2px',
                background: getTrackBackground({
                  values: currentValues,
                  colors: ['rgba(255,255,255,0.9)', 'rgba(255,255,255,0.5)'],
                  min,
                  max,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} style={{ ...props.style, outline: 'none' }}>
            <RangeHolder />
          </div>
        )}
      />
    </>
  );
};
