import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Select } from 'modules/common/ui';
import { Service, Name, NameType } from 'modules/common/daData';

import { useGender } from '../GenderContext';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'unique'
> & {
  /**
   * Тип имени для ввода.
   */
  nameType: NameType;
};

/**
 * Отображает поле ввода имени.
 */
export const NameInput: FC<Props> = ({ onChange, nameType, ...props }) => {
  const { gender, setGender, resetType } = useGender();
  const daData = Service.use();

  const handleSuggest = useCallback(
    (value: string) => daData.suggestName(value, nameType, gender),
    [daData, gender, nameType],
  );

  const handleFormat = useCallback((item: Name) => item.value, []);

  const handleChange = useCallback(
    (value?: Name) => {
      if (value) {
        setGender(nameType, value.gender);
      }

      if (!value) {
        resetType(nameType);
      }

      if (onChange) {
        onChange(value?.value);
      }
    },
    [nameType, onChange, resetType, setGender],
  );

  const handleUnique = useCallback((value?: Name) => value?.value || '', []);

  const fallback = useCallback(
    (search: string) => {
      const head = search.substr(0, 1).toLocaleUpperCase();
      const tail = search.substr(1);

      const model: Name = {
        gender,
        type: nameType,
        value: `${head}${tail}`,
      };

      return model;
    },
    [nameType, gender],
  );

  return (
    <Select
      {...props}
      values={handleSuggest}
      format={handleFormat}
      unique={handleUnique}
      onChange={handleChange}
      fallback={fallback}
      trim
    />
  );
};
