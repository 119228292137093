import { PaymentDataIssue } from '../types/PaymentDataIssue';

/**
 * Преобразует ответ сервера в данные о предмете платежа по займу.
 * @param data Ответ сервера.
 */
export function transformPaymentDataIssue(data: any): PaymentDataIssue {
  return {
    dateAt: data.date_at ? new Date(data.date_at) : undefined,
    amount: data.sum_amount,
  };
}
