import { ComponentPropsWithoutRef, FC } from 'react';

import { Checkbox } from 'modules/common/ui';
import { ApplicationAgreement } from 'services/RpcService/types/ApplicationAgreement';
import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';

import { renderText } from './Item.renderText';

import { Store } from '../../../stores';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Checkbox>,
  'name' | 'valid' | 'children'
> & {
  /**
   * Модель соглашения, которому соответствует данный элемент.
   */
  model: ApplicationAgreement;
};

/**
 * Отображает отдельный чекбокс соглашения.
 */
export const Item: FC<Props> = ({ model, ...props }) => {
  const { personalInfoAgreements, setPersonalInfoAgreements } = Store.use();

  const handleChange = (value: boolean) => {
    if (
      model.type === ApplicationAgreementType.PersonalDataProcessing &&
      personalInfoAgreements
    ) {
      const newValues: Record<string, boolean> = {};

      Object.entries(personalInfoAgreements).forEach(([k]) => {
        newValues[k] = value;
      });

      setPersonalInfoAgreements(newValues);
    }
  };

  return (
    <Checkbox
      {...props}
      name={model.type}
      id={model.type}
      valid={false}
      onChange={handleChange}
    >
      {renderText(model.type, model.documentUrl)}
    </Checkbox>
  );
};
