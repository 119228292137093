import { FC, ComponentPropsWithoutRef } from 'react';

import { getEnumValues, Select } from 'modules/common/ui';
import { UserChildrenCount } from 'services/RpcService/types/UserChildrenCount';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

const recordProps = getEnumValues(UserChildrenCount, {
  [UserChildrenCount.None]: 'Нет',
  [UserChildrenCount.One]: '1',
  [UserChildrenCount.Two]: '2',
  [UserChildrenCount.Three]: '3',
  [UserChildrenCount.Four]: '4',
  [UserChildrenCount.Five]: '5',
  [UserChildrenCount.MoreThanFive]: 'Более 5',
});

/**
 * Отображает поле выбора количества детей до 18 лет.
 */
export const ChildrenCountInput: FC<Props> = (props) => (
  <Select {...props} {...recordProps} readOnly />
);
