import styled from '@emotion/styled';

/**
 * Компонент для отображение дополнительной информации в Footer.
 */
export const Description = styled.div`
  /**
   * Стилизация ссылок в тексте.
   */
  a {
    outline: none;
    color: inherit;
  }
`;
