/**
 * Ошибка, произошедшая во время операции.
 */
export enum ErrorType {
  /**
   * Указывает на то, что пользователь превысил максимальное количество
   * запросов на получение кода подтверждения.
   */
  REQUEST_LIMIT_EXCEEDED = 'Превышено максимальное количество запросов кода. Пожалуйста, обратитесь в техподдержку',

  /**
   * Указывает на то, что пользователь превысил максимальное количество
   * попыток ввести код.
   */
  CONFIRM_LIMIT_EXCEEDED = 'Превышено максимальное количество попыток. Попробуйте запросить новый код',

  /**
   * Указывает на то, что пользователь ввёл неверный код подтверждения.
   */
  INVALID_CODE = 'Неверный код подтверждения',
}
