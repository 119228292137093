import { FC } from 'react';
import Head from 'next/head';

import { Container } from 'modules/common/ui';

import { Provider } from '../Provider';
import { Heading } from '../Heading';
import { Access } from '../Access';
import { Divider } from '../Divider';
import { Params } from '../Params';
import { Confirmation } from '../Confirmation';
import { Info } from '../Info';
import { Documents } from '../Documents';
import { Back } from '../Back';
import { PageContent } from '../PageContent';

/**
 * Отображает содержимое страницы подписания договора на реструктуризацию.
 */
export const Page: FC = () => {
  return (
    <PageContent>
      <Provider>
        <Access>
          <Head>
            <title>Аполлон займ - Снижение платежа</title>
          </Head>
          <Container size="medium" offset="large">
            <Back href="/loan">Назад</Back>
            <Heading />
            <Params />
            <Divider />
            <Confirmation />
            <Info>
              <Documents />
            </Info>
          </Container>
        </Access>
      </Provider>
    </PageContent>
  );
};
