import { FC, SVGProps } from 'react';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGSVGElement>, 'ref'>;

/*
 * Пиктограмма отключенного режима отображения.
 */
export const Show: FC<Props> = (props) => (
  <svg
    width="21"
    height="14"
    viewBox="0 0 21 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 14C16.5 14 20.5 8.4 20.5 7C20.5 5.6 16.5 0 10.5 0C4.5 0 0.5 5.6 0.5 7C0.5 8.4 4.5 14 10.5 14ZM10.5 12C13.2614 12 15.5 9.76142 15.5 7C15.5 4.23858 13.2614 2 10.5 2C7.73858 2 5.5 4.23858 5.5 7C5.5 9.76142 7.73858 12 10.5 12ZM13.001 7C13.001 8.38071 11.8817 9.5 10.501 9.5C9.12024 9.5 8.00095 8.38071 8.00095 7C8.00095 5.61929 9.12024 4.5 10.501 4.5C11.8817 4.5 13.001 5.61929 13.001 7Z"
      fill="#0B1F35"
    />
  </svg>
);
