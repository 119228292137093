import styled from '@emotion/styled';

/**
 * Разделитель пунктов меню шапки.
 */
export const Divider = styled.span`
  display: block;

  margin-left: 1.25rem;

  height: 1px;
  max-height: 1px;
  width: calc(100% - 2.5rem);

  background-color: ${({ theme }) => theme.colors.light.separator};
`;
