export const Webmoney = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_8503_74337)">
        <path
          d="M11.9408 0.239746C13.8129 0.239746 15.594 0.66865 17.1923 1.43588C17.443 1.54897 17.6953 1.68432 17.9451 1.81979L16.8492 2.79056L15.2062 1.09767L12.3752 3.58029L10.7081 1.79694L5.50228 6.4242L8.83521 10.0582L7.53407 11.1865L10.8223 14.8204L9.52109 15.9483L14.2248 21.1172L17.0087 18.5891L19.43 21.2983C18.9501 21.6595 18.425 22.0214 17.8549 22.3368C16.1193 23.3073 14.1098 23.8705 11.9639 23.8705C5.36479 23.8705 7.56237e-05 18.5674 7.56237e-05 12.045C-0.0232284 5.56662 5.34244 0.239746 11.9408 0.239746ZM9.74856 11.3222L12.6027 8.79408L15.1604 11.6154L12.3057 14.1432L9.74856 11.3222ZM11.7812 16.1976L14.6121 13.6472L17.1923 16.4682L14.3381 18.9964L11.7812 16.1976ZM7.71771 6.51479L10.571 3.9867L13.1281 6.80798L10.2739 9.33595L7.71771 6.51479ZM12.9452 5.16009L15.0688 3.26424L16.9871 5.36342L14.8636 7.28167L12.9452 5.16009ZM16.7358 13.8499L18.8586 11.9539L20.7768 14.075L18.6532 15.9722L16.7358 13.8499ZM18.6077 18.1162L20.7314 16.2199L22.6481 18.319L20.5253 20.237L18.6077 18.1162ZM19.4755 8.36517L20.8909 7.10107L22.169 8.50099L20.7539 9.76463L19.4755 8.36517ZM17.6489 4.18955L19.0644 2.90329L20.3435 4.32501L18.9269 5.589L17.6489 4.18955ZM21.279 12.5411L22.6944 11.2772L23.9958 12.6766L22.5579 13.9403L21.279 12.5411ZM14.909 9.40369L17.0327 7.50771L18.9501 9.60643L16.8274 11.5028L14.909 9.40369Z"
          fill="#036CB5"
        />
      </g>
      <defs>
        <clipPath id="clip0_8503_74337">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
