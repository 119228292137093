import styled from '@emotion/styled';

/**
 * Корневой элемент калькулятора.
 */
export const Root = styled.div`
  max-width: 580px;
  user-select: none;
  z-index: 1;
  position: relative;
  transition: all 0.3s ease-in-out;
`;
