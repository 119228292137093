import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';

import { Store } from '../../../stores';

import { Item } from '../Item';

/**
 * Отображает список чекбоксов.
 */
const List: FC = () => {
  const { model = [], personalInfoAgreements } = Store.use();
  const { setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (personalInfoAgreements) {
      const isAllChecked = Object.entries(personalInfoAgreements).some(
        ([, v]) => v === true,
      );

      if (isAllChecked) {
        setFieldValue(ApplicationAgreementType.PersonalDataProcessing, true);
      } else {
        setFieldValue(ApplicationAgreementType.PersonalDataProcessing, false);
      }
    }
  }, [personalInfoAgreements]);

  return (
    <>
      {model.map((value) => (
        <Item key={value.type} model={value} />
      ))}
    </>
  );
};

const component = observer(List);
export { component as List };
