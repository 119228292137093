/**
 * Статус займа.
 */
export enum LoanStatus {
  /**
   * Готов к выдаче средств.
   */
  ReadyForIssue = 'ready_to_issue',

  /**
   * Договор подписан.
   */
  Signed = 'signed',

  /**
   * Производится выдача средств.
   */
  PayoutPending = 'pending',

  /**
   * Выдача средств.
   */
  Payout = 'payout',

  /**
   * Средства выданы.
   */
  Issued = 'issued',

  /**
   * Заём закрыт.
   */
  Closed = 'closed',
}
