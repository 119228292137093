import { ComponentPropsWithoutRef, FC, SVGProps, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
  Card,
  Doc,
  Alfabank,
  Sberbank,
  Webmoney,
  Yoomoney,
} from 'modules/common/icons';
import { Store } from 'modules/repay';
import { LoanService as LoanMetricService } from 'modules/common/metrics';
import { PaymentProvider } from 'services/RpcService/types/PaymentProvider';
import { PaymentMethodType } from 'services/RpcService/types/PaymentMethodType';

import { Surface } from '../Surface';

import { Heading } from './Repay.Heading';
import { Container } from './Repay.Container';
import { Footer } from './Repay.Footer';
import { Grid } from './Repay.Grid';
import { Back } from './Back';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Container>;

/**
 * Страница способов оплаты.
 */
const Component: FC<Props> = ({ ...props }) => {
  const repayStore = Store.use();
  const loanMetric = LoanMetricService.use();
  const { paymentMethods } = repayStore;

  useEffect(() => {
    loanMetric.repay();
    repayStore.fetchMethods();
  }, [loanMetric]);

  function handlePayCard(provider: PaymentProvider, method: PaymentMethodType) {
    repayStore.pay(method, provider);
    loanMetric.payout();
  }

  function getHeading(method: PaymentMethodType): string {
    const headings: Record<PaymentMethodType, string> = {
      [PaymentMethodType.TinkoffCard]: 'Любой банковской картой',
      [PaymentMethodType.YooMoneyCard]: 'Любой банковской картой',
      [PaymentMethodType.YooMoneyWallet]: 'Электронным кошельком ЮMoney',
      [PaymentMethodType.WebMoneyWallet]: 'Электронным кошельком WebMoney',
      [PaymentMethodType.SberBank]: 'Оплата через SberPay',
      [PaymentMethodType.AlfaBank]: 'Оплата через Альфа-Клик',
    };

    return headings[method];
  }

  function getIcon(icon: PaymentMethodType) {
    const icons: Record<PaymentMethodType, SVGProps<SVGSVGElement>> = {
      [PaymentMethodType.TinkoffCard]: <Card />,
      [PaymentMethodType.YooMoneyCard]: <Card />,
      [PaymentMethodType.YooMoneyWallet]: <Yoomoney />,
      [PaymentMethodType.WebMoneyWallet]: <Webmoney />,
      [PaymentMethodType.SberBank]: <Sberbank />,
      [PaymentMethodType.AlfaBank]: <Alfabank />,
    };

    return icons[icon];
  }

  return (
    <Container {...props} size="large">
      <Heading>Выберите способ оплаты</Heading>
      <Grid>
        {paymentMethods.map(({ provider, method }) => (
          <Surface
            heading={getHeading(method)}
            key={`${provider}-${method}`}
            text="Мгновенно"
            icon={getIcon(method)}
            onClick={() => handlePayCard(provider, method)}
          />
        ))}
        <Surface
          as="a"
          heading="По реквизитам"
          text="От 1 до 3-х рабочих дней"
          icon={<Doc />}
          rel="noreferrer"
          target="_blank"
          href="/docs/payment-reminder.pdf"
        />
      </Grid>
      <Footer>
        <Back href="/loan">Назад</Back>
      </Footer>
    </Container>
  );
};

export const Repay = observer(Component);
