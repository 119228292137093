import { LazyService } from '@devim-front/service';
import { AuthTokens } from 'services/RpcService/types/AuthTokens';

/**
 * Содержит низкоуровневые методы для работы с авторизацией.
 */
export class Service extends LazyService {
  /**
   * Ключ, по которому в браузере сохраняется токен доступа к системе.
   */
  private ACCESS_TOKEN_KEY = 'authAccessToken';

  /**
   * Ключ, по которому в браузере сохраняется токен обновления сессии.
   */
  private REFRESH_TOKEN_KEY = 'authRefreshToken';

  /**
   * Ключ, по которому в браузере сохраняется адрес для перехода после
   * авторизации.
   */
  private BACK_URL_KEY = 'authBackUrl';

  /**
   * Ключ, по которому в браузере сохраняется телефон, под которым вошел
   * пользователь.
   */
  private PHONE_KEY = 'authPhone';

  /**
   * Сохраняет значение в браузере по указанному ключу.
   * @param key Ключ.
   * @param value Значение.
   */
  private save(key: string, value: any) {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }

  /**
   * Считывает из браузера сохранённое значение по указанному ключу.
   * @param key Ключ.
   */
  private read(key: string) {
    return localStorage.getItem(key) || undefined;
  }

  /**
   * Сохраняет токен возобновления авторизации в браузере.
   * @param value Значение.
   */
  public saveAuthTokens(tokens: AuthTokens | undefined) {
    this.save(this.ACCESS_TOKEN_KEY, tokens?.accessToken ?? '');
    this.save(this.REFRESH_TOKEN_KEY, tokens?.refreshToken ?? '');
  }

  /**
   * Считывает из браузера сохранённый токен возобновления авторизации.
   */
  public readAuthTokens(): AuthTokens | undefined {
    const accessToken = this.read(this.ACCESS_TOKEN_KEY);
    const refreshToken = this.read(this.REFRESH_TOKEN_KEY);

    if (!accessToken || !refreshToken) {
      return undefined;
    }

    return {
      accessToken,
      refreshToken,
    };
  }

  /**
   * Сохраняет в браузере ссылку для перехода после авторизации.
   * @param value Значение.
   */
  public saveBackUrl(value?: string) {
    this.save(this.BACK_URL_KEY, value);
  }

  /**
   * Считывает из браузера сохранённую ссылку для перехода после авторизации.
   */
  public readBackUrl() {
    return this.read(this.BACK_URL_KEY);
  }

  /**
   * Сохраняет в браузере телефон, под которым авторизовался пользователь.
   * @param value Телефон.
   */
  public savePhone(value?: string) {
    this.save(this.PHONE_KEY, value);
  }

  /**
   * Считывает из браузера телефон, под которым авторизовался пользователь.
   */
  public readPhone() {
    return this.read(this.PHONE_KEY);
  }
}
