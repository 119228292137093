import styled from '@emotion/styled';

/**
 * Разделитель между полями ввода на калькуляторе.
 */
export const Divider = styled.div`
  align-self: stretch;
  flex: 0 0 auto;

  margin-right: 1rem;
  margin-left: 1rem;

  background-color: ${({ theme }) => theme.colors.light.border.hover};
  width: 1px;
`;
