import { FC, useContext, useEffect } from 'react';
import { Modal } from 'modules/common/ui/components/Modal';
import { Button } from 'modules/common/ui';
import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';
import { FormName } from 'modules/application/steps';
import { useFormikContext } from 'formik';

import { Store } from '../../stores';

import { Context } from './Context';
import { Form } from './Form';
import { Content, CheckboxesList, Checkbox } from './PersonalInfo.Root';
import { GroupCheckbox } from './GroupCheckbox';

/**
 * Компонент отображающий список соглашений о персональных данных.
 */
export const PersonalInfo: FC = () => {
  const { isOpen, toggle } = useContext(Context);
  const { model = [], personalInfoAgreements } = Store.use();
  const { setFieldValue } = useFormikContext<any>();

  let personalInfoAgreementsModel =
    model.find(
      ({ type }) => type === ApplicationAgreementType.PersonalDataProcessing,
    )?.children || [];

  useEffect(() => {
    if (personalInfoAgreements) {
      Object.entries(personalInfoAgreements).forEach(([k, v]) => {
        setFieldValue(k, v);

        personalInfoAgreementsModel = personalInfoAgreementsModel.map(
          (item) => {
            if (item.type === k) {
              item.isCheckedByDefault = v;
            }

            return item;
          },
        );
      });
    }
  }, [personalInfoAgreements, personalInfoAgreementsModel]);

  return (
    <Modal isOpen={isOpen} ariaHideApp={false} onRequestClose={toggle}>
      <Content>
        <h3>Документы</h3>
        <p>Я ознакомлен(а) и согласен(на) с нижеуказанными документами:</p>
        <Form name={FormName.PERSONAL_INFO_AGREEMENTS}>
          <CheckboxesList>
            {personalInfoAgreementsModel.map((value) => (
              <Checkbox key={value?.text} model={value}>
                <a href={value?.documentUrl} target="_blank">
                  {value?.text}
                </a>
              </Checkbox>
            ))}
          </CheckboxesList>
          <GroupCheckbox>Согласен со всем</GroupCheckbox>
        </Form>
        <br />
        <Button variant="filled" onClick={toggle}>
          Ok
        </Button>
      </Content>
    </Modal>
  );
};
