import { FC, ComponentPropsWithoutRef } from 'react';
import { observer } from 'mobx-react';

import { Heading as Base } from 'modules/common/ui';
import { Store as LoanStore } from 'modules/loan';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base>;

/**
 * Отображает заголовок (номер займа и текст) на странице подписания реструктуризации.
 */
const Heading: FC<Props> = ({ ...props }) => {
  const loan = LoanStore.use();

  const overhead = `Заём №${loan.model?.number}`;

  const heading =
    'Поздравляем! Теперь вы будете платить меньше. Осталось ввести SMS-код.';

  return (
    <Base {...props} overhead={overhead}>
      {heading}
    </Base>
  );
};

const component = observer(Heading);
export { component as Heading };
