import { BankCard } from '../types/BankCard';

/**
 * Преобразует ответ сервера в данные о привязанной банковской карте.
 * @param data Ответ сервера.
 */
export function transformBankCard(data: any): BankCard {
  return {
    id: data.id,
    expiryYear: data.expired_year,
    expiryMonth: data.expired_month,
    maskedNumber: data.mask_card,
  };
}
