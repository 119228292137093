import { Themed } from '@emotion/react';

/**
 * Добавляет медиа запрос для экрана больше или равного указанной ширине.
 * @param size Размер контейнера, ширине которого должна равняться ширина экрана при данном медиа-запросе.
 */
export const greaterThanContainer =
  (size: 'large' | 'medium' | 'small') => (props: Themed<{}>) => {
    let minWidth: number;

    switch (size) {
      case 'large': {
        minWidth = props.theme.container.size.large;
        break;
      }
      case 'medium': {
        minWidth = props.theme.container.size.medium;
        break;
      }
      case 'small': {
        minWidth = props.theme.container.size.small;
        break;
      }
      default: {
        throw new Error(`Unexpected value ${size}`);
      }
    }

    return `screen and (min-width: ${minWidth}px)`;
  };
