import styled from '@emotion/styled';
import { HTMLProps } from 'react';

import { Block } from './Field.Block';

/**
 * Свойства компонента.
 */
type Props = Omit<HTMLProps<HTMLLabelElement>, 'size'> & {
  /**
   * Позиционирование текста
   */
  align?: 'left' | 'center';

  /**
   * Размер текста
   */
  size?: 'default' | 'large';
};

/**
 * Элемент для ошибки в поле ввода компонента Field.
 */
export const Error = styled.label<Props>`
  ${Block}

  color: ${({ theme }) => theme.colors.light.accent.error};

  margin-top: 0.125rem;
`;
