import { ComponentType, FC } from 'react';
import { observer } from 'mobx-react';

import { Store } from './Store';

/**
 * Свойства дочернего компонента.
 */
type InnerProps = {
  /**
   * Сумма займа (в копейках).
   */
  value: number;
};

/**
 * Свойства итогового компонента.
 */
type OuterProps<P extends InnerProps> = Omit<P, 'value'>;

/**
 * Возвращает обёртку над указанным компонентом `loan.ParamsAmount`, которая
 * заполняет его данными из текущей модели заявки.
 * @param Target Дочерний компонент `loan.ParamsAmount`.
 */
export const connectParamsAmount = <P extends InnerProps>(
  Target: ComponentType,
) => {
  const Result: FC<OuterProps<P>> = ({ ...props }) => {
    const store = Store.use();
    return <Target {...props} value={store.model.amount} />;
  };

  Result.displayName = `ConnectedParamsAmount(${
    Target.displayName || Target.name
  })`;

  return observer(Result);
};
