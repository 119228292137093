import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Documents as Element } from '../Documents';

/**
 * Компонент с документами.
 */
export const Documents = styled(Element)`
  margin-top: 4rem;

  @media ${lessThanContainer('small')} {
    margin-top: 3rem;
  }
`;
