import { ComponentPropsWithoutRef, FC } from 'react';

import { Content } from './Info.Content';
import { Title } from './Info.Title';
import { Root } from './Info.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок платежа.
   */
  title: string;

  /**
   * Информация о платеже.
   */
  content: string;
};

/**
 * Элемент блока информации по выбору платежа.
 */
export const Info: FC<Props> = ({ title, content, ...props }) => (
  <Root {...props}>
    <Title>{title}</Title>
    <Content>{content}</Content>
  </Root>
);
