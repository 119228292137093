import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Корневой элемент.
 */
export const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;

  font-size: 0.6875rem;

  background-color: ${({ theme }) => theme.color.secondary.main};
  border-color: ${({ theme }) => theme.color.border.main};
  color: ${({ theme }) => theme.color.text.main.great};

  transition: ${transition('border-color', 'color')};

  cursor: pointer;
  outline: none;

  :hover {
    border-color: ${({ theme }) => theme.color.text.main.main};
    color: ${({ theme }) => theme.color.text.main.main};
  }

  :active {
    border-color: ${({ theme }) => theme.color.border.main};
    color: ${({ theme }) => theme.color.text.main.great};
  }
`;
