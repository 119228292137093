/**
 * Возвращает свойства для компонента `Select`, позволяющие использовать
 * перечисления в качестве списка ключей для выпадающего списка.
 * @param enumeration Перечисление.
 * @param translations Значения для отображения.
 */
export const getEnumValues = <
  TEnumeration extends Record<string, TValue>,
  TValue extends string | number,
>(
  enumeration: TEnumeration,
  translations: Record<TValue, string>,
) => {
  const format = (key: TValue) => translations[key];
  const values = Object.values(enumeration);

  return {
    format,
    values,
  };
};
