import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Field, Select } from 'modules/common/ui';
import { Service } from 'modules/common/daData';
import { Address } from 'modules/common/daData/types';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Select>;

/**
 * Отображает поле ввода для поиска адреса организации.
 */
export const CompanyAddress: FC<Props> = ({ ...props }) => {
  const daData = Service.use();

  const suggestAddress = useCallback(
    (value: string) => (value === '' ? [] : daData.suggestAddress(value)),
    [daData],
  );

  const handleFormat = useCallback(
    (item) =>
      [item.region, item.city, item.street, item.house, item.flat]
        .filter(Boolean)
        .join(', '),
    [],
  );

  const handleUnique = useCallback(
    (value: Address) => Object.values(value).join('|'),
    [],
  );

  return (
    <Field
      label="Адрес организации"
      htmlFor="address"
      name={IncomeField.EMPLOYER_ADDRESS}
    >
      <Select
        {...props}
        id="address"
        name={IncomeField.EMPLOYER_ADDRESS}
        values={suggestAddress}
        format={handleFormat}
        unique={handleUnique}
        placeholder="Москва, ул. Тверская, д. 1"
        valuesOnly
      />
    </Field>
  );
};
