import { FC, ComponentPropsWithoutRef, useCallback } from 'react';

import { Field, Select } from 'modules/common/ui';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

/**
 * Отображает поле выбора числа до ближайшей зарплаты.
 */
export const Payment: FC<Props> = (props) => {
  const format = useCallback((value: number) => `${value} числа`, []);

  const values = (() => {
    const list: number[] = [];

    for (let i = 1; i <= 31; i += 1) {
      list.push(i);
    }

    return list;
  })();

  return (
    <Field
      htmlFor="day"
      label="Ближайшая выплата"
      name={IncomeField.SALARY_DAY}
    >
      <Select
        {...props}
        id={IncomeField.SALARY_DAY}
        name={IncomeField.SALARY_DAY}
        format={format}
        values={values}
        readOnly
      />
    </Field>
  );
};
