import * as yup from 'yup';

import { Field } from '../types';

/**
 * Схема формы Address.
 */
export const schema = yup.object().shape({
  /**
   * Адрес регистрации не может быть пустым.
   */
  [Field.REGISTRATION]: yup
    .object()
    .shape({
      city: yup.string().nullable(),
      street: yup.string().optional().nullable(),
      house: yup.string().nullable(),
    })
    .test('city', 'Укажите название населённого пункта', (values) =>
      Boolean(values?.city),
    )
    .test('street', 'Укажите название улицы', (values) =>
      Boolean(values?.street),
    )
    .test('house', 'Укажите номер дома', (values) => Boolean(values?.house))
    .typeError('Укажите адрес регистрации'),

  /**
   * Адрес проживания совпадает с регистрацией.
   */
  [Field.IS_SAME]: yup
    .boolean()
    .required('Выберите один из предложенных вариантов')
    .typeError('Выберите один из предложенных вариантов'),

  /**
   * Адрес проживания не может быть пустым.
   */
  [Field.LIVING]: yup.object().when('isSameAddress', {
    is: false,
    then: yup
      .object()
      .shape({
        city: yup.string().nullable(),
        street: yup.string().optional().nullable(),
        house: yup.string().nullable(),
      })
      .test('city', 'Укажите название населённого пункта', (values) =>
        Boolean(values?.city),
      )
      .test('street', 'Укажите название улицы', (values) =>
        Boolean(values?.street),
      )
      .test('house', 'Укажите номер дома', (values) => Boolean(values?.house))
      .typeError('Укажите адрес проживания'),
  }),
});
