/*
 * Тип метода оплаты.
 */
export enum PaymentMethodType {
  /**
   * Банковская карта.
   */
  TinkoffCard = 'card',

  /**
   * Кошелёк YooMoney.
   */
  YooMoneyWallet = 'yoo_money',

  /**
   * Кошелёк WebMoney.
   */
  WebMoneyWallet = 'webmoney',

  /**
   * Банковская карта через YooMoney.
   */
  YooMoneyCard = 'bank_card',

  /**
   * Сервис SberPay.
   */
  SberBank = 'sberbank',

  /**
   * Сервис Альфа клик.
   */
  AlfaBank = 'alfabank',
}
