import styled from '@emotion/styled';

/**
 * Обёртка для иконки.
 */
export const Icon = styled.div`
  margin-bottom: 0.875rem;

  color: ${({ theme }) => theme.colors.light.text.tertiary};
`;
