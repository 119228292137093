import { ComponentPropsWithoutRef, FC } from 'react';

import { Back } from './Back';
import { Root } from './NavigationTop.Root';

/**
 * Свойства компонента.
 */
export type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Номер шага, на котором используется блок. Есть три варианта: 1, 2, 3.
   */
  step: number;
  /**
   * Флаг указывает, что кнопки должны находиться в состоянии загрузки.
   */
  pending?: boolean;
  /**
   * Событие клика по кнопкам "Назад".
   */
  onBack?: () => void;
};

/**
 * Компонент "Навигации". Отображает кнопки "Назад" над формой заявки.
 */
export const NavigationTop: FC<Props> = ({
  step,
  pending = false,
  onBack = () => {},
  ...props
}) => {
  const isBack = step !== 1;

  return (
    <Root {...props}>
      {isBack && (
        <Back pending={pending} onClick={onBack}>
          Назад
        </Back>
      )}
    </Root>
  );
};
