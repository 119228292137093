import styled from '@emotion/styled';

/**
 * Компонент для отображение заголовка иконки футера.
 */
export const Title = styled.div`
  align-self: center;

  margin-top: 0.625rem;

  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
`;
