import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент для компонентов подвала страницы.
 */
export const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.light.separator};

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 4.375rem;

  padding: 3.75rem 0;

  @media ${lessThanContainer('large')} {
    gap: 3.75rem 3.125rem;
  }

  @media ${lessThanContainer('medium')} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${lessThanContainer('small')} {
    gap: 3.75rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);

    padding: 0 0 1.875rem;
  }
`;
