/**
 * Срок займа, отображающийся по умолчанию (в днях).
 */
export const DEFAULT_PERIOD = 15;

/**
 * Минимальный срок займа (в днях).
 */
export const MIN_PERIOD = 7;

/**
 * Максимальный срок займа (в днях).
 */
export const MAX_PERIOD = 30;
