/**
 * Тип услуги.
 */
export enum ServiceType {
  /**
   * Заём.
   */
  Loan = 'loan',

  /**
   * Клиентская услуга.
   */
  Option = 'option',
}
