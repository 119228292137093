import React, { ComponentPropsWithoutRef, FC } from 'react';
import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'> & {
  /**
   * Расчётная высота контента.
   */
  height?: number;
};

/**
 * Экранирование от лишних props
 */
const EscapedRoot: FC<Props> = ({ height, children, ...props }) => (
  <div {...props}>{children}</div>
);

/**
 * Корневой элемент компонента.
 */
export const Root = styled(EscapedRoot)`
  transition: ${transition('height')};

  height: ${({ height }) => height}px;

  overflow: hidden;
`;
