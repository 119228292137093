import styled from '@emotion/styled';

/**
 * Корневой элемент.
 */
export const Root = styled.div`
  background: ${({ theme }) => theme.color.background.inverse.main};

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 101;
`;
