import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';

import { Store } from '../Store';

/**
 * Инициирует загрузку модели клиента. Пока она не загружена, показывает
 * экран загрузки вместо своего содержимого.
 */
const Provider: FC = ({ children }) => {
  const store = Store.use();

  useEffect(() => {
    store.touch();
  });

  return store.ready ? <>{children}</> : <PageLoading />;
};

const component = observer(Provider);
export { component as Provider };
