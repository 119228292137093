import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент вложенного меню шапки.
 */
export const Root = styled.ul`
  display: none;

  position: absolute;
  left: -1.25rem;
  top: -1.25rem;
  z-index: 1;

  margin: 0;
  padding: 3rem 0 0.75rem;

  list-style: none;

  background-color: ${({ theme }) => theme.colors.light.background.primary};

  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.light.border.default};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);

  @media ${lessThanContainer('medium')} {
    display: block;

    position: relative;
    left: auto;
    top: auto;

    padding: 0;

    background-color: transparent;

    border-radius: 0;
    border: none;
    box-shadow: none;
  }
`;
