export const Yoomoney = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.4701 3.6665C10.8287 3.6665 7.13672 7.41124 7.13672 11.9998C7.13672 16.6412 10.8815 20.3332 15.4701 20.3332C20.0587 20.3332 23.8034 16.5884 23.8034 11.9998C23.8561 7.41124 20.0587 3.6665 15.4701 3.6665ZM15.4701 15.1116C13.7823 15.1116 12.3582 13.6876 12.3582 11.9998C12.3582 10.3121 13.7823 8.88804 15.4701 8.88804C17.1578 8.88804 18.5819 10.3121 18.5819 11.9998C18.5819 13.6876 17.2106 15.1116 15.4701 15.1116Z"
        fill="#8B3FFD"
      />
      <path
        d="M7.13974 6.09277V18.2236H4.1334L0.335938 6.09277H7.13974Z"
        fill="#8B3FFD"
      />
    </svg>
  );
};
