/**
 *  События метрик.
 */
export enum Event {
  /**
   * Клиент авторизовался.
   */
  LOGIN = 'login',

  /**
   * Клик по кнопке Личный кабинет.
   */
  ACCOUNT_BUTTON = 'accountButton',

  /**
   * Клиент нажал на кнопку калькулятора займов.
   * TODO: Нужно сравнить места вызова с событием APPLICATION_CALCULATOR_SUBMIT.
   */
  CALCULATOR_SUBMITTED = 'calculatorSubmitted',

  /**
   * Клик на галочке развернуть в окне параметров займа.
   */
  APPLICATION_CALCULATOR_OPEN = 'applicationCalculatorOpen',

  /**
   * Клиент заполнил поле сумма займа в параметрах займа на шаге анкеты.
   */
  APPLICATION_CALCULATOR_AMOUNT = 'applicationCalculatorAmount',

  /**
   * Клиент заполнил поле cрок займа в параметрах займа на шаге анкеты.
   */
  APPLICATION_CALCULATOR_PERIOD = 'applicationCalculatorPeriod',

  /**
   * Клиент сохранил параметры займа на шаге анкеты.
   * TODO: Нужно сравнить места вызова с событием CALCULATOR_SUBMITTED.
   */
  APPLICATION_CALCULATOR_SUBMIT = 'applicationCalculatorSubmit',

  /**
   * Клиент заполнил поле Почта.
   */
  APPLICATION_EMAIL = 'applicationEmail',

  /**
   * Клиент заполнил поле Почта.
   */
  APPLICATION_EMAIL_ERROR = 'applicationEmailError',

  /**
   * Клиент заполнил поле Фамилия.
   */
  APPLICATION_LAST_NAME = 'applicationLastName',

  /**
   * Клиент заполнил поле Имя.
   */
  APPLICATION_FIRST_NAME = 'applicationFirstName',

  /**
   * Клиент заполнил поле Отчество.
   */
  APPLICATION_SECOND_NAME = 'applicationSecondName',

  /**
   * Клиент заполнил поле Серия и номер паспорт.
   */
  APPLICATION_PASSPORT_NUMBER = 'applicationPassportNumber',

  /**
   * Клиент заполнил поле Дата рождения.
   */
  APPLICATION_BIRTH_DATE = 'applicationBirthDate',

  /**
   * Клиент заполнил поле Место рождения.
   */
  APPLICATION_BIRTH_PLACE = 'applicationBirthPlace',

  /**
   * Клиент заполнил поле Код подразделения.
   */
  APPLICATION_PASSPORT_CODE = 'applicationPassportCode',

  /**
   * Клиент заполнил поле Дата выдачи.
   */
  APPLICATION_PASSPORT_DATE = 'applicationPassportDate',

  /**
   * Клиент заполнил поле Дата выдачи. Появилась ошибка заполнения.
   */
  APPLICATION_PASSPORT_DATE_ERROR = 'applicationPassportDateError',

  /**
   * Клиент заполнил поле Кем выдан.
   */
  APPLICATION_PASSPORT_UNIT = 'applicationPassportUnit',

  /**
   * Клиент заполнил поле ИНН.
   */
  APPLICATION_PASSPORT_INN = 'applicationPassportInn',

  /**
   * Клиент заполнил поле ИНН. Появилась ошибка заполнения.
   */
  APPLICATION_PASSPORT_INN_ERROR = 'applicationPassportInnError',

  /**
   * Клиент приложил файл в поле Фото паспорта.
   */
  APPLICATION_PASSPORT_PHOTO = 'applicationPassportPhoto',

  /**
   * Клиент приложил файл в поле Фото паспорта. Появилась ошибка заполнения.
   */
  APPLICATION_PASSPORT_PHOTO_ERROR = 'applicationPassportPhotoError',

  /**
   * Клиент заполнил поле Адрес регистрации.
   */
  APPLICATION_ADDRESS_REGISTRATION = 'applicationAddressRegistration',

  /**
   * Клиент заполнил поле Адрес регистрации. Появилась ошибка заполнения.
   */
  APPLICATION_ADDRESS_REGISTRATION_ERROR = 'applicationAddressRegistrationError',

  /**
   * Клиент заполнил поле Адрес проживания совпадает с адресом регистрации.
   */
  APPLICATION_SAME_ADDRESS_TRUE = 'applicationSameAddressTrue',

  /**
   * Клиент заполнил поле Адрес проживания не совпадает с адресом регистрации.
   */
  APPLICATION_SAME_ADDRESS_FALSE = 'applicationSameAddressFalse',

  /**
   * Клиент заполнил поле Адрес проживания.
   */
  APPLICATION_ADDRESS_LIVING = 'applicationAddressLiving',

  /**
   * Клиент заполнил поле Адрес проживания. Появилась ошибка заполнения.
   */
  APPLICATION_ADDRESS_LIVING_ERROR = 'applicationAddressLivingError',

  /**
   * Клиент заполнил поле Семейное положение.
   */
  APPLICATION_FAMILY_STATUS = 'applicationFamilyStatus',

  /**
   * Клиент заполнил поле Кол-во членов семьи.
   */
  APPLICATION_FAMILY_SIZE = 'applicationFamilySize',

  /**
   * Клиент заполнил поле Кол-во детей до 18-ти.
   */
  APPLICATION_FAMILY_CHILDREN_COUNT = 'applicationFamilyChildrenCount',

  /**
   * Клиент заполнил поле Проживаю с родителями.
   */
  APPLICATION_FAMILY_WITH_PARENTS = 'applicationFamilyWithParents',

  /**
   * Клиент заполнил поле ФИО контактного лица.
   */
  APPLICATION_CONTACT_NAME = 'applicationContactName',

  /**
   * Клиент заполнил поле Кем приходится.
   */
  APPLICATION_CONTACT_TYPE = 'applicationContactType',

  /**
   * Клиент заполнил поле Телефон.
   */
  APPLICATION_CONTACT_PHONE = 'applicationContactPhone',

  /**
   * Клиент заполнил поле Телефон. Появилась ошибка заполнения.
   */
  APPLICATION_CONTACT_PHONE_ERROR = 'applicationContactPhoneError',

  /**
   * Клик по кнопке Добавить еще.
   */
  APPLICATION_CONTACT_ADD = 'applicationContactAdd',

  /**
   * Клиент заполнил поле Вид деятельности.
   */
  APPLICATION_WORK_STATUS = 'applicationWorkStatus',

  /**
   * Клиент заполнил поле Ежемесячный доход.
   */
  APPLICATION_MONTHLY_INCOME = 'applicationMonthlyIncome',

  /**
   * Клиент заполнил поле Место работы.
   */
  APPLICATION_COMPANY_NAME = 'applicationCompanyName',

  /**
   * Клиент заполнил поле Адрес организации.
   */
  APPLICATION_COMPANY_ADDRESS = 'applicationCompanyAddress',

  /**
   * Клиент заполнил поле Адрес организации. Появилась ошибка заполнения.
   */
  APPLICATION_COMPANY_ADDRESS_ERROR = 'applicationCompanyAddressError',

  /**
   * Клиент заполнил поле Занимаемая должность.
   */
  APPLICATION_COMPANY_POSITION = 'applicationCompanyPosition',

  /**
   * Клиент заполнил поле Номер телефона организации.
   */
  APPLICATION_COMPANY_PHONE = 'applicationCompanyPhone',

  /**
   * Клиент заполнил поле Номер телефона организации. Появилась ошибка заполнения.
   */
  APPLICATION_COMPANY_PHONE_ERROR = 'applicationCompanyPhoneError',

  /**
   * Клиент заполнил поле Ближайшая выплата.
   */
  APPLICATION_COMPANY_PAYMENT = 'applicationCompanyPayment',

  /**
   * Клик по кнопке Получить код.
   */
  APPLICATION_REQUEST_CODE = 'applicationRequestCode',

  /**
   * Клиент ввел код из SMS.
   */
  APPLICATION_CONFIRM_CODE = 'applicationConfirmCode',

  /**
   * Клиент ввел код из SMS. Появилась ошибка заполнения.
   */
  APPLICATION_CONFIRM_CODE_ERROR = 'applicationConfirmCodeError',

  /**
   * Клиент создал заявку
   */
  APPLICATION_CREATED = 'applicationCreated',

  /**
   * Клиент заполнил первый шаг анкеты.
   */
  APPLICATION_FIRST_STEP_SUBMITTED = 'applicationFirstStepSubmitted',

  /**
   * Клиент заполнил второй шаг анкеты.
   */
  APPLICATION_SECOND_STEP_SUBMITTED = 'applicationSecondStepSubmitted',

  /**
   * Клиент заполнил третий шаг анкеты.
   */
  APPLICATION_THIRD_STEP_SUBMITTED = 'applicationThirdStepSubmitted',

  /**
   * Клиенту одобрили заём.
   */
  APPLICATION_APPROVED = 'applicationApproved',

  /**
   * Клиенту отказали в займе.
   */
  APPLICATION_REJECTED = 'applicationRejected',

  /**
   * Клиент подписал заявку на займ.
   */
  APPLICATION_SIGNED = 'applicationSigned',

  /**
   * Клиент подписал договор.
   */
  CONTRACT_SIGNED = 'contractSigned',

  /**
   * Клиент выбрал карту, как способ оплаты.
   */
  LOAN_PAYOUT = 'loanPayout',

  /**
   * Клиент получил деньги.
   */
  LOAN_ISSUED = 'loanIssued',

  /**
   * Клиент выбирает способ оплаты.
   */
  LOAN_REPAY = 'loanRepay',

  /**
   * Клиент совершил минимальный платёж по активному займу.
   */
  LOAN_PAID = 'loanPaid',

  /**
   * Клиент закрыл заём.
   */
  LOAN_CLOSED = 'loanClosed',

  /**
   * Клиент перешел на заполнение заявления для отказа от услуги.
   */
  REFUSAL_OF_SERVICE_FORM = 'refusalOfServiceForm',

  /**
   * Клиент успешно отправил заявления для отказа от услуги.
   */
  REFUSAL_OF_SERVICE_SUCCESS = 'refusalOfServiceSuccess',
}
