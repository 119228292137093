import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Container } from 'modules/common/ui/components';

import { Root } from './PageHeader.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  isContainerMedium?: boolean;
};

/**
 * Компонент-обёртка заголовка страницы.
 */
export const PageHeader: FC<Props> = ({
  isContainerMedium,
  children,
  ...props
}) => {
  return (
    <Root {...props}>
      <Container size={isContainerMedium ? 'medium' : undefined}>
        {children}
      </Container>
    </Root>
  );
};
