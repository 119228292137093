import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './Spinner.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает иконку загрузки.
 */
export const Spinner: FC<Props> = ({ ...props }) => <Root {...props} />;
