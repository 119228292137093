import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Menu as Base } from './Menu';

/**
 * Компонент для отображение меню в части подвала страницы.
 */
export const Menu = styled(Base)`
  @media ${lessThanContainer('small')} {
    padding-top: 3.75rem;
  }
`;
