import React, {
  FC,
  ComponentProps,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import { RadioContext } from '../RadioContext';
import { Point } from '../Point';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentProps<typeof Point>,
  'type' | 'value' | 'checked' | 'onChange'
> & {
  /**
   * Значение, соответствующее данной кнопке.
   */
  value?: any;
};

let lastId: number = 0;

/**
 * Отображает радио-кнопку. Следует помнить, что радио-кнопки не работают вне
 * компонента `RadioGroup`.
 */
export const Radio: FC<Props> = ({ value, id, ...props }) => {
  const { selectedValue, selectValue, compare, name } =
    useContext(RadioContext);

  const index = useMemo(() => {
    lastId += 1;
    return lastId;
  }, []);

  const handleChange = useCallback(() => {
    selectValue(value);
  }, [selectValue, value]);

  const checked = compare(selectedValue, value);

  const nextId = id ?? `${name}${index}`;

  return (
    <Point
      {...props}
      type="radio"
      name={name}
      onChange={handleChange}
      checked={checked}
      id={nextId}
    />
  );
};
