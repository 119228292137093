import { Button } from 'modules/common/ui';

import { Root } from './ScreenAction.Root';
import { Root as Payment } from './ScreenAction.Payment';
import { Root as Message } from './ScreenAction.Message';

type Props = {
  payment: string;
  onActionClick: () => void;
};

export const ScreenAction = ({ payment, onActionClick }: Props) => {
  return (
    <Root>
      <Message>Настройте «Снижение платежа» - и забудьте о заботах.</Message>
      <Payment>{payment}</Payment>
      <Button onClick={onActionClick} variant="filled">
        Снизить мой платеж
      </Button>
    </Root>
  );
};
