import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Корневой элемент Button.
 */
export const Root = styled.button`
  display: grid;
  grid-template-areas:
    'title icon'
    'description icon';
  grid-gap: 0.625rem;
  justify-content: space-between;

  text-decoration: none;

  transition: ${transition('border-width, border-color')};

  border-style: solid;
  border-width: 1.5px;
  border-color: transparent;
  border-radius: 0.25rem;

  outline: 0;
  background-color: ${({ theme }) => theme.colors.light.background.secondary};

  cursor: pointer;

  padding: 1.25rem 1.5rem 1rem;

  :hover {
    border-color: ${({ theme }) => theme.colors.light.border.hover};
  }

  :focus {
    border-color: ${({ theme }) => theme.colors.light.accent.link};
  }
`;
