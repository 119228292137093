import React, { HTMLProps, forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';

/**
 * Свойства корневого элемента.
 */
export type RootProps = {
  /**
   * Указывает, что поле находится в фокусе.
   */
  focused?: boolean;

  /**
   * Указывает, что поле должно выглядеть так, словно на него наведён курсор.
   */
  hovered?: boolean;

  /**
   * Указывает, что поле заблокировано.
   */
  disabled?: boolean;

  /**
   * Указывает, что поле содержит ошибку.
   */
  invalid?: boolean;

  /**
   * Указывает, что поле провалидировано верно.
   */
  valid?: boolean;
};

/**
 * Экранирует свойства корневого элемента.
 */
const RootBase = forwardRef<
  HTMLDivElement,
  Omit<HTMLProps<HTMLDivElement>, 'ref'> & RootProps
>(({ disabled, focused, hovered, invalid, valid, ...props }, ref) => (
  <div {...props} ref={ref} />
));

/**
 * Корневой элемент компонента.
 */
export const Root = styled(RootBase)`
  position: relative;

  display: flex;
  align-items: center;

  transition: ${transition(
    'border-color',
    'box-shadow',
    'background-color',
    'color',
  )};

  background-color: ${({ theme }) => theme.colors.light.background.primary};
  box-shadow: none;
  border-radius: 0.75rem;
  border-color: ${({ theme }) => theme.colors.light.input.border.default};
  border-style: dashed;
  border-width: 2px;

  cursor: pointer;
  outline: none;

  ${({ invalid, theme }) =>
    invalid
      ? css`
          background-color: ${theme.colors.light.input.background.error};
          border-color: ${theme.colors.light.accent.error};
        `
      : null}

  ${({ valid, theme }) =>
    valid
      ? css`
          border-color: ${theme.colors.light.input.border.default};
        `
      : null}

  ${({ focused, theme }) =>
    focused
      ? css`
          background-color: ${theme.colors.light.input.background.default};
          border-color: ${theme.colors.light.input.border.focus} !important;
        `
      : null}

  ${({ hovered, theme }) =>
    hovered
      ? css`
          border-color: ${theme.colors.light.input.border.hover};
        `
      : null}

  ${({ disabled, theme }) =>
    disabled
      ? css`
          background-color: ${theme.colors.light.input.background.disabled};
          border-color: ${theme.colors.light.input.border.default};
          color: ${theme.colors.light.text.disabled};

          cursor: not-allowed;

          :hover {
            border-color: ${theme.colors.light.input.border.default} !important;
          }
        `
      : null}

  :hover {
    border-color: ${({ theme }) => theme.colors.light.input.border.hover};
  }
`;
