import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  flex: 1;
  min-height: 190px;
  padding: 24px 32px;

  border: 1px solid rgba(255, 255, 255, 0.4);
  border-right: none;
  border-radius: 8px 0 0 8px;

  background: linear-gradient(
      184deg,
      rgba(255, 255, 255, 0.1) 17.47%,
      rgba(0, 0, 0, 0) 97.16%
    ),
    rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);

  @media ${lessThanContainer('small')} {
    padding: 18px;

    border-radius: 0 0 8px 8px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-top: none;
  }
`;
