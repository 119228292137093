import * as yup from 'yup';

import { PhoneHelper } from 'modules/common/values/helpers';

import { Field } from '../types';
import { UserEmploymentStatus } from 'services/RpcService/types/UserEmploymentStatus';

/**
 * Схема формы информации о доходах.
 */
export const schema = yup.object().shape({
  /**
   * Тип занятости.
   */
  [Field.EMPLOYMENT]: yup.string().required('Укажите тип вашей занятости'),

  /**
   * Ежемесячный доход.
   */
  [Field.WAGE]: yup.string().required('Укажите размер ежемесячного дохода'),

  /**
   * Название рабочей организации.
   */
  [Field.EMPLOYER]: yup
    .object()
    .nullable()
    .when(Field.EMPLOYMENT, {
      is: UserEmploymentStatus.Employed,
      then: yup
        .object()
        .nullable()
        .shape({
          name: yup.string(),
        })
        .test('name', 'Укажите организацию, в которой вы работаете', (values) =>
          Boolean(values?.name),
        )
        .typeError('Укажите организацию, в которой вы работаете'),
    }),

  /**
   * Адрес рабочей организации.
   */
  [Field.EMPLOYER_ADDRESS]: yup
    .object()
    .nullable()
    .when(Field.EMPLOYMENT, {
      is: UserEmploymentStatus.Employed,
      then: yup
        .object()
        .nullable()
        .shape({
          city: yup.string().nullable(),
          street: yup.string().optional().nullable(),
          house: yup.string().nullable(),
        })
        .test('city', 'Укажите название населённого пункта', (values) =>
          Boolean(values?.city),
        )
        .test('house', 'Укажите номер дома', (values) => Boolean(values?.house))
        .typeError('Укажите адрес организации, в которой вы работаете'),
    }),

  /**
   * Занимаемая должность пользователя.
   */
  [Field.EMPLOYMENT_POSITION]: yup
    .string()
    .nullable()
    .when(Field.EMPLOYMENT, {
      is: UserEmploymentStatus.Employed,
      then: yup.string().nullable().required('Укажите занимаемую должность'),
    }),

  /**
   * Ближайшая выплата заработной платы.
   */
  [Field.SALARY_DAY]: yup
    .string()
    .nullable()
    .when(Field.EMPLOYMENT, {
      is: UserEmploymentStatus.Employed,
      then: yup.string().nullable().required('Укажите число выплаты зарплаты'),
    }),

  /**
   * Номер телефона организации.
   */
  [Field.EMPLOYER_PHONE]: yup
    .string()
    .nullable()
    .when(Field.EMPLOYMENT, {
      is: UserEmploymentStatus.Employed,
      then: yup
        .string()
        .nullable()
        .required('Укажите рабочий телефон организации')
        .matches(
          PhoneHelper.CONTACT_VALIDATION_REGEXP,
          'Неверный формат номера телефона',
        ),
    }),
});
