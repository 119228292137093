import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент деталей займа.
 */
export const Root = styled.div`
  @media ${lessThanContainer('small')} {
    width: 100%;
  }
`;
