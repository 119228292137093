import { ComponentPropsWithoutRef, FC } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../Store';

import { Item } from './Item';

import { Heading } from './Documents.Heading';
import { Root } from './Documents.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент отображающий список документов.
 * @todo Убрать из списка Памятку об оплате, когда бэк научится её отдавать.
 */
const Documents: FC<Props> = ({ ...props }) => {
  const { model } = Store.use();

  if (!model || !model.documents) {
    return null;
  }

  return (
    <Root {...props}>
      <Heading>Документы</Heading>
      {model.documents.map((document) => (
        <Item key={document.type} href={document?.href as string}>
          {document.name}
        </Item>
      ))}
      <Item href="/docs/payment-reminder.pdf" key="payment-reminder">
        Памятка об оплате
      </Item>
    </Root>
  );
};

const component = observer(Documents);
export { component as Documents };
