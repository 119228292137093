import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Обёртка для группы полей ввода калькулятора.
 */
export const Inputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 2rem;

  flex: 1 1 auto;

  @media ${lessThanContainer('small')} {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 2rem;
  }
`;
