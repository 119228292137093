import { LazyStore, reactive } from '@devim-front/store';
import { observable, action, computed } from 'mobx';

import { Page, Helper, Query } from 'modules/common/routing';
import { AmountHelper } from 'modules/common/values';

import {
  DEFAULT_AMOUNT,
  DEFAULT_PERIOD,
  MIN_AMOUNT,
  MAX_AMOUNT,
  MIN_PERIOD,
  MAX_PERIOD,
} from '../consts';

/**
 * Хранилище состояния калькулятора.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Выбранное значение суммы займа (в рублях).
   */
  @observable
  public amount: number = DEFAULT_AMOUNT;

  /**
   * Выбранное значение срока займа (в днях).
   */
  @observable
  public period: number = DEFAULT_PERIOD;

  /**
   * Ссылка на создание займа с текущими параметрами.
   */
  @computed
  public get getHref() {
    return (query: Query = {}) => {
      const headerReferer =
        typeof window === 'undefined' ? undefined : document.referrer;

      const searchReferer =
        query.referer === 'undefined' ? undefined : query.referer;

      const referer = searchReferer || headerReferer;

      return Helper.combineUrl(Page.APPLICATION_NEW, {
        ...query,
        referer,
        amount: String(this.amount),
        period: String(this.period),
      });
    };
  }

  /**
   * Нормализует указанное значение таким образом, чтобы оно оказалось в
   * заданных границах.
   * @param value Значение.
   * @param minValue Минимальное допустимое значение.
   * @param maxValue Максимально допустимое значение.
   */
  private normalizeValue = (
    value: number,
    minValue: number,
    maxValue: number,
  ) => Math.min(Math.max(minValue, value), maxValue);

  /**
   * Задаёт новое значение суммы займа.
   * @param amount Сумма займа.
   */
  @action
  public setAmount = (amount: number) => {
    let nextAmount = this.normalizeValue(amount, MIN_AMOUNT, MAX_AMOUNT);

    nextAmount =
      this.amount >= nextAmount
        ? AmountHelper.floor(nextAmount, -3)
        : AmountHelper.ceil(nextAmount, -3);

    this.amount = nextAmount;
  };

  /**
   * Задаёт новое значение срока займа.
   *
   * @param period Срок займа.
   */
  @action
  public setPeriod = (period: number) => {
    this.period = this.normalizeValue(period, MIN_PERIOD, MAX_PERIOD);
  };

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.amount = DEFAULT_AMOUNT;
    this.period = DEFAULT_PERIOD;
  }
}
