import styled from '@emotion/styled';

export const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1.5px solid #f2f2f2;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
`;

export const OverlayStyle = styled.div`
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background-color: rgba(0, 0, 0, 0.5);
`;
