import styled from '@emotion/styled';

import { Root as Base } from './Root';

/**
 * Корневой элемент компонента.
 */
export const Root = styled(Base)`
  position: relative;
`;
