import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент контейнер для текста в баннере.
 */
export const Content = styled.div`
  max-width: 20rem;

  @media ${lessThanContainer('small')} {
  }
`;
