import styled from '@emotion/styled';

/**
 * Описание. Маленький текст снизу.
 */
export const Text = styled.span`
  grid-area: description;

  font-style: normal;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 1rem;
  text-align: left;

  color: ${({ theme }) => theme.color.text.main.greater};
`;
