/**
 * Тип формы заявки.
 */
export enum ApplicationFormType {
  /**
   * Форма заявки на заём.
   */
  LoanApplicationForm = 'baseApplicationForm',

  /**
   * Форма заявки на отказ от услуг.
   */
  ServicePaymentRefund = 'refund_service_payment',

  /**
   * Форма заявки на юридическую поддержку.
   */
  LegalSupport = 'legalSupport',
}
