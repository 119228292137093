/**
 * Тип опции.
 */
export enum OptionType {
  /**
   * Реструктуризация займа.
   */
  Restructuring = 'restructuring',

  /**
   * Отказ от услуги.
   */
  ServicePaymentRefund = 'refund_service_payment',

  /**
   * Юридическая поддержка.
   */
  LegalSupport = 'legal_support',
}
