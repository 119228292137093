import { LazyStore, reactive } from '@devim-front/store';
import { observable, action, computed, runInAction } from 'mobx';

import { Store as RoutingStore, Page } from 'modules/common/routing';
import { TokensModel } from '../types';
import { Service } from '../services';
import { CompatService } from 'services/CompatService';
import { AuthTokens } from 'services/RpcService/types/AuthTokens';

/**
 * Хранилище состояния авторизации текущего пользователя.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Ссылка на сервис авторизации.
   */
  private get service() {
    return Service.get(this);
  }

  /**
   * Ссылка на сервис RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Экземпляр сервиса Routing.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Указывает, что экземпляр данного сервиса уже был инициализован.
   */
  @observable
  public initialized: boolean = false;

  /**
   * Токен, с помощью которого происходит обновление токена авторизации, когда
   * тот истекает.
   */
  @observable
  public refreshToken?: string = undefined;

  /**
   * Токен авторизации.
   */
  @observable
  public accessToken?: string = undefined;

  /**
   * Страница, на которую хотел попасть неавторизованный пользователь. После
   * процедуры авторизации его следует перенаправить на эту страницу, а
   * значение данного свойства очистить.
   */
  @observable
  public backUrl?: string = undefined;

  /**
   * Указывает, что текущий пользователь авторизован в системе.
   */
  @computed
  public get isAuthorized() {
    return Boolean(this.refreshToken);
  }

  /**
   * Указывает, что текущий пользователь не авторизован в системе.
   */
  @computed
  public get isNotAuthorized() {
    return !this.isAuthorized;
  }

  /**
   * Инициализирует экземпляр хранилища.
   */
  @action
  public inititalize = async () => {
    if (this.initialized) {
      return;
    }

    const storedTokens = this.service.readAuthTokens();
    this.backUrl = this.service.readBackUrl();

    if (storedTokens) {
      this.accessToken = storedTokens.accessToken;
      this.refreshToken = storedTokens.refreshToken;

      await this.rpc.authorize(storedTokens);
    }

    this.rpc.on('refreshToken', this.onRefreshTokens);

    runInAction(() => {
      this.initialized = true;
    });
  };

  /**
   * Задаёт значение свойства `backUrl` или очищает его, если было передано
   * `undefined`.
   *
   * Допустим, неавторизованный пользователь захотел попасть в раздел
   * `/application`, который доступен только после входа. В таком случае
   * нужно сохранить исходный адрес `/application` с помощью данного метода,
   * а после завершения авторизации воспользоваться этим сохранённым значением,
   * чтобы перенаправить пользователя на изначальный адрес.
   * @param backUrl Адрес страницы.
   */
  @action
  public setBackUrl = (backUrl?: string) => {
    this.backUrl = backUrl;
    this.service.saveBackUrl(this.backUrl);
  };

  /**
   * Задаёт пару токенов авторизации.
   * @param tokens Токены авторизации.
   */
  @action
  public setTokens = async (tokens?: TokensModel) => {
    this.refreshToken = tokens?.refreshToken;
    this.accessToken = tokens?.accessToken;

    if (tokens) {
      this.service.saveAuthTokens(tokens);
      await this.rpc.authorize({
        ...tokens,
      });
    } else {
      this.service.saveAuthTokens(undefined);
      this.rpc.deauthorize();
    }
  };

  /**
   * Перенаправляет пользователя на страницу "Аккаунт заблокирован".
   */
  @action
  public redirectToBlocked = async () => {
    this.setTokens(undefined);
    await this.routing.push(Page.ACCOUNT_UNAVAILABLE);

    await this.rpc.deauthorize();
  };

  /**
   * Событие, возникающее, когда сервис RPC автоматически обновил токены.
   */
  @action
  private onRefreshTokens = (tokens: AuthTokens) => {
    this.refreshToken = tokens.refreshToken;
    this.accessToken = tokens.accessToken;

    this.service.saveAuthTokens(tokens);
  };

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.initialized = false;
    this.refreshToken = undefined;
    this.setTokens(undefined);
    this.accessToken = undefined;
    this.backUrl = undefined;
    this.setBackUrl(undefined);
    this.rpc.deauthorize();
    this.rpc.off('refreshToken', this.onRefreshTokens);
  }
}
