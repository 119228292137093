import { FC, ComponentPropsWithoutRef } from 'react';
import { observer } from 'mobx-react';

import { PhoneHelper } from 'modules/common/values';
import { Nowrap } from 'modules/common/ui';

import { Field } from '../../../consts';
import { Store } from '../../../stores';

import { Root } from './Text.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'htmlFor'> & {
  /**
   * Хранилище состояния компонента.
   */
  store: Store;
};

/**
 * Отображает текст над формой кода подтверждения.
 */
const Text: FC<Props> = ({ store, ...props }) => {
  const { phone } = store;
  const value = phone ? PhoneHelper.format(phone) : '';

  return (
    <Root {...props} htmlFor={Field.CODE}>
      SMS с кодом будет отправлено на номер <Nowrap>{value}</Nowrap>
    </Root>
  );
};

const component = observer(Text);
export { component as Text };
