import { LazyStore, reactive } from '@devim-front/store';
import { observable, action } from 'mobx';

import {
  FETCHABLE_FETCH,
  FETCHABLE_CLEAR,
  applyFetchable,
} from 'modules/common/stores';
import { CompatService } from 'services/CompatService';
import { BankCard } from 'services/RpcService/types/BankCard';

/**
 * Хранилище состояния списка привязанных карт пользователя.
 */
@reactive
class CardsStore extends LazyStore {
  /**
   * Список моделей привязанных карт. Если свойство равно `undefined`, то
   * список ещё не был загружен с сервера.
   */
  @observable
  private unsafeModels?: BankCard[] = undefined;

  /**
   * Ссылка на сервис API.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Список моделей привязанных карт. Если он ещё не был загружен с сервера,
   * при обращении к свойству будет вызвано исключение.
   */
  public get models() {
    if (this.unsafeModels == null) {
      throw new Error(
        `payout.CardStore is not ready yet. Please use touch() method`,
      );
    }

    return this.unsafeModels;
  }

  /**
   * Задаёт свойству `models` новое значение.
   * @param models Новое значение.
   */
  @action
  private setModels = (models?: BankCard[]) => {
    this.unsafeModels = models;
  };

  /**
   * @inheritdoc
   */
  public async [FETCHABLE_FETCH]() {
    const models = await this.rpc.paymentGetCards();

    return () => {
      this.setModels(models);
    };
  }

  /**
   * @inheritdoc
   */
  public [FETCHABLE_CLEAR]() {
    this.setModels(undefined);
  }
}

const type = applyFetchable(CardsStore);

export { type as CardsStore };
