/**
 * Тип документа.
 */
export enum Type {
  /**
   * Договор на получение займа.
   */
  LOAN = 'loan',

  /**
   * Договор на подключение услуг по займу.
   */
  SERVICE = 'service_agreement',

  /**
   * График платежей по займу.
   */
  SCHEDULE = 'schedule',

  /**
   * Текстовый вариант заявки на займ.
   */
  APPLICATION = 'application',

  /**
   * Соглашение на реструктуризацию.
   */
  RESTRUCTURING = 'agreement_restructuring',

  /**
   * Соглашение на реструктуризацию.
   */
  RESTRUCTURING_SHEDULE = 'schedule_document',

  /**
   * Соглашение о способах и частоте взаимодействия с должником
   */
  AGREEMENT_FREQUENCY_INTERACTION = 'agreement_frequency_interaction',

  /**
   * Уведомление о взыскани просроченной задолженности
   */
  LOAN_COLLECTOR_NOTICE = 'loan_collector_notice',
}
