import styled from '@emotion/styled';

import { Adornment } from './FileInput.Adornment';

/**
 * Обёртка для дополнительного контента в конце поля ввода.
 */
export const EndAdornment = styled.div`
  ${Adornment}

  padding-right: 1.25rem;
`;
