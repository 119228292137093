import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Icon as Logo } from './Icon';

/**
 * Стили логотипа.
 */
export const Icon = styled(Logo)`
  vertical-align: top;
  max-width: 9.8125rem;

  @media ${lessThanContainer('medium')} {
    max-width: 7.25rem;
    max-height: 1.812rem;
  }
`;
