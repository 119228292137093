/**
 * Генерирует коллекцию первоначальных значений формы.
 * @param names Список названий полей.
 */
export const generateInitialValues = (names: string[]) => {
  let initialValues: Record<string, boolean> = {};
  const { length } = names;

  for (let i = 0; i < length; i += 1) {
    const name = names[i];
    initialValues = { ...initialValues, [name]: true };
  }

  return initialValues;
};
