import styled from '@emotion/styled';
import { ComponentPropsWithoutRef, FC } from 'react';
import { css } from '@emotion/react';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<'div'>, 'placeholder'> & {
  /**
   * Указывает, что данный текст - плейсхолдер.
   */
  placeholder: boolean;
};

/**
 * Экранирует свойства компонента.
 */
const EscapedText: FC<Props> = ({ placeholder, ...props }) => (
  <div {...props} />
);

/**
 * Текст в поле загрузки файла.
 */
export const Text = styled(EscapedText)`
  text-align: center;
  font-size: 0.875rem;

  ${({ placeholder, theme }) =>
    placeholder
      ? css`
          color: ${theme.colors.light.text.tertiary};
        `
      : css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          max-width: 100%;
        `}

  & > * {
    margin: 0;
  }
`;
