import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TextInput } from '../../TextInput';

/**
 * Корневой элемент компонента.
 */
export const Root = styled(TextInput)`
  cursor: text;

  ${(p) =>
    p.readOnly
      ? css`
          cursor: pointer;

          input {
            cursor: pointer;
          }
        `
      : null}

  ${(p) =>
    p.disabled
      ? css`
          cursor: not-allowed;
        `
      : null}
`;
