import { FC, SVGProps } from 'react';

/**
 * Иконка "Документ"
 */
export const Doc: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47035 2C5.28062 2 3.5 3.70603 3.5 5.81765V17.5824C3.5 19.694 5.28062 21.4 7.47035 21.4H16.6797C18.8694 21.4 20.65 19.694 20.65 17.5824V9.79265C20.65 9.1869 20.4268 8.60128 20.0209 8.13974L15.4167 2.90423C14.9118 2.33022 14.1708 2 13.391 2H7.47035ZM13.25 3.75294H7.47035C6.2693 3.75294 5.30116 4.68053 5.30116 5.81765V17.5824C5.30116 18.7195 6.2693 19.6471 7.47035 19.6471H16.6797C17.8807 19.6471 18.8488 18.7195 18.8488 17.5824V10.9H16.575C14.7387 10.9 13.25 9.41135 13.25 7.575V3.75294ZM15.025 7.95C15.025 8.59893 15.5511 9.125 16.2 9.125H18.5165L15.025 5.15485V7.95Z"
      fill="currentColor"
    />
  </svg>
);
