import { OptionParams } from '../types/OptionParams';
import { OptionType } from '../types/OptionType';
import { UserOption } from '../types/UserOption';
import { UserOptionParams } from '../types/UserOptionParams';
import { transformOption } from './transformOption';

/**
 * Преобразует ответ сервера в данные опции клиента.
 * @param data Ответ сервера.
 */
export function transformUserOption(data: any): UserOption<any> {
  const value = transformOption(data.option_dto);
  let params: any;

  switch (value?.type) {
    case OptionType.LegalSupport:
      params = transformUserOptionLegalSupport(data.params);
      break;

    case OptionType.ServicePaymentRefund:
      params = transformUserOptionServicePaymentRefund(data.params);
      break;

    default:
      params = undefined;
      break;
  }

  return {
    id: data.id,
    clientID: data.client_id,
    status: data.status,
    appliedAt: data.applied_at ? new Date(data.applied_at) : undefined,
    expiresAt: new Date(data.expired_at),
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    value,
    params,
  };
}

/**
 * Преобразует ответ сервера в данные опции реструктуризации.
 * @param data Ответ сервера.
 */
function transformUserOptionLegalSupport(
  data: any,
): UserOptionParams[keyof OptionParams] {
  return {
    priceAmount: data.price_amount,
  };
}

/**
 * Преобразует ответ сервера в данные опции отказа от услуг.
 * @param data Ответ сервера.
 */
function transformUserOptionServicePaymentRefund(
  data: any,
): UserOptionParams[keyof OptionParams] {
  return {
    loans: data.loans || undefined,
  };
}
