import { ComponentPropsWithoutRef, FC } from 'react';

import { useMenu } from '../MenuContext';

import { ButtonClose } from './ButtonClose';
import { Root } from './SideNav.Root';
import { Heading } from './SideNav.Heading';
import { MenuWrapper } from './SideNav.MenuWrapper';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Обертка меню шапки. Компонент нужен для показа меню в мобильной версии.
 */
export const SideNav: FC<Props> = ({ children, ...props }) => {
  const { open } = useMenu();

  return (
    <Root {...props} open={open}>
      <Heading>
        <ButtonClose />
      </Heading>
      <MenuWrapper>{children}</MenuWrapper>
    </Root>
  );
};
