import styled from '@emotion/styled';

import { lessThanContainer } from '../../functions';

/**
 * Коревой элемент компонента.
 */
export const Root = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @media ${lessThanContainer('small')} {
    grid-template-columns: 1fr;
  }
`;
