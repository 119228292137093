import styled from '@emotion/styled';

import { Amount as Base } from './Amount';

/**
 * Поле ввода суммы.
 */
export const Amount = styled(Base)`
  flex: 1 1 auto;
`;
