import styled from '@emotion/styled';

/**
 * Основная часть блока (сам кнопка).
 */
export const Main = styled.button`
  display: grid;
  grid-template-areas:
    'title icon'
    'description icon';
  grid-gap: 0.625rem;
  justify-content: space-between;

  width: 100%;

  padding: 1.25rem 1.5rem 1rem;

  outline: 0;
  border: 0;

  background-color: ${({ theme }) => theme.color.background.main.main};

  cursor: pointer;
`;
