import { Root as Skip } from './ScreenHeader.Skip';
import { Root as Title } from './ScreenHeader.Title';
import { Root } from './ScreenHeader.Root';

type Props = {
  onSkip: () => void;
};

export const ScreenHeader = ({ onSkip }: Props) => {
  return (
    <Root>
      <Title>Живите сейчас. Платите меньше.</Title>
      <Skip onClick={onSkip}>Пропустить</Skip>
    </Root>
  );
};
