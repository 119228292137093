import React, { ComponentPropsWithoutRef, FC } from 'react';

import { Hide } from './Hide';
import { Show } from './Show';

import { Root } from './Button.Root';

/*
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Root>,
  'children' | 'type'
> & {
  /**
   * Определяет видим ли пароль в данный момент.
   */
  visible?: boolean;
};

/**
 * Кнопка переключения режима отображения ПИН-кода.
 */
export const Button: FC<Props> = ({ visible, ...props }) => (
  <Root {...props} type="button">
    {visible ? <Hide /> : <Show />}
  </Root>
);
