/**
 * Источник созданной заявки на заём денежных средств.
 */
export enum Source {
  /**
   * Заявка создана в личной кабинете.
   */
  WEB = 'web',

  /**
   * Заявка создана на сервере автоматически.
   */
  AUTO = 'auto',
}
