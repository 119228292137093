import { FC } from 'react';
import Head from 'next/head';

import { PageHeader } from 'modules/common/layout';
import { Container } from 'modules/common/ui';
import { Section } from 'modules/application/common';
import {
  Form as AgreementsForm,
  Provider,
  Document,
} from 'modules/application/signing';
import { Form as IncomeForm } from 'modules/application/income';
import { Form as CreditObligationsForm } from 'modules/application/creditObligations';
import { Access } from 'modules/inn';

import { FormName } from '../../types';

import { Step } from '../Step';
import { StepForms } from '../StepForms';
import { StepAccess } from '../StepAccess';
import { PageContent } from '../PageContent';

import { ConfirmationForm } from './ConfirmationForm';
import { ApplicationCalculator } from '../ApplicationCalculator';

/**
 * Отображает содержимое страницы третьего шага заполнения заявки.
 */
export const ThirdStepPage: FC = () => (
  <StepAccess step={3}>
    <PageHeader isContainerMedium>
      <ApplicationCalculator step={3} />
    </PageHeader>
    <Provider>
      <Access>
        <PageContent>
          <Head>
            <title>Аполлон-займ - оформление заявки, шаг 3 из 3</title>
          </Head>
          <Container size="medium">
            <StepForms step={3}>
              <Section heading="Информация о доходе" aside={<Step>3</Step>}>
                <IncomeForm name={FormName.INCOME} />
              </Section>
              <Section heading="Выплаты по иным кредитам">
                <CreditObligationsForm name={FormName.CREDIT_OBLIGATIONS} />
              </Section>
              <Section heading="Подписание заявки">
                <AgreementsForm name={FormName.AGREEMENTS} />
              </Section>
              <ConfirmationForm />
              <Document />
            </StepForms>
          </Container>
        </PageContent>
      </Access>
    </Provider>
  </StepAccess>
);
