import { ComponentPropsWithoutRef, FC } from 'react';

import { RadioGroup, Radio, Field, useFormValue } from 'modules/common/ui';
import { FormItem } from 'modules/application/common';

import { Field as ResidenceField } from '../../types';

import { AddressInput } from '../AddressInput';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof RadioGroup>;

/**
 * Отображает поле выбора "адрес регистрации совпадает с проживанием".
 */
export const SameAddress: FC<Props> = ({ className, ...props }) => {
  const isSameAddress = useFormValue<boolean>(ResidenceField.IS_SAME);

  return (
    <>
      <FormItem>
        <Field
          htmlFor={ResidenceField.IS_SAME}
          name={ResidenceField.IS_SAME}
          className={className}
        >
          <RadioGroup
            {...props}
            heading="Адрес проживания совпадает с регистрацией"
            name={ResidenceField.IS_SAME}
          >
            <Radio value>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </RadioGroup>
        </Field>
      </FormItem>
      {isSameAddress === false && (
        <FormItem>
          <AddressInput name={ResidenceField.LIVING} label="Адрес проживания" />
        </FormItem>
      )}
    </>
  );
};
