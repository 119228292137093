import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './PageContent.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент обёртки основного содержимого страницы.
 */
export const PageContent: FC<Props> = ({ ...props }) => <Root {...props} />;
