import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Тело со списком документов компонента Info.
 */
export const Body = styled.div`
  padding-top: 1.25rem;

  @media ${lessThanContainer('small')} {
    padding-top: 0.75rem;
  }
`;
