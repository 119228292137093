import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Icon.Root';
import { Image } from './Icon.Image';
import { Title } from './Icon.Title';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заглавие иконки
   */
  title?: string;

  /**
   * URL иконки
   */
  src: string;

  /**
   * Альтернативный текст иконки
   */
  alt: string;

  /**
   * Ссылка
   */
  href?: string;
};

/**
 * Отображает иконку в подвале страницы.
 */
export const Icon: FC<Props> = ({ title, src, alt, href, ...props }) => (
  <Root {...props}>
    {href ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Image src={src} alt={alt} />
      </a>
    ) : (
      <Image src={src} alt={alt} />
    )}
    {title && <Title>{title}</Title>}
  </Root>
);
