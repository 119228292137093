import styled from '@emotion/styled';

/**
 * Заголовок.
 */
export const Heading = styled.span`
  grid-area: title;

  font-size: 1rem;
  text-align: left;

  color: ${({ theme }) => theme.colors.light.text.primary};
`;
