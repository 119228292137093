import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui/functions';

/**
 * Элемент отображающий подзаголовок.
 */
export const SubHeading = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  margin-top: 1rem;

  @media ${lessThanContainer('small')} {
    margin-bottom: 0.75rem;
  }
`;
