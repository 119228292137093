import { css, Themed } from '@emotion/react';

type Props = {
  /**
   * Позиционирование текста
   */
  align?: 'left' | 'center';

  /**
   * Размер текста
   */
  size?: 'default' | 'large';
};

/**
 * Общие стили для Field.
 */
export const Block = (props: Themed<Props>) => {
  const { size, align } = props;

  return css`
    display: block;

    font-size: ${size === 'large' ? 0.875 : 0.75}rem;
    text-align: ${align === 'center' ? 'center' : 'left'};
  `;
};
