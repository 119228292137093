import { ComponentPropsWithoutRef, FC } from 'react';
import styled from '@emotion/styled';

import { Root } from './Value.Root';
import { Heading } from './Value.Heading';
import { Text } from './Value.Text';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок информации по займу.
   */
  title?: string;
};

/**
 * Блок для отображения информации по займу.
 */
const Value: FC<Props> = ({ title, children, ...props }) => {
  return (
    <Root {...props}>
      <Heading>{title}</Heading>
      <Text>{children}</Text>
    </Root>
  );
};

const component = styled(Value)``;
export { component as Value };
