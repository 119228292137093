import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент отображающий заголовок баннера.
 */
export const Title = styled.h2`
  margin: 0;

  font-weight: 600;
  font-size: 1.625rem;
  line-height: 2.125rem;
  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    font-size: 1.1875rem;
    line-height: 1.4375rem;
  }
`;
