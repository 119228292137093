import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Info.Root';
import { Heading } from './Info.Heading';
import { SubHeading } from './Info.SubHeading';
import { Body } from './Info.Body';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает информацию по договору (исключая список документов).
 */
export const Info: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>
    <Heading>Информация по договору</Heading>
    <SubHeading>
      Пожалуйста, ознакомьтесь с пакетом документов, который для вас
      сформирован:
    </SubHeading>
    <Body>{children}</Body>
  </Root>
);
