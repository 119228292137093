import React, { FC, SVGProps } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGElement>, 'ref'> & {
  /**
   * Определяет, какого размера должен быть спиннер.
   * @default `default`
   */
  size?: 'default' | 'large';
};

/**
 * Экранированный корневой элемент.
 */
const EscapedRoot: FC<Props> = ({ size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99275 0.425726L7.17969 0L8.03143 2.37306L6.84449 2.79879C4.27332 3.72101 2.52205 6.16844 2.52205 8.94853C2.52205 12.5554 5.44281 15.4788 9.04498 15.4788C11.8381 15.4788 14.2944 13.705 15.203 11.1085L15.6194 9.9186L18 10.7511L17.5836 11.941C16.3238 15.5409 12.9186 18 9.04498 18C4.04923 18 0 13.9472 0 8.94853C0 5.09638 2.42692 1.70471 5.99275 0.425726Z"
      fill="currentColor"
    />
  </svg>
);

/**
 * Анимация вращения.
 */
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

/**
 * Возвращает размер иконки.
 */
const iconSize = ({ size = 'default' }: Props) =>
  size === 'default' ? '1rem' : '2rem';

/**
 * Корневой элемент компонента.
 */
export const Root = styled(EscapedRoot)`
  animation: ${rotation} 1s linear infinite;

  display: block;

  width: ${iconSize};
  height: ${iconSize};
`;
