import styled from '@emotion/styled';

import { Button as Base } from 'modules/common/ui';

/**
 * Кнопка в тексте.
 */
export const Button = styled(Base)`
  color: inherit;

  cursor: text !important;
`;
