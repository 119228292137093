import { Root } from './ScreenBody.Root';
import { ScreenAction } from './ScreenAction';
import { ScreenDescription } from './ScreenDescription';

type Props = {
  payment: string;
  onActionClick: () => void;
};

export const ScreenBody = ({ payment, onActionClick }: Props) => {
  return (
    <Root>
      <ScreenDescription />
      <ScreenAction payment={payment} onActionClick={onActionClick} />
    </Root>
  );
};
