import styled from '@emotion/styled';

import { LinkButton as Link } from 'modules/common/ui';
import { transition } from 'modules/common/theme';

/**
 * Ссылка элемента единицы хлебной крошки.
 */
export const LinkButton = styled(Link)`
  display: inline;

  padding: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 0.687rem;
  line-height: 1rem;

  color: inherit;

  border-style: none;

  transition: ${transition('opacity')};

  white-space: nowrap;

  :hover {
    color: inherit;
    opacity: 1;
  }
`;
