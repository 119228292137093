/**
 * Название поля ввода на форме паспортных данных.
 */
export enum Field {
  NUMBER = 'passportNumber',
  BIRTH_DATE = 'birthDate',
  BIRTH_PLACE = 'birthPlace',
  CODE = 'passportCode',
  DATE = 'passportDate',
  UNIT = 'passportUnit',
  INN_SNILS = 'innSnils',
  PHOTO = 'photo',
}
