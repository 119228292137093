import { LazyService } from '@devim-front/service';

import { Query } from '../types';

/**
 * Сервис, который предоставляет мехинизмы работы с UTM-метками.
 */
export class Service extends LazyService {
  /**
   * Ключ, по которому в сессию записывается сохранённое значение UTM-меток.
   */
  private SESSION_KEY = 'UTM_TO_FORWARD';

  /**
   * Устанавливает значение `utm` меток в `sessionStorage`.
   *
   * @param params Коллекция UTM-меток.
   */
  public setToSession(params: Query) {
    if (typeof window === 'undefined') {
      return;
    }

    if (Object.keys(params).length === 0 && params.constructor === Object) {
      return;
    }

    let values = params;

    if (this.getFromSession()) {
      values = { ...values, ...this.getFromSession() };
    }

    sessionStorage.setItem(this.SESSION_KEY, JSON.stringify(values));
  }

  /**
   * Возвращает коллекцию UTM-меток, сохранённую в `sessionStorage`. Если
   * в нём пусто, возвращает undefined;
   */
  public getFromSession() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const value = sessionStorage.getItem(this.SESSION_KEY);

    if (value == null) {
      return undefined;
    }

    const values = JSON.parse(value);
    return values;
  }
}
