import { ComponentPropsWithoutRef, FC, useCallback, useMemo } from 'react';

import { Field, Select, useFormValue } from 'modules/common/ui';
import { Service } from 'modules/common/daData';

import { Field as PassportField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'inputMode' | 'mask' | 'name'
> & {
  /**
   * Заголовок поля ввода.
   */
  label: string;

  /**
   * Имя поля ввода.
   */
  name: string;
};

/**
 * Отображает поле ввода кода подразделения.
 */
export const PassportCode: FC<Props> = ({ label, name, ...props }) => {
  const daData = Service.use();

  const passportUnit = useFormValue(PassportField.UNIT);

  const unit = useMemo(() => passportUnit || null, [passportUnit]);

  const suggestPassportCode = useCallback(
    (value: string) => {
      if (!value) {
        return [];
      }

      return unit == null
        ? daData.suggestPassportCode(value)
        : daData.suggestPassportCode(unit);
    },
    [unit, daData],
  );

  return (
    <Field label={label} name={name} htmlFor={name}>
      <Select
        {...props}
        id={name}
        name={name}
        values={suggestPassportCode}
        inputMode="numeric"
        mask="000-000"
        placeholder="100-200"
      />
    </Field>
  );
};
