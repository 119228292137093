import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui/functions';

import { Item as Base } from './Item';

/**
 * Блок отдельной цифры в поле ввода кода.
 */
export const Item = styled(Base)`
  @media ${lessThanContainer('xSmall')} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
