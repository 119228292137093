import { FC, SVGProps } from 'react';

import { useLayout } from '../../../LayoutContext';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGElement>, 'ref'>;

/**
 * Отображает иконку логотипа сайта.
 *
 * @param props Свойства компонента.
 */
export const Icon: FC<Props> = (props) => {
  const { layout } = useLayout();

  let fill = '#000515';
  let fillRub = 'url(#paint0_linear_8314_373)';

  if (layout === 'screen') {
    fill = 'white';
    fillRub = 'white';
  }

  return (
    <svg
      width="172"
      height="44"
      viewBox="0 0 172 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.1343 4.74889C64.0493 4.74889 63.9727 4.83397 63.9046 5.00413L59.3359 18.0213H55.5583L60.7142 3.4727C61.0034 2.65594 61.4458 2.06889 62.0414 1.71156C62.654 1.33721 63.3686 1.15004 64.1854 1.15004C65.0022 1.15004 65.7083 1.33721 66.3039 1.71156C66.9164 2.06889 67.3674 2.65594 67.6566 3.4727L72.8124 18.0213H69.0349L64.4406 5.00413C64.3726 4.83397 64.3045 4.74889 64.2364 4.74889H64.1343Z"
        fill={fill}
      />
      <path
        d="M74.3708 12.8399C74.3708 11.1894 74.8472 9.93873 75.8001 9.08793C76.77 8.22012 78.0972 7.78622 79.7818 7.78622H83.151C83.5593 7.78622 83.8997 7.64159 84.1719 7.35232C84.4612 7.06305 84.6058 6.71422 84.6058 6.30584C84.6058 5.89746 84.4612 5.54864 84.1719 5.25937C83.8997 4.95308 83.5678 4.79994 83.1765 4.79994H74.3708V1.22661H82.7426C83.6955 1.22661 84.5122 1.36273 85.1929 1.63499C85.8905 1.90724 86.4605 2.27308 86.903 2.73251C87.3454 3.19194 87.6687 3.72794 87.8729 4.34051C88.077 4.95308 88.1791 5.59968 88.1791 6.28032C88.1791 6.9099 88.077 7.53098 87.8729 8.14355C87.6857 8.73911 87.3709 9.27511 86.9285 9.75155C86.5031 10.228 85.9416 10.6194 85.2439 10.9256C84.5633 11.2149 83.7295 11.3596 82.7426 11.3596H79.2969C78.9055 11.3596 78.5822 11.5127 78.327 11.819C78.0717 12.1253 77.9441 12.4826 77.9441 12.891V18.0213H74.3708V12.8399Z"
        fill={fill}
      />
      <path
        d="M96.9611 4.79994C95.8211 4.79994 94.9703 5.1913 94.4087 5.97403C93.8472 6.73975 93.5665 7.9734 93.5665 9.67498C93.5665 12.8569 94.6895 14.4479 96.9356 14.4479H99.6411C101.887 14.4479 103.01 12.8569 103.01 9.67498C103.01 7.9734 102.729 6.73975 102.168 5.97403C101.606 5.1913 100.756 4.79994 99.6156 4.79994H96.9611ZM98.9009 1.22661C103.972 1.22661 106.533 4.0087 106.584 9.57289V9.64946C106.584 15.2307 104.031 18.0213 98.9265 18.0213H97.6503C92.5455 18.0213 89.9931 15.2307 89.9931 9.64946V9.57289C90.0272 6.7993 90.6908 4.71486 91.984 3.31956C93.2772 1.92426 95.1745 1.22661 97.6758 1.22661H98.9009Z"
        fill={fill}
      />
      <path
        d="M116.096 18.0213C114.854 18.0213 113.765 17.8511 112.829 17.5108C111.91 17.1705 111.136 16.6855 110.506 16.0559C109.894 15.4093 109.426 14.6351 109.102 13.7333C108.796 12.8314 108.643 11.819 108.643 10.6959V1.22661H112.216V10.7215C112.216 11.8956 112.539 12.8144 113.186 13.478C113.85 14.1246 114.794 14.4479 116.019 14.4479H121.277V18.0213H116.096Z"
        fill={fill}
      />
      <path
        d="M130.777 18.0213C129.535 18.0213 128.446 17.8511 127.51 17.5108C126.591 17.1705 125.817 16.6855 125.187 16.0559C124.575 15.4093 124.107 14.6351 123.783 13.7333C123.477 12.8314 123.324 11.819 123.324 10.6959V1.22661H126.897V10.7215C126.897 11.8956 127.221 12.8144 127.867 13.478C128.531 14.1246 129.475 14.4479 130.7 14.4479H135.958V18.0213H130.777Z"
        fill={fill}
      />
      <path
        d="M143.447 4.79994C142.307 4.79994 141.456 5.1913 140.895 5.97403C140.333 6.73975 140.053 7.9734 140.053 9.67498C140.053 12.8569 141.176 14.4479 143.422 14.4479H146.127C148.373 14.4479 149.496 12.8569 149.496 9.67498C149.496 7.9734 149.216 6.73975 148.654 5.97403C148.093 5.1913 147.242 4.79994 146.102 4.79994H143.447ZM145.387 1.22661C150.458 1.22661 153.019 4.0087 153.07 9.57289V9.64946C153.07 15.2307 150.517 18.0213 145.413 18.0213H144.136C139.032 18.0213 136.479 15.2307 136.479 9.64946V9.57289C136.513 6.7993 137.177 4.71486 138.47 3.31956C139.763 1.92426 141.661 1.22661 144.162 1.22661H145.387Z"
        fill={fill}
      />
      <path
        d="M172 13.9119C172 14.5585 171.881 15.1541 171.643 15.6986C171.422 16.2261 171.107 16.677 170.699 17.0514C170.29 17.4257 169.805 17.715 169.244 17.9192C168.699 18.1234 168.112 18.2255 167.483 18.2255C166.564 18.2255 165.73 17.9362 164.981 17.3576C164.25 16.7791 163.714 15.9794 163.373 14.9584L160.285 5.36146C160.217 4.93606 159.996 4.72337 159.621 4.72337C159.128 4.72337 158.881 5.06368 158.881 5.74432V18.0213H155.129V5.43803C155.129 4.79143 155.24 4.20438 155.461 3.67689C155.699 3.13238 156.022 2.67296 156.431 2.29861C156.839 1.92426 157.316 1.63499 157.86 1.4308C158.405 1.22661 159 1.12451 159.647 1.12451C160.566 1.12451 161.391 1.41378 162.123 1.99232C162.871 2.57086 163.416 3.37061 163.756 4.39156L166.844 13.9885C166.913 14.4139 167.159 14.6266 167.585 14.6266C168.027 14.6266 168.248 14.2863 168.248 13.6056V1.22661H172V13.9119Z"
        fill={fill}
      />
      <path
        d="M58.8873 40.8782H64.9717V42.8759H55.7788V41.417L61.7471 32.8209H55.7871V30.8066H64.839V32.2324L58.8873 40.8782Z"
        fill="#06D4FC"
      />
      <path
        d="M81.9648 40.3891H77.6046L76.7757 42.8759H74.1314L78.6242 30.8066H80.9286L85.4463 42.8759H82.802L81.9648 40.3891ZM78.276 38.3748H81.2934L79.7764 33.8571L78.276 38.3748Z"
        fill="#06D4FC"
      />
      <path
        d="M97.7062 42.8759H95.2194V30.8066H97.7062V42.8759Z"
        fill="#06D4FC"
      />
      <path
        d="M111.757 30.8066L114.857 39.5602L117.94 30.8066H121.206V42.8759H118.711V39.5767L118.96 33.882L115.702 42.8759H113.995L110.745 33.8903L110.994 39.5767V42.8759H108.507V30.8066H111.757Z"
        fill="#06D4FC"
      />
      <path
        d="M13.8293 3.31737C16.3974 2.14014 19.2541 1.48389 22.2641 1.48389C33.4556 1.48389 42.5282 10.5564 42.5282 21.748C42.5282 32.9395 33.4556 42.012 22.2641 42.012C11.0725 42.012 2 32.9395 2 21.748C2 17.8787 3.08445 14.2627 4.966 11.1873"
        stroke="url(#paint0_linear)"
        strokeWidth="2.15194"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2285 25.3406C37.5042 24.1881 37.6502 22.9852 37.6502 21.7482C37.6502 13.2505 30.7614 6.36182 22.2638 6.36182C13.7661 6.36182 6.87744 13.2505 6.87744 21.7482C6.87744 30.2458 13.7661 37.1345 22.2638 37.1345C25.1225 37.1345 27.799 36.3549 30.0925 34.9968"
        stroke="url(#paint1_linear)"
        strokeWidth="2.15194"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1096 24.9754C11.7863 23.9573 11.6118 22.8729 11.6118 21.7478C11.6118 15.8648 16.3809 11.0957 22.2639 11.0957C28.1469 11.0957 32.916 15.8648 32.916 21.7478C32.916 27.6308 28.1469 32.3999 22.2639 32.3999C20.8561 32.3999 19.512 32.1268 18.2818 31.6306"
        stroke="url(#paint2_linear)"
        strokeWidth="2.15194"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6706 28.2396V26.3799H17.2786V24.694H18.6706V23.3384H17.2786V21.1832H18.6706V15.8301H22.638C24.2389 15.8301 25.4047 16.1545 26.1356 16.8034C26.878 17.4522 27.2492 18.3444 27.2492 19.4799C27.2492 20.152 27.0984 20.7835 26.7968 21.3744C26.4952 21.9653 25.9906 22.4404 25.2829 22.7996C24.5869 23.1588 23.6356 23.3384 22.4292 23.3384H21.2981V24.694H23.9779V26.3799H21.2981V28.2396H18.6706ZM22.1682 21.1832C22.9106 21.1832 23.4964 21.0557 23.9257 20.8008C24.3665 20.5459 24.5869 20.1346 24.5869 19.5668C24.5869 18.5124 23.8909 17.9852 22.4988 17.9852H21.2981V21.1832H22.1682Z"
        fill={fillRub}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="22.2641"
          y1="1.48389"
          x2="39.4437"
          y2="36.5246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06F7F7" />
          <stop offset="1" stopColor="#007EFE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="22.2638"
          y1="6.36182"
          x2="37.6501"
          y2="37.1345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06F7F7" />
          <stop offset="1" stopColor="#007EFE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="22.2639"
          y1="11.0957"
          x2="32.916"
          y2="24.6888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06F7F7" />
          <stop offset="1" stopColor="#007EFE" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_8314_373"
          x1="4.59502"
          y1="0.384399"
          x2="10.4078"
          y2="6.39328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06F7F7" /> <stop offset="1" stopColor="#007EFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
