import React, { FC, SVGProps } from 'react';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGElement>, 'ref'>;

/**
 * Отображает иконку на кнопке.
 *
 * @param props Свойства компонента.
 */
export const Icon: FC<Props> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.9509 15.1922L17.9509 7.19216C17.5046 6.74584 16.781 6.74584 16.3347 7.19216C15.9227 7.60414 15.891 8.2524 16.2396 8.70074L16.3347 8.8084L22.3839 14.8574H6.85709C6.27099 14.8574 5.78794 15.2986 5.72192 15.867L5.71423 16.0003C5.71423 16.5864 6.15542 17.0694 6.72381 17.1354L6.85709 17.1431H22.3839L16.3347 23.1922C15.9227 23.6041 15.891 24.2524 16.2396 24.7007L16.3347 24.8084C16.7467 25.2204 17.3949 25.2521 17.8433 24.9035L17.9509 24.8084L25.9509 16.8084L26.0613 16.6806L26.1432 16.5535L26.2043 16.4247L26.2447 16.3043L26.2731 16.17L26.2811 16.1023L26.2855 15.9784L26.2811 15.8983L26.2625 15.7709L26.2288 15.6435L26.1786 15.5166L26.1188 15.4052L26.0425 15.2954C26.014 15.2592 25.9835 15.2247 25.9509 15.1922L17.9509 7.19216L25.9509 15.1922Z"
      fill="currentColor"
    />
  </svg>
);
