/**
 * Название пакета услуг.
 *
 * Займы классифицируются по продуктам. Каждый продукт имеет дополнительные
 * услуги (помимо выдачи денег). Эти услуги группируются в пакеты.
 */
export enum Package {
  /**
   * Пакет услуг для повторных клиентов.
   */
  REPEAT = 'Приоритет',

  /**
   * Пакет услуг для первичных клиентов.
   */
  FIRST = 'Комфортный',
}
