import styled from '@emotion/styled';

import { Period as Base } from './Period';

/**
 * Поле ввода срока.
 */
export const Period = styled(Base)`
  flex: 1 1 auto;
`;
