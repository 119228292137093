import { FC, ComponentPropsWithoutRef } from 'react';

import { Checkbox } from 'modules/common/ui';

import { CheckboxText } from './Item.CheckboxText';
import { Button } from './Item.Button';
import { Group } from './Item.Group';
import { Inner } from './Item.Inner';
import { Root } from './Item.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'name'> & {
  /**
   * Ссылка на документ.
   */
  href?: string;

  /**
   * Название документа.
   */
  text: string;

  /**
   * Имя чекбокса
   */
  name: string;
};

/**
 * Отображает компонент с документом и чекбоксом.
 */
export const Item: FC<Props> = ({ text, name, href, ...props }) => {
  return (
    <Root {...props}>
      <Inner>
        <Group>
          <Button href={href} target="_blank" rel="noreferrer">
            {text}
          </Button>
          <Checkbox
            name={name}
            direction="reverse"
            variant="solid"
            valid={false}
          >
            <CheckboxText>Я согласен(а)</CheckboxText>
          </Checkbox>
        </Group>
      </Inner>
    </Root>
  );
};
