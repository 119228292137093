import { createContext } from 'react';

/**
 * Значение контекста.
 */
type Value = {
  /**
   * Выбранное в данный момент значение.
   */
  selectedValue: any;

  /**
   * Делает указанное значение выбранным.
   * @param value Новое значение.
   */
  selectValue: (value: any) => void;

  /**
   * Функция, которая сравнивает два значения и возвращает `true`, если они
   * эквивалентны.
   * @param valueA Первое значение.
   * @param valueB Второе значение.
   */
  compare: (valueA: any, valueB: any) => boolean;

  /**
   * Уникальное название поля ввода на форме.
   */
  name?: string;
};

/**
 * Контекст группы радио-кнопок.
 */
export const RadioContext = createContext<Value>({
  selectedValue: undefined,
  selectValue: () => {},
  compare: () => false,
  name: undefined,
});
