import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, getEnumValues, Select } from 'modules/common/ui';
import { UserMonthlySalary } from 'services/RpcService/types/UserMonthlySalary';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

const recordProps = getEnumValues(UserMonthlySalary, {
  [UserMonthlySalary.LessThan10]: 'до 10 000 ₽',
  [UserMonthlySalary.From10To20]: '10 000 - 20 000 ₽',
  [UserMonthlySalary.From20To35]: '20 000 ₽ - 35 000 ₽',
  [UserMonthlySalary.From35To50]: '35 000 - 50 000 ₽',
  [UserMonthlySalary.MoreThan50]: 'более 50 000 ₽',
});

/**
 * Отображает поле выбора дохода пользователя.
 */
export const MonthlyIncome: FC<Props> = (props) => (
  <Field htmlFor="size" label="Ежемесячный доход" name={IncomeField.WAGE}>
    <Select
      {...props}
      {...recordProps}
      id="size"
      name={IncomeField.WAGE}
      readOnly
    />
  </Field>
);
