import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './AlertBanner.Root';
import { Title } from './AlertBanner.Title';
import { Description } from './AlertBanner.Description';
import { Content } from './AlertBanner.Content';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Баннер "Кредитные каникулы."
 */
export const AlertBanner: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Content>
      <Title>Вам активированы кредитные каникулы.</Title>
      <Description>
        Вы можете ознакомиться с пакетом документов, который для вас
        сформирован.
      </Description>
    </Content>
  </Root>
);
