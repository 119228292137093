import { ComponentPropsWithoutRef, FC } from 'react';
import { Checkbox as BaseCheckbox } from 'modules/common/ui';
import { ApplicationAgreement } from 'services/RpcService/types/ApplicationAgreement';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof BaseCheckbox>,
  'name' | 'valid' | 'children'
> & {
  /**
   * Модель соглашения, которому соответствует данный элемент.
   */
  model: ApplicationAgreement;
};

/**
 * Отображает отдельный чекбокс соглашения.
 */
export const Checkbox: FC<Props> = ({ model, children, ...props }) => (
  <BaseCheckbox {...props} name={model.type} id={model.type} valid={false}>
    {children}
  </BaseCheckbox>
);
