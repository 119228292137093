import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Text } from '../Text';

/**
 * Компонент для отображение дополнительной информации в части подвала страницы.
 */
export const Description = styled.div`
  grid-column: 2 / span 3;

  @media ${lessThanContainer('large')} {
    grid-column: 2 / span 3;
  }

  @media ${lessThanContainer('small')} {
    grid-column: auto;
    grid-row: auto;

    padding-top: 3.75rem;

    border-top: 1px solid ${({ theme }) => theme.colors.light.separator};
  }

  /**
   * Стилизация ссылок в тексте.
   */
  a {
    outline: none;
    color: inherit;
  }

  ${Text}:first-of-type {
    margin-top: 0;
  }
`;
