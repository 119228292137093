export enum ABGroups {
  groupA = 'A',
  groupB = 'B',
}

export class ABTestService {
  private static NOW_DATE = new Date(Date.now());
  private static DUE_DATE = new Date('2021-12-17');
  public static TEST_KEY = 'test_lk';

  /**
   *
   */
  public static init() {
    // если тест не активен
    if (!this.isActive()) return;
    // если тест активен, проверяем наличие группы
    if (this.hasGroup()) return;
    // если нет группы
    this.setGroup();
  }

  /**
   *
   */
  public static getGroup() {
    // если тест не активен
    if (!this.isActive()) {
      return undefined;
    }

    // если тест активен
    // проверяем наличие группы
    if (!this.hasGroup()) {
      return this.setGroup();
    }

    //если есть группа
    return this.readGroup();
  }

  public static isActive() {
    return this.NOW_DATE < this.DUE_DATE;
  }

  /**
   *
   */
  public static setGroup() {
    let group: ABGroups;
    group = Math.random() >= 0.1 ? ABGroups.groupA : ABGroups.groupB;
    window.localStorage.setItem(this.TEST_KEY, group);
    return group;
  }

  /**
   *
   */
  public static readGroup(): ABGroups {
    let group: ABGroups;
    const LSGroup = window.localStorage.getItem(this.TEST_KEY);
    group = LSGroup ? (LSGroup as ABGroups) : ABGroups.groupA;
    return group;
  }

  /**
   *
   */
  public static hasGroup() {
    return Boolean(window.localStorage.getItem(this.TEST_KEY));
  }
}
