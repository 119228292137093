/* eslint-disable camelcase */
import { LazyService } from '@devim-front/service';

import {
  Gender,
  Name,
  NameType,
  FullName,
  Address,
  CompanyName,
} from '../types';

/**
 * Значение, возвращаемое API на запрос имени.
 */
type FIOResponse = {
  /**
   * Результат поиска.
   */
  suggestions: Array<{
    /**
     * Полное значение ФИО.
     */
    value: string;

    /**
     * Данные имени.
     */
    data: {
      /**
       * Фамилия.
       */
      surname: string;

      /**
       * Имя.
       */
      name: string;

      /**
       * Отчество.
       */
      patronymic: string;

      /**
       * Пол.
       */
      gender: Gender;
    };
  }>;
};

/**
 * Значение, возвращаемое API на запрос адреса.
 */
type AddressResponse = {
  /**
   * Результат поиска.
   */
  suggestions: Array<{
    /**
     * Адрес одной строкой (полный, с индексом).
     */
    unrestricted_value: string;

    /**
     * Данные адреса.
     */
    data: {
      /**
       * Город.
       */
      city: string;

      /**
       * Улица.
       */
      street: string | null;

      /**
       * Дом.
       */
      house: string;

      /**
       * Квартира / офис
       */
      flat: string | null | undefined;

      /**
       * Город с типом
       */
      city_with_type: string | null;

      /**
       * Населенный пункт с типом
       */
      settlement_with_type: string | null;

      /**
       * Улица с типом
       */
      street_with_type: string | null;

      /**
       * Регион с типом.
       */
      region_with_type?: string;

      /**
       * Тип города.
       */
      city_type_full: string | null;

      /**
       * Тип региона.
       */
      region_type_full: string | null;

      /**
       * Корпус/строение.
       */
      block: string | null;

      /**
       * Тип корпуса/строения.
       */
      block_type: string | null;

      /**
       * Тип дома.
       */
      house_type: string | null;

      /**
       * Тип квартиры / офиса.
       */
      flat_type: string | null;
    };
  }>;
};

/**
 * Значение, возвращаемое API на запрос организации.
 */
type PartyResponse = {
  /**
   * Результат поиска.
   */
  suggestions: Array<{
    /**
     * Данные организации.
     */
    data: {
      /**
       * Данные по имени организации.
       */
      name: {
        /**
         * Полный тип записи названия организации.
         */
        full_with_opf: string;
      };

      /**
       * Уникальный ключ организации.
       */
      hid: string;
    };
  }>;
};

/**
 * Сервис, осуществляющий работу с данными DaData.
 */
export class Service extends LazyService {
  /**
   * Ключ авторизации для сервиса DaData.
   */
  private readonly ACCESS_TOKEN = process.env.NEXT_PUBLIC_DADATA;

  /**
   * Количество возвращаемых значений.
   */
  private readonly SUGGESTION_COUNT = 10;

  /**
   * Отправляет указанный запрос к конечной точке API DaData и возвращает
   * результат.
   * @param url Адрес API, с которого загружаются данные.
   * @param payload Данные, отправляемые на сервер.
   */
  private async fetch<TResponse = any>(url: string, payload: any) {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Token ${this.ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const { ok, status, statusText } = response;

    if (ok) {
      return response.json() as Promise<TResponse>;
    }

    throw new Error(`Request to ${url} has failed: ${status} ${statusText}`);
  }

  /**
   * Осуществляет поиск имён по заданному запросу.
   * @param query Значение строки для поиска имени.
   * @param type Тип имени.
   * @param gender Половая принадлежность имени.
   */
  public async suggestName(
    query: string,
    type: NameType,
    gender: Gender = 'UNKNOWN',
  ): Promise<Name[]> {
    const { suggestions } = await this.fetch<FIOResponse>(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio`,
      {
        query,
        gender,
        count: this.SUGGESTION_COUNT,
        parts: [type],
      },
    );

    const result = suggestions.map(
      ({ data: { gender: innerGender, name, surname, patronymic } }) => ({
        gender: innerGender,
        value: {
          NAME: name,
          SURNAME: surname,
          PATRONYMIC: patronymic,
        }[type],
        type,
      }),
    );

    return result;
  }

  /**
   * Осуществляет поиск адреса по заданному запросу.
   * @param query Значение строки для поиска адреса.
   */
  public async suggestAddress(query: string): Promise<Address[]> {
    const { suggestions } = await this.fetch<AddressResponse>(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
      {
        query,
        count: this.SUGGESTION_COUNT,
      },
    );

    const result = suggestions.map(({ data, unrestricted_value: id }) => {
      let { block, house, flat } = data;

      const city =
        data.city_with_type ?? data.settlement_with_type ?? data.city;
      const street = data.street_with_type ?? data.street ?? undefined;

      let region = data.region_with_type;

      if (region === city) {
        region = undefined;
      }

      if (data.city_type_full === data.region_type_full) {
        region = undefined;
      }

      if (block != null) {
        block = `${data.block_type} ${block}`;
      }

      if (house != null) {
        house = `${data.house_type} ${house}`;

        if (block != null) {
          house = `${house} ${block}`;
        }
      }

      if (flat != null) {
        flat = `${data.flat_type} ${flat}`;
      } else {
        flat = undefined;
      }

      return { id, region, city, street, house, flat };
    });

    return result;
  }

  /**
   * Возвращает модель адреса по его строковому представлению.
   * @param value Строка.
   */
  public async parseAddress(value: string) {
    const suggestions = await this.suggestAddress(value);

    const { length } = suggestions;

    for (let i = 0; i < length; i += 1) {
      const suggestion = suggestions[i];
      const text = [
        suggestion.region,
        suggestion.city,
        suggestion.street,
        suggestion.house,
        suggestion.flat,
      ]
        .filter(Boolean)
        .join(', ');

      if (value === text) {
        return suggestion;
      }
    }

    return suggestions[0];
  }

  /* Осуществляет поиск полного имени по заданному запросу.
   * @param query Значение строки для поиска.
   */
  public async suggestFullName(query: string): Promise<FullName[]> {
    const { suggestions } = await this.fetch<FIOResponse>(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio`,
      {
        query,
        count: 10,
        parts: ['SURNAME', 'NAME', 'PATRONYMIC'],
      },
    );

    const result = suggestions.reduce(
      (acc, { data: { name, patronymic, surname, gender } }) => {
        acc.push({
          first: name ?? undefined,
          second: patronymic ?? undefined,
          last: surname ?? undefined,
          gender: gender ?? 'UNKNOWN',
        });

        return acc;
      },
      [] as FullName[],
    );

    return result;
  }

  /* Выполняет поиск по кодам подразделений паспортов или организациям, их
   * выдавших, и возвращает список совпадений.
   *
   * @param query Строка для поиска.
   * @param select Извлекает нужную строку из ответа сервиса dadata.
   */
  private async suggestPassportUnit(
    query: string,
    select: (data: any) => string,
  ) {
    const { suggestions } = await this.fetch(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit`,
      {
        query,
        count: 20,
      },
    );

    const { length } = suggestions;
    const units = new Set<string>();

    for (let i = 0; i < length && units.size < 10; i += 1) {
      const { data } = suggestions[i];
      const unit = select(data);

      units.add(unit);
    }

    return Array.from(units);
  }

  /**
   * Выполняет поиск по кодам подразделений паспортов, и возвращает список
   * совпадений.
   *
   * @param query Строка для поиска.
   */
  public async suggestPassportCode(query: string) {
    return this.suggestPassportUnit(query, (data: any) => data.code);
  }

  /**
   * Выполняет поиск по наименованиям организаций, выдающих паспорта, и
   * ввозвращает список совпадений.
   *
   * @param query Строка для поиска.
   */
  public async suggestPassportSource(query: string) {
    return this.suggestPassportUnit(query, (data: any) => data.name);
  }

  /**
   * Осуществляет поиск названия организации по заданному запросу.
   * @param query Значение строки для поиска названия организации.
   */
  public async suggestCompanyName(query: string): Promise<CompanyName[]> {
    const { suggestions } = await this.fetch<PartyResponse>(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party`,
      {
        query,
        count: this.SUGGESTION_COUNT,
        status: ['ACTIVE'],
      },
    );

    const result = suggestions.map(({ data }) => {
      return { name: data.name.full_with_opf, hid: data.hid };
    });

    return result;
  }
}
