import { NextRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { Store } from '../../stores';
import { Service } from '../../services';

import { BackToProvider } from '../BackToProvider';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Маршрутизатор.
   */
  router: NextRouter;
};

/**
 * Регистрирует экземпляр роутера в хранилище.
 */
export const Provider: FC<Props> = ({ router, children }) => {
  const store = Store.use();
  const service = Service.use();

  useEffect(() => {
    store.setRouter(router);
  });

  service.setToSession(router.query);

  return <BackToProvider>{children}</BackToProvider>;
};
