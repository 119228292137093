import { Component, ContextType } from 'react';
import { FormikContext } from 'formik';

/**
 * Функция валидации формы. Возвращает `true`, если в форме нет ошибок.
 */
type Validate = () => Promise<boolean>;

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Вызывается при подключении компонента на форму и принимает на вход
   * функцию принудительной валидации формы.
   * @param validate Функция валидации формы.
   */
  onRegister?: (validate: Validate) => void;

  /**
   * Вызывается, когда данный компонент отмонтируется от формы.
   */
  onUnregister?: () => void;
};

/**
 * Служебный компонент, служащий для получения "вовне" метода принудительной
 * валидации формы, в которую он помещён. Данный метод отправляется в
 * обработчик свойства `onRegister` компонента.
 */
export class InferValidate extends Component<Props> {
  /**
   * @inheritdoc
   */
  public static contextType = FormikContext;

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    const { onRegister } = this.props;

    if (onRegister) {
      onRegister(this.validate);
    }
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    const { onUnregister } = this.props;

    if (onUnregister) {
      onUnregister();
    }
  }

  /**
   * Производит принудительную отправку формы и возвращает `true`, если форма
   * оказалась валидной. В противном случае возвращает `false`.
   */
  private validate = async () => {
    const { submitForm } = this.context as ContextType<typeof FormikContext>;
    await submitForm();

    const { isValid } = this.context;
    return isValid;
  };

  /**
   * @inheritdoc
   */
  public render() {
    return null;
  }
}
