import { FC, ComponentPropsWithoutRef } from 'react';

import { Init } from './InputGroup.Init';
import { Label } from './InputGroup.Label';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Init>, 'width'> & {
  /**
   * Ширина блока.
   * @default `full`
   */
  width?: 'full' | 'medium';

  /**
   * Заголовок группы.
   */
  label?: string;
};

/**
 * Компонент объеденяющий в блок несколько компонентов UI.
 */
export const InputGroup: FC<Props> = ({
  children,
  label,
  width = 'full',
  ...props
}) => (
  <div>
    {label !== undefined ? <Label>{label}</Label> : null}
    <Init {...props} width={width}>
      {children}
    </Init>
  </div>
);
