import { FC, ReactNode } from 'react';

import { Label } from './Field.Label';
import { Error } from './Field.Error';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Текст ошибки под полем ввода.
   */
  error?: ReactNode;

  /**
   * Текст над полем ввода.
   */
  label?: ReactNode;

  /**
   * Позиционирование текста
   */
  align?: 'left' | 'center';

  /**
   * Размер текста
   */
  size?: 'default' | 'large';

  /**
   * id поля ввода, для указания его в label
   */
  htmlFor: string;

  /**
   * Стили компонента.
   */
  className?: string;
};

/**
 * Компонент отображающий название поля ввода.
 */
export const Base: FC<Props> = ({ error, label, children, ...props }) => (
  <div {...props}>
    <Label {...props}>{label}</Label>
    {children}
    <Error {...props}>{error}</Error>
  </div>
);
