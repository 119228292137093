import { Option } from '../types/Option';
import { OptionParams } from '../types/OptionParams';
import { OptionType } from '../types/OptionType';

/**
 * Преобразует ответ сервера в данные опции займа.
 * @param data Ответ сервера.
 */
export function transformOption(data: any): Option<any> {
  let params: any;

  switch (data.type) {
    case OptionType.Restructuring:
      params = transformOptionRestructuring(data.params);
      break;

    default:
      params = undefined;
      break;
  }

  return {
    id: data.id,
    name: data.name,
    type: data.type,
    description: data.description,
    params,
  };
}

/**
 * Преобразует ответ сервера в данные опции реструктуризации.
 * @param data Ответ сервера.
 */
function transformOptionRestructuring(
  data: any,
): OptionParams[keyof OptionParams] {
  return {
    minRestructuringPaymentAmount: data.min_restructuring_payment_amount,
    nearestPaymentAmountBefore: data.nearest_payment_amount_before,
    nearestPaymentDate: new Date(data.nearest_payment_date),
    restructuringPaymentsCount: data.restructure_payments_count,
    restructuringPayments: (data.restructuring_payments as any[]).map(
      (payment) => ({
        dateFrom: new Date(payment.dateFrom),
        dateTo: new Date(payment.dateTo),
        percent: payment.percent,
        payment: {
          body: payment.payment.body,
          percent: payment.payment.percent,
          service: payment.payment.service,
          scheduledDate: new Date(payment.payment.scheduled_date),
        },
      }),
    ),
    schedule: (data.schedule as any[]).map((schedule) => ({
      body: schedule.body,
      percent: schedule.percent,
      scheduledDate: new Date(schedule.scheduled_date),
      service: schedule.service,
    })),
  };
}
