/**
 * Ошибка работы сервиса RPC.
 */
export abstract class BaseError extends Error {
  /**
   * Создает экземпляр ошибки с указанным текстом и служебным именем.
   *
   * @param name Программный идентификатор ошибки.
   * @param message Текст сообщения об ошибке.
   */
  public constructor(name: string, message: string) {
    super(message);
    this.name = name;

    const proto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, proto);
    } else {
      // @ts-ignore
      this.__proto__ = proto;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
