import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './FormItem.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'width'> & {
  /**
   * Ширина ячейки
   */
  width?: 'half' | 'full';
};

/**
 * Компонент каркаса формы на странице заявки
 */
export const FormItem: FC<Props> = ({ ...props }) => <Root {...props} />;
