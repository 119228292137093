import { FC, ComponentPropsWithoutRef } from 'react';

import { useLayout } from '../../../LayoutContext';

import { Root } from './Icon.Root';

type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает иконку кнопки открытия меню.
 */
export const Icon: FC<Props> = ({ ...props }) => {
  const { layout } = useLayout();

  let fill = layout === 'screen' ? '#fff' : '#000515';

  return (
    <Root
      width="24"
      height="25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Меню</title>
      <path
        d="M4 5.5h16a1 1 0 110 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2z"
        fill={fill}
      />
    </Root>
  );
};
