import { ComponentPropsWithoutRef, FC } from 'react';

import { useMenu } from '../../MenuContext';

import { Icon } from './Icon';
import { Root } from './ButtonClose.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Кнопка закрытия меню мобильной версии.
 */
export const ButtonClose: FC<Props> = ({ ...props }) => {
  const { setOpen } = useMenu();

  return (
    <Root type="button" onClick={() => setOpen(false)} {...props}>
      <Icon />
    </Root>
  );
};
