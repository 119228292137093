import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Item.Root';
import { Heading } from './Item.Heading';
import { Text } from './Item.Text';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок информации.
   */
  title?: string;
};

/**
 * Блок для отображения информации.
 */
export const Item: FC<Props> = ({ title, children, ...props }) => {
  return (
    <Root {...props}>
      <Heading>{title}</Heading>
      <Text>{children}</Text>
    </Root>
  );
};
