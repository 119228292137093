import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонет для имитации шапки. Должен содержать кнопку закрытия меню.
 */
export const Heading = styled.div`
  display: none;

  @media ${lessThanContainer('medium')} {
    display: flex;
    align-items: center;

    min-height: 3.375rem;

    background-color: ${({ theme }) => theme.colors.light.background.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.light.separator};
  }
`;
