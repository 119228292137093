import styled from '@emotion/styled';

import icon from './assets/fileIcon.svg';

/**
 * Группирует кнопку и чекбокс в Item.
 */
export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &:before {
    background-image: url(${icon});
    background-position: 50%;
    background-size: contain;

    display: block;
    height: 1.5rem;
    width: 1.5rem;

    flex: 0 0 auto;

    content: '';
  }
`;
