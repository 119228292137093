/**
 * Продукт, которым является заём. Все выданные займы компания классифицирует по
 * продуктам. В зависимости от продукта, которым является заём, у него могут
 * быть незначительные отклонения в user flow.
 */
export enum Product {
  /**
   * Заём для первичного клиента. Он же "Кредит Доверия 1" или "КД1".
   */
  FIRST = 'Кредит доверия',

  /**
   * Заём для повторного клиента. Он же "Кредит Доверия 2" или "КД2".
   */
  REPEAT = 'Кредит доверия 2.0',

  /**
   * Я вообще хз, как он выдаётся. Нужно читать доки.
   */
  SMART = 'Смарт',

  /**
   * Самый хреновый продукт с самыми хреновыми условиями. Обычно предлагается
   * клиенту, чтобы напугать его и подтолкнуть к взятию чего-нибудь более
   * адекватного.
   */
  MINI = 'Мини',
}
