import styled from '@emotion/styled';

import { Form } from 'modules/common/ui';

/**
 * Корневой элемент Documents.
 */
export const Root = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
`;
