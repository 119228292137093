import { AmountHelper } from 'modules/common/values';

/**
 * Сумма займа, отображающаяся по умолчанию.
 */
export const DEFAULT_AMOUNT = AmountHelper.create(8000);

/**
 * Минимальная сумма займа.
 */
export const MIN_AMOUNT = AmountHelper.create(1000);

/**
 * Максимальная сумма займа.
 */
export const MAX_AMOUNT = AmountHelper.create(20000);
