import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, TextInput } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof TextInput>, 'name'> & {
  /**
   * Имя поля ввода.
   */
  name: string;

  /**
   * Заголовок поля ввода.
   */
  label: string;
};

/**
 * Отображает поле ввода места рождения.
 */
export const BirthPlace: FC<Props> = ({ name, label, ...props }) => (
  <Field label={label} name={name} htmlFor={name}>
    <TextInput {...props} id={name} name={name} placeholder="г. Москва" trim />
  </Field>
);
