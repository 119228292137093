import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Корневой элемент Button.
 */
export const Root = styled.div`
  border-radius: 0.25rem;
  overflow: hidden;

  transition: ${transition('border-color', 'box-shadow')};
  box-shadow: none;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;

  :hover {
    border-color: ${({ theme }) => theme.color.info.main};
  }

  :focus {
    box-shadow: 0px 2px 16px rgba(67, 100, 192, 0.2);
  }
`;
