import { Helper as Base } from '@devim-front/helper';

/**
 * Содержит методы для работы с ПИН-кодом и одноразовыми SMS-кодами.
 */
export class PinHelper extends Base {
  /**
   * Регулярное выражение для проверки ПИН-кода.
   */
  public static VALIDATION_REGEXP = /^\d{4}$/;

  /**
   * Длина ПИН-кода.
   */
  public static PIN_LENGTH = 4;
}
