import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Блок текста.
 */
export const Text = styled.div`
  font-weight: bold;
  font-size: 0.94rem;

  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    text-align: right;
    font-size: 0.825rem;
  }
`;
