import { ComponentPropsWithoutRef, FC } from 'react';

import { Container, Nowrap } from 'modules/common/ui';

import { Text } from '../Text';

import { Root } from './Small.Root';
import { Content } from './Small.Content';
import { Description } from './Small.Description';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'layout'>;

/**
 * Отображает малый футер, который должен отображаться на страницах личного
 * кабинета.
 */
export const Small: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Container size="large" offset="small">
      <Content>
        <Description>
          <Text>
            Телефон для связи:{' '}
            <a href="tel:+74996499059">
              <Nowrap>+7 (499) 649-90-59</Nowrap>
            </a>
          </Text>
          <Text>
            Служба поддержки клиентов:{' '}
            <a href="mailto:support@apollon-zaym.ru">
              <Nowrap>support@apollon-zaym.ru</Nowrap>
            </a>
          </Text>
        </Description>
      </Content>
    </Container>
  </Root>
);
