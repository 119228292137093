import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заголовок компонента Info.
 */
export const Heading = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    font-size: 1.25rem;
  }
`;
