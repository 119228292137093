import { Helper as Base } from '@devim-front/helper';

/**
 * Содержит методы для работы с датами. Дата представлена встроенным объектом
 * `Date`.
 */
export class DateHelper extends Base {
  /**
   * Регулярное выражение для разбора даты в человекопонятном формате
   * (DD.MM.YYYY).
   */
  private static DISPLAY_REGEXP = /^(\d{2}).(\d{2}).(\d{4})$/;

  /**
   * Регулярное выражение для разбора даты в ISO-формате
   * ("YYYY-MM-DDThh:mm:ss+ZZ:00").
   */
  private static ISO_REGEXP =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})[+-](\d{2}):(\d{2})$/;

  /**
   * Возвращает объект даты с указанными параметрами.
   *
   * @param year Год.
   * @param month Номер месяца (нумерация начинается с единицы).
   * @param day День.
   */
  public static create(year: number, month: number, day: number) {
    return new Date(year, month - 1, day);
  }

  /**
   * Текущая дата.
   */
  public static get CURRENT() {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  /**
   * Возвращает год указанной даты.
   *
   * @param date Дата.
   */
  public static getYear(date: Date) {
    return date.getFullYear();
  }

  /**
   * Возвращает месяц указанной даты (нумерация начинается с единицы).
   *
   * @param date Дата.
   */
  public static getMonth(date: Date) {
    return date.getMonth() + 1;
  }

  /**
   * Возвращает день указанной даты.
   *
   * @param date Дата.
   */
  public static getDay(date: Date) {
    return date.getDate();
  }

  /**
   * Проверяет, является ли переданная строка записью даты в человекопонятном
   * формате (DD.MM.YYYY). Данный метод проверяет только соответствие формату,
   * но не корректность даты в целом. Например, для записей "00.00.0000" или
   * "31.02.1991" метод вернёт `true`.
   *
   * @param value Строка.
   */
  public static validateDisplayFormat(value: string) {
    return this.DISPLAY_REGEXP.test(value);
  }

  /**
   * Проверяет, является ли переданная строка записью даты в человекопонятном
   * формате (DD.MM.YYYY). Данный метод проверяет только соответствие формату,
   * но не корректность даты в целом. Например, для записей "00.00.0000" или
   * "31.02.1991" метод вернёт `true`.
   *
   * @param value Строка.
   */
  public static validateDisplayValue(value: string) {
    const match = value.match(this.DISPLAY_REGEXP);

    if (match == null) {
      throw new Error(`The string "${value}" cannot be parsed as a date`);
    }

    const month = Number(match[2]);
    const year = Number(match[3]);
    const day = Number(match[1]);

    const date = this.create(year, month, day);
    const dateMonth = this.getMonth(date);
    const dateYear = this.getYear(date);
    const dateDay = this.getDay(date);

    return month === dateMonth && year === dateYear && dateDay === day;
  }

  /**
   * Возвращает строковую интерпретацию числа нужной длины, добавляя в строку
   * ведущие нули.
   * @param number Число.
   * @param length Нужная длина строки.
   */
  private static prependByZero(number: number, length: number = 2) {
    let result: string = String(number);

    while (result.length < length) {
      result = `0${result}`;
    }

    return result;
  }

  /**
   * Преобразует дату в человекопонятный формат "ДД.ММ.ГГГГ".
   * @param date Дата.
   */
  public static formatDisplay(date: Date) {
    const month = this.prependByZero(this.getMonth(date));
    const year = this.prependByZero(this.getYear(date), 4);
    const day = this.prependByZero(this.getDay(date));

    return `${day}.${month}.${year}`;
  }

  /**
   * Отнимает от даты один день. Если дата в аргументе равна текущему дню,
   * то возвратит неизмененную дату.
   * @param date Дата.
   */
  public static yesterdayDisplay(date: Date) {
    const currentDate = new Date();

    if (
      this.getDay(currentDate) === this.getDay(date) &&
      this.getMonth(currentDate) === this.getMonth(date) &&
      this.getYear(currentDate) === this.getYear(date)
    ) {
      return this.formatDisplay(date);
    }

    const yesterday = new Date(date ?? new Date());
    yesterday.setDate(yesterday.getDate() - 1);

    return this.formatDisplay(yesterday);
  }

  /**
   * Возвращает объект даты, соответствующий строке в ISO-формате.
   *
   * @param value Строка.
   */
  public static parseIso(value: string) {
    const match = value.match(this.ISO_REGEXP);

    if (match == null) {
      throw new Error(`The value ${value} cannot be parsed as ISO date`);
    }

    const year = Number(match[1]);
    const month = Number(match[2]);
    const days = Number(match[3]);

    return this.create(year, month, days);
  }

  /**
   * Возвращает объект даты, соответствующий строке в человекопонятном формате
   * DD.MM.YYYY.
   *
   * @param value Строка.
   */
  public static parseDisplay(value: string) {
    const match = value.match(this.DISPLAY_REGEXP);

    if (match == null) {
      throw new Error(`The string "${value}" cannot be parsed as a date`);
    }

    const month = Number(match[2]);
    const year = Number(match[3]);
    const day = Number(match[1]);

    return this.create(year, month, day);
  }

  /**
   * Возвращает `0`, если указанные даты равны друг другу; `-1`, если первая
   * дата раньше второй, и `1` - если вторая раньше первой.
   *
   * @param dateA Первая дата.
   * @param dateB Вторая дата.
   */
  public static compare(dateA: Date, dateB: Date) {
    const diff = dateA.getTime() - dateB.getTime();

    if (diff === 0) {
      return 0;
    }

    return diff < 0 ? -1 : 1;
  }

  /**
   * Возвращает `true`, если первая дата раньше второй.
   *
   * @param dateA Первая дата.
   * @param dateB Вторая дата.
   */
  public static isBefore(dateA: Date, dateB: Date) {
    return this.compare(dateA, dateB) < 0;
  }

  /**
   * Возвращает `true`, если первая дата позже второй.
   *
   * @param dateA Первая дата.
   * @param dateB Вторая дата.
   */
  public static isAfter(dateA: Date, dateB: Date) {
    return this.compare(dateA, dateB) > 0;
  }

  /**
   * Возвращает количество милисекунд между указанными датами. Если вторая
   * дата раньше первой, то возвращаемое число будет отрицательным.
   *
   * @param dateA Первая дата.
   * @param dateB Вторая дата.
   */
  public static getMillisecondsBetween(dateA: Date, dateB: Date) {
    return dateB.getTime() - dateA.getTime();
  }

  /**
   * Возвращает количество полных лет между двумя датами. Если вторая дата
   * раньше первой, число будет отрицательным.
   *
   * @param dateA Первая дата.
   * @param dateB Вторая дата.
   */
  public static getYearsBetween(dateA: Date, dateB: Date) {
    const sign = this.compare(dateB, dateA);

    if (sign === 0) {
      return 0;
    }

    const valueA = sign > 0 ? dateA : dateB;
    const valueB = sign > 0 ? dateB : dateA;

    const monthA = this.getMonth(valueA);
    const monthB = this.getMonth(valueB);
    const yearA = this.getYear(valueA);
    const yearB = this.getYear(valueB);
    const dayA = this.getDay(valueA);
    const dayB = this.getDay(valueB);

    let diff = yearB - yearA;

    if ((monthA === monthB && dayA > dayB) || monthA > monthB) {
      diff -= 1;
    }

    return diff * sign;
  }

  /**
   * Возвращает новую дату, которая получилась путём добавления к указанной
   * дате переданного количества лет.
   *
   * @param date Исходная дата.
   * @param years Количество лет.
   */
  public static addYears(date: Date, years: number) {
    const next = new Date(date);
    next.setFullYear(this.getYear(date) + years);
    return next;
  }

  /**
   * Возвращает новую дату, которая получилась путём добавления к указанной
   * дате переданного количества дней.
   *
   * @param date Исходная дата.
   * @param days Количество дней.
   */
  public static addDays(date: Date, days: number) {
    const next = new Date(date);
    next.setDate(this.getDay(next) + days);
    return next;
  }

  /**
   * Возвращает дату для нужного количества лет начиная от сегодняшней.
   *
   * @param years Количество лет.
   */
  public static getBirthDate(years: number) {
    const currentDate = new Date();

    currentDate.setFullYear(currentDate.getFullYear() - years);
    return this.formatDisplay(currentDate);
  }
}
