import { ReactNode } from 'react';

/**
 * Вставляет в конец adornments ещё один элемент.
 * @param adornments значение свойства startAdornment или endAdornment.
 * @param node элемент, который должен быть добавлен в конец.
 */
export const appendAdornments = (adornments: ReactNode, node: ReactNode) => {
  if (!adornments) {
    return <>{node}</>;
  }

  return (
    <>
      {adornments}
      {node}
    </>
  );
};
