import { FC, ComponentPropsWithoutRef, useMemo, useEffect } from 'react';

import { RequestCode, ConfirmCode } from '../../types';
import { Store } from '../../stores';

import { Root } from './Confirmation.Root';
import { Text } from './Confirmation.Text';
import { Form } from './Confirmation.Form';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Функция, которая запрашивает отправку на телефон пользователя СМС к кодом
   * подтверждения.
   * @param phone Телефон, полученный в свойстве `phone`.
   */
  requestCode: RequestCode;

  /**
   * Функция, которая отправляет введённый код подтверждения на сервер.
   * @param code Код, который ввёл пользователь.
   * @param phone Телефон, полученный в свойстве `phone`.
   */
  confirmCode: ConfirmCode;

  /**
   * Номер телефона, на который должен быть отправлен код подтверждения.
   */
  phone: string;
};

/**
 * Отображает форму подтверждения действия через СМС.
 */
export const Confirmation: FC<Props> = ({
  requestCode,
  confirmCode,
  phone,
  ...props
}) => {
  const store = useMemo(() => new Store(), []);

  useEffect(() => {
    store.setParams(phone, requestCode, confirmCode);
    store.touch();
  }, [requestCode, confirmCode, phone, store]);

  useEffect(() => {
    return () => {
      store.clear();
    };
  }, [store]);

  return (
    <Root {...props}>
      <Text store={store} />
      <Form store={store} />
    </Root>
  );
};
