import { FC, ComponentPropsWithoutRef } from 'react';

import { Store as ClientStore } from 'modules/client';

import { Confirmation } from 'modules/confirmation';

import { Store } from '../../Store';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Confirmation>,
  'requestCode' | 'confirmCode' | 'phone'
>;

/**
 * Отображает форму ввода кода подтверждения.
 */
export const Form: FC<Props> = ({ ...props }) => {
  const client = ClientStore.use();
  const store = Store.use();

  return (
    <Confirmation
      {...props}
      requestCode={store.requestCode}
      confirmCode={store.confirmCode}
      phone={client.model.phoneNumber}
    />
  );
};
