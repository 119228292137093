export const Mir = () => (
  <svg
    width="44"
    height="16"
    viewBox="0 0 44 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7529 2V2.00521C11.7475 2.00521 10.0494 2 9.59641 3.57758C9.0605 5.42823 8.52138 7.278 7.97908 9.12689H7.65554C7.65554 9.12689 6.45862 5.04465 6.0382 3.57202C5.58525 1.99444 3.88175 2 3.88175 2H0V14H3.88175V6.87311H4.20529L6.46935 14H9.16491L11.4293 6.87867H11.7529V14H15.6346V2H11.7529ZM25.9317 2C25.9317 2 24.7945 2.09973 24.2608 3.25789L21.5109 9.12689H21.1873V2H17.3059V14H20.9721C20.9721 14 22.1633 13.8951 22.6971 12.7421L25.3926 6.87311H25.7162V14H29.5979V2H25.9317ZM31.3232 7.44993V14H35.205V10.1746H39.4099C41.2432 10.1746 42.7954 9.03759 43.3724 7.44993H31.3232Z"
      fill="#4DB45E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.4112 2H30.7793C31.2104 4.2847 32.9733 6.11872 35.2538 6.71605C35.7829 6.85541 36.3287 6.92599 36.8768 6.92593H43.5296C43.5884 6.65013 43.6173 6.3691 43.6157 6.08745C43.6161 3.82881 41.7346 2 39.4108 2H39.4112Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="30.7803"
        y1="4.46296"
        x2="43.6158"
        y2="4.46296"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3" stopColor="#01B4E6" />
        <stop offset="1" stopColor="#0890CE" />
      </linearGradient>
    </defs>
  </svg>
);
