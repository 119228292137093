import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента
 */
type Props = {
  isOpened: boolean;
};

/**
 * Стили для текста над кальулятором в анкете
 */
export const Root = styled.div<Props>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};
  user-select: none;

  position: relative;
  width: 100%;

  cursor: pointer;

  @media ${lessThanContainer('small')} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;
