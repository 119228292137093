import { createContext, FC, useState, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

/**
 * Значение контекста.
 */
type Value = {
  /**
   * Указывает, что выпадающая область должна быть развёрнута.
   */
  expanded: boolean;

  /**
   * Переключает значение свойства `expanded` на противоположное.
   */
  toggleExpanded: () => void;
};

/**
 * Контекст выпадающей области.
 */
export const Context = createContext<Value>({
  expanded: false,
  toggleExpanded: () => {},
});

/**
 * Предоставляет контекст, в котором содержится состояния выпадающей области.
 */
export const Provider: FC = ({ children }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { isValid, submitCount } = useFormikContext();

  const toggleExpanded = useCallback(() => setExpanded((value) => !value), []);

  const isKeepExpanded = submitCount > 0 && !isValid;

  const value: Value = useMemo(
    () => ({
      toggleExpanded,
      expanded: isKeepExpanded || expanded,
    }),
    [toggleExpanded, expanded, isKeepExpanded],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
