import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './ChangeParams.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Кнопка "Изменить условия".
 */
export const ChangeParams: FC<Props> = (props) => (
  <Root {...props}>Изменить условия</Root>
);
