import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент.
 */
export const Root = styled.div`
  display: grid;
  gap: 10px;

  align-items: center;

  @media ${lessThanContainer('small')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
