import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding-bottom: 36px;

  @media ${lessThanContainer('small')} {
    padding: 12px 0 24px 0;
  }
`;
