import {
  ComponentPropsWithoutRef,
  FC,
  Children,
  ContextType,
  useMemo,
  useCallback,
} from 'react';

import { withFormControl } from '../../hocs';

import { RadioContext } from '../RadioContext';

import { Root, Box } from './RadioGroup.Root';
import { Text } from './RadioGroup.Text';

/**
 * Тип контекста.
 */
type Value = ContextType<typeof RadioContext>;

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Текст заголовка над блоком радио-кнопок.
   */
  heading?: string;

  /**
   * Уникальное название вложенных радио-кнопок на форме.
   */
  name?: string;

  /**
   * Текущее значение группы радио-кнопок.
   */
  value?: any;

  /**
   * Обрабатывает изменение текущего значения группы радио-кнопок.
   * @param value Новое значение.
   */
  onChange?: (value?: any) => void;

  /**
   * Сравнивает указанные значения и возвращает `true`, если они равны.
   * @param valueA Первое значение.
   * @param valueB Второе значение.
   */
  compare?: (valueA?: any, valueB?: any) => boolean;
};

/**
 * Функция сравнения значений по умолчанию.
 * @param valueA Первое значение.
 * @param valueB Второе значение.
 */
const defaultCompare = (valueA?: any, valueB?: any) => valueA === valueB;

/**
 * Компонент для группировки радио-кнопок.
 */
const RadioGroup: FC<Props> = ({
  onChange,
  compare = defaultCompare,
  heading,
  children,
  value,
  name,
  ...props
}) => {
  const isChildren = Boolean(children) && Children.count(children) > 0;

  const selectValue = useCallback(
    (nextValue: any) => {
      if (onChange) {
        onChange(nextValue);
      }
    },
    [onChange],
  );

  const context: Value = useMemo(
    () => ({
      selectValue,
      compare,
      selectedValue: value,
      name,
    }),
    [selectValue, compare, value, name],
  );

  return (
    <Root {...props}>
      <RadioContext.Provider value={context}>
        {heading && <Text>{heading}</Text>}
        {isChildren && <Box>{children}</Box>}
      </RadioContext.Provider>
    </Root>
  );
};

const component = withFormControl(undefined)(RadioGroup);
export { component as RadioGroup };
