import { BaseError } from './BaseError';

/**
 * Ошибка обработки данных ответа сервера.
 */
export class BadResponseError extends BaseError {
  /**
   * Ответ внешнего сервера.
   */
  public readonly response: any;

  /**
   * Создаёт экземпляр ошибки данных ответа сервера.
   * @param response Данные ответа сервера.
   */
  constructor(response: any) {
    super('E_BAD_RESPONSE', 'Invalid server response');

    this.response = response;
  }
}
