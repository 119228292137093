import styled from '@emotion/styled';

/**
 * Элемент отображающий подвал контента.
 */
export const Footer = styled.div`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  margin-top: 1.75rem;
`;
