import { Global as Base, css, useTheme } from '@emotion/react';
import { FC } from 'react';

/**
 * Добавляет на страницу глобальные стили для инвертированной цветовой схемы.
 */
export const Inverse: FC = () => {
  const theme = useTheme();

  return (
    <Base
      styles={css`
        body {
          background-color: ${theme.colors.light.background.primary};
          color: ${theme.color.text.inverse.main};
        }
      `}
    />
  );
};
