import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент заглавия страницы.
 */
export const PageTitle = styled.h1`
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.5rem;

  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;
