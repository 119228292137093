import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Page } from 'modules/common/routing';

import { Nav } from './Breadcrumb.Nav';
import { List } from './List';
import { Item } from './Item';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Nav>;

/**
 * Компонент хлебной крошки.
 */
export const Breadcrumb: FC<Props> = ({ children, ...props }) => {
  return (
    <Nav {...props}>
      <List>
        <Item position="1" href={Page.HOME}>
          Главная
        </Item>
        {children}
      </List>
    </Nav>
  );
};
