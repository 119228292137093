import * as yup from 'yup';

import { Field } from '../types';

/**
 * Схема формы Выплаты по иным кредитам.
 */
export const schema = yup.object().shape({
  [Field.CREDIT_OBLIGATIONS_HAS_UNPAID]: yup
    .boolean()
    .required('Выберите один из предложенных вариантов')
    .typeError('Выберите один из предложенных вариантов'),
  [Field.CREDIT_OBLIGATIONS_AVERAGE_PAYMENT_AMOUNT]: yup.number(),
  [Field.CREDIT_OBLIGATIONS_PAYMENTS_PER_MONTH]: yup.number(),
});
