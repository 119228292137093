/**
 * Статус займа (обозначает этап его жизненного цикла).
 */
export enum Status {
  /**
   * Заём ещё не создан (например, пользователь ещё заполняет заявку или вообще
   * только что зашел на сайт).
   */
  NONE = 'none',

  /**
   * Заём создан и находится на стадии подписания договора.
   */
  SIGNING = 'SIGNING',

  /**
   * Пользователь подписал договор на заём, но ещё не инициировал выдачу денег.
   */
  SIGNED = 'signed',

  /**
   * Договор на заём подписан, клиент инициировал выдачу денег, и в данный
   * момент они идут клиенту.
   */
  PAYOUT = 'payout',

  /**
   * Деньги были выданы, клиент может гасить займ. Так называемый, активный
   * заём.
   */
  ACTIVE = 'active',

  /**
   * Клиент рассчитался с компанией полностью, займ закрыт.
   */
  CLOSED = 'closed',
}
