import { css } from '@emotion/react';

/**
 * Предоставляет стили для абстрактного укашения поля ввода: `startAdornment`
 * или `endAdornment`.
 */
export const Adornment = css`
  align-self: stretch;
  flex: 0 0 auto;

  display: flex;
  align-items: center;

  padding-bottom: 0.25rem;
  padding-top: 0.25rem;

  & > * + * {
    margin-left: 0.25rem;
  }
`;
