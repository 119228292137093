import { useRouter } from 'next/router';

import { Page } from 'modules/common/routing';
import { PageContent } from 'modules/common/layout';
import { Container } from 'modules/common/ui';

import { Store } from '../Store';

import { ScreenHeader } from './ScreenHeader';
import { ScreenBody } from './ScreenBody';
import { Root } from './Screen.Root';
import { Helper } from '../Helper';

export const Screen = () => {
  const store = Store.use();
  const router = useRouter();

  const payment = Helper.formatPayment(
    store.model?.params.nearestPaymentAmountBefore,
    store.model?.params.minRestructuringPaymentAmount,
  );

  return (
    <PageContent offset="none" height="full">
      <Root>
        <Container size="medium">
          <ScreenHeader onSkip={() => store.hideScreen()} />
          <ScreenBody
            payment={payment}
            onActionClick={() => router.push(Page.RESTRUCTURE)}
          />
        </Container>
      </Root>
    </PageContent>
  );
};
