import { FC, SVGProps } from 'react';

/**
 * Иконка "Стрелки влево"
 */
export const ArrowLeft: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="9"
    height="8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.9 3.5l2.45-2.45a.495.495 0 10-.7-.7L.353 3.646a.5.5 0 000 .708L3.65 7.65a.495.495 0 10.7-.7L1.9 4.5H8a.5.5 0 100-1H1.9z"
      fill="currentColor"
    />
  </svg>
);
