import { Helper as Base } from '@devim-front/helper';

import { AmountHelper } from 'modules/common/values';
/**
 * Содержит метод работы с значениями платежа в реструктуризации.
 */
export class Helper extends Base {
  /**
   * Округляет денежную сумму до рублей по правилам математики и
   * преобразует её в строку в человекочитаемом формате "10 000" и добавляет знак валюты.
   * @param amount Сумма в копейках.
   */
  public static formatDisplayInteger(amount?: number) {
    return `${AmountHelper.formatDisplayInteger(
      amount ?? 0,
    )} ${AmountHelper.getShortUnits()}`;
  }

  /**
   * Принимает два параметра платежей, и формирует строку для отображения изменений
   * в реструктуризации платежа.
   * @param oldPayment Сумма платежа до реструктуризации.
   * @param newPayment Сумма платежа после реструктуризации.
   */
  public static formatPayment(oldPayment?: number, newPayment?: number) {
    const oldAmount = this.formatDisplayInteger(oldPayment);
    const newAmount = this.formatDisplayInteger(newPayment);
    return `${oldAmount} → ${newAmount}`;
  }
}
