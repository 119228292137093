import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, getEnumValues, Select } from 'modules/common/ui';
import { UserEmploymentStatus } from 'services/RpcService/types/UserEmploymentStatus';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

const recordProps = getEnumValues(UserEmploymentStatus, {
  [UserEmploymentStatus.Unemployed]: 'Не работаю',
  [UserEmploymentStatus.Employed]: 'Работаю',
  [UserEmploymentStatus.Retired]: 'Пенсионер',
});

/**
 * Отображает поле выбора вида деятельности.
 */
export const WorkStatus: FC<Props> = (props) => (
  <Field htmlFor="type" label="Вид деятельности" name={IncomeField.EMPLOYMENT}>
    <Select
      {...props}
      {...recordProps}
      id="type"
      name={IncomeField.EMPLOYMENT}
      readOnly
    />
  </Field>
);
