import React, { ComponentPropsWithoutRef, FC } from 'react';

import { Type } from '../../consts';

import { Transition } from '../Transition';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Transition>,
  'enter' | 'leave'
>;

/**
 * Добавляет содержимому компонента анимацию появления / исчезнования.
 */
export const FadeTransition: FC<Props> = ({ ...props }) => (
  <Transition
    {...props}
    enter={`${Type.FADE}-enter`}
    leave={`${Type.FADE}-leave`}
  />
);
