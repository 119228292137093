import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, TextInput } from 'modules/common/ui';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof TextInput>;

/**
 * Отображает поле ввода занимаемой должности.
 */
export const Position: FC<Props> = ({ ...props }) => {
  return (
    <Field
      htmlFor="position"
      label="Занимаемая должность"
      name={IncomeField.EMPLOYMENT_POSITION}
    >
      <TextInput
        {...props}
        id="position"
        name={IncomeField.EMPLOYMENT_POSITION}
        placeholder="Менеджер колл-центра"
        trim
      />
    </Field>
  );
};
