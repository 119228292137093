/**
 * Размер ежемесячной зарплаты.
 */
export enum UserMonthlySalary {
  /**
   * Менее 10 тысяч.
   */
  LessThan10 = 'less_than_10',

  /**
   * От 10 до 20 тысяч.
   */
  From10To20 = 'from_10_to_20',

  /**
   * От 20 до 35 тысяч.
   */
  From20To35 = 'from_20_to_35',

  /**
   * От 35 до 50 тысяч.
   */
  From35To50 = 'from_35_to_50',

  /**
   * Более 50  тысяч.
   */
  MoreThan50 = 'more_than_50',
}
