import { FC, ComponentPropsWithoutRef } from 'react';

import { Confirmation as Base } from 'modules/confirmation';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base>;

/**
 * Отображает блок подтверждения заявки на третьем шаге анкеты.
 */
export const Confirmation: FC<Props> = ({ ...props }) => <Base {...props} />;
