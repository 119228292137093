import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui/functions/lessThanContainer';

/**
 * Основной заголовок.
 */
export const Main = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.light.text.primary};

  padding-bottom: 1.25rem;

  @media ${lessThanContainer('small')} {
    font-size: 0.87rem;
  }
`;
