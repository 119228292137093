import { ComponentPropsWithoutRef, FC, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

import { Store as AuthStore } from 'modules/common/auth';
import { Store as LoanStore } from 'modules/loan/Store';
import { Page } from 'modules/common/routing';
import { Link } from 'modules/common/ui';

import { Helper } from '../helpers';

import { Icon } from './Logo.Icon';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Link>, 'href'>;

/**
 * Отображает кликабельный логотип в шапке сайта. Ссылка с логотипа ведёт на
 * главную страницу.
 */
const Logo: FC<Props> = ({ ...props }) => {
  const { pathname } = useRouter();
  const { isAuthorized, isNotAuthorized } = AuthStore.use();
  const { status } = LoanStore.use();

  const { title, href, isLoanActive } = Helper.logoRouting(
    isAuthorized,
    status,
  );

  const onClick = (event: MouseEvent) => {
    if (isNotAuthorized && pathname === Page.HOME) {
      event.preventDefault();
    }

    if (isLoanActive && pathname === Page.LOAN) {
      event.preventDefault();
    }
  };

  return (
    <Link {...props} onClick={onClick} href={href} title={title}>
      <Icon />
    </Link>
  );
};

const component = observer(Logo);
export { component as Logo };
