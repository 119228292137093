import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Store as AuthStore } from 'modules/common/auth';

import { Store } from '../../stores';

/**
 * Инициирует загрузку модели доступа отказа от услуги.
 * Проверяет возможно ли пользователю подать заявление для отказа от услуги.
 */
const Provider: FC = ({ children }) => {
  const store = Store.use();
  const auth = AuthStore.use();

  useEffect(() => {
    if (auth.initialized && auth.isAuthorized) {
      store.touch();
    }
  }, [store, auth.isAuthorized, auth.initialized]);

  return store.isTrue ? <>{children}</> : null;
};

const component = observer(Provider);
export { component as Provider };
