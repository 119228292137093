import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';

type Props = {
  isInvalid?: boolean;
  isValid?: boolean;
};

export const Radio = styled.span<Props>`
  position: relative;

  overflow: hidden;

  align-self: flex-start;
  flex-shrink: 0;

  border-width: 1px;
  border-style: solid;
  border-radius: 100%;

  transition: ${transition('border-color')};
  border-color: ${({ theme }) => theme.colors.light.input.border.focus};
  background-color: ${({ theme }) => theme.colors.light.background.primary};

  width: 1.5rem;
  height: 1.5rem;

  ${(p) =>
    p.isInvalid
      ? css`
          border-color: ${p.theme.colors.light.accent.error};
        `
      : null}

  ${(p) =>
    p.isValid
      ? css`
          border-color: ${p.theme.colors.light.input.border.focus};
        `
      : null}

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    transition: ${transition('transform')};
    transform: scale(0);

    border-style: solid;
    border-color: ${({ theme }) => theme.colors.light.input.border.focus};
    border-width: 9px;

    border-radius: 100%;

    width: 1.5rem;
    height: 1.5rem;

    margin-left: -${1.5 / 2}rem;
    margin-top: -${1.5 / 2}rem;

    content: '';
  }
`;
