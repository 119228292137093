import styled from '@emotion/styled';

import { Box as Base } from './RadioGroup.Box';

/**
 * Корневой элемент компонента.
 */
export const Root = styled.div``;

/**
 * Блок радио-кнопок с добавлением отступа для вложенных элементов.
 */
export const Box = styled(Base)`
  padding-bottom: ${0.625 / 2}rem;

  & > * {
    margin-top: 0.625rem;
    margin-right: 2.25rem;
  }

  & > *:last-of-type {
    margin-right: 0;
  }
`;
