import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

import largeBackground from './assets/largeBackground.png';
import smallBackground from './assets/smallBackground.png';

export const Root = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem 0;

  background-image: url(${largeBackground});
  background-position: center top;
  background-color: black;
  background-size: cover;

  @media ${lessThanContainer('small')} {
    padding: 1rem 0;

    background-image: url(${smallBackground});
  }
`;
