import { FC } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';
import { Page, Redirect } from 'modules/common/routing';
import { Store } from 'modules/restructure/Store';

/**
 * Проверяет, может ли текущий пользователь просматривать эту страницу и, если
 * нет, перенаправляет его на главную страницу ЛК.
 */
const Access: FC = ({ children }) => {
  const store = Store.use();

  if (!store.isAvailable) {
    return (
      <Redirect url={Page.ACCOUNT} replace>
        <PageLoading />
      </Redirect>
    );
  }

  return <>{children}</>;
};

const component = observer(Access);
export { component as Access };
