import { FC, ComponentPropsWithoutRef } from 'react';

import { getEnumValues, Select } from 'modules/common/ui';
import { UserFamilyMembersCount } from 'services/RpcService/types/UserFamilyMembersCount';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

const recordProps = getEnumValues(UserFamilyMembersCount, {
  [UserFamilyMembersCount.One]: '1',
  [UserFamilyMembersCount.Two]: '2',
  [UserFamilyMembersCount.Three]: '3',
  [UserFamilyMembersCount.Four]: '4',
  [UserFamilyMembersCount.Five]: '5',
  [UserFamilyMembersCount.MoreThanFive]: 'Более 5',
});

/**
 * Отображает поле выбора количества членов семьи.
 */
export const SizeInput: FC<Props> = (props) => (
  <Select {...props} {...recordProps} readOnly />
);
