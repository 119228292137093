import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Позиционирование элементов в header.
 */
export const Nav = styled.nav`
  display: flex;
  align-items: center;

  padding: 1.15rem 0;

  @media ${lessThanContainer('medium')} {
    padding: 0.7rem 0;
  }
`;
