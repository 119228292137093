import styled from '@emotion/styled';

/**
 * Подвал блока.
 */
export const Aside = styled.div`
  width: 100%;

  padding: 0.5rem 1.5rem;

  background-color: #f9f9f9;
`;
