import { Helper as Base } from '@devim-front/helper';

/**
 * Содержит методы для работы с числами.
 */
export class NumberHelper extends Base {
  /**
   * Преобразует число в строку в человекочитаемом формате "10 000". Дробная
   * часть числа при этом отбрасывается.
   * @param number Число.
   */
  public static formatIntegerDisplay(number: number) {
    return String(Math.trunc(number)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  /**
   * Преобразует число в строку в человекочитаемом формате. Дробная
   * часть числа при этом разделяется запятой.
   * @param number Число.
   */
  public static formatFloatDisplay(number: number) {
    const [integer, float] = number.toFixed(2).split('.');
    const head = this.formatIntegerDisplay(Number(integer));
    return `${head},${float}`;
  }

  /**
   * Возвращает слово, относящееся к указанному числу, выбирая его нужную
   * грамматическую форму из предложенных.
   * @param value Число.
   * @param one Форма слова для числа типа "один ...".
   * @param two Форма слова для числа типа "два ...".
   * @param many Форма слова для числа типа "пять ...".
   */
  public static getPluralWord(
    value: number,
    one: string,
    two: string,
    many: string,
  ) {
    if (value >= 11 && value <= 14) {
      return many;
    }

    const rest = value % 10;

    if (rest === 1) {
      return one;
    }

    if (rest >= 2 && rest <= 4) {
      return two;
    }

    return many;
  }

  /**
   * Округляет указанное число с заданной точностью (до указанного знака после
   * запятой). Округление происходит по правилам математики.
   * @param number Число.
   * @param precision Точность округления. Если точность положительная,
   * округляется дробная часть числа. Например, при точности 2 число 1.005 будет
   * округлено как 1.01. Если точность отрицательная, то округляется целая
   * часть. Например, при точности -2 число 150 будет округлено как 200 (иными
   * словами, Math.round(X / 10 ** 2) * (10 ** 2)).
   */
  public static round(number: number, precision: number = 0) {
    if (precision < 0) {
      const degree = 10 ** (precision * -1);
      return Math.round(number / degree) * degree;
    }

    return Number(number.toFixed(precision));
  }

  /**
   * Округляет указанное число с заданной точностью (до указанного знака после
   * запятой). Округление происходит к большему значению.
   * @param number Число.
   * @param precision Точность округления. Если точность положительная,
   * округляется дробная часть числа. Например, при точности 2 число 1.005 будет
   * округлено как 1.01. Если точность отрицательная, то округляется целая
   * часть. Например, при точности -2 число 150 будет округлено как 200 (иными
   * словами, Math.ceil(X / 10 ** 2) * (10 ** 2)).
   */
  public static ceil(number: number, precision: number = 0) {
    if (precision === 0) {
      return Math.ceil(number);
    }

    if (precision < 0) {
      const degree = 10 ** (precision * -1);
      return Math.ceil(number / degree) * degree;
    }

    const degree = 10 ** precision;
    return Math.trunc(Math.ceil(number * degree) / degree);
  }

  /**
   * Округляет указанное число с заданной точностью (до указанного знака после
   * запятой). Округление происходит к меньшему значению.
   * @param number Число.
   * @param precision Точность округления. Если точность положительная,
   * округляется дробная часть числа. Например, при точности 2 число 1.005 будет
   * округлено как 1.01. Если точность отрицательная, то округляется целая
   * часть. Например, при точности -2 число 150 будет округлено как 200 (иными
   * словами, Math.ceil(X / 10 ** 2) * (10 ** 2)).
   */
  public static floor(number: number, precision: number = 0) {
    if (precision === 0) {
      return Math.floor(number);
    }

    if (precision < 0) {
      const degree = 10 ** (precision * -1);
      return Math.floor(number / degree) * degree;
    }

    const degree = 10 ** precision;
    return Math.trunc(Math.floor(number * degree) / degree);
  }

  /**
   * Возвращает указанное число, если оно находится в переданных границах. Если
   * число меньше минимального, будет возвращено минимальное значение. Если
   * больше максимального - максимальное.
   * @param value Число.
   * @param minValue Минимальное значение.
   * @param maxValue Максимальное значение.
   */
  public static getInRange(value: number, minValue: number, maxValue: number) {
    if (value < minValue) {
      return minValue;
    }

    if (value > maxValue) {
      return maxValue;
    }

    return value;
  }
}
