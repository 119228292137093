import { PaymentMethod } from '../types/PaymentMethod';

/**
 * Преобразует ответ сервера в данные о методе оплаты.
 * @param data Ответ сервера.
 */
export function transformPaymentMethod(data: any): PaymentMethod {
  return {
    provider: data.payment_provider_alias,
    method: data.payment_method_alias,
  };
}
