import styled from '@emotion/styled';

/**
 * Элемент отображающий изображение.
 */
export const Img = styled.img`
  max-width: 144px;
  max-height: 144px;

  margin-bottom: 2rem;
`;
