import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui/functions';

/**
 * Элемент отображающий заголовок.
 */
export const Heading = styled.div`
  font-size: 1.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    font-size: 1.25rem;
    font-weight: bold;
  }
`;
