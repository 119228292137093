import { FC, SVGProps } from 'react';

/**
 * Иконка "Стрелки"
 */
export const Arrow: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="10"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.339 8L.7 2.362A1.219 1.219 0 012.424.638l6.5 6.5a1.219 1.219 0 010 1.724l-6.5 6.5a1.219 1.219 0 01-1.723-1.724L6.339 8z"
      fill="currentColor"
    />
  </svg>
);
