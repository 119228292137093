/**
 * Статус трудойстройства.
 */
export enum UserEmploymentStatus {
  /**
   * Трудоустроен.
   */
  Employed = 'work',

  /**
   * Безработный.
   */
  Unemployed = 'not_work',

  /**
   * Пенсионер.
   */
  Retired = 'pensioner',
}
