import { Loan } from '../types/Loan';
import { transformLoanProductOption } from './transformLoanProductOption';
import { transformPaymentDataIssue } from './transformPaymentDataIssue';

/**
 * Преобразует ответ сервера в данные о займе.
 * @param data Ответ сервера.
 */
export function transformLoan(data: any): Loan {
  return {
    id: data.id,
    loanNumber: data.loan_number,
    amount: data.amount,
    period: data.period,
    basePercentRate: data.base_percent_rate,
    dailyPercentRate: data.daily_percent_rate,
    freePeriod: data.free_period,
    status: data.status,
    product: data.product.alias,
    productOptions: (data.product_options as any[]).map(
      transformLoanProductOption,
    ),
    paymentSummary: {
      earlyRepayment: transformPaymentDataIssue(
        data.payment_summary.early_repayment,
      ),
      debt: transformPaymentDataIssue(data.payment_summary.expiration),
      fullRepayment: transformPaymentDataIssue(
        data.payment_summary.full_repayment,
      ),
      nextPayment: transformPaymentDataIssue(data.payment_summary.next_payment),
    },
    createdAt: new Date(data.created_at),
    issuedAt: data.issued_at ? new Date(data.issued_at) : undefined,
    documents: data.documents,
  };
}
