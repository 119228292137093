import { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { InferType } from 'yup';
import { observer } from 'mobx-react';

import { Field } from 'modules/common/ui';
import { Form as Base, FormItem } from 'modules/application/common';
import { Store } from 'modules/application/statement';
import { Store as CoreStore, Status } from 'modules/application/core';
import { Gender, plainStringToName } from 'modules/common/daData';

import { Field as ContactsField } from '../../types';
import { schema } from '../../schemas';

import { GenderProvider } from '../GenderContext';
import { GenderInput } from '../GenderInput';
import { NameInput } from '../NameInput';
import { EmailInput } from '../EmailInput';

/**
 * Свойства компонента.
 */ type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'schema' | 'onSubmit' | 'initialValues'
>;

/**
 * Форма контактной информации пользователя.
 */
const Form: FC<Props> = (props) => {
  const store = Store.use();
  const applicationStore = CoreStore.use();

  const disabled = applicationStore.model?.status === Status.NONE;

  useEffect(() => {
    const input = window.document.getElementById(ContactsField.EMAIL);

    if (!disabled && input != null) {
      input.focus();
    }
  }, [disabled]);

  const handleSubmit = (form: InferType<typeof schema>) => {
    store.updateValues({
      email: form.email,
      firstName: form.name.value,
      lastName: form.surname.value,
      secondName: form.patronymic.value,
      gender: form.gender as Gender,
    });
  };

  const initialValues = {
    [ContactsField.EMAIL]: store.model?.email,
    [ContactsField.FIRST_NAME]: plainStringToName(
      store.model?.firstName,
      store.model?.gender,
    ),
    [ContactsField.LAST_NAME]: plainStringToName(
      store.model?.lastName,
      store.model?.gender,
    ),
    [ContactsField.SECOND_NAME]: plainStringToName(
      store.model?.secondName,
      store.model?.gender,
    ),
    [ContactsField.GENDER]: store.model?.gender,
  };

  return (
    <GenderProvider>
      <Base
        {...props}
        schema={schema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <FormItem>
          <Field
            label="Электронная почта"
            htmlFor={ContactsField.EMAIL}
            name={ContactsField.EMAIL}
          >
            <EmailInput
              name={ContactsField.EMAIL}
              id={ContactsField.EMAIL}
              placeholder="ivan@mail.ru"
              autoFocus
              disabled={disabled}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Field
            label="Фамилия"
            htmlFor={ContactsField.LAST_NAME}
            name={ContactsField.LAST_NAME}
          >
            <NameInput
              name={ContactsField.LAST_NAME}
              id={ContactsField.LAST_NAME}
              placeholder="Иванов"
              nameType="SURNAME"
              disabled={disabled}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Field
            label="Имя"
            htmlFor={ContactsField.FIRST_NAME}
            name={ContactsField.FIRST_NAME}
          >
            <NameInput
              name={ContactsField.FIRST_NAME}
              id={ContactsField.FIRST_NAME}
              placeholder="Иван"
              nameType="NAME"
              disabled={disabled}
            />
          </Field>
        </FormItem>
        <FormItem>
          <Field
            label="Отчество"
            htmlFor={ContactsField.SECOND_NAME}
            name={ContactsField.SECOND_NAME}
          >
            <NameInput
              name={ContactsField.SECOND_NAME}
              id={ContactsField.SECOND_NAME}
              placeholder="Иванович"
              nameType="PATRONYMIC"
              disabled={disabled}
            />
          </Field>
        </FormItem>

        <GenderInput name={ContactsField.GENDER} />
      </Base>
    </GenderProvider>
  );
};

const component = observer(Form);
export { component as Form };
