import { Themed } from '@emotion/react';

/**
 * Добавляет медиа запрос для экрана меньше указанной ширины.
 * @param size Размер контейнера, ширине которого должна равняться ширина экрана при данном медиа-запросе.
 */
export const lessThanContainer =
  (size: 'large' | 'medium' | 'small' | 'xSmall') => (props: Themed<{}>) => {
    let maxWidth: number;

    switch (size) {
      case 'large': {
        maxWidth = props.theme.container.size.large;
        break;
      }
      case 'medium': {
        maxWidth = props.theme.container.size.medium;
        break;
      }
      case 'small': {
        maxWidth = props.theme.container.size.small;
        break;
      }
      case 'xSmall': {
        maxWidth = props.theme.container.size.xSmall;
        break;
      }
      default: {
        throw new Error(`Unexpected value ${size}`);
      }
    }

    return `screen and (max-width: ${maxWidth - 1}px)`;
  };
