import { Pool } from '@devim-front/service';
import { LazyStore } from '@devim-front/store';
import { RpcService, RpcServiceOptions } from 'services/RpcService';
import { Service as FingerprintService } from 'modules/common/fingerprint';
import { UnauthorizedError } from 'services/RpcService/errors/UnauthorizedError';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';
import { Page } from 'modules/common/routing';
import { Store as AuthStore } from 'modules/common/auth';

/**
 * Сервис слоя совместимости с устаревшим API.
 * @todo Данный сервис подлежит удалению, когда все зависимые сущности будут
 * адаптированы для работы с новым API.
 */
export class CompatService extends LazyStore {
  /**
   * Внутренний экземпляр сервиса RPC.
   */
  private _rpcService: RpcService;

  /**
   * Настройки сервиса RPC.
   */
  private readonly RPC_SERVICE_OPTIONS: Readonly<RpcServiceOptions> = {};

  /**
   * Создаёт экземпляр сервиса слоя совместимости.
   * @param pool Пул сервисов.
   */
  constructor(pool: Pool) {
    super(pool);

    this._rpcService = new RpcService(
      process.env.NEXT_PUBLIC_RPC_API!,
      this.RPC_SERVICE_OPTIONS,
    );

    FingerprintService.get(this)
      .getId()
      .then((fingerprint) => {
        this._rpcService.setOptions({
          ...this.RPC_SERVICE_OPTIONS,
          fingerprint,
        });
      });

    this._rpcService.on('error', async (error: any) => {
      if (
        error instanceof UnauthorizedError ||
        RpcError.isType(error, RpcErrorCode.RefreshTokenExpired) ||
        RpcError.isType(error, RpcErrorCode.RefreshTokenInvalid)
      ) {
        await AuthStore.get(this).setTokens(undefined);

        window.location.href = new URL(
          Page.LOGIN,
          window.location.origin,
        ).toString();
      }
    });
  }

  /**
   * Возвращает глобальный экземпляр сервиса RPC.
   */
  public get rpcService(): Readonly<RpcService> {
    return this._rpcService;
  }
}
