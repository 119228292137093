import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент калькулятора.
 */
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  @media ${lessThanContainer('small')} {
    flex-direction: column;
    padding-top: 0;
  }

  @media ${lessThanContainer('medium')} and (orientation: landscape) {
    padding-top: 10px;
  }
`;
