import { FC, ComponentPropsWithoutRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { observer } from 'mobx-react';

import { Form, LinkButton, Surfaces as Base } from 'modules/common/ui';

import { CardsStore } from '../../CardsStore';

import { AddCard } from '../AddCard';
import { Card } from '../Card';
import { Checkbox } from '../NewCard/NewCard.Checkbox';
import { Root } from './Cards.Root';
import { Aside } from './Cards.Aside';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'overhead' | 'children'
> & {
  isAside?: boolean;
  noClick?: boolean;
};

/**
 * Отображает список банковских карт пользователя.
 */
const Cards: FC<Props> = ({ isAside, noClick }) => {
  const store = CardsStore.use();

  const aside = (
    <Form>
      <Checkbox name="reccurent" valid={false}>
        Не соглашаюсь на применение{' '}
        <LinkButton
          href="/docs/08-soglashenie-o-primenenii-rekurrentnyh-platezhej.pdf"
          variant="plain"
          color="info"
          target="_blank"
          rel="noreferrer"
        >
          рекуррентных платежей.
        </LinkButton>
      </Checkbox>
    </Form>
  );

  return (
    <Root>
      <Swiper slidesPerView="auto" spaceBetween={20} freeMode>
        <SwiperSlide style={{ width: 0 }} />
        <SwiperSlide style={{ width: '170px' }}>
          <AddCard />
        </SwiperSlide>
        {store.models.map((card) => (
          <SwiperSlide key={card.id} style={{ width: '170px' }}>
            <Card key={card.id} model={card} noClick={noClick} />
          </SwiperSlide>
        ))}
        <SwiperSlide style={{ width: 0 }} />
      </Swiper>
      {isAside ? <Aside>{aside}</Aside> : null}
    </Root>
  );
};

const component = observer(Cards);
export { component as Cards };
