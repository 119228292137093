import * as yup from 'yup';

import { PinHelper } from 'modules/common/values/helpers';

import { Field } from '../../../consts';

/**
 * Схема формы кода подтверждения.
 */
export const schema = yup.object().shape({
  /**
   * Код подтверждения.
   */
  [Field.CODE]: yup
    .string()
    .required('Введите код подтверждения')
    .matches(PinHelper.VALIDATION_REGEXP, 'Введите код подтверждения'),
});

/**
 * Значения схемы.
 */
export type Values = yup.InferType<typeof schema>;
