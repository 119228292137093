import { FC, ReactNode } from 'react';

import { useFormValue } from 'modules/common/ui';
import { UserEmploymentStatus } from 'services/RpcService/types/UserEmploymentStatus';

import { Field as IncomeField } from '../../types';

type Props = {
  /**
   * Компоненты, отображающиеся по условию Select.
   */
  children: ReactNode;
};

/**
 * Отслеживание состояния Select.
 */
export const WhenEmployed: FC<Props> = ({ children }) => {
  const isEmployed = useFormValue<UserEmploymentStatus>(IncomeField.EMPLOYMENT);

  return <>{isEmployed === UserEmploymentStatus.Employed && children}</>;
};
