import { Helper as Base } from '@devim-front/helper';

/**
 * Содержит методы для работы со СНИЛС.
 */
export class SnilsHelper extends Base {
  /**
   * Регулярное выражение для проверки.
   */
  public static VALIDATION_REGEXP = /^(\d{3})-(\d{3})-(\d{3}) (\d{2})$/;

  /**
   * Маска для поля ввода.
   */
  public static INPUT_MASK = '000-000-000 00';

  /**
   * Обрабатывает значение СНИЛС и вырезает форматирование, возвращая числовое
   * значение.
   * @param snils СНИЛС.
   */
  public static parse(snils: string): string {
    const match = snils.match(/\d/g);

    if (match === null) {
      throw new Error(`The value ${snils} cannot be parsed as a SNILS`);
    }

    return match.join('');
  }

  /**
   * Обрабатывает значение СНИЛС и форматирует его согласно шаблону.
   * @param snils СНИЛС.
   */
  public static format(snils: string): string {
    const match = snils.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

    if (match === null) {
      throw new Error(`The value ${snils} cannot be parsed as a SNILS`);
    }

    const [, a, b, c, d] = match;
    return `${a}-${b}-${c} ${d}`;
  }
}
