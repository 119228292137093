import { LazyService } from '@devim-front/service';
import { load } from '@fingerprintjs/fingerprintjs';
import { v4 as generateUuid } from 'uuid';

/**
 * Сервис, который позволяет получить уникальный идентификатор устройства,
 * с которого просматривается сайт.
 * @see https://github.com/fingerprintjs/fingerprintjs
 */
export class Service extends LazyService {
  /**
   * Ключ, по которому в сессии сохраняется идентификатор устройства.
   */
  private SESSION_KEY = 'fingerprint';

  /**
   * Процесс загрузки идентификатора устройства с сервера FingerprintJS.
   * Используется для того, чтобы если загрузка уже идет, запрос не выполнялся
   * повторно.
   */
  private fetching?: Promise<string>;

  /**
   * Уникальный идентификатор устройства, с которого пользователь просматривает
   * портал.
   */
  private id?: string;

  /**
   * Получает уникальный идентификатор устройства с сервера FingerprintJS.
   */
  private async fetchId() {
    const api = await load();
    const { visitorId } = await api.get();
    return visitorId;
  }

  /**
   * Возвращает уникальный идентификатор устройства, с которого пользователь
   * зашел на портал.
   */
  public getId() {
    if (this.id != null) {
      return Promise.resolve(this.id);
    }

    if (typeof window === 'undefined') {
      this.id = '';
      return Promise.resolve(this.id);
    }

    const sessionValue = window.localStorage.getItem(this.SESSION_KEY);

    if (sessionValue != null) {
      this.id = sessionValue;
      return Promise.resolve(this.id);
    }

    if (this.fetching) {
      return this.fetching;
    }

    const handleSuccess = (id: string) => {
      this.fetching = undefined;
      this.id = id;

      window.localStorage.setItem(this.SESSION_KEY, id);

      return this.id;
    };

    this.fetching = this.fetchId().then(handleSuccess, () => {
      const id = generateUuid();
      return handleSuccess(id);
    });

    return this.fetching;
  }
}
