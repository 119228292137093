import * as yup from 'yup';

/**
 * Генерирует схему формы для указанного списка одинаковых полей.
 * @param names Список названий полей.
 */
export const generateSchema = (names: string[]) => {
  let shape: Record<string, any> = {};
  const { length } = names;

  for (let i = 0; i < length; i += 1) {
    const name = names[i];
    const item = yup.boolean().isTrue().required('Согласитесь с условиями');
    shape = { ...shape, [name]: item };
  }

  return yup.object().shape(shape);
};
