import { FC, ComponentPropsWithoutRef } from 'react';

import { getEnumValues, Select } from 'modules/common/ui';
import { UserMartialStatus } from 'services/RpcService/types/UserMartialStatus';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

const recordProps = getEnumValues(UserMartialStatus, {
  [UserMartialStatus.CivilMarriage]: 'Гражданский брак',
  [UserMartialStatus.Divorced]: 'В разводе',
  [UserMartialStatus.Married]: 'Женат / замужем',
  [UserMartialStatus.Single]: 'Холост / не замужем',
});

/**
 * Отображает поле выбора семейного положения.
 */
export const MartialStatusInput: FC<Props> = (props) => (
  <Select {...props} {...recordProps} readOnly />
);
