import styled from '@emotion/styled';

export const Root = styled.div`
  height: 105px;
  width: 170px;
  border: 1px solid ${({ theme }) => theme.colors.light.button.outline.border};
  border-radius: 16px;
  cursor: pointer;

  font-weight: 500;
  font-size: 15px;
  line-height: 105px;
  text-align: center;
`;
