import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, TextInput } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof TextInput>,
  'inputMode' | 'name'
> & {
  /**
   * Имя поля ввода.
   */
  name: string;

  /**
   * Заголовок поля ввода.
   */
  label: string;
};

/**
 * Отображает поле ввода ИНН/СНИЛС.
 */
export const InnSnils: FC<Props> = ({ name, label, ...props }) => (
  <Field label={label} name={name} htmlFor={name}>
    <TextInput
      {...props}
      id={name}
      name={name}
      inputMode="numeric"
      mask="0000 0000 0000"
      placeholder="0000 0000 0000"
    />
  </Field>
);
