import styled from '@emotion/styled';

import { lessThanContainer, LinkButton as Element } from 'modules/common/ui';

import icon from './assets/fileIcon.svg';

/**
 * Корневой элемент компонента.
 */
export const LinkButton = styled(Element)`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 0.87rem;
  text-align: left;

  border-radius: 0.25rem;

  padding: 1.25rem;

  background-color: ${({ theme }) => theme.colors.light.background.secondary};
  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    font-size: 0.75rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.light.background.secondary};
    border-color: ${({ theme }) => theme.colors.light.border.hover};
  }

  &:before {
    background-image: url(${icon});
    background-position: 50%;
    background-size: contain;

    display: block;
    height: 1.5rem;
    width: 1.5rem;

    flex: 0 0 auto;

    margin-right: 0.875rem;

    content: '';
  }
`;
