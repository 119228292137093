import styled from '@emotion/styled';

/**
 * Корневой элемент компонента.
 */
export const Root = styled.label`
  display: block;

  font-size: 0.875rem;
`;
