import { ComponentPropsWithoutRef, FC } from 'react';

import { PercentHelper } from 'modules/common/values';

import { ParamsCommon } from '../ParamsCommon';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof ParamsCommon> & {
  /**
   * Значение процентной ставки.
   */
  value?: number;
};

/**
 * Компонент процентной ставки.
 */
export const ParamsInterestRate: FC<Props> = ({
  title = 'Ставка, в день',
  value,
  ...props
}) => {
  let text: string = '';

  if (value != null) {
    const unit = PercentHelper.getShortUnits();
    const item = PercentHelper.formatDisplayFloat(value);
    text = `${item} ${unit}`;
  }

  return (
    <ParamsCommon {...props} title={title}>
      {text}
    </ParamsCommon>
  );
};
