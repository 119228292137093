import { LazyService } from '@devim-front/service';
import ym from 'react-yandex-metrika';

import { Event } from '../types';
import { ClickhouseService } from 'services/СlickhouseService';
import { ABTestService } from '../../../../services/ABTestService';

/**
 * Настройки счётчика.
 */
type Options = Record<string, any>;

/**
 * Содержит методы для отправки событий метрики.
 */
export class Service extends LazyService {
  /**
   * Идентификатор счётчика. Если не указан в ENV, то свойство равно undefined.
   */
  public id = process.env.NEXT_PUBLIC_YANDEX_COUNTER_ID
    ? Number(process.env.NEXT_PUBLIC_YANDEX_COUNTER_ID)
    : undefined;

  /**
   * Настройки счётчика.
   */
  public options: Options = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  };

  /**
   * Отправляет событие в метрику.
   * @param event Название события.
   * @param payload Доп. данные
   */
  public send(event: Event, payload?: Record<string, any>) {
    const data = {
      ...payload,
      [ABTestService.TEST_KEY]: ABTestService.getGroup(),
    };

    if (typeof window === 'undefined') {
      return;
    }

    if (typeof this.id === 'number') {
      ym('reachGoal', event, data);
    } else {
      // eslint-disable-next-line no-console
      console.debug('yandex_event', event, data);
    }

    ClickhouseService.push(event, payload, {
      testGroup: { [ABTestService.TEST_KEY]: data[ABTestService.TEST_KEY] },
    });
  }
}
