import { LoanApplication } from '../types/LoanApplication';

/**
 * Преобразует ответ сервера в данные о заявке на заём.
 * @param data Ответ сервера.
 */
export function transformLoanApplication(data: any): LoanApplication {
  return {
    id: data.id,
    status: data.status,
    period: data.period,
    amount: data.amount,
    createdAt: new Date(data.createdAt),
    expiresAt: data.decision_expired_at
      ? new Date(data.decision_expired_at)
      : undefined,
  };
}
