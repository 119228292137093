import styled from '@emotion/styled';
import { transition } from 'modules/common/theme';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.a`
  display: inline-block;
  padding: 20px 28px;
  height: 64px;

  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.light.button.primary.text};

  border: 2px solid ${({ theme }) => theme.colors.light.button.primary.text};
  border-radius: 32px;

  transition: ${transition('background-color', 'color')};

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.light.button.overlay.background};
    color: ${({ theme }) => theme.colors.light.button.overlay.text};
  }

  @media ${lessThanContainer('small')} {
    display: block;
    padding: 14px 0;

    border-radius: 26px;
    height: 52px;

    font-size: 13px;
    line-height: 20px;
  }
`;
