import { FC, ComponentPropsWithoutRef } from 'react';

import {
  Field,
  Select,
  useFormValue,
  RadioGroup,
  Radio,
  TextInput,
} from 'modules/common/ui';
import { FormItem } from 'modules/application/common';

import { Field as CreditObligationsField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'values' | 'format' | 'readOnly'
>;

/**
 * Отображает поле "Выплаты по иным кредитам".
 */
export const CreditObligations: FC<Props> = ({ className, ...props }) => {
  const isTrue = useFormValue<boolean>(
    CreditObligationsField.CREDIT_OBLIGATIONS_HAS_UNPAID,
  );

  return (
    <>
      <FormItem>
        <Field
          htmlFor={CreditObligationsField.CREDIT_OBLIGATIONS_HAS_UNPAID}
          name={CreditObligationsField.CREDIT_OBLIGATIONS_HAS_UNPAID}
          className={className}
        >
          <RadioGroup
            {...props}
            heading="У меня есть неоплаченные денежные обязательства"
            name={CreditObligationsField.CREDIT_OBLIGATIONS_HAS_UNPAID}
          >
            <Radio value>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </RadioGroup>
        </Field>
      </FormItem>
      <br />
      {isTrue && (
        <>
          <FormItem>
            <Field
              label="Число платежей в месяц"
              name={
                CreditObligationsField.CREDIT_OBLIGATIONS_PAYMENTS_PER_MONTH
              }
              htmlFor={
                CreditObligationsField.CREDIT_OBLIGATIONS_PAYMENTS_PER_MONTH
              }
            >
              <TextInput
                {...props}
                name={
                  CreditObligationsField.CREDIT_OBLIGATIONS_PAYMENTS_PER_MONTH
                }
                mask="000"
                placeholder="0"
                min={0}
                max={999}
              />
            </Field>
          </FormItem>
          <br />
          <FormItem>
            <Field
              label="Средняя сумма 1 платежа"
              name={
                CreditObligationsField.CREDIT_OBLIGATIONS_AVERAGE_PAYMENT_AMOUNT
              }
              htmlFor={
                CreditObligationsField.CREDIT_OBLIGATIONS_AVERAGE_PAYMENT_AMOUNT
              }
            >
              <TextInput
                {...props}
                name={
                  CreditObligationsField.CREDIT_OBLIGATIONS_AVERAGE_PAYMENT_AMOUNT
                }
                mask={Number}
                placeholder="0"
                scale={2}
                thousandsSeparator=" "
                padFractionalZeros
                radix="."
                mapToRadix={['.']}
                min={0}
                max={9999999.99}
              />
            </Field>
          </FormItem>
        </>
      )}
    </>
  );
};
