import { ComponentPropsWithoutRef, Component, FocusEvent } from 'react';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'>;

/**
 * Предоставляет управление фокусом всей группы элементов.
 */
export class Root extends Component<Props> {
  /**
   * Таймаут задержки, после которой наступит запланированное событие `onBlur`.
   * Задержка введена, чтобы поле не генерировало лишних событий, когда
   * фокус переходит между элементами группы.
   */
  private blurTimeout: any = undefined;

  /**
   * Размер задержки, после которой наступает запланированное событие
   * `onBlur`.
   */
  private blurDelay: number = 175;

  /**
   * Указывает, находится ли данный компонент в фокусе.
   */
  private focused: boolean = false;

  /**
   * Обрабатывает получение элементом фокуса.
   * @param event Событие.
   */
  private handleFocus = (event: FocusEvent<HTMLDivElement>) => {
    clearTimeout(this.blurTimeout);

    if (this.focused) {
      return;
    }

    this.focused = true;

    const { onFocus } = this.props;

    if (onFocus) {
      onFocus(event);
    }
  };

  /**
   * Обрабатывает потерю элементом фокуса.
   * @param event Событие.
   */
  private handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    clearTimeout(this.blurTimeout);

    const handleBlur = () => {
      this.focused = false;

      const { onBlur } = this.props;

      if (onBlur) {
        onBlur(event);
      }
    };

    this.blurTimeout = setTimeout(handleBlur, this.blurDelay);
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { onFocus, onBlur, ...props } = this.props;

    return (
      <div {...props} onFocus={this.handleFocus} onBlur={this.handleBlur} />
    );
  }
}
