import styled from '@emotion/styled';

/**
 * Корневой элемент компонента.
 */
export const Root = styled.div`
  text-align: left;

  border-radius: 0.25rem;

  background-color: ${({ theme }) => theme.colors.light.background.secondary};
`;
