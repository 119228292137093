import React, { FC, HTMLProps } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';

/**
 * Свойства корневого элемента.
 */
type Props = HTMLProps<HTMLDivElement> & {
  /**
   * Указывает, что поле находится в фокусе.
   */
  focused?: boolean;

  /**
   * Указывает, что поле заблокировано.
   */
  disabled?: boolean;

  /**
   * Указывает, что поле должно выглядеть так, словно на него наведён курсор.
   */
  hovered?: boolean;

  /**
   * Указывает, что поле содержит ошибку.
   */
  invalid?: boolean;

  /**
   * Указывает, что поле провалидировано верно.
   */
  valid?: boolean;
};

/**
 * Экранирует свойства корневого элемента.
 */
const RootBase: FC<Props> = ({
  disabled,
  focused,
  hovered,
  invalid,
  valid,
  ...props
}) => <div {...props} />;

/**
 * Корневой элемент компонента.
 */
export const Root = styled(RootBase)`
  position: relative;

  display: flex;
  align-items: center;

  overflow: hidden;

  transition: ${transition(
    'border-color',
    'box-shadow',
    'background-color',
    'color',
  )};

  background-color: ${({ theme }) =>
    theme.colors.light.input.background.default};
  box-shadow: none;
  border-radius: 0.75rem;
  border-color: ${({ theme }) => theme.colors.light.input.border.default};
  border-style: solid;
  border-width: 1.5px;

  ${({ valid, theme }) =>
    valid
      ? css`
          border-color: ${theme.colors.light.input.border.default};
        `
      : null}

  ${({ invalid, theme }) =>
    invalid
      ? css`
          background-color: ${theme.colors.light.input.background.error};
          border-color: ${theme.colors.light.accent.error};
        `
      : null}

  ${({ hovered, theme }) =>
    hovered
      ? css`
          border-color: ${theme.colors.light.input.border.hover};
        `
      : null}

  ${({ focused, theme }) =>
    focused
      ? css`
          background-color: ${theme.colors.light.input.background.default};
          border-color: ${theme.colors.light.input.border.focus} !important;
        `
      : null}

  ${({ disabled, theme }) =>
    disabled
      ? css`
          background-color: ${theme.colors.light.input.background.disabled};
          border-color: ${theme.colors.light.input.border.default};
          color: ${theme.colors.light.text.disabled};

          cursor: not-allowed;

          :hover {
            border-color: ${theme.colors.light.input.border.default} !important;
          }
        `
      : null}

  :hover {
    border-color: ${({ theme }) => theme.colors.light.input.border.hover};
  }
`;
