import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { Root } from './Step.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Номер шага в заполнении заявки
   */
  children?: ReactNode;
};

/**
 * Элемент отображения текущего шага заполнения Application
 */
export const Step: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>Шаг {children} из 3</Root>
);
