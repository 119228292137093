import styled from '@emotion/styled';

/**
 * Корневой элемент части подвала страницы.
 */
export const Root = styled.div`
  flex: 0 0 auto;

  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.light.text.primary};
`;
