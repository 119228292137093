import styled from '@emotion/styled';

import { Button as Base } from 'modules/common/ui';

/**
 * Корневой элемент компонента Back.
 */
export const Root = styled(Base)`
  font-style: normal;
  font-weight: normal;
  font-size: 0.812rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};
`;
