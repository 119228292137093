export const Alfabank = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_8503_74303)">
        <path
          d="M12.3274 0.000199465C13.9708 0.000199465 14.4872 1.17893 14.9204 2.35581C15.1024 2.85557 19.373 14.5505 19.5014 14.9015C19.5356 14.9995 19.5125 15.1602 19.3619 15.2184C19.2104 15.2757 16.9915 16.1385 16.8687 16.1847C16.7458 16.2318 16.6082 16.1634 16.5638 16.0406C16.5176 15.9195 15.5966 13.3699 15.4932 13.0854H9.16351C9.06374 13.3699 8.13166 15.9251 8.09009 16.0406C8.0476 16.1579 7.9155 16.2373 7.78432 16.1847C7.65315 16.1329 5.42594 15.2701 5.29291 15.2184C5.16543 15.1685 5.10723 15.0235 5.15157 14.9015C5.30862 14.4637 9.51454 2.95627 9.72886 2.35581C10.1464 1.19648 10.6803 0.000199465 12.3274 0.000199465ZM4.81348 20.9264C4.68045 20.9264 4.57422 21.0372 4.57422 21.1721V23.9055C4.57422 24.0404 4.68045 24.1494 4.81348 24.1494H19.8349C19.9707 24.1494 20.0769 24.0404 20.0769 23.9055V21.1721C20.0769 21.0372 19.9707 20.9264 19.8349 20.9264H4.81348ZM12.3126 3.81907L10.1732 10.2439H14.4872L12.3404 3.81907H12.3126Z"
          fill="#DC4533"
        />
      </g>
      <defs>
        <clipPath id="clip0_8503_74303">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
