export const Sberbank = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.0262 4.62158C21.5837 5.3521 22.0557 6.14574 22.4326 6.98658L11.7415 14.9736L7.27344 12.1351V8.72016L11.7415 11.5587L21.0262 4.62158Z"
        fill="#21A038"
      />
      <path
        d="M2.71147 11.894C2.71147 11.7408 2.71516 11.5886 2.72253 11.4372L0.0147471 11.3027C0.00553065 11.4989 3.27223e-06 11.6969 3.27223e-06 11.8968C-0.00114881 13.4584 0.30193 15.0048 0.891862 16.4473C1.48179 17.8899 2.34695 19.2003 3.43772 20.3032L5.35842 18.3576C4.51879 17.5101 3.8527 16.5027 3.39844 15.3935C2.94419 14.2843 2.71073 13.095 2.71147 11.894Z"
        fill="url(#paint0_linear_8502_74285)"
      />
      <path
        d="M11.7369 2.74707C11.888 2.74707 12.0382 2.75268 12.1875 2.76015L12.323 0.0149955C12.1289 0.00565503 11.9335 0.000985384 11.7369 0.000985384C10.1953 -0.000919837 8.66862 0.305795 7.24437 0.903517C5.82012 1.50124 4.5264 2.3782 3.4375 3.48404L5.3582 5.43059C6.19448 4.57937 7.18855 3.90408 8.28324 3.44355C9.37793 2.98301 10.5516 2.74632 11.7369 2.74707Z"
        fill="url(#paint1_linear_8502_74285)"
      />
      <path
        d="M11.7394 21.0414C11.5883 21.0414 11.438 21.0414 11.2878 21.0293L11.1523 23.7735C11.3471 23.7835 11.5428 23.7885 11.7394 23.7885C13.2803 23.7901 14.8063 23.4832 16.2298 22.8853C17.6533 22.2874 18.9462 21.4103 20.0342 20.3045L18.1172 18.3589C17.2808 19.2097 16.2868 19.8846 15.1923 20.3449C14.0979 20.8053 12.9244 21.042 11.7394 21.0414Z"
        fill="url(#paint2_linear_8502_74285)"
      />
      <path
        d="M16.8256 4.34332L19.1075 2.63869C17.0218 0.927018 14.4182 -0.00475142 11.7344 1.82208e-05V2.74704C13.5518 2.74476 15.327 3.30137 16.8256 4.34332Z"
        fill="url(#paint3_linear_8502_74285)"
      />
      <path
        d="M23.4728 11.8942C23.4743 11.1796 23.4126 10.4662 23.2885 9.7627L20.7623 11.6495C20.7623 11.7308 20.7623 11.812 20.7623 11.8942C20.763 13.1722 20.4986 14.4361 19.9864 15.604C19.4742 16.7719 18.7256 17.8176 17.7891 18.6735L19.6112 20.7144C20.8287 19.6004 21.8016 18.2396 22.4669 16.7202C23.1322 15.2007 23.4749 13.5565 23.4728 11.8942Z"
        fill="#21A038"
      />
      <path
        d="M11.7382 21.0411C10.4771 21.0415 9.22986 20.7735 8.07735 20.2545C6.9249 19.7354 5.89284 18.9768 5.04801 18.0278L3.03516 19.8735C4.13417 21.1076 5.47687 22.0938 6.97617 22.7682C8.47546 23.4425 10.0979 23.79 11.7382 23.7881V21.0411Z"
        fill="url(#paint4_linear_8502_74285)"
      />
      <path
        d="M5.68469 5.11462L3.86353 3.07373C2.64564 4.18746 1.67235 5.54819 1.00676 7.06767C0.341187 8.58713 -0.0018146 10.2314 7.21935e-06 11.8939H2.71148C2.71092 10.6159 2.9753 9.352 3.48748 8.18415C3.99967 7.01633 4.74824 5.97055 5.68469 5.11462Z"
        fill="url(#paint5_linear_8502_74285)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8502_74285"
          x1="3.9861"
          y1="19.9604"
          x2="0.952465"
          y2="11.3072"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.14" stopColor="#F1E813" />
          <stop offset="0.3" stopColor="#E6E418" />
          <stop offset="0.58" stopColor="#C9DA26" />
          <stop offset="0.89" stopColor="#A2CC39" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8502_74285"
          x1="4.21167"
          y1="3.96974"
          x2="11.8675"
          y2="1.09094"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0FA7DF" />
          <stop offset="0.54" stopColor="#0098F8" />
          <stop offset="0.92" stopColor="#0290EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8502_74285"
          x1="10.9736"
          y1="22.1305"
          x2="19.613"
          y2="20.2047"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#A2CC39" />
          <stop offset="0.28" stopColor="#86C239" />
          <stop offset="0.87" stopColor="#219F38" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8502_74285"
          x1="11.1704"
          y1="0.920051"
          x2="18.5641"
          y2="3.17144"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0290EA" />
          <stop offset="0.79" stopColor="#0C89CA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8502_74285"
          x1="3.71532"
          y1="19.5391"
          x2="11.7707"
          y2="22.5097"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stopColor="#F1E813" />
          <stop offset="0.3" stopColor="#EAE616" />
          <stop offset="0.53" stopColor="#D8DF1F" />
          <stop offset="0.8" stopColor="#BAD52D" />
          <stop offset="0.98" stopColor="#A2CC39" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8502_74285"
          x1="1.01012"
          y1="12.2264"
          x2="4.30893"
          y2="3.75897"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stopColor="#A2CC39" />
          <stop offset="0.26" stopColor="#81C45E" />
          <stop offset="0.92" stopColor="#0FA7DF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
