import { FC, ComponentPropsWithoutRef, useCallback } from 'react';
import { InferType } from 'yup';

import { Field } from 'modules/common/ui';
import { Form as Base, FormItem } from 'modules/application/common';
import { Store } from 'modules/application/statement';

import { Field as FamilyField } from '../../types';
import { schema } from '../../schemas';

import { MartialStatusInput } from '../MartialStatusInput';
import { SizeInput } from '../SizeInput';
import { ChildrenCountInput } from '../ChildrenCountInput';
import { LivingWithParentsInput } from '../LivingWithParentsInput';
import { UserMartialStatus } from 'services/RpcService/types/UserMartialStatus';
import { UserFamilyMembersCount } from 'services/RpcService/types/UserFamilyMembersCount';
import { UserChildrenCount } from 'services/RpcService/types/UserChildrenCount';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'schema' | 'onSubmit' | 'initialValues'
>;

/**
 * Отображает форму заполнения семейного положения.
 */
export const Form: FC<Props> = (props) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (form: InferType<typeof schema>) => {
      store.updateValues({
        martialStatus: form.status as UserMartialStatus,
        familySize: form.size as UserFamilyMembersCount,
        childrenCount: form.childrenCount as UserChildrenCount,
        isLivingWithParents: form.isLivingWithParents,
      });
    },
    [store],
  );

  if (store.model == null) {
    return null;
  }

  const initialValues = {
    [FamilyField.STATUS]: store.model?.martialStatus,
    [FamilyField.SIZE]: store.model?.familySize,
    [FamilyField.CHILDREN_COUNT]: store.model?.childrenCount,
    [FamilyField.IS_LIVING_WITH_PARENTS]: store.model?.isLivingWithParents,
  };

  return (
    <Base
      {...props}
      schema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormItem width="half">
        <Field
          htmlFor={FamilyField.STATUS}
          name={FamilyField.STATUS}
          label="Семейное положение"
        >
          <MartialStatusInput
            id={FamilyField.STATUS}
            name={FamilyField.STATUS}
          />
        </Field>
      </FormItem>

      <FormItem width="half">
        <Field
          htmlFor={FamilyField.SIZE}
          name={FamilyField.SIZE}
          label="Количество членов семьи"
        >
          <SizeInput id={FamilyField.SIZE} name={FamilyField.SIZE} />
        </Field>
      </FormItem>

      <FormItem width="half">
        <Field
          htmlFor={FamilyField.CHILDREN_COUNT}
          name={FamilyField.CHILDREN_COUNT}
          label="Кол-во детей до 18-ти"
        >
          <ChildrenCountInput
            name={FamilyField.CHILDREN_COUNT}
            id={FamilyField.CHILDREN_COUNT}
          />
        </Field>
      </FormItem>

      <FormItem width="half">
        <Field
          htmlFor={FamilyField.IS_LIVING_WITH_PARENTS}
          name={FamilyField.IS_LIVING_WITH_PARENTS}
        >
          <LivingWithParentsInput name={FamilyField.IS_LIVING_WITH_PARENTS} />
        </Field>
      </FormItem>
    </Base>
  );
};
