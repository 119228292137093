import { ComponentPropsWithoutRef, FC } from 'react';

import { RadioGroup, Radio } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof RadioGroup>,
  'children' | 'heading'
> & {
  /**
   * Имя поля формы.
   */
  name: string;
};

/**
 * Отображает радиогруппу выбора опции проживания с родителями.
 */
export const LivingWithParentsInput: FC<Props> = ({ name, ...props }) => (
  <RadioGroup {...props} name={name} heading="Проживаю с родителями">
    <Radio value>Да</Radio>
    <Radio value={false}>Нет</Radio>
  </RadioGroup>
);
