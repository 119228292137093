import styled from '@emotion/styled';

/**
 * Корневой элемент компонента Toggle.
 */
export const Root = styled.button`
  position: relative;
  display: inline-flex;

  padding: 0;
  border: 0;
  outline: 0;

  color: ${({ theme }) => theme.color.info.main};
  background-color: transparent;

  cursor: pointer;
`;
