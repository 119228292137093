import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';

type Props = {
  isInvalid?: boolean;
  isValid?: boolean;

  /**
   * Вариант отображения элемента.
   */
  variant?: 'default' | 'solid';
};

export const Checkbox = styled.span<Props>`
  position: relative;

  overflow: hidden;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  align-self: flex-start;
  flex-shrink: 0;

  border-width: ${(p) => (p.variant === 'solid' ? '2px' : '1px')};

  border-style: solid;

  transition: ${transition('border-color, border-radius')};

  border-color: ${({ theme }) => theme.colors.light.input.border.focus};
  border-radius: 7.3px;

  svg {
    width: ${(p) => (p.variant === 'solid' ? '9px' : '12px')};
    height: ${(p) => (p.variant === 'solid' ? '7px' : '9px')};
    path {
      stroke-width: ${(p) => (p.variant === 'solid' ? '3.2px' : '2px')};
    }
  }

  width: 20px;
  height: 20px;

  ${(p) =>
    p.isInvalid
      ? css`
          border-color: ${p.theme.colors.light.accent.error};
        `
      : null}

  ${(p) =>
    p.isValid
      ? css`
          border-color: ${p.theme.colors.light.input.border.focus};
        `
      : null}
`;
