import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент для прокрутки меню в мобильной версии.
 */
export const MenuWrapper = styled.div`
  @media ${lessThanContainer('medium')} {
    max-height: calc(100vh - 3.375rem);

    overflow: auto;
  }
`;
