import styled from '@emotion/styled';

/**
 * Корневой элемент калькулятора.
 */
export const Value = styled.p`
  margin: 0;
  padding-bottom: 10px;
  font-size: 28px;
  line-height: 30.8px;
`;
