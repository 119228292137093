import { ComponentPropsWithoutRef, FC, SVGProps } from 'react';

import { Heading } from './Surface.Heading';
import { Text } from './Surface.Text';
import { Icon } from './Surface.Icon';
import { Root } from './Surface.Root';

/**
 * Базовые свойства компонента.
 */
type BaseProps = Omit<ComponentPropsWithoutRef<typeof Root>, 'as'> & {
  /**
   * Заголовок.
   */
  heading: string;

  /**
   * Описание.
   */
  text: string;

  /**
   * Иконка.
   */
  icon: SVGProps<SVGSVGElement>;
};

/**
 * Свойства с типом кнопки.
 */
type ButtonProps = BaseProps;

/**
 * Свойства с типом ссылки.
 */
type LinkProps = {
  /**
   * Определяет, что компонент должен отображаться как ссылка.
   */
  as: 'a';

  /**
   * Определяет отношение ссылки.
   */
  rel?: string;

  /**
   * Маршрут для перехода.
   */
  href: string;

  /**
   * Целевое окно для перехода.
   */
  target: string;
} & BaseProps;

/**
 * Свойства компонента.
 */
type Props = ButtonProps | LinkProps;

/**
 * Компонент для отображения варианта платежа.
 */
export const Surface: FC<Props> = ({ heading, text, icon, ...props }) => (
  <Root {...props}>
    <Heading>{heading}</Heading>
    <Text>{text}</Text>
    <Icon>{icon}</Icon>
  </Root>
);
