import { Helper as Base } from '@devim-front/helper';

import { Package } from './Package';
import { Product } from './Product';
import { Model } from './Model';

/**
 * Содержит методы для работы с займом.
 */
export class Helper extends Base {
  /**
   * Возвращает название пакета услуг для указанного займа.
   * @param loan Модель займа.
   */
  public static getPackageName(loan: Model) {
    return loan.product === Product.REPEAT ? Package.REPEAT : Package.FIRST;
  }
}
