import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Тип темы сайта
   */
  layout: 'screen' | 'default';
};

/**
 * Корневой элемент меню шапки.
 */
export const Root = styled.header<Props>`
  min-height: ${({ theme }) => theme.header.size.large}rem;
  position: relative;
  z-index: 3;
  background-color: transparent;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.light.border.default};

  ${(props) =>
    props.layout === 'screen'
      ? css`
          position: absolute;
          width: 100%;

          border-color: transparent;
        `
      : null}

  @media ${lessThanContainer('medium')} {
    min-height: ${({ theme }) => theme.header.size.medium}rem;
  }
`;
