import React, { ComponentProps, FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { AmountHelper } from 'modules/common/values';

import { Store } from '../../../stores';

import { Input } from '../Input';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentProps<typeof Input>,
  'format' | 'value' | 'onChange' | 'label' | 'name'
> & {
  amount?: number;
};

/**
 * Отображает поле ввода суммы займа на калькуляторе.
 */
const Amount: FC<Props> = ({ amount, ...props }) => {
  const { amount: storedAmount, setAmount } = Store.use();

  const onChange = useCallback(
    (value: number) => {
      const nextAmount = AmountHelper.create(value);
      setAmount(nextAmount);
    },
    [setAmount],
  );

  useEffect(() => {
    if (!amount) return;
    onChange(AmountHelper.formatInteger(amount));
  }, [amount, onChange]);

  const value = AmountHelper.formatInteger(storedAmount);

  return (
    <Input
      {...props}
      label="Сумма займа"
      name="amountField"
      value={value}
      onChange={onChange}
      format={AmountHelper.inputFormat}
    />
  );
};

const component = observer(Amount);
export { component as Amount };
