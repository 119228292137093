import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  display: flex;
  width: 100%;

  @media ${lessThanContainer('small')} {
    flex-direction: column;
    flex: 1;
  }
`;
