import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui/functions';

import { Button as Base } from './Button';

/**
 * Кнопка показа / скрытия цифр в поле ввода.
 */
export const Button = styled(Base)`
  @media ${lessThanContainer('xSmall')} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
