import styled from '@emotion/styled';

/**
 * Корневой элемент дополнительного текста подвала страницы.
 */
export const Root = styled.div`
  flex: 0 0 auto;

  font-size: 0.75rem;

  color: ${({ theme }) => theme.colors.light.text.primary};
`;
