import React, {
  ComponentPropsWithoutRef,
  FC,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { Root } from './SlideTransition.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'height'> & {
  /**
   * Статус видимости компонента.
   */
  visible?: boolean;
};

export const SlideTransition: FC<Props> = ({ visible, children, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null!);

  const [height, setHeight] = useState<number>(0);

  const updateHeight = useCallback(() => {
    if (containerRef.current && visible) {
      setHeight(containerRef.current.clientHeight);
    } else {
      setHeight(0);
    }
  }, [visible]);

  useEffect(() => {
    window.addEventListener('resize', updateHeight, { passive: true });
    updateHeight();

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [updateHeight]);

  return (
    <Root {...props} height={height}>
      <div ref={containerRef}>{children}</div>
    </Root>
  );
};
