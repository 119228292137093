import styled from '@emotion/styled';

/**
 * Head элемент Section.
 */
export const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
