import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { useMenu } from '../MenuContext';

import { Icon } from './Icon';
import { Root } from './Toggler.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Кнопка открытия меню мобильной версии.
 */
export const Toggler: FC<Props> = ({ ...props }) => {
  const { setOpen } = useMenu();

  const handleClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <Root {...props} type="button" onClick={handleClick}>
      <Icon />
    </Root>
  );
};
