import { FC } from 'react';

import { usePreloaderTask } from 'modules/common/preloader';

/**
 * Компонент, который отрисовывается вместо содержимого страницы в момент
 * подгрузки данных.
 */
export const PageLoading: FC = () => {
  usePreloaderTask();

  return null;
};
