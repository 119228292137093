import styled from '@emotion/styled';

import { Note as Base } from './Note';

/**
 * Элемент с дополнительным текстом.
 */
export const Note = styled(Base)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.color.text.main.great};

  margin-top: 2rem;
`;
