import { FC, ComponentPropsWithoutRef, useContext } from 'react';

import { SlideTransition } from 'modules/common/transition';

import { Context } from '../Context';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof SlideTransition>, 'visible'>;

/**
 * Раскрываемая область для группы чекбоксов.
 */
export const Expandable: FC<Props> = ({ ...props }) => {
  const { expanded } = useContext(Context);
  return <SlideTransition {...props} visible={expanded} />;
};
