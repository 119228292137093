import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

import largeBackground from './assets/largeBackground.png';
import smallBackground from './assets/smallBackground.png';

export const Root = styled.div`
  position: relative;
  z-index: 0;

  padding: 66px 0;

  background-image: url(${largeBackground});
  background-position: center center;
  background-color: black;
  background-size: cover;

  @media ${lessThanContainer('small')} {
    position: sticky;
    top: 0;

    padding: 30px 24px 70px 24px;

    background-image: url(${smallBackground});
    background-position: center 70%;
  }
`;
