import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';
import { lessThanContainer } from 'modules/common/ui/functions';

/**
 * Корневой элемент кнопки переключения режима отображения ПИН-кода.
 */
export const Root = styled.button`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  width: 4.375rem;
  height: 4.375rem;

  border-style: solid;
  border-width: 1.5px;
  border-radius: 0.75rem;

  transition: ${transition('border-color', 'box-shadow')};

  background-color: ${({ theme }) =>
    theme.colors.light.input.background.default};
  border-color: ${({ theme }) => theme.colors.light.input.border.default};
  box-shadow: none;

  :hover {
    border-color: ${({ theme }) => theme.colors.light.input.border.hover};
  }

  :focus {
    background-color: ${({ theme }) =>
      theme.colors.light.input.background.default};
    border-color: ${({ theme }) =>
      theme.colors.light.input.border.focus} !important;
    outline: none;
  }

  :active {
    box-shadow: none;
  }

  @media ${lessThanContainer('small')} {
    width: 3rem;
    height: 3rem;
  }
`;
