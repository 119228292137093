import { FC, ReactNodeArray } from 'react';

import { Helper } from 'modules/docs';
import { Store as LoanStore } from 'modules/loan';
import { Document } from 'services/RpcService/types/Document';

import { Item } from '../Item';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Список моделей документов, которые следует отобразить.
   */
  documents: Document[];
};

/**
 * Отображает список документов.
 */
export const List: FC<Props> = ({ documents }) => {
  const loan = LoanStore.use();

  const content: ReactNodeArray = [];
  const { length } = documents;

  for (let i = 0; i < length; i += 1) {
    const model = documents[i];
    const text = Helper.getNameForContract(model as any, loan.model);

    content.push(
      <Item href={model?.url} name={model.type} text={text} key={model.type} />,
    );
  }

  return <>{content}</>;
};
