import React, { Component, ComponentPropsWithoutRef } from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import { Root } from './Form.Root';

/*
 * Свойства компонента.
 */
type Props<TSchema extends yup.ObjectSchema<any> = any> = Omit<
  ComponentPropsWithoutRef<'form'>,
  'noValidate' | 'onSubmit' | 'onReset'
> &
  Omit<
    ComponentPropsWithoutRef<typeof Formik>,
    | 'component'
    | 'render'
    | 'as'
    | 'children'
    | 'onSubmit'
    | 'validationSchema'
    | 'initialValues'
  > & {
    /**
     * Имя класса формы.
     */
    className?: string;

    /**
     * Схема элементов ввода формы и их валидации.
     */
    schema?: TSchema;

    /**
     * Изначальные значения элементов ввода.
     */
    initialValues?: yup.InferType<TSchema>;

    /**
     * Должен ли Formik сбрасывать форму при изменении initialValues.
     */
    enableReinitialize?: boolean;

    /**
     * Уникальное(в пределах группы) имя формы.
     */
    name?: string;

    /**
     * Обрабатывает успешную отправку формы.
     * @param values Значения полей формы.
     */
    onSubmit?: (values: yup.InferType<TSchema>) => void;

    /**
     * Обрабатывает ошибку валидации при отправке формы.
     * @param errors Коллекция ошибок валидации.
     */
    onErrors?: (errors: Record<any, string>) => void;
  };

export class Form<TSchema extends yup.ObjectSchema<any>> extends Component<
  Props<TSchema>
> {
  /**
   * Отображает форму.
   */
  private renderForm = (formik: FormikProps<any>) => {
    const { autoComplete, name, onSubmit, onErrors, children, className } =
      this.props;
    const { handleSubmit, submitForm, values, errors } = formik;

    return (
      <Root
        className={className}
        name={name}
        values={values}
        errors={errors}
        onSubmit={onSubmit}
        onErrors={onErrors}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
        autoComplete={autoComplete}
      >
        {children}
      </Root>
    );
  };

  private handleSubmit = (values: yup.InferType<TSchema>) => {
    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit(values);
    }
  };

  private getInnerInitialTouched = (value: {}) => {
    return Object.keys(value).reduce((result: any, key: string) => {
      // @ts-ignore
      const initialValue = value[key];

      if (Array.isArray(initialValue) && initialValue.length !== 0) {
        const initialValues: Array<{}> = [];
        for (let i = 0; i < initialValue.length; i += 1) {
          initialValues.push(this.getInnerInitialTouched(initialValue[i]));
        }

        return { ...result, [key]: initialValues };
      }

      return { ...result, [key]: initialValue != null };
    }, {});
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
      initialValues,
      enableReinitialize = false,
      initialErrors,
      initialStatus,
      initialTouched,
      validate,
      validateOnBlur,
      validateOnChange,
      validateOnMount,
      schema,
      onReset,
    } = this.props;

    const innerInitialValues =
      (initialValues || schema?.getDefaultFromShape()) ?? {};

    const innerInitialTouched =
      initialTouched ??
      (innerInitialValues != null
        ? this.getInnerInitialTouched(innerInitialValues)
        : undefined);

    return (
      <Formik
        initialValues={innerInitialValues}
        enableReinitialize={enableReinitialize}
        initialErrors={initialErrors}
        initialStatus={initialStatus}
        initialTouched={innerInitialTouched}
        validate={validate}
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        validateOnMount={validateOnMount}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        onReset={onReset}
      >
        {this.renderForm}
      </Formik>
    );
  }
}
