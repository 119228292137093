import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Button, Announcement } from 'modules/common/ui';

import { Store } from '../../stores';

import error from './assets/error.svg';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Announcement>,
  'img' | 'alt' | 'heading'
>;

/**
 * Компонент отображающий "Ошибку в операции" на информационной странице платежей.
 */
export const Failure: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleClick = useCallback(() => {
    store.goToAccount();
  }, [store]);

  return (
    <Announcement
      {...props}
      img={error}
      alt="Ошибка операции"
      heading="Что-то пошло не так"
      subheading="Не удалось произвести оплату"
    >
      <Button variant="filled" onClick={handleClick}>
        Попробовать снова
      </Button>
    </Announcement>
  );
};
