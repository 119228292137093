import styled from '@emotion/styled';

import { LinkButton as Base, lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент компонента Back.
 */
export const Root = styled(Base)`
  display: inline-block;

  margin-bottom: 1.5rem;

  font-style: normal;
  font-weight: normal;
  font-size: 0.812rem;
  line-height: 1rem;
  letter-spacing: 0.00625rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  @media ${lessThanContainer('small')} {
    margin-bottom: 0.812rem;
  }
`;
