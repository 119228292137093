import { ComponentPropsWithoutRef, FC } from 'react';

import { AmountHelper } from 'modules/common/values';

import { ParamsCommon } from '../ParamsCommon';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof ParamsCommon> & {
  /**
   * Значение суммы займа.
   */
  value?: number;
};

/**
 * Компонент суммы займа.
 */
export const ParamsAmount: FC<Props> = ({
  title = 'Сумма займа',
  value,
  ...props
}) => {
  let text: string = '';

  if (value != null) {
    const unit = AmountHelper.getShortUnits();
    const item = AmountHelper.formatDisplayFloat(value);
    text = `${item} ${unit}`;
  }

  return (
    <ParamsCommon {...props} title={title}>
      {text}
    </ParamsCommon>
  );
};
