import styled from '@emotion/styled';

import { LinkButton as Link } from 'modules/common/ui';
import { transition } from 'modules/common/theme';

/**
 * Ссылка элемента меню бокового меню подвала.
 */
export const LinkButton = styled(Link)`
  display: inline;

  padding: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;

  border-bottom: 1px transparent;
  border-radius: 0;

  transition: ${transition('border-bottom')};
  text-decoration: none;
  outline: none;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.light.text.primary};

    color: inherit;
  }
`;
