import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { InferType } from 'yup';

import { Store as ClientStore } from 'modules/client';
import { Form as Base, FormItem } from 'modules/application/common';
import { Store as StatementStore } from 'modules/application/statement';
import { UserType } from 'services/RpcService/types/UserType';
import { PassportHelper } from 'modules/common/values';

import { Field } from '../../types';
import { schema } from '../../schemas';

import { PassportNumber } from '../PassportNumber';
import { BirthDate } from '../BirthDate';
import { BirthPlace } from '../BirthPlace';
import { PassportCode } from '../PassportCode';
import { PassportDate } from '../PassportDate';
import { PassportUnit } from '../PassportUnit';
import { Photo } from '../Photo';
import { InnSnils, Note } from '../InnSnils';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'schema' | 'initialValues' | 'onSubmit'
>;

/**
 * Форма паспортных данных пользователя.
 */
export const Form: FC<Props> = ({ ...props }) => {
  const clientStore = ClientStore.use();
  const statementStore = StatementStore.use();

  const handleSubmit = useCallback(
    (form: InferType<typeof schema>) => {
      statementStore.updateValues({
        passportNumber: form.passportNumber,
        birthDate: form.birthDate,
        birthPlace: form.birthPlace,
        passportCode: form.passportCode,
        passportDate: form.passportDate,
        innSnils: form.innSnils ?? undefined,
        passportUnit: form.passportUnit,
        passportPhoto: form.photo,
      });
    },
    [statementStore],
  );

  if (clientStore.model == null || statementStore.model == null) {
    return null;
  }

  const disabled = clientStore.model.type === UserType.Repeat;
  const model = statementStore.model;

  const initialValues = {
    [Field.NUMBER]: model.passportNumber,
    [Field.BIRTH_DATE]: model.birthDate,
    [Field.BIRTH_PLACE]: model.birthPlace,
    [Field.CODE]: model.passportCode
      ? PassportHelper.formatCode(model.passportCode)
      : undefined,
    [Field.DATE]: model.passportDate,
    [Field.INN_SNILS]: model.innSnils,
    [Field.UNIT]: model.passportUnit,
    [Field.PHOTO]: model.passportPhoto,
  };

  return (
    <Base
      {...props}
      schema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <FormItem width="half">
        <PassportNumber
          label="Серия и номер"
          name={Field.NUMBER}
          autoFocus
          disabled={disabled}
        />
      </FormItem>
      <FormItem width="half">
        <BirthDate
          label="Дата рождения"
          name={Field.BIRTH_DATE}
          disabled={disabled}
        />
      </FormItem>
      <FormItem>
        <BirthPlace
          label="Место рождения"
          name={Field.BIRTH_PLACE}
          disabled={disabled}
        />
      </FormItem>
      <FormItem width="half">
        <PassportCode
          label="Код подразделения"
          name={Field.CODE}
          disabled={disabled}
        />
      </FormItem>
      <FormItem width="half">
        <PassportDate
          label="Дата выдачи"
          name={Field.DATE}
          disabled={disabled}
        />
      </FormItem>
      <FormItem>
        <PassportUnit label="Кем выдан" name={Field.UNIT} disabled={disabled} />
      </FormItem>
      <FormItem width="half">
        <InnSnils
          label="ИНН / СНИЛС"
          name={Field.INN_SNILS}
          disabled={disabled}
        />
      </FormItem>
      <FormItem width="half">
        <Note>Заполненное поле ИНН / СНИЛС повышает шанс получения займа</Note>
      </FormItem>
      <FormItem>
        <Photo label="Фото паспорта" name={Field.PHOTO} disabled={disabled} />
      </FormItem>
    </Base>
  );
};
