import { FC } from 'react';

import { PageLoading } from 'modules/common/layout';
import { FetchableProvider } from 'modules/common/stores';

import { Store as StoreLoan } from 'modules/loan';
import { Store as ClientStore } from 'modules/client';
import { Store } from '../Store';

/**
 * Отображает своё содержимое только тогда, когда сведения о реструктуризации будут
 * загружены с сервера. До тех пор отображает компонент загрузки страницы
 * `common.layout.PageLoading`.
 */
export const Provider: FC = ({ children }) => {
  const store = Store.use();
  const loan = StoreLoan.use();
  const client = ClientStore.use();

  return (
    <FetchableProvider
      stores={[store, loan, client]}
      fallback={<PageLoading />}
    >
      {children}
    </FetchableProvider>
  );
};
