import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заголовок.
 */
export const Heading = styled.div`
  font-weight: 600;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.light.text.primary};

  padding-bottom: 1.25rem;

  @media ${lessThanContainer('small')} {
    font-weight: bold;
    font-size: 1.25rem;
  }
`;
