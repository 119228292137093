import BaseApp from 'next/app';
import { ThemeProvider } from '@emotion/react';
import { enableStaticRendering } from 'mobx-react';

import { Sentry } from 'modules/common/sentry';
import { PoolProvider as ServiceProvider } from 'modules/common/reset';
import { Provider as RoutingProvider, Page } from 'modules/common/routing';
import { Provider as AuthProvider } from 'modules/common/auth';
import { Provider as MetricsProvider } from 'modules/common/metrics';
import { Global, Header, Footer, ErrorBoundary } from 'modules/common/layout';
import { FadeTransitionStyle } from 'modules/common/transition';
import { ApolloTheme } from 'modules/common/theme';
import { LayoutProvider } from 'modules/common/layout/components/LayoutContext';
import { PreloaderContextProvider } from 'modules/common/preloader';
import { MenuProvider } from 'modules/common/layout/components/Header/MenuContext';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'modules/home/components/How/assets/swiper-home.css';

enableStaticRendering(typeof window === 'undefined');
Sentry.enable(typeof window !== 'undefined');

/**
 * Компонент приложения, который осуществляет отрисовку страниц и является
 * входной точкой маршрутизатора.
 */
class App extends BaseApp {
  /**
   * @inheritdoc
   */
  render() {
    const { Component, pageProps, router } = this.props;
    const { pathname, query } = router;

    const isFromApp = query.fromApp && pathname === Page.DOCUMENTS;

    return (
      <ServiceProvider>
        <RoutingProvider router={router}>
          <MetricsProvider>
            <LayoutProvider>
              <MenuProvider>
                <ThemeProvider theme={ApolloTheme}>
                  <PreloaderContextProvider>
                    <Global />
                    <FadeTransitionStyle />
                    <AuthProvider>
                      {!isFromApp && <Header />}
                      <ErrorBoundary>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                      {!isFromApp && <Footer />}
                    </AuthProvider>
                  </PreloaderContextProvider>
                </ThemeProvider>
              </MenuProvider>
            </LayoutProvider>
          </MetricsProvider>
        </RoutingProvider>
      </ServiceProvider>
    );
  }
}

export default App;
