import { FC, PropsWithChildren, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Page, Helper, Redirect } from 'modules/common/routing';

import { Store } from '../../stores';

/**
 * Свойства компонента.
 */
type Props = PropsWithChildren<{
  /**
   * Указывает, что не следует использовать механизм Back URL (сохранить
   * текущий адрес, на который пользователь пока не может попасть, и после
   * авторизации перенаправить сделать перенаправление на него).
   * @default `false`
   */
  noBackUrl?: boolean;

  /**
   * Элемент, который должен отображаться вместо содержимого компонента, пока
   * происходят проверки.
   */
  fallback?: ReactNode;
}>;

/**
 * Содержимое данного компонента будет показано лишь в том случае, когда
 * текущий пользователь авторизован. Если же нет, пользователь будет
 * перенаправлен в раздел авторизации, и лишь после неё вернётся на
 * запрашиваемую страницу.
 */
const AuthorizedOnly: FC<Props> = ({
  noBackUrl = false,
  fallback = null,
  children,
}) => {
  const router = useRouter();
  const store = Store.use();
  if (!store.initialized) {
    return <>{fallback}</>;
  }

  if (store.isAuthorized) {
    return <>{children}</>;
  }

  if (!noBackUrl) {
    const { pathname, query } = router;

    const backUrl = Helper.combineUrl(pathname, query);
    store.setBackUrl(backUrl);
  }

  return <Redirect url={Page.LOGIN}>{fallback}</Redirect>;
};

const component = observer(AuthorizedOnly);
export { component as AuthorizedOnly };
