import { FC, SVGProps } from 'react';

/**
 * Иконка "Банковская карточка"
 */
export const Card: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x=".8"
      y="4"
      width="22.4"
      height="16"
      rx="2.4"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      clipRule="evenodd"
      d="M9.6 15.2a3.2 3.2 0 100-6.4 3.2 3.2 0 000 6.4z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M12.154 14.28a3.2 3.2 0 10-.054-4.505"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
