import styled from '@emotion/styled';

/**
 * Корневой элемент обёртки основного содержимого страницы.
 */
export const Root = styled.main`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;
