import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Form as Base, InputGroup, Field as UiField } from 'modules/common/ui';

import { Field } from '../../../consts';
import { Store } from '../../../stores';

import { Input } from '../Input';

import { schema } from './Form.Schema';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base> & {
  /**
   * Хранилище состояния.
   */
  store: Store;
};

/**
 * Отображает форму ввода кода подтверждения.
 */
const Form: FC<Props> = ({ store, ...props }) => {
  const handleSubmit = useCallback(
    async (values: any) => {
      const code = values[Field.CODE] as string;
      store.confirmCode(code);
    },
    [store],
  );

  return (
    <Base {...props} schema={schema} onSubmit={handleSubmit}>
      <UiField
        htmlFor={Field.CODE}
        name={Field.CODE}
        error={store.error}
        errorPriority="outer"
        nativeErrors={false}
      >
        <InputGroup>
          <Input store={store} placeholder="Введите код из СМС" />
        </InputGroup>
      </UiField>
    </Base>
  );
};

const component = observer(Form);
export { component as Form };
