import styled from '@emotion/styled';

import { Container as Element } from 'modules/common/ui';

/**
 * Контейнер страницы Repay.
 */
export const Container = styled(Element)`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;
