import React, { FC, SVGProps } from 'react';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGElement>, 'ref'>;

/**
 * Отображает иконку открытия/закрытия калькулятора в анкете.
 */
export const Icon: FC<Props> = (props) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.00002 3.77843L8.77324 0.671074C9.09299 0.407755 9.56565 0.453497 9.82897 0.773241C10.0923 1.09298 10.0465 1.56565 9.7268 1.82897L5.4768 5.32897C5.19986 5.55704 4.80018 5.55704 4.52324 5.32897L0.273241 1.82897C-0.0465032 1.56565 -0.092245 1.09298 0.171074 0.773241C0.434392 0.453497 0.907058 0.407755 1.2268 0.671074L5.00002 3.77843Z"
      fill="currentColor"
    />
  </svg>
);
