import React, { FC } from 'react';

import { Text } from './Current.Text';

/**
 * Последний элемент списка хлебной крошки.
 */
export const Current: FC = ({ children, ...props }) => {
  return (
    <li {...props}>
      <Text>{children}</Text>
    </li>
  );
};
