import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;

  height: 105px;
  background: ${({ theme }) => theme.colors.light.background.secondary};
  border-radius: 1rem;
  cursor: pointer;
`;
