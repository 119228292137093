import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';
import { Root as Heading } from 'modules/common/ui/components/Heading/Heading.Root';
import { Container } from '../../loan/Dashboard/Dashboard.Container';
import { Root as Banner } from './BannerV2.Root';

export const Wrapper = styled.div`
  ${Container} {
    position: relative;
    z-index: 1;

    background: ${({ theme }) => theme.colors.light.background.primary};

    @media ${lessThanContainer('small')} {
      padding-top: 32px;
      padding-bottom: 36px;
      margin-top: -40px;
      margin-bottom: 0;

      border-radius: 40px 0 0 0;
    }
  }

  ${Banner} {
    z-index: 0;
  }

  ${Heading} {
    padding-left: 24px;
  }
`;
