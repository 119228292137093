import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import type { UrlObject } from 'url';

/**
 * Url адрес.
 */
type Url = UrlObject | string;

/**
 *  Свойства выполнения перехода.
 */
type TransitionOptions = {
  /**
   * Опциональный параметр, обновить путь к текущей странице без повторного
   * запуска
   */
  shallow?: boolean;

  /**
   * Опциональный параметр, языковой стандарт
   */
  locale?: string | false;

  /**
   * Опциональный параметр, элементы управления прокручиваются в
   * верхнюю часть страницы после навигации.
   */
  scroll?: boolean;
};

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Маршрут для перехода.
   */
  url: Url;

  /**
   * Опциональный декоратор адреса, отображаемый в браузере.
   */
  as?: Url;

  /**
   *  Опциональный параметр выполнения перехода.
   */
  options?: TransitionOptions;

  /**
   * Параметры выполнения перехода. Если `true`, то предотвращает добавление
   * новой записи URL-адреса в стек истории.
   * @default false
   */
  replace?: boolean;
};

/**
 * Компонент перенаправления маршрута.
 */
export const Redirect: FC<Props> = ({
  children,
  url,
  as,
  options,
  replace = false,
}) => {
  const router = useRouter();

  useEffect(() => {
    if (replace) {
      router.replace(url, as, options);
    } else {
      router.push(url, as, options);
    }
  });

  return <>{children}</>;
};
