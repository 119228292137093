import { FC } from 'react';

import { Root } from './CalculatorV2.Root';
import { Footer } from './CalculatorV2.Footer';
import { Amount } from './Amount';
import { Period } from './Period';
import { Details } from './Details';

export const CalculatorV2: FC = () => {
  return (
    <Root>
      <Amount />
      <Period />
      <Footer>
        <Details />
      </Footer>
    </Root>
  );
};
