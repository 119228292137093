import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Payments as Element } from '../Payments';

/**
 * Компонент с вариантами платежа.
 */
export const Payments = styled(Element)`
  margin-top: 1.5rem;

  @media ${lessThanContainer('small')} {
    margin-top: 1.25rem;
  }
`;
