import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  padding: 0 0 12px 0;
  margin: 0;

  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.light.text.overlay};

  @media ${lessThanContainer('small')} {
    font-size: 13px;
    line-height: 20px;
  }
`;
