import styled from '@emotion/styled';

/**
 *  Единица списка бокового меню в подвале страницы.
 */
export const Item = styled.li`
  margin-top: 1rem;

  :first-of-type {
    margin-top: 0;
  }
`;
