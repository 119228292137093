import React, { FC, ComponentProps, ChangeEvent, useCallback } from 'react';

import { withFormControl } from '../../hocs';

import { Point } from '../Point';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentProps<typeof Point>,
  'type' | 'onChange' | 'value' | 'checked'
> & {
  /**
   * Порядок отображения элементов Point / Children.
   */
  direction?: 'default' | 'reverse';

  /**
   * Вариант отображения элемента.
   */
  variant?: 'default' | 'solid';

  /**
   * Обрабатывает изменение значения поля ввода.
   * @param value Новое значение.
   */
  onChange?: (value: boolean) => void;

  /**
   * Текущее значение поля ввода.
   */
  value?: boolean;
};

/**
 * Отображает чекбокс.
 */
const Checkbox: FC<Props> = ({ onChange, value, ...props }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target as HTMLInputElement;

      if (onChange) {
        const { checked } = input;
        onChange(checked);
      }
    },
    [onChange],
  );

  return <Point {...props} onChange={handleChange} checked={value || false} />;
};

const component = withFormControl(undefined)(Checkbox);
export { component as Checkbox };
