import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './MockBlock.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'theme' | 'as'>;

/**
 * Отображает цветной блок, который является заглушкой для ещё не реализованных
 * блоков во время вёрстки.
 */
export const MockBlock: FC<Props> = ({ ...props }) => <Root {...props} />;
