import styled from '@emotion/styled';

import { Text as Base } from './Point.Text';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Порядок отображения элементов Point / Children.
   */
  direction?: 'default' | 'reverse';
};

export const Root = styled.label<Props>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.direction === 'reverse' ? 'row-reverse' : 'row'};

  cursor: pointer;
`;

export const Text = styled(Base)`
  a {
    text-decoration: none;

    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  button {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-color: inherit;
    border-radius: 0;
  }
`;
