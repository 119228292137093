import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Text } from './Menu.Text';
import { Root as SubMenu } from './SubMenu/SubMenu.Root';

/**
 * Единица элемента меню шапки.
 */
export const Item = styled.li`
  position: relative;

  margin-left: 1.5rem;

  :hover > ${SubMenu} {
    display: block;
  }

  :hover ${Text} {
    z-index: 2;

    color: ${({ theme }) => theme.color.primary.lighter};

    cursor: default;
  }

  @media ${lessThanContainer('medium')} {
    margin-left: 0;
  }
`;
