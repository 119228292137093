import { FC, ComponentPropsWithoutRef, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../../../stores';

import { NavigationTop as Base } from '../../NavigationTop';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base>;

/**
 * Отображает навигацию по шагам заявки над формой.
 */
const NavigationTop: FC<Props> = ({ step, ...props }) => {
  const store = Store.use();

  const handleBack = useCallback(() => {
    store.back(step);
  }, [store, step]);

  return (
    <Base {...props} step={step} pending={store.pending} onBack={handleBack} />
  );
};

const component = observer(NavigationTop);
export { component as NavigationTop };
