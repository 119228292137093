import { FC } from 'react';
import Head from 'next/head';

import { PageHeader } from 'modules/common/layout';
import { Container } from 'modules/common/ui';

import { Section } from 'modules/application/common';
// import { Form as ContactPersonsForm } from 'modules/application/contactPersons';
import { Form as PassportForm } from 'modules/application/passport';
import { Form as AddressForm } from 'modules/application/residence';
import { Form as FamilyForm } from 'modules/application/family';

import { FormName } from '../../types';

import { StepAccess } from '../StepAccess';
import { StepForms } from '../StepForms';
import { Step } from '../Step';
import { ApplicationCalculator } from '../ApplicationCalculator';
import { PageContent } from '../PageContent';

/**
 * Отображает содержимое страницы второго шага заполнения заявки.
 */
export const SecondStepPage: FC = () => (
  <StepAccess step={2}>
    <PageHeader isContainerMedium>
      <ApplicationCalculator />
    </PageHeader>
    <PageContent>
      <Head>
        <title>Аполлон-займ - оформление заявки, шаг 2 из 3</title>
      </Head>
      <Container size="medium">
        <StepForms step={2}>
          <Section heading="Паспортные данные" aside={<Step>2</Step>}>
            <PassportForm name={FormName.PASSPORT} />
          </Section>

          <Section heading="Проживание">
            <AddressForm name={FormName.ADDRESS} />
          </Section>

          <Section heading="Семья">
            <FamilyForm name={FormName.FAMILY} />
          </Section>

          {/* 
            Блок "Контактные лица" временно убран в задаче WL-2396
          */}
          {/* <Section heading="Контактные лица">
            <ContactPersonsForm name={FormName.CONTACT_PERSONS} />
          </Section> */}
        </StepForms>
      </Container>
    </PageContent>
  </StepAccess>
);
