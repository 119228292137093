import { FC, ComponentPropsWithoutRef, ReactElement } from 'react';
import { AmountHelper, PeriodHelper } from 'modules/common/values';
import { Root } from './ApplicationCalculator.Text.Root';
import { Root as Bold } from './ApplicationCalculator.TextBold.Root';
import { Root as Icon } from './ApplicationCalculator.Icon';

/**
 * Свойства компонента
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Вводный текст
   */
  intro?: string;
  /**
   * Сумма заёма
   */
  amount?: number;
  /**
   * Текст разделителя
   */
  separator?: string;
  /**
   * Текст периода заёма
   */
  period?: number;
  /**
   * Показывать ли иконку
   */
  icon?: ReactElement;
  /**
   *  Состояние направления иконки
   */
  isOpened: boolean;
};

/**
 * Отображает текст над кальулятором в анкете
 */
export const Text: FC<Props> = ({
  intro,
  amount,
  separator,
  period,
  icon,
  isOpened,
  ...props
}) => {
  const amountFormatted = AmountHelper.formatDisplayIntegerWithUnit(
    amount || 0,
  );

  const periodFormatted = PeriodHelper.formatDisplay(period || 0);

  return (
    <Root isOpened={isOpened} {...props}>
      {intro} <Bold>{amount && amountFormatted}</Bold> {separator}{' '}
      <Bold>{period && periodFormatted}</Bold>
      {icon ? <Icon isOpened={isOpened}>{icon}</Icon> : null}
    </Root>
  );
};
