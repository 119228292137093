import styled from '@emotion/styled';

import { Container } from 'modules/common/ui/components';

/**
 * Корневой элемент.
 */
export const Root = styled(Container)`
  text-align: center;

  margin-bottom: auto;
  margin-top: auto;

  padding-top: 4rem;
  padding-bottom: 4rem;
`;
