import { Helper as Base } from '@devim-front/helper';

import { Helper as LoanHelper, Model as LoanModel } from 'modules/loan';

import { Model } from './Model';
import { Type } from './Type';

/**
 * Содержит методы для работы с документами.
 */
export class Helper extends Base {
  /**
   * Преобразует тип документа, полученный от RPC API, в системный тип
   * документа.
   * @param value Тип документа, полученный от RPC API.
   * @returns
   */
  private static parseRpcType(value: any) {
    switch (value) {
      case 'loan_agreement': {
        return Type.LOAN;
      }
      case 'service_agreement': {
        return Type.SERVICE;
      }
      case 'schedule_document': {
        return Type.SCHEDULE;
      }
      case 'application_form': {
        return Type.APPLICATION;
      }
      case 'agreement_restructuring': {
        return Type.RESTRUCTURING;
      }
      case 'shedule_document': {
        return Type.RESTRUCTURING_SHEDULE;
      }
      case 'agreement_frequency_interaction': {
        return Type.AGREEMENT_FREQUENCY_INTERACTION;
      }
      case 'loan_collector_notice': {
        return Type.LOAN_COLLECTOR_NOTICE;
      }
      default: {
        throw new Error(`Unexpected RPC type of document ${value}`);
      }
    }
  }

  /**
   * Преобразует значение из массива документов, полученного от API, в
   * модель.
   * @param value Значение из массива, полученного от API.
   */
  public static parseRpc(value: any): Model {
    const type = this.parseRpcType(value.type);
    const href = String(value.url);
    return { type, href, name: value.name };
  }

  /**
   * Возвращает название указанного документа для отображения в списке
   * документов при подписании займа.
   * @param model Модель документа.
   * @param loan Модель заёма.
   */
  public static getNameForContract(model: Model, loan: LoanModel) {
    return this.getName(model, loan);
  }

  /**
   * Возвращает название указанного документа полностью, с заглавной буквы и в
   * именительном падеже.
   * @param model Модель документа.
   * @param loan Модель займа, к которому относится данный документ.
   */
  public static getName(model: Model, loan: LoanModel) {
    switch (model.type) {
      case Type.APPLICATION: {
        return 'Анкета-заявление';
      }

      case Type.LOAN: {
        return `Договор №${loan.number}`;
      }

      case Type.SCHEDULE: {
        return 'График платежей';
      }

      case Type.SERVICE: {
        const service = LoanHelper.getPackageName(loan);
        return `Абонентский договор в рамках пакета «${service}»`;
      }

      case Type.RESTRUCTURING: {
        return `Согласие с дополнительным соглашением на опцию Комфортный платёж`;
      }

      case Type.RESTRUCTURING_SHEDULE: {
        return `Я согласен(а) с графиком платежей`;
      }

      case Type.AGREEMENT_FREQUENCY_INTERACTION: {
        return `Соглашение о способах и частоте взаимодействия с должником`;
      }

      case Type.LOAN_COLLECTOR_NOTICE: {
        return `Уведомление о взыскании просроченной задолженности`;
      }

      default: {
        throw new Error(`Unknown document type ${model.type}`);
      }
    }
  }

  /**
   * Возвращает название документа с заглавной буквы и в
   * именительном падеже для списка документов в активном займе.
   * @param model Модель документа.
   * @param loan Модель займа, к которому относится данный документ.
   */
  public static getNameForLoan(model: Model, loan: LoanModel) {
    return this.getName(model, loan);
  }
}
