import { ComponentPropsWithoutRef, FC } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../Store';
import { Type } from '../Type';
import { Full } from './Full';
import { Minimal } from './Minimal';
import { Overdue } from './Overdue';

import { Root } from './Payments.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент для выбора размера платежа при погашении займа.
 */
const Payments: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  return (
    <Root {...props}>
      <Full />
      {store.model?.expirationPayment?.amount ? <Overdue /> : null}
      {(store.model?.type === Type.INSTALLMENT || store.isRestructured) &&
      !store.model?.expirationPayment?.amount ? (
        <Minimal />
      ) : null}
    </Root>
  );
};

const component = observer(Payments);
export { component as Payments };
