import { Service as Api } from '@devim-clickhouse/core';

/**
 * Сервис для работы с API Clickhouse
 */
export class ClickhouseService {
  /**
   * Экземпляр API ClickHouse.
   */
  private static api: Api = new Api();

  /**
   * Инициализирует сервис.
   * @param url Адрес API.
   * @param id Идентификатор API.
   * @param clientId Идентификатор API.
   */
  public static async init(url?: string, id?: string, clientId?: string) {
    this.api = new Api({
      url,
      id,
      clientId,
      verbose: process.env.NODE_ENV !== 'production',
    });

    try {
      await this.api.sendVisit();
    } catch {}
  }

  /**
   * Записывает событие в метрику.
   * @param event Событие.
   * @param payload Дополнительный параметр события.
   * @param fields Дополнительные поля в таблице кликхаус
   */
  public static async push(
    event: string,
    payload?: Record<string, any>,
    fields?: Record<string, any>,
  ) {
    try {
      await this.api.send(event, {
        payload,
        operator: false,
        ...fields,
      });
    } catch {}
  }
}
