import {
  PureComponent,
  ComponentPropsWithoutRef,
  ReactNodeArray,
  createRef,
} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import { Content } from './Options.Content';
import { Root } from './Options.Root';

import { Option } from '../Option';

/**
 * Список значений выпадающего списка или функция, его возвращающая.
 * @param search Строка для поиска.
 */
type Values = ((search: string) => any[] | Promise<any[]>) | any[];

/**
 * Свойство, в котором хранится скрытый идентификатор функции для кэширования
 * её результатов.
 */
const CACHED_KEY = Symbol('CACHED_KEY');

/**
 * Узел кэша загруженных значений.
 */
type CacheNode = Map<string, any[]>;

/**
 * Кэш загруженных значений.
 */
type Cache = Map<number, CacheNode>;

/**
 * Функция, которая на основе переданного значения генерирует содержимое
 * соответствующего ему элемента выпадающего списка.
 * @param value Значение.
 */
type Format = (value: any) => string;

/**
 * Функция, которая на основе переданного значения генерирует соответствующий
 * ему уникальный ключ.
 * @param value Значение.
 */
type Unique = (value: any) => string;

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'children'> & {
  /**
   * Если свойство задано, то перед списком вариантов отображается служебный
   * вариант, при выборе которого поле должно быть очищено.
   */
  reset?: boolean | string;

  /**
   * Выбранное в данный момент значение.
   */
  value?: any;

  /**
   * Обрабатывает выбор нового значения.
   * @param value Новое значение или `undefined`, если поле ввода было очищено.
   */
  onChange?: (value?: any) => void;

  /**
   * Список значений, доступных для выбора в данном списке, или функция,
   * которая его возвращает.
   */
  values: Values;

  /**
   * Строка для поиска.
   */
  search: string;

  /**
   * Функция, которая на основе переданного значения генерирует содержимое
   * соответствующего ему элемента выпадающего списка.
   * @param value Значение.
   */
  format: Format;

  /**
   * Функция, которая на основе переданного значения генерирует соответствующий
   * ему уникальный ключ.
   * @param value Значение.
   */
  unique: Unique;

  /**
   * Указывает, что функцию загрузки значений можно вызывать.
   */
  allowValues: boolean;

  /**
   * Указывает, что для пустой входной строки функцию загрузки вариатов тоже
   * следует вызывать.
   */
  allowEmpty: boolean;

  /**
   * Минимальная длина поисковой строки, для которой запускается функция
   * загрузки вариантов. В противном случае поле ввода находится в состоянии
   * загрузки.
   */
  minLength: number;

  /**
   * Обрабатывает изменение состояния процесса генерации списка значений.
   * @param pending Указывает, происходит ли в данный момент процесс
   * генерации списка значений.
   */
  onPendingChange?: (pending: boolean) => void;
};

/**
 * Состояние компонента.
 */
type State = {
  /**
   * Порядковый номер текущего подсвеченного элемента.
   */
  highlighted: number;

  /**
   * Указывает, что в данный момент происходит асинхронная генерация списка
   * значений.
   */
  pending: boolean;

  /**
   * Список значений для отрисовки.
   */
  values: any[];
};

/**
 * Отображает список вариантов для выбора.
 */
export class Options extends PureComponent<Props, State> {
  /**
   * Последний присвоенный ключ, по которому значения свойства `values`
   * кэшируются.
   */
  private static lastCachedKey: number = 0;

  /**
   * Возвращает ключ, по которому значение свойства `values` кэшируется.
   * @param values Функция, загружающая значения или массив значений.
   */
  private static getCacheKey(values: Values) {
    // @ts-ignore
    let key = values[CACHED_KEY] as number | undefined;

    if (key == null) {
      this.lastCachedKey += 1;
      key = this.lastCachedKey;

      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      values[CACHED_KEY] = key;
    }

    return key;
  }

  /**
   * Функция загрузки вариантов по поисковой строке, которая никогда не
   * возвращает результата. Используется, чтобы показывать вечную загрузку
   * до тех пор, пока длина поисковой строки не превысит `minLength`.
   */
  private static infiniteValues() {
    return new Promise<any[]>(() => {});
  }

  /**
   * Ссылка на компонент области прокрутки.
   */
  private scrollbarsRef = createRef<Scrollbars>();

  /**
   * Ссылка на элемент, содержащий список вариантов.
   */
  private contentRef = createRef<HTMLDivElement>();

  /**
   * Сохранённое значение номера подсвеченного элемента. Используется
   * для того, чтобы не пересчитывать позицию прокрутки при каждом обновлении
   * компонента.
   */
  private previousHighlighted: number = 0;

  /**
   * Сохранённое значение свойства `values`. Используется для того, чтобы не
   * пересчитывать список значений при каждом обновлении компонента.
   */
  private previousValues?: Values = undefined;

  /**
   * Сохранённое значение свойства `search`. Используется для того, чтобы не
   * пересчитывать список значений при каждом обновлении компонента.
   */
  private previousSearch?: string = undefined;

  /**
   * Кэш результатов выполнения функций загрузки опций.
   */
  private cache: Cache = new Map();

  /**
   * Указывает, что компонент был отмонтирован от дерева.
   */
  private unmounted: boolean = false;

  /**
   * Таймаут вызова функции, которая загружает список значений асинхронно.
   */
  private timeout: any = undefined;

  /**
   * Указывает, что данный акт загрузки вариантов выбора будет первым.
   */
  private isInitial: boolean = true;

  /**
   * Задержка, через которую будет совершен следующий вызов функции получения
   * значения, если в данный момент загрузка списка значений уже идёт.
   */
  private defaultDelay: number = 750;

  /**
   * @inheritdoc
   */
  public state: State = {
    highlighted: 0,
    pending: false,
    values: [],
  };

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    this.touchValues();
    this.touchScrollbars();
  }

  /**
   * @inheritdoc
   */
  public componentDidUpdate() {
    this.touchValues();
    this.touchScrollbars();
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    this.unmounted = true;
  }

  /**
   * Указывает, что в данный момент происходит генерация списка значений для
   * выбора.
   */
  public get pending() {
    const { pending } = this.state;
    return pending;
  }

  /**
   * Количество элементов в списке. Если в данный момент список генерируется,
   * количество элементов равно `0`.
   */
  private get count() {
    const { pending, values } = this.state;
    const { length } = values;
    return pending ? 0 : length;
  }

  /**
   * Подсвечивает предыдущее значение по списку.
   */
  public highlightPrevious = () => {
    const { highlighted: previous } = this.state;

    const highlighted = Math.max(previous - 1, 0);
    this.setState({ highlighted });
  };

  /**
   * Подсвечивает первое значение из списка.
   */
  public highlightFirst = () => {
    this.setState({ highlighted: 0 });
  };

  /**
   * Подсвечивает следующее значение по списку.
   */
  public highlightNext = () => {
    const { highlighted: previous } = this.state;

    const highlighted = Math.max(0, Math.min(this.count - 1, previous + 1));
    this.setState({ highlighted });
  };

  /**
   * Подсвечивает последнее значение из списка.
   */
  public highlightLast = () => {
    const highlighted = Math.max(this.count - 1, 0);
    this.setState({ highlighted });
  };

  /**
   * Выбирает подсвеченное в данный момент значение.
   */
  public choose = () => {
    const { highlighted } = this.state;
    const nextValue = this.getByIndex(highlighted);

    const { onChange } = this.props;

    if (onChange) {
      onChange(nextValue);
    }
  };

  /**
   * Возвращает значение из списка, которое должно быть использовано по
   * умолчанию.
   * @param search Содержимое поля ввода.
   */
  public getDefault = (search: string) => {
    const { format } = this.props;
    const { values } = this.state;
    const { length } = values;

    if (length === 0) {
      return undefined;
    }

    for (let i = 0; i < length; i += 1) {
      const value = values[i];
      const text = value != null ? format(value) : undefined;

      if (text === search) {
        return value;
      }
    }

    return values[0];
  };

  /**
   * Возвращает элемент из списка значений по его позиции.
   * @param index Позиция.
   */
  private getByIndex = (index: number) => {
    const { values } = this.state;
    const { length } = values;
    const { reset } = this.props;

    const isReset = Boolean(reset) || reset === '';
    const max = isReset ? length : length - 1;

    if (index > max) {
      return undefined;
    }

    if (index < 0) {
      return undefined;
    }

    if (isReset) {
      return index === 0 ? undefined : values[index - 1];
    }

    return values[index];
  };

  /**
   * Обрабатывает наведение мыши на элемент с указанным номером.
   * @param index Номер элемента.
   */
  private handleHighlight = (index: number) => {
    this.setState({ highlighted: index });
  };

  /**
   * Обрабатывает выбор одного элемента из списка.
   * @param index Номер элемента.
   */
  private handleChoose = (index: number) => {
    const value = this.getByIndex(index);
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }

    this.setState({ highlighted: index });
  };

  /**
   * Обновляет положение прокрутки списка опций. Если позиция подсвеченного
   * элемента не изменилась с последней проверки, ничего не происходит.
   */
  private touchScrollbars = () => {
    const { previousHighlighted } = this;
    const { highlighted } = this.state;

    if (highlighted === previousHighlighted) {
      return;
    }

    this.previousHighlighted = highlighted;

    const { scrollbarsRef, contentRef } = this;
    const { current: scrollbars } = scrollbarsRef;
    const { current: content } = contentRef;

    if (scrollbars == null || content == null) {
      return;
    }

    const { childNodes } = content;

    if (highlighted > childNodes.length - 1) {
      return;
    }

    const node = childNodes[highlighted] as HTMLElement;

    const { scrollTop, clientHeight: scrollHeight } = scrollbars.getValues();
    const { offsetTop: nodeTop, clientHeight: nodeHeight } = node;

    const scrollBottom = scrollTop + scrollHeight;
    const nodeBottom = nodeTop + nodeHeight;

    if (nodeTop < scrollTop) {
      scrollbars.scrollTop(nodeTop);
    }

    if (nodeBottom > scrollBottom) {
      scrollbars.scrollTop(nodeBottom - scrollHeight);
    }
  };

  /**
   * Сохраняет в кэш результат выполнения функции загрузки значения для
   * указанной входной строки.
   * @param values Значение свойства `values`.
   * @param search Строка для поиска.
   * @param result Результат выполнения функции.
   */
  private setToCache = (values: Values, search: string, result: any[]) => {
    const key = Options.getCacheKey(values);

    let node = this.cache.get(key);

    if (node == null) {
      node = new Map();
      this.cache.set(key, node);
    }

    node.set(search, result);
  };

  /**
   * Возвращает из кэша результат выполнения функци загрузки значений для
   * указанной входной строки.
   * @param values Значение свойства `values`.
   * @param search Строка для поиска.
   */
  private getFromCache = (values: Values, search: string) => {
    const key = Options.getCacheKey(values);
    const node = this.cache.get(key);
    return node == null ? undefined : (node.get(search) as any[] | undefined);
  };

  /**
   * Ищет указанную поисковую строку в отображаемых в данный момент вариантах.
   * Возвращает `true`, если находит.
   * @param search Строка для поиска.
   */
  private isIncluded = (search: string) => {
    const { format } = this.props;
    const { values } = this.state;

    const { length } = values;

    const normalizedSearch = search.toLocaleLowerCase();

    for (let i = 0; i < length; i += 1) {
      const value = values[i];
      const text = value != null ? format(value) : '';
      const normalizedText = text.toLocaleLowerCase();

      if (normalizedSearch === normalizedText) {
        return true;
      }
    }

    return false;
  };

  /**
   * Возвращает `true`, если указанные аргументы устарели.
   * @param values Функция, генерирующая список значений.
   * @param search Строка для поиска.
   */
  private isChanged = (values: Values, search: string) =>
    this.previousValues !== values || this.previousSearch !== search;

  /**
   * Генерирует список значений для отрисовки. Если исходные данные для списка
   * элементов не изменились, ничего не происходит.
   */
  private touchValues = () => {
    const { values, search, allowValues } = this.props;

    if (!allowValues || !this.isChanged(values, search)) {
      return;
    }

    clearTimeout(this.timeout);

    this.previousValues = values;
    this.previousSearch = search;

    const setResult = (result: any[]) => {
      this.setState({ highlighted: 0 });
      this.setState({ values: result });

      if (this.timeout != null) {
        const { onPendingChange } = this.props;
        this.setState({ pending: false });

        if (onPendingChange) {
          onPendingChange(false);
        }

        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
    };

    if (typeof values !== 'function') {
      setResult(values);
      return;
    }

    const cached = this.getFromCache(values, search);

    if (cached != null) {
      setResult(cached);
      return;
    }

    if (this.isIncluded(search)) {
      const { values: items } = this.state;
      setResult(items);
      return;
    }

    const handleTimeout = async () => {
      if (this.unmounted || this.isChanged(values, search)) {
        return;
      }

      let result: Promise<any[]> | any[] | undefined;

      const { allowEmpty, minLength } = this.props;

      if (allowEmpty) {
        result =
          search.length < minLength ? Options.infiniteValues() : values(search);
      } else if (search === '') {
        result = [];
      } else {
        result =
          search.length < minLength ? Options.infiniteValues() : values(search);
      }

      if (result instanceof Promise) {
        result = await result;
      }

      if (this.unmounted) {
        return;
      }

      this.setToCache(values, search, result);

      if (this.isChanged(values, search)) {
        return;
      }

      setResult(result);
    };

    const { onPendingChange } = this.props;
    this.setState({ pending: true });

    if (onPendingChange) {
      onPendingChange(true);
    }

    const delay = this.isInitial ? 0 : this.defaultDelay;
    this.isInitial = false;

    this.timeout = setTimeout(handleTimeout, delay);
  };

  /**
   * Генерирует контент компонента.
   */
  private renderContent = () => {
    const { highlighted, pending, values } = this.state;

    if (pending) {
      return <Option service>Идёт загрузка вариантов</Option>;
    }

    const { format, unique, value, reset } = this.props;

    const isReset = Boolean(reset) || reset === '';
    const resetText =
      typeof reset === 'string' && reset !== '' ? reset : 'Выберите вариант';

    const content: ReactNodeArray = [];
    const { length } = values;

    if (length === 0) {
      return <Option service>Нет вариантов для выбора</Option>;
    }

    const valueId = value != null ? unique(value) : '';
    const count = isReset ? length + 1 : length;

    for (let i = 0; i < count; i += 1) {
      const index = isReset ? i - 1 : i;
      const item = isReset && i === 0 ? undefined : values[index];

      let itemText = item != null ? format(item) : '';
      const itemId = item != null ? unique(item) : '';
      const itemDisabled = itemId === valueId;
      const itemHighlighted = i === highlighted;

      if (isReset && i === 0) {
        itemText = resetText;
      }

      content.push(
        <Option
          onHighlight={this.handleHighlight}
          onChoose={this.handleChoose}
          highlighted={itemHighlighted}
          disabled={itemDisabled}
          value={item}
          reset={isReset && i === 0}
          index={i}
          key={itemId}
        >
          {itemText}
        </Option>,
      );
    }

    return content;
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
      onPendingChange,
      allowValues,
      allowEmpty,
      minLength,
      onChange,
      format,
      unique,
      values,
      search,
      reset,
      value,
      ...props
    } = this.props;

    return (
      <Root {...props}>
        <Scrollbars ref={this.scrollbarsRef} autoHeight autoHide universal>
          <Content ref={this.contentRef}>{this.renderContent()}</Content>
        </Scrollbars>
      </Root>
    );
  }
}
