import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './Caret.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает иконку раскрытия списка.
 */
export const Caret: FC<Props> = ({ ...props }) => (
  <Root
    {...props}
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10.1676L17.6599 5.50661C18.1395 5.11163 18.8485 5.18025 19.2435 5.65986C19.6384 6.13948 19.5698 6.84848 19.0902 7.24345L12.7152 12.4935C12.2998 12.8356 11.7003 12.8356 11.2849 12.4935L4.90986 7.24345C4.43025 6.84848 4.36163 6.13948 4.75661 5.65986C5.15159 5.18025 5.86059 5.11163 6.3402 5.50661L12 10.1676Z"
      fill="currentColor"
    />
  </Root>
);
