import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { InferType } from 'yup';

import { Form as Base, FormItem } from 'modules/application/common';
import { Store } from 'modules/application/statement';

import { Field } from '../../types';
import { schema } from '../../schemas';

import { AddressInput } from '../AddressInput';
import { SameAddress } from '../SameAddress';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'schema' | 'onSubmit' | 'initialValues'
>;

/**
 * Отображает форму "Проживание".
 */
export const Form: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (form: InferType<typeof schema>) => {
      store.updateValues({
        registrationAddress: form.registration as any,
        isSameAddresses: form.isSameAddress,
        livingAddress: form.residence as any,
      });
    },
    [store],
  );

  if (store.model == null) {
    return null;
  }

  const initialValues = {
    [Field.REGISTRATION]: store.model.registrationAddress,
    [Field.IS_SAME]: store.model.isSameAddresses,
    [Field.LIVING]: store.model.livingAddress,
  };

  return (
    <Base
      {...props}
      schema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormItem>
        <AddressInput name={Field.REGISTRATION} label="Адрес регистрации" />
      </FormItem>
      <SameAddress />
    </Base>
  );
};
