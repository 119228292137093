import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Подзаголовок элемента Info.
 */
export const SubHeading = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  padding-top: 1.5rem;

  @media ${lessThanContainer('small')} {
    padding-top: 1rem;
  }
`;
