import styled from '@emotion/styled';

/**
 * Блок радио-кнопок.
 */
export const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
