import { Root } from './ScreenDescription.Root';
import { Root as Paragraph } from './ScreenDescription.Text';
import { Root as ListItem } from './ScreenDescription.ListItem';

export const ScreenDescription = () => {
  return (
    <Root>
      <Paragraph>
        Близится срок оплаты займа – но скоро праздники и нужно купить
        подарки... близким, друзьям, коллегам, в конце концов – себе!
      </Paragraph>
      <Paragraph>
        Не отказывайте себе в радости жить: с нашей опцией «Снижение платежа»
        можно не беспокоиться о просрочках и пенях.
      </Paragraph>
      <Paragraph>
        <ListItem>Хотите снизить сумму платежа?</ListItem>
        <ListItem>Изменить график на более удобный?</ListItem>
        <ListItem>Сохранить кредитную историю?</ListItem>
      </Paragraph>
    </Root>
  );
};
