import { FC, ComponentPropsWithoutRef } from 'react';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'>;

/**
 * Отображает замечания под формой отправки кода подтверждения.
 */
export const Note: FC<Props> = ({ ...props }) => {
  return (
    <div {...props}>
      На Ваш номер телефона будет отправлено SMS-сообщение с кодом. Введите его{' '}
      в поле, чтобы подписать Дополнительное соглашение на опцию Комфортный{' '}
      платёж и График платежей.
    </div>
  );
};
