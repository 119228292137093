import { useMemo } from 'react';
import { useFormikContext, getIn } from 'formik';

/**
 * Возвращает текущее значение элемента ввода из контекста формы по имени.
 *
 * @param name Имя элемента ввода.
 * @param defaultValue Значение по умолчанию.
 */
export const useFormValue = <TValue = any>(
  name: string,
  defaultValue?: TValue,
) => {
  const { values } = useFormikContext();

  return useMemo(
    () => getIn(values, name, defaultValue),
    [name, values, defaultValue],
  ) as TValue;
};
