import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Корневой элемент калькулятора.
 */
export const RangeHolder = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background: white;
  outline: none;
  transition: ${transition('transform', 'background-color')};
  transform-origin: center;

  &:hover {
    transform: scale(1.15, 1.15);
    &:active {
      transform: scale(1.1, 1.1);
      background-color: #dcdff0;
    }
  }
`;
