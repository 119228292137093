import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Field, Select } from 'modules/common/ui';
import { Service } from 'modules/common/daData';
import { Address } from 'modules/common/daData/types';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Select>, 'name'> & {
  /**
   * Заголовок Field.
   */
  label: string;

  /**
   * Имя компонента.
   */
  name: string;
};

/**
 * Отображает поле ввода адреса.
 */
export const AddressInput: FC<Props> = ({ label, name, ...props }) => {
  const daData = Service.use();

  const suggestAddress = useCallback(
    (value: string) => (value === '' ? [] : daData.suggestAddress(value)),
    [daData],
  );

  const handleFormat = useCallback(
    (item) =>
      [item.region, item.city, item.street, item.house, item.flat]
        .filter(Boolean)
        .join(', '),
    [],
  );

  const handleUnique = useCallback(
    (value: Address) => Object.values(value).join('|'),
    [],
  );

  return (
    <Field label={label} name={name} htmlFor={name}>
      <Select
        {...props}
        id={name}
        name={name}
        values={suggestAddress}
        format={handleFormat}
        unique={handleUnique}
        placeholder="Москва, ул. Тверская, д. 1, кв. 1"
        valuesOnly
      />
    </Field>
  );
};
