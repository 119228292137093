import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Store as LoanStore, Status as LoanStatus } from 'modules/loan';
import { Page as Url, Redirect } from 'modules/common/routing';
import { PageLoading } from 'modules/common/layout';

const Component: FC = ({ children }) => {
  const loanStore = LoanStore.use();

  useEffect(() => {
    loanStore.touch();
  }, [loanStore]);

  if (!loanStore.ready) {
    return <PageLoading />;
  }

  if (
    loanStore.model.status !== LoanStatus.ACTIVE ||
    !loanStore.payment.getPaymentType()
  ) {
    return (
      <Redirect url={Url.ACCOUNT}>
        <PageLoading />
      </Redirect>
    );
  }

  return <>{children}</>;
};

export const Provider = observer(Component);
