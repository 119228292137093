/**
 * Код ошибки RPC API.
 *
 * @see https://confluence.devim.team/pages/viewpage.action?pageId=74884974
 */
export enum RpcErrorCode {
  /**
   * Срок жизни ACCESS_TOKEN истёк.
   */
  AccessTokenExpired = 401,

  /**
   * Срок жизни REFRESH_TOKEN истёк.
   */
  RefreshTokenExpired = -320015,

  /**
   * Переданный на сервер REFRESH_TOKEN не соответствует заголовку X-DEVICE-ID
   * с которым была сгенерирована пара токенов.
   */
  RefreshTokenInvalid = -320022,

  /**
   * Переданы неверные параметры.
   */
  InvalidParams = -32602,

  /**
   * Не найдены данные для аутентификации.
   */
  CredentialsNotFound = -320010,

  /**
   * Неверный номер телефона или пароль.
   */
  CredentialsInvalid = -320012,

  /**
   * Не найден идентификатор устройства клиента.
   */
  FingerprintNotFound = -320011,

  /**
   * Исчерпан лимит попыток установки пароля.
   */
  SetPasswordAttemptsExceeded = -320021,

  /**
   * Ошибка создания токена сессии.
   */
  UnableToCreateToken = -320013,

  /**
   * Ошибка создания аккаунта.
   */
  UnableToCreateUser = -320017,

  /**
   * Неверный код подтверждения при установке пароля.
   */
  InvalidSetPasswordConfirmationCode = -320020,

  /**
   * Действия клиента заблокированы политикой сервера.
   */
  ActionBlocked = -320800,

  /**
   * Клиент не найден по номеру телефона.
   */
  ClientNotFoundByPhone = -320009,

  /**
   * Превышен лимит отправки SMS сообщений.
   */
  SmsLimitExceeded = -320018,

  /**
   * Отправка SMS сообщения не произошла.
   */
  SmsNotSent = -320019,

  /**
   * Неверный код подтвеждения операции.
   */
  InvalidConfirmationCode = -32603,

  /**
   * Договор займа не найден.
   */
  LoanNotFound = -320100,

  /**
   * Данные заявки уже подписаны.
   */
  ApplicationFormAlreadySigned = -320002,

  /**
   * Превышено количество попыток подписи заявки.
   */
  ApplicationFormSignLimitExceeded = -320005,

  /**
   * Неверный код подтверждения процедуры подписи заявки.
   */
  ApplicationFormConfirmationInvalid = -320006,

  /**
   * Выполнение операции невозможно для заявки на заём, находящейся в этом
   * статусе.
   */
  LoanApplicationStatusInvalid = -320024,

  /**
   * Превышен лимит отправки SMS сообщений для подписания формы заявки.
   */
  ApplicationSmsLimitExceeded = -320008,

  /**
   * Форма заявки не существует.
   */
  ApplicationNotExists = -320003,

  /**
   * Клиент не идентифицирован.
   */
  ClientNotIdentified = -320126,

  /**
   * Заём не подписан.
   */
  LoanIsNotSigned = -320122,

  /**
   * Используйте другую карту.
   */
  TryAnotherCard = -320700,

  /**
   * Вывод на указанную карту невозможен.
   */
  PayoutCardNotAllowed = -32036,

  /**
   * Вложение не найдено.
   */
  AttachmentNotFound = -320401,

  /**
   * Заявка на заём не существует.
   */
  LoanApplicationNotExists = -320026,

  /**
   * Неверный код подписи займа..
   */
  LoanSignInvalidCode = -320103,

  /**
   * Превышено количество запросов кода подписи займа.
   */
  LoanSmsLimitExceeded = -320105,

  /**
   * Превышено количество отправок кода подписи займа.
   */
  LoanSignLimitExceeded = -320102,

  /**
   * Опция займа не существует.
   */
  LoanOptionNotExists = -320107,

  /**
   * Опция займа недоступна.
   */
  LoanOptionNotAvailable = -320109,

  /**
   * Превышено количество отправок кода подписи договора на опцию займа.
   */
  LoanOptionSmsLimitExceeded = -320110,

  /**
   * Неверный код подтверждения подписания договора на опцию займа.
   */
  LoanOptionInvalidConfirmationCode = -320111,

  /**
   * Превышено количество попыток подписи договора на опцию займа.
   */
  LoanOptionSignLimitExceeded = -320112,

  /**
   * Сервер не смог получить доступ к данным для проверки ИНН.
   */
  CheckPassportError = -320403,
}
