/**
 * Название формы заполнения заявки.
 */
export enum FormName {
  /**
   * Форма заполнения контактных данных пользователя.
   */
  CONTACTS = 'contacts',

  /**
   * Форма заполнения паспортных данных пользователя.
   */
  PASSPORT = 'passport',

  /**
   * Форма заполнения паспортных данных пользователя.
   */
  ADDRESS = 'address',

  /**
   * Форма заполнения данных о семье.
   */
  FAMILY = 'family',

  /**
   * Форма заполнения данных о контактных лицах.
   */
  CONTACT_PERSONS = 'contact_persons',

  /**
   * Форма заполнения данных о доходе.
   */
  INCOME = 'income',

  /**
   * Форма соглашений.
   */
  AGREEMENTS = 'agreements',

  /**
   * Форма информация о кредитных обязательствах.
   */
  CREDIT_OBLIGATIONS = 'creditObligations',

  /**
   * Форма информация о персональных данных.
   */
  PERSONAL_INFO_AGREEMENTS = 'personalInfoAgreements',
}
