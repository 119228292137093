import { ComponentType, Component, createElement } from 'react';
import { FormikConsumer, FormikContextType } from 'formik';

/**
 * Свойства, которые должен поддерживать целевой компонент.
 */
export type WithFormProps = {
  /**
   * Набор методов и свойств формы, в которой находится компонент.
   */
  form: FormikContextType<any>;
};

/**
 * Добавляет компоненту свойство form, в котором содержится контекст формы,
 * в которой он находится.
 * @param target Целевой компонент.
 */
export const withForm = <TProps extends WithFormProps>(
  target: ComponentType<TProps>,
) => {
  /**
   * Свойства компонента.
   */
  type Props = Omit<TProps, keyof WithFormProps>;

  /**
   * Добавляет свойство form дочернему компоненту.
   */
  return class WithForm extends Component<Props> {
    /**
     * @inheritdoc
     */
    public static displayName = `WithForm(${
      target.displayName || target.name
    })`;

    /**
     * Отображает содержимое компонента.
     */
    private renderContent = (value: FormikContextType<any>) => {
      return createElement(target, { ...(this.props as TProps), form: value });
    };

    /**
     * @inheritdoc
     */
    public render() {
      return createElement(FormikConsumer, null, this.renderContent);
    }
  };
};
