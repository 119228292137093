import React, { FC, useEffect } from 'react';
import Head from 'next/head';

import { PageHeader, PageLoading } from 'modules/common/layout';
import { Container } from 'modules/common/ui';
import { Section } from 'modules/application/common';
import { Form as ContactsForm } from 'modules/application/contacts';
import { Store as LoanStore, Status as LoanStatus } from 'modules/loan';
import { Page, Redirect } from 'modules/common/routing';
import { Store as ApplicationStore, Status } from 'modules/application/core';
import { Store as StatementStore } from 'modules/application/statement';

import { FormName } from '../../types';

import { StepForms } from '../StepForms';
import { Step } from '../Step';
import { PageContent } from '../PageContent';
import { ApplicationCalculator } from '../ApplicationCalculator';
import { StepAccess } from '../StepAccess';

/**
 * Отображает содержимое страницы первого шага заполнения заявки.
 */
export const FirstStepPage: FC = () => {
  const loan = LoanStore.use();
  const application = ApplicationStore.use();
  const statement = StatementStore.use();

  useEffect(() => {
    if (
      application.model?.status !== Status.NONE &&
      application.model?.status !== Status.REJECTED
    ) {
      statement.touch();
    }
  }, [statement, application.model]);

  if (loan.status == null) {
    throw new Error(`This component must be placed inside loan.Provider`);
  }

  if (
    loan.status !== LoanStatus.NONE ||
    application.model?.status === Status.REJECTED
  ) {
    return (
      <Redirect url={Page.ACCOUNT} replace>
        <PageLoading />
      </Redirect>
    );
  }

  return (
    <StepAccess step={1}>
      <PageHeader isContainerMedium>
        <ApplicationCalculator />
      </PageHeader>
      <PageContent>
        <Head>
          <title>Аполлон-займ - оформление заявки, шаг 1 из 3</title>
        </Head>
        <Container size="medium">
          <StepForms step={1}>
            <Section heading="Контактная информация" aside={<Step>1</Step>}>
              <ContactsForm name={FormName.CONTACTS} />
            </Section>
          </StepForms>
        </Container>
      </PageContent>
    </StepAccess>
  );
};
