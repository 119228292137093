import React, { FC, SVGProps } from 'react';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGElement>, 'ref'>;

/**
 * Отображает иконку на чекбоксе.
 */
export const Icon: FC<Props> = (props) => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 4.5L4.5 7.5L11 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
