import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  max-width: 620px;

  font-size: 36px;
  line-height: 40px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.light.text.overlay};

  @media ${lessThanContainer('small')} {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
`;
