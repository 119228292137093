import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент для отображение иконок в сторах в части подвала страницы.
 */
export const Apps = styled.div`
  display: flex;
  gap: 1.25rem;

  margin-left: 4.3rem;

  @media ${lessThanContainer('large')} {
    flex-direction: column;
  }

  @media ${lessThanContainer('medium')} {
    margin-left: 0;
    padding-top: 3.75rem;

    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${lessThanContainer('small')} {
    justify-content: center;
  }
`;
