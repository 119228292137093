import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './ParamsCommon.Root';

import { Value } from '../Value';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Value>;

/**
 * Компонент обертки для информации по займу.
 */
export const ParamsCommon: FC<Props> = ({ title, children, ...props }) => (
  <Root {...props}>
    <Value title={title}>{children}</Value>
  </Root>
);
