import { LoanProductOption } from '../types/LoanProductOption';

/**
 * Преобразует ответ сервера в данные об опции продукта займа.
 * @param data Ответ сервера.
 */
export function transformLoanProductOption(data: any): LoanProductOption {
  return {
    name: data.name,
    status: data.status,
    type: data.type,
  };
}
