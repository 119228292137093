import { Service } from './Service';

import { Event } from '../types/Event';

/**
 * Сервис содержит методы для отправки событий метрики по активному займу.
 */
export class LoanService extends Service {
  /**
   * Вызывается, когда пользователь выбирает карту из списка.
   */
  payout() {
    localStorage.setItem(Event.LOAN_PAYOUT, 'true');
  }

  /**
   * Клиент забрал деньги.
   */
  issued() {
    const flag = localStorage.getItem(Event.LOAN_PAYOUT);

    if (flag) {
      this.send(Event.LOAN_ISSUED);
      localStorage.removeItem(Event.LOAN_PAYOUT);
    }
  }

  /**
   * Вызывается при выборе способа оплаты.
   */
  repay() {
    localStorage.setItem(Event.LOAN_REPAY, 'true');
  }

  /**
   * Клиент совершил минимальный платёж по активному займу.
   */
  paid() {
    const flag = localStorage.getItem(Event.LOAN_REPAY);

    if (flag) {
      this.send(Event.LOAN_PAID);
      localStorage.removeItem(Event.LOAN_REPAY);
    }
  }

  /**
   * Клиент закрыл заём.
   */
  closed() {
    const flag = localStorage.getItem(Event.LOAN_REPAY);

    if (flag) {
      this.send(Event.LOAN_CLOSED);
      localStorage.removeItem(Event.LOAN_REPAY);
    }
  }
}
