import styled from '@emotion/styled';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Тип темы сайта
   */
  layout: 'screen' | 'default';
};

/**
 * Элемент Footer для компонентов информации.
 */
export const Content = styled.div<Props>`
  border-top: 1px solid
    ${(props) => {
      return props.layout === 'screen'
        ? 'transparent'
        : props.theme.colors.light.separator;
    }};

  padding: 3.75rem 0;
`;
