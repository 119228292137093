import {
  Component,
  ComponentPropsWithoutRef,
  createRef,
  FocusEvent,
} from 'react';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'>;

/**
 * Предоставляет обёртку всех элементов компонентов, которая фильтрует события
 * фокуса и его потери.
 */
export class Group extends Component<Props> {
  /**
   * Ссылка на элемент.
   */
  private rootRef = createRef<HTMLDivElement>();

  /**
   * Указывает, что группа элементов находится в фокусе.
   */
  private focused: boolean = false;

  /**
   * Таймаут задержки перед генерацией события `onBlur`.
   */
  private timeout: any = undefined;

  /**
   * Время задержки перед генерацией события `onBlur`.
   */
  private delay: number = 175;

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  /**
   * Переводит фокус на поле ввода внутри компонента.
   */
  public focusInput = () => {
    const { current } = this.rootRef;

    if (current == null) {
      return;
    }

    const input = current.querySelector('input') as HTMLInputElement;

    if (input == null) {
      return;
    }

    input.focus();
  };

  /**
   * Обрабатывает событие получение фокуса любым элементом из группы.
   * @param args Аргументы.
   */
  private handleFocus = (event: FocusEvent<HTMLDivElement>) => {
    clearTimeout(this.timeout);

    if (this.focused) {
      return;
    }

    this.focused = true;

    const { onFocus } = this.props;

    if (onFocus) {
      onFocus(event);
    }
  };

  /**
   * Обрабатывает событие потери фокуса любым элементом из группы.
   * @param event Событие.
   */
  private handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    clearTimeout(this.timeout);

    if (!this.focused) {
      return;
    }

    const handleTimeout = () => {
      this.focused = false;

      const { onBlur } = this.props;

      if (onBlur) {
        onBlur(event);
      }
    };

    this.timeout = setTimeout(handleTimeout, this.delay);
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { onFocus, onBlur, ...props } = this.props;

    return (
      <div
        {...props}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={this.rootRef}
      />
    );
  }
}
