import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент компонента.
 */
export const Root = styled.button`
  border-radius: 4.75rem;
  border-width: 0.5rem;
  border-style: solid;

  padding: 0;

  transition: ${transition(
    'background-color',
    'border-color',
    'opacity',
    'color',
  )};

  background-color: ${(props) => props.theme.color.primary.contrastAccent};
  border-color: ${(props) => props.theme.color.primary.contrastHidden};
  color: ${(props) => props.theme.color.primary.contrast};
  opacity: 1;

  width: 219px;

  text-decoration: none;
  text-align: left;
  font-weight: 600;
  font-size: 1rem;

  cursor: pointer;
  outline: none;

  :hover {
    background-color: transparent;
    border-color: ${(props) => props.theme.color.primary.contrastAccent};
    color: ${(props) => props.theme.color.primary.main};
  }

  :active {
    opacity: 0.5;
  }

  &[disabled] {
    background-color: ${(props) => props.theme.color.primary.contrastAccent};
    border-color: ${(props) => props.theme.color.primary.contrastHidden};
    color: ${(props) => props.theme.color.primary.contrast};
    opacity: 0.25;

    cursor: default;
  }

  @media ${lessThanContainer('small')} {
    display: flex;

    align-items: center;

    height: 3.25rem;

    width: 100%;

    border: 0;
    border-radius: 0;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
  }
`;
