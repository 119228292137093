import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 300px;

  background: ${({ theme }) => theme.colors.light.background.primary};
  border-radius: 0 8px 8px 0;

  @media ${lessThanContainer('small')} {
    order: -1;

    width: auto;
    padding: 24px 0;

    border-radius: 8px 8px 0 0;
  }
`;
