import { observer } from 'mobx-react';
import { Root } from './Details.Root';
import { Row } from './Details.Row';
import { Offer } from './Details.Offer';
import { Value } from './Details.Value';

import { Store } from '../../../stores';
import { getLastPaymentDate } from '../utils';

const Details = () => {
  const { period } = Store.use();
  const displayEndDate = getLastPaymentDate(period);

  return (
    <Root>
      <Row>
        <Offer>Ставка в день</Offer>
        <Value>0,8%</Value>
      </Row>
      <Row>
        <Offer>Вернуть до</Offer>
        <Value>{displayEndDate}</Value>
      </Row>
    </Root>
  );
};

const component = observer(Details);
export { component as Details };
