import { FC, SVGProps } from 'react';

/**
 * Свойства компонента.
 */
type Props = Omit<SVGProps<SVGSVGElement>, 'ref'>;

/*
 * Пиктограмма включенного режима отображения.
 */
export const Hide: FC<Props> = (props) => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71302 0.797125C4.3225 0.406524 3.68928 0.406487 3.29872 0.797044C2.90823 1.18754 2.90819 1.82064 3.29864 2.21118L4.83998 3.75288C2.1182 5.51991 0.5 8.10948 0.5 8.99994C0.5 10.3999 4.5 15.9999 10.5 15.9999C12.4497 15.9999 14.1882 15.4086 15.6469 14.5623L17.2853 16.2011C17.6758 16.5917 18.3091 16.5917 18.6996 16.2012C19.0901 15.8107 19.0901 15.1776 18.6997 14.787L17.3137 13.4007C17.3145 13.3999 17.3154 13.3992 17.3163 13.3985L15.0322 11.1144C15.0318 11.1154 15.0313 11.1164 15.0308 11.1173L12.9995 9.08554C12.9996 9.0843 12.9996 9.08307 12.9996 9.08183L10.4191 6.50126C10.418 6.50129 10.417 6.50132 10.4159 6.50136L8.38365 4.46861C8.38428 4.46831 8.38491 4.46802 8.38554 4.46773L6.69148 2.77367C6.69092 2.7739 6.69036 2.77414 6.6898 2.77437L4.71302 0.797125ZM6.76378 5.67711C5.97762 6.56044 5.5 7.72441 5.5 8.99994C5.5 11.7614 7.73858 13.9999 10.5 13.9999C11.7751 13.9999 12.9387 13.5226 13.822 12.7369L12.0488 10.9633C11.623 11.2994 11.0854 11.4999 10.501 11.4999C9.12024 11.4999 8.00095 10.3807 8.00095 8.99994C8.00095 8.41526 8.20166 7.87747 8.53794 7.45169L6.76378 5.67711ZM20.5 8.99994C20.5 9.55169 19.8787 10.7558 18.7586 12.0123L8.883 2.13676C9.40372 2.04816 9.94325 1.99994 10.5 1.99994C16.5 1.99994 20.5 7.59994 20.5 8.99994Z"
      fill="#0B1F35"
    />
  </svg>
);
