import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.h2`
  margin-bottom: 48px;
  max-width: 700px;

  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.light.text.overlay};

  @media ${lessThanContainer('small')} {
    margin-bottom: 27px;

    font-size: 13px;
  }
`;
