import { RpcErrorCode } from '../types/RpcErrorCode';
import { BaseError } from './BaseError';

/**
 * Ошибка вызова метода RPC.
 */
export class RpcError extends BaseError {
  /**
   * Данные запроса на сервер.
   */
  public readonly request: Readonly<RequestInit>;

  /**
   * Ошибка ответа сервера.
   */
  public readonly error: Readonly<any>;

  /**
   * Код ошибки.
   */
  public readonly code: RpcErrorCode;

  /**
   * Создаёт экземпляр ошибки данных ответа сервера.
   * @param response Данные ответа сервера.
   */
  constructor(request: RequestInit, error: any) {
    super('E_RPC', `[${error.code}] ${error.message}`);

    this.request = request;
    this.error = error;
    this.code = error.code;
  }

  /**
   * Выполняет проверку переданного значения на соответствие типу RpcError.
   * При указанном code дополнительно проверяет код ошибки.
   * @param value Значение для проверки.
   * @param code Значение кода ошибки для проверки.
   */
  public static isType(value: unknown, code?: RpcErrorCode): value is RpcError {
    if (!(value instanceof RpcError)) {
      return false;
    }

    if (code !== undefined) {
      return value.code === code;
    }

    return true;
  }
}
