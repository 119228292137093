import styled from '@emotion/styled';

import { Checkbox } from './Point.Checkbox';
import { Icon } from './Point.Icon';
import { Radio } from './Point.Radio';

export const Input = styled.input`
  position: absolute;
  left: -9999px;

  &:checked ~ ${Checkbox} {
    ${Icon} {
      visibility: visible;
      opacity: 1;
    }
  }

  &:checked ~ ${Radio} {
    &:before {
      transform: scale(1);
    }
  }
`;
