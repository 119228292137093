import styled from '@emotion/styled';

/**
 * Корневой компонент.
 */
export const Root = styled.div`
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;

  border-top: 1px solid ${({ theme }) => theme.colors.light.separator};
`;
