import styled from '@emotion/styled';

import { Form as Base } from './Form';

/**
 * Форма ввода кода.
 */
export const Form = styled(Base)`
  margin-top: 0.875rem;
`;
