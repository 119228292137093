import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

export const Input = styled.input`
  background-color: transparent;
  border: 0;
  padding: 0;

  display: block;
  width: 100%;

  padding-top: 0.25rem;

  font-weight: 500;
  font-family: inherit;
  color: inherit;
  font-size: 1.125rem;

  outline: none;

  @media ${lessThanContainer('small')} {
    padding-top: 0.125rem;

    font-size: 1rem;
  }
`;
