import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент названия детали калькулятора.
 */
export const Offer = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 120px;

  @media ${lessThanContainer('small')} {
    width: 100%;
    flex: 1;
  }

  @media ${lessThanContainer('medium')} and (orientation: landscape) {
    font-size: 14px;
    line-height: 16px;
  }
`;
