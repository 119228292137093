import { ComponentPropsWithoutRef, FC, useContext, useCallback } from 'react';

import { Context } from '../Context';

import { Caret } from './Toggle.Caret';
import { Root } from './Toggle.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент показывает раскрытие списка, для индикации использует компонент
 * Caret справа
 */
export const Toggle: FC<Props> = ({ onClick, children, ...props }) => {
  const { toggleExpanded, expanded } = useContext(Context);

  const handleClick = useCallback(
    (event: any) => {
      toggleExpanded();

      if (onClick) {
        onClick(event);
      }
    },
    [onClick, toggleExpanded],
  );

  return (
    <Root {...props} onClick={handleClick} type="button">
      {children}
      <Caret active={expanded} />
    </Root>
  );
};
