import { FC, HTMLProps } from 'react';
import styled from '@emotion/styled';
import { Themed } from '@emotion/react';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  /**
   * Высота внутренних отступы от верхнего и нижнего края.
   * @default `default`
   */
  offset?: 'default' | 'large' | 'extraLarge' | 'none';

  /**
   * Высота внутренних отступы от верхнего края.
   * @default `default`
   */
  topOffset?: 'default' | 'large' | 'extraLarge' | 'none';

  /**
   * Высота внутренних отступы от нижнего края.
   * @default `default`
   */
  bottomOffset?: 'default' | 'large' | 'extraLarge' | 'none';

  /**
   * Определяет, как контент позиционируется по вертикали.
   * @default `top`
   */
  align?: 'top' | 'center';

  /**
   * Высота обёртки основного содержимого страницы.
   * @default `auto`
   */
  height?: 'auto' | 'full';
};

/**
 * Экранирует свойства корневого элемента.
 */
const RootBase: FC<Props> = ({
  offset,
  topOffset,
  bottomOffset,
  align,
  height,
  ...props
}) => {
  return <div {...props} />;
};

/**
 * Возвращает внутренний отступ.
 */
const padding =
  (offset?: string) =>
  ({ theme }: Themed<{}>) => {
    switch (offset) {
      case 'default': {
        return `${theme.pageContent.offset.default}rem`;
      }
      case 'large': {
        return `${theme.pageContent.offset.large}rem`;
      }
      case 'extraLarge': {
        return `${theme.pageContent.offset.extraLarge}rem`;
      }
      case 'none': {
        return theme.pageContent.offset.none;
      }
      default: {
        return `${theme.pageContent.offset.default}rem`;
      }
    }
  };

/**
 * Корневой элемент обёртки основного содержимого страницы.
 */
export const Root = styled(RootBase)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.align === 'center' ? 'center' : 'flex-start'};

  flex: 1 1 auto;

  min-height: ${(props) =>
    props.height === 'full' ? 'calc(100vh - 5rem)' : 'auto'};

  padding-top: ${({ topOffset, offset }) =>
    topOffset ? padding(topOffset) : padding(offset)};
  padding-bottom: ${({ bottomOffset, offset }) =>
    bottomOffset ? padding(bottomOffset) : padding(offset)};

  @media ${lessThanContainer('medium')} {
    min-height: ${(props) =>
      props.height === 'full' ? 'calc(100vh - 3.375rem)' : 'auto'};
  }
`;
