import { RpcError } from '../errors/RpcError';
import { Middleware } from './Middleware';

/**
 * Перехватывает ошибки в json-ответе сервера и выбрасывает ошибку RpcError.
 */
export const errorMiddleware: Middleware = async ({ request, json }) => {
  if (!('error' in json)) {
    return;
  }

  throw new RpcError(request, json.error);
};
