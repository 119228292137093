import { FC } from 'react';
import { CardTypes } from './Card.Utils';
import { Mir, Visa, MasterCard } from './icons';

export const Logo: FC<{ type: CardTypes }> = ({ type }) => {
  switch (type) {
    default:
    case CardTypes.MC:
      return <MasterCard />;
    case CardTypes.MIR:
      return <Mir />;
    case CardTypes.VISA:
      return <Visa />;
  }
};
