import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { InferType } from 'yup';

import { Form as Base, FormItem } from 'modules/application/common';
import { Store } from 'modules/application/statement';

import { schema } from '../../schemas';
import { Field as CreditObligationsField } from '../../types';

import { CreditObligations } from '../CreditObligations';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'onSubmit' | 'schema' | 'initialValues'
>;

/**
 * Отображает форму "Выплаты по иным кредитам".
 */
export const Form: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (form: InferType<typeof schema>) => {
      store.updateValues({
        creditObligationsHasUnpaid: form.creditObligationsHasUnpaid,
        creditObligationsPaymentsPerMonth: Number(
          form.creditObligationsPaymentsPerMonth,
        ),
        creditObligationsAveragePaymentAmount: Number(
          String(form.creditObligationsAveragePaymentAmount).replace(/\s/g, ''),
        ),
      });
    },
    [store],
  );

  const initialValues = {
    [CreditObligationsField.CREDIT_OBLIGATIONS_HAS_UNPAID]:
      store.model?.creditObligationsHasUnpaid,
    [CreditObligationsField.CREDIT_OBLIGATIONS_AVERAGE_PAYMENT_AMOUNT]: String(
      store.model?.creditObligationsAveragePaymentAmount,
    ),
    [CreditObligationsField.CREDIT_OBLIGATIONS_PAYMENTS_PER_MONTH]: String(
      store.model?.creditObligationsPaymentsPerMonth,
    ),
  };

  return (
    <Base
      {...props}
      onSubmit={handleSubmit}
      schema={schema}
      initialValues={initialValues}
    >
      <FormItem>
        <CreditObligations />
      </FormItem>
    </Base>
  );
};
