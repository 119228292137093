import styled from '@emotion/styled';

/**
 * Контейнер для блоков отдельных цифр поля ввода четырёхзначного кода.
 */
export const Items = styled.div`
  position: relative;

  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: center;

  margin: 0.5rem 0;

  background-color: inherit;
`;
