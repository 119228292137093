import styled from '@emotion/styled';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Вариант отображения границ блока
   */
  variant?: 'default' | 'danger' | 'warning';
};

/**
 * Корневой элемент Item.
 */
export const Root = styled.div<Props>`
  background: ${({ theme }) => theme.colors.light.background.secondary};
  border: ${(p) => {
    switch (p.variant) {
      case 'warning':
        return `2px solid ${p.theme.colors.light.accent.warning}`;
      case 'danger':
        return `2px solid ${p.theme.colors.light.accent.error}`;
      default:
        return `2px solid transparent`;
    }
  }};
  box-sizing: border-box;
  border-radius: 0.5rem;

  padding: 1.5rem;
`;
