import styled from '@emotion/styled';

import { Button as Base } from './Button';

/**
 * Кнопка на калькуляторе.
 */
export const Button = styled(Base)`
  flex: 0 0 auto;
`;
