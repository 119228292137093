import React, { ComponentPropsWithoutRef, FC } from 'react';

import { Announcement } from 'modules/common/ui';

import success from './assets/success.svg';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Announcement>,
  'img' | 'alt' | 'heading'
>;

/**
 * Компонент отображающий "Оплату юридической услуги" на информационной странице платежей.
 */
export const SuccessLegalSupport: FC<Props> = ({ ...props }) => {
  return (
    <Announcement
      {...props}
      img={success}
      alt="Услуга оплачена"
      heading="Услуга оплачена"
    >
      Договор отправлен на указанную электронную почту
    </Announcement>
  );
};
