import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент-обертка хлебной крошки.
 */
export const Nav = styled.nav`
  @media ${lessThanContainer('small')} {
    overflow-x: auto;
  }
`;
