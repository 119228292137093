import styled from '@emotion/styled';

/**
 * Подзаголовок для блоков с кнопками.
 */
export const Subheading = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};
`;
