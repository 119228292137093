import styled from '@emotion/styled';

import { AlertBanner as Element } from '../AlertBanner';

/**
 * Баннер "Кредитные каникулы."
 */
export const AlertBanner = styled(Element)`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;
