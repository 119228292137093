/**
 * Статус заполнения заявки.
 */
export enum ApplicationStatus {
  /**
   * Заявка требует заполнения.
   */
  FillingRequired = 'filling_required',

  /**
   * Первичная проверка.
   */
  InitialCheck = 'initial_check',

  /**
   * Требуется одобрение.
   */
  DecisionRequired = 'decision_required',

  /**
   * Заявка в процессе обработки.
   */
  Processing = 'in_processing',

  /**
   * Заявка отклонена.
   */
  Rejected = 'rejected',

  /**
   * Заявка отменена.
   */
  Cancelled = 'canceled',

  /**
   * Заявка одобрена.
   */
  Approved = 'approved',
}
