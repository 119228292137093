/**
 * Тип пользователя.
 */
export enum UserType {
  /**
   * Первичный клиент.
   */
  First = 'first',

  /**
   * Повторный клиент.
   */
  Repeat = 'repeat',
}
