import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { AmountHelper, NumberHelper, DateHelper } from 'modules/common/values';
import { Button } from 'modules/common/ui';
import { PaymentType } from 'modules/repay';

import { Store } from '../../Store';
import { Type } from '../../Type';

import { Item } from '../Item';
import { Info } from '../Info';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Item>, 'heading' | 'body'>;

/**
 * Отображает блок полного погашения займа.
 */
const Full: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const amount = `${AmountHelper.formatDisplayFloat(
    store.model?.earlyPayment?.amount ?? 0,
  )} ${AmountHelper.getShortUnits()}`;

  const interestRate = `${NumberHelper.formatFloatDisplay(
    store.model?.interestRate ?? 0,
  )} %`;

  const paymentDate = !store.model?.expirationPayment?.amount
    ? DateHelper.yesterdayDisplay(
        store.model?.nextPayment?.dateAt ?? new Date(),
      )
    : DateHelper.formatDisplay(
        store.model?.expirationPayment?.dateAt ?? new Date(),
      );

  const handleClick = useCallback(
    () => store.navigateToPayment(PaymentType.EARLY),
    [store],
  );

  return (
    <Item
      {...props}
      heading="Сумма полного погашения"
      body={
        <>
          <Info title="Сумма займа" content={amount} />
          <Info title="Ставка, в день" content={interestRate} />
          {store.model?.type === Type.PDL && !store.isRestructured ? (
            <Info title="Дата платежа" content={paymentDate} />
          ) : null}
        </>
      }
    >
      <Button variant="filled" type="button" onClick={handleClick}>
        Погасить заём
      </Button>
    </Item>
  );
};

const component = observer(Full);
export { component as Full };
