import { FC, useMemo, ComponentPropsWithoutRef, useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { ClickhouseService } from 'services/СlickhouseService';
import { Service } from '../../services';
import { ABTestService } from '../../../../../services/ABTestService';

/**
 * Инициализирует сервис метрик.
 */
export const Provider: FC = ({ children }) => {
  const { id, options } = Service.use();

  useEffect(() => {
    ClickhouseService.init(
      process.env.NEXT_PUBLIC_CLICKHOUSE_URL,
      process.env.NEXT_PUBLIC_CLICKHOUSE_KEY,
      process.env.NEXT_PUBLIC_CLICKHOUSE_CLIENT_ID,
    );
  }, []);

  const isActive = typeof id === 'number';

  const props = useMemo<ComponentPropsWithoutRef<typeof YMInitializer>>(
    () => ({
      accounts: typeof id === 'number' ? [id] : [],
      options,
      version: '2',
    }),
    [id, options],
  );

  useEffect(() => {
    ABTestService.init();
  }, []);

  if (!isActive) {
    return <>{children}</>;
  }

  return <YMInitializer {...props}>{children}</YMInitializer>;
};
