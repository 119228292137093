import { FC, useCallback } from 'react';
import { Root } from './AddCard.Root';

import { Store } from '../../Store';

export const AddCard: FC = () => {
  const store = Store.use();

  const handleClick = useCallback(() => {
    store.addCard();
  }, [store]);

  return <Root onClick={handleClick}>Добавить карту</Root>;
};
