import { ComponentPropsWithRef, FC } from 'react';
import ReactModal from 'react-modal';

import { ModalStyle, OverlayStyle } from './Modal.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithRef<typeof ReactModal>;

/**
 * Компонент модального окна.
 */
export const Modal: FC<Props> = ({ ...props }) => (
  <ReactModal
    {...props}
    className="_"
    overlayClassName="_"
    contentElement={(props, children) => (
      <ModalStyle {...props}>{children}</ModalStyle>
    )}
    overlayElement={(props, contentElement) => (
      <OverlayStyle {...props}>{contentElement}</OverlayStyle>
    )}
  />
);
