import styled from '@emotion/styled';

import { Checkbox as Base } from 'modules/common/ui';

/**
 * Корневой элемент компонента.
 */
export const Checkbox = styled(Base)`
  align-items: center;

  font-size: 0.875rem;

  & span {
    align-self: center;
  }
`;
