import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { Subheading } from './Section.Subheading';
import { Heading } from './Section.Heading';
import { Content } from './Section.Content';
import { Root } from './Section.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Текст заголовка.
   */
  heading?: ReactNode;

  /**
   * Текст подзаголовка.
   */
  subheading?: ReactNode;
};

/**
 * Предоставляёт обёртку для основного содержимого страницы.
 */
export const Section: FC<Props> = ({
  heading,
  subheading,
  children,
  ...props
}) => (
  <Root {...props}>
    {heading && <Heading>{heading}</Heading>}
    {subheading && <Subheading>{subheading}</Subheading>}
    <Content>{children}</Content>
  </Root>
);
