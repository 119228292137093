import { Component, ComponentPropsWithoutRef } from 'react';

import { Root } from './Input.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'defaultValue'> & {
  /**
   * Значение поля ввода по умолчанию.
   */
  defaultValue?: string;
};

/**
 * Поле ввода для компонента выбора из выпадающего списка.
 */
export class Input extends Component<Props> {
  /**
   * @inheritdoc
   */
  public render() {
    const { ...props } = this.props;
    return <Root {...props} />;
  }
}
