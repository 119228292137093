import { Middleware } from './middleware/Middleware';
import { errorMiddleware } from './middleware/errorMiddleware';
import { unautorizedMiddleware } from './middleware/unauthorizedMiddleware';

/**
 * Коллекция Middleware, регистрируемая по умолчанию.
 */
export const defaultMiddleware: Readonly<Middleware[]> = [
  unautorizedMiddleware,
  errorMiddleware,
];
