import styled from '@emotion/styled';

/**
 * Блок текста.
 */
export const Text = styled.div`
  font-weight: bold;
  font-size: 0.94rem;

  color: ${({ theme }) => theme.colors.light.text.primary};
`;
