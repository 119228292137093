import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  color: ${({ theme }) => theme.colors.light.text.overlay};
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;

  @media ${lessThanContainer('small')} {
    max-width: 160px;

    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
  }
`;
