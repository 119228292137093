/**
 * Количество членов семьи.
 */
export enum UserFamilyMembersCount {
  /**
   * Один.
   */
  One = 'one',

  /**
   * Два.
   */
  Two = 'two',

  /**
   * Три.
   */
  Three = 'three',

  /**
   * Четыре.
   */
  Four = 'four',

  /**
   * Пять.
   */
  Five = 'five',

  /**
   * Больше пяти.
   */
  MoreThanFive = 'more_than_five',
}
