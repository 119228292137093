import { Helper as Base } from '@devim-front/helper';

import { NumberHelper } from './NumberHelper';

/**
 * Содержит методы для работы с процентами. Процент представляется числом (1%
 * соответствует `1`).
 */
export class PercentHelper extends Base {
  /**
   * Преобразует указанную процент в строку в человекопонятном формате
   * "10 000,00".
   * @param amount Денежная сумма.
   */
  public static formatDisplayFloat(percent: number) {
    const [integer, float] = percent.toFixed(2).split('.');
    const head = NumberHelper.formatIntegerDisplay(Number(integer));
    return `${head},${float}`;
  }

  /**
   * Возвращает коротную запись единиц измерения для процентов (знак '%').
   */
  public static getShortUnits() {
    return '%';
  }
}
