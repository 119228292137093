import styled from '@emotion/styled';

/**
 * Заголовок Section.
 */
export const Heading = styled.div`
  flex: 1;

  font-weight: 500;
  font-size: 1.25rem;
`;
