import { Component, ComponentPropsWithoutRef, MouseEvent } from 'react';

import { Root } from './Option.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Root>,
  'type' | 'tabIndex' | 'children' | 'tabIndex'
> & {
  /**
   * Порядковый номер элемента.
   */
  index?: number;

  /**
   * Обрабатывает наведение мыши на указанный вариант.
   * @param index Порядковый номер элемента.
   */
  onHighlight?: (index: number) => void;

  /**
   * Обрабатывает выбор данного варианта.
   * @param index Порядковый номер элемента.
   */
  onChoose?: (index: number) => void;
};

/**
 * Отображает отдельный вариант для выбора.
 */
export class Option extends Component<Props> {
  /**
   * Обрабатывает событие нажатия.
   */
  private handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { onChoose, onClick, index } = this.props;

    if (onClick) {
      onClick(event);
    }

    if (onChoose) {
      onChoose(index as number);
    }
  };

  /**
   * Обрабатывает событие наведения мыши на данный элемент.
   * @param event Событие.
   */
  private handleMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
    const { onHighlight, onMouseEnter, index } = this.props;

    if (onHighlight) {
      onHighlight(index as number);
    }

    if (onMouseEnter) {
      onMouseEnter(event);
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { onMouseEnter, onHighlight, onChoose, onClick, index, ...props } =
      this.props;

    return (
      <Root
        {...props}
        onMouseEnter={this.handleMouseEnter}
        onClick={this.handleClick}
        type="button"
        tabIndex={-1}
      />
    );
  }
}
