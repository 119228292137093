import * as yup from 'yup';

import { Field } from '../types';

/**
 * Возвращает схему любого имени.
 */
const getNameSchema = (requiredErrorMessage: string) =>
  yup
    .object({
      value: yup.string(),
    })
    .nullable()
    .required(requiredErrorMessage)
    .test(
      'NameRequired',
      requiredErrorMessage,
      (value) => value?.value?.length !== undefined && value.value.length > 0,
    )
    .test(
      'NameRegExp',
      'Поле может содержать только русские буквы, точки, дефисы и апострофы',
      (value) => /^[а-яА-ЯёЁ.'`-]+$/.test(value?.value ?? ''),
    );

/**
 * Схема контактной информации пользователя.
 */
export const schema = yup.object().shape({
  /**
   * Поле Email не может быть пустым.
   * Проверка на корректный email.
   */
  [Field.EMAIL]: yup
    .string()
    .required('Введите адрес электронной почты')
    .email('Электронный адрес введён неверно'),

  /**
   * Фамилия.
   */
  [Field.LAST_NAME]: getNameSchema('Введите вашу фамилию'),

  /**
   * Имя.
   */
  [Field.FIRST_NAME]: getNameSchema('Введите ваше имя'),

  /**
   * Отчество.
   */
  [Field.SECOND_NAME]: getNameSchema('Введите ваше отчество'),

  /**
   * Пол.
   */
  [Field.GENDER]: yup.string(),
});
