import {
  FC,
  useEffect,
  useRef,
  ComponentPropsWithoutRef,
  useCallback,
} from 'react';

import { withFormControl } from 'modules/common/ui';
import { Gender } from 'modules/common/daData';

import { useGender } from '../GenderContext';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> & {
  /**
   * Обрабатывает изменение значения поля ввода.
   * @param value Новое значение поля ввода.
   */
  onChange?: (value: any) => void;
};

/**
 * Создаёт синтетическое поле, хранящее значение пола пользователя.
 */
const GenderInput: FC<Props> = ({ onChange, ...props }) => {
  const { gender } = useGender();
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    async (nextGender: Gender) => {
      if (onChange) {
        onChange(nextGender);
      }

      await Promise.resolve();

      const { current } = ref;

      if (current != null) {
        current.blur();
      }
    },
    [onChange],
  );

  useEffect(() => {
    handleChange(gender);
  }, [handleChange, gender]);

  return <input {...props} ref={ref} type="hidden" />;
};

const component = withFormControl('UNKNOWN')(GenderInput);
export { component as GenderInput };
