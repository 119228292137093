import { createContext, useState, FC, useContext } from 'react';

/**
 * Возможные значения глобальной разметки сайта.
 */
type Layout = 'screen' | 'default';

/**
 * Контекст каркаса шапки.
 */
type Value = {
  /**
   * Текущая глобальная разметка сайта.
   */
  layout: Layout;

  /**
   * Задаёт новую глобальную разметку сайта.
   * @param layout Новая разметка сайта.
   */
  setLayout: (layout: Layout) => void;
};

/**
 * Контекст глобальной разметки экрана.
 */
export const LayoutContext = createContext<Value>({
  layout: 'default',
  setLayout: () => {},
});

/**
 * Объявляет контект глобальной разметки экрана.
 */
export const LayoutProvider: FC = ({ children }) => {
  const [layout, setLayout] = useState<Layout>('default');
  const value: Value = { layout, setLayout };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

/**
 * Возвращает контект глобальной разметки экрана.
 */
export const useLayout = () => useContext(LayoutContext);
