import { FC, ComponentType } from 'react';
import { css, Themed } from '@emotion/react';
import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

import { Spinner } from '../components/Spinner';

/**
 * Свойства дочернего компонента.
 */
type InnerProps = {};

/**
 * Свойства итогового компонента.
 */
type OuterProps<P extends InnerProps> = Omit<
  P,
  'variant' | 'color' | 'width' | 'focused'
> & {
  /**
   * Тип кнопки.
   * @default `default`
   */
  variant?: 'default' | 'filled' | 'outlined' | 'plain';

  /**
   * Цвет кнопки.
   * @default `default`
   */
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default'
    | 'inverse';

  /**
   * Размер кнопки.
   * @default `medium`
   */
  size?: 'large' | 'medium' | 'small';

  /**
   * Ширина кнопки.
   * @default `auto`
   */
  width?: 'auto' | 'full' | 'medium';

  /**
   * Указывает, что кнопка должна быть заблокирована.
   */
  disabled?: boolean;

  /**
   * Флаг, который указывает, что компонент находится в состоянии ожидания
   * завершения асинхронной операции.
   */
  pending?: boolean;
};

/**
 * Чистые свойства компонента.
 */
type Props = OuterProps<{}>;

/**
 * Возвращает коллекцию цветов, которые будет использовать кнопка, полученную на
 * основе переданных свойств.
 * @param props Свойства компонента.
 */
const palette = ({ theme, color = 'primary' }: Themed<Props>) => {
  switch (color) {
    case 'primary': {
      return theme.color.primary;
    }
    case 'secondary': {
      return theme.color.secondary;
    }
    case 'error': {
      return theme.color.error;
    }
    case 'warning': {
      return theme.color.warning;
    }
    case 'success': {
      return theme.color.success;
    }
    case 'info': {
      return theme.color.info;
    }
    case 'inverse': {
      return {
        ...theme.color.text.inverse,
        contrast: theme.color.text.inverse.accent.main,
        contrastHidden: theme.color.text.inverse.accent.greatest,
      };
    }
    default: {
      return {
        ...theme.color.text.main,
        contrast: theme.color.text.main.accent.main,
        contrastHidden: theme.color.text.main.accent.greatest,
      };
    }
  }
};

/**
 * Возвращает свойство display для кнопки, на основе переданных свойств
 * @param props Свойства компонента.
 */
const display = (props: Themed<Props>) =>
  props.variant === 'plain' ? 'inline' : 'block';

/**
 * Возвращает ширину кнопки на основе переданных свойств компонента.
 * @param props Свойства компонента.
 */
const width = (props: Themed<Props>) => {
  if (props.variant === 'plain') {
    return 'auto';
  }

  switch (props.width) {
    case 'medium': {
      return '24rem';
    }
    case 'full': {
      return '100%';
    }
    default: {
      return 'auto';
    }
  }
};

/**
 * Возвращает вертикальные отступы кнопки, на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const paddingY = (props: Themed<Props>) => {
  if (props.variant === 'plain') {
    return 0;
  }

  switch (props.size) {
    case 'small': {
      return '0.5rem';
    }
    default: {
      return '1rem';
    }
  }
};

/**
 * Возвращает горизонтальные отступы кнопки на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const paddingX = (props: Themed<Props>) => {
  if (props.variant === 'plain') {
    return 0;
  }

  switch (props.size) {
    case 'small': {
      return '1.75rem';
    }
    default: {
      return '2rem';
    }
  }
};

/**
 * Возвращает размер текста кнопки, на основе переданных свойств компонента.
 * @param props Свойства компонента.
 */
const textSize = (props: Themed<Props>) => {
  if (props.variant === 'plain') {
    return 'inherit';
  }

  switch (props.size) {
    case 'large': {
      return '1.25rem';
    }
    default:
      return '1rem';
  }
};

/**
 * Возвращает стиль границ кнопки на основе переданных свойств компонента.
 * @param props Свойства компонента.
 */
const borderStyle = (props: Themed<Props>) =>
  props.variant === 'plain' ? 'none' : 'solid';

/**
 * Возвращает цвет фона кнопки на основе переданных свойств компонента.
 * @param props Свойства компонента.
 */
const backgroundColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined': {
      return 'inherit';
    }
    case 'filled': {
      return palette(props).main;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет границ кнопки на основе переданных свойств компонента.
 * @param props Свойства компонента.
 */
const borderColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined':
    case 'filled': {
      return palette(props).main;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет текста кнопки на основе переданных свойств компонента.
 * @param props Свойства компонента.
 */
const textColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'filled': {
      return palette(props).contrast;
    }
    default: {
      return palette(props).main;
    }
  }
};

/**
 * Возвращает цвет фона кнопки при наведении на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const hoverBackgroundColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined': {
      return 'inherit';
    }
    case 'filled': {
      return palette(props).great;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет границ кнопки при наведении на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const hoverBorderColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined':
    case 'filled': {
      return palette(props).great;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет текста кнопки при наведении на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const hoverTextColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'filled': {
      return palette(props).contrast;
    }
    default: {
      return palette(props).great;
    }
  }
};

/**
 * Возвращает цвет фона кнопки при нажатии на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const activeBackgroundColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined': {
      return 'inherit';
    }
    case 'filled': {
      return palette(props).greater;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет границ кнопки при нажатии на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const activeBorderColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined':
    case 'filled': {
      return palette(props).greater;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет текста кнопки при нажатии на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const activeTextColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'filled': {
      return palette(props).contrast;
    }
    default: {
      return palette(props).greater;
    }
  }
};

/**
 * Возвращает цвет фона заблокированной кнопки на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const disabledBackgroundColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined': {
      return 'inherit';
    }
    case 'filled': {
      return palette(props).greatest;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет границ заблокированной кнопки на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const disabledBorderColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'outlined':
    case 'filled': {
      return palette(props).greatest;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Возвращает цвет текста заблокированной кнопки на основе переданных свойств
 * компонента.
 * @param props Свойства компонента.
 */
const disabledTextColor = (props: Themed<Props>) => {
  switch (props.variant) {
    case 'filled': {
      return palette(props).contrastHidden;
    }
    default: {
      return palette(props).greatest;
    }
  }
};

/**
 * Корневой элемент компонента.
 */
const Root = styled.span`
  transition: ${transition('opacity')};
`;

/**
 * Контейнер спиннера.
 */
const SpinnerContainer = styled.span`
  transition: ${transition('opacity')};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0;
`;

/**
 * Возвращает обёртку над дочерним компонентом, которая добавляет ему стили
 * и свойства кнопки.
 * @param Target Дочерний компонент.
 * @see https://confluence.devim.team/display/WL/%5BFront%5D+Button
 */
export const withButtonStyle = <P extends InnerProps>(
  Target: ComponentType<P>,
) => {
  const Wrapper: FC<OuterProps<P>> = ({
    width: outerWidth,
    variant,
    size,
    color,
    pending,
    disabled,
    children,
    ...props
  }) => (
    <Target {...(props as P)} disabled={pending || disabled}>
      <Root>{children}</Root>
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    </Target>
  );

  const WithButtonStyle = styled(Wrapper)`
    display: ${display};

    position: relative;

    padding-bottom: ${paddingY};
    padding-top: ${paddingY};
    padding-right: ${paddingX};
    padding-left: ${paddingX};

    width: ${width};

    text-decoration: none;
    text-align: center;
    line-height: 1.25;
    font-size: ${textSize};

    border-style: ${borderStyle};
    border-width: 1px;
    border-radius: 4rem;

    cursor: pointer;

    transition: ${transition('background-color', 'border-color', 'color')};

    background-color: ${backgroundColor};
    border-color: ${borderColor};
    color: ${textColor};

    box-shadow: none;

    :hover {
      background-color: ${hoverBackgroundColor};
      border-color: ${hoverBorderColor};
      color: ${hoverTextColor};
    }

    :active {
      background-color: ${activeBackgroundColor};
      border-color: ${activeBorderColor};
      color: ${activeTextColor};
    }

    :disabled {
      background-color: ${disabledBackgroundColor};
      border-color: ${disabledBorderColor};

      cursor: not-allowed;

      ${Root} {
        color: ${disabledTextColor};
      }
    }

    :focus {
      outline: none;
    }

    ${(props) =>
      props.disabled
        ? css`
            background-color: ${hoverBackgroundColor(props)};
            border-color: ${hoverBorderColor(props)};
            color: ${hoverTextColor(props)};

            cursor: not-allowed;
          `
        : null}

    ${(props) =>
      props.pending
        ? css`
            ${SpinnerContainer} {
              opacity: 1;
            }

            ${Root} {
              opacity: 0;
            }
          `
        : null}
  `;

  WithButtonStyle.displayName = `WithButtonStyle(${
    Target.displayName || Target.name
  })`;

  return WithButtonStyle;
};
