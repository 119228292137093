import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';
import { Page, Redirect } from 'modules/common/routing';

import { Status, Store as CoreStore } from 'modules/application/core';
import { Store as StatementStore } from 'modules/application/statement';

import { StepHelper } from '../../helpers';
import { Store } from '../../stores';

/**
 * Свойства компонента.
 */
type Props = PropsWithChildren<{
  /**
   * Номер шага, доступ к которому нужно проверять.
   */
  step: number;
}>;

/**
 * Проверяет, может ли пользователь попасть на страницу шага заполнения заявки.
 * Если нет, то определяет, куда его перенаправить, и осуществляет
 * перенаправление.
 */
const StepAccess: FC<Props> = ({ step, children }) => {
  const core = CoreStore.use();
  const statement = StatementStore.use();
  const store = Store.use();

  if (step === 1) {
    if (core.model?.status === Status.FILLING && !statement.initialized) {
      return <PageLoading />;
    }

    return <>{children}</>;
  }

  if (core.model?.status !== Status.FILLING) {
    return (
      <Redirect url={Page.ACCOUNT} replace>
        <PageLoading />
      </Redirect>
    );
  }

  if (!statement.initialized || !statement.model) {
    return <PageLoading />;
  }

  const current = StepHelper.getCurrentStep(statement.model);

  if (step > current) {
    store.navigateToCurrentStep();

    return <PageLoading />;
  }

  return <>{children}</>;
};

const component = observer(StepAccess);
export { component as StepAccess };
