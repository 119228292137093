import {
  FC,
  ComponentPropsWithoutRef,
  useCallback,
  ReactNodeArray,
  useState,
  useMemo,
  useRef,
} from 'react';

import { withFormControl, WithFormValidProps, withFormValid } from '../../hocs';

import { Input as BaseInput } from './Input';

import { Button } from './CodeInput.Button';
import { Items } from './CodeInput.Items';
import { Input } from './CodeInput.Input';
import { Item } from './CodeInput.Item';
import { Root } from './CodeInput.Root';

/**
 * Символ, обозначающий пустой слот в поле ввода.
 */
const EMPTY_VALUE_PLACEHOLDER = '—';

/**
 * Символ, обозначающий зашифрованный слот в поле ввода.
 */
const MASKED_VALUE_PLACEHOLDER = '•';

/**
 * Свойства компонента.
 */
type Props = WithFormValidProps<
  Omit<
    ComponentPropsWithoutRef<typeof Input>,
    | 'onPositionChange'
    | 'position'
    | 'onDirtyFocus'
    | 'onDirtyBlur'
    | 'value'
    | 'maxLength'
  > & {
    /**
     * Текущее значение поля ввода.
     */
    value?: string;

    /**
     * Определяет, должны ли в блоках вместо реальных цифр отображаться точки.
     */
    secure?: boolean;

    /**
     * Максимальное количество вводимых символов.
     */
    maxLength?: number;
  }
>;

/**
 * Отображает поле ввода числового кода. Длина кода указывается с помощью
 * свойства `maxLength` (по умолчанию - 4). Также, если задать
 * `type="password"`, вводимые символы будут скрыты с помощью маски.
 */
const CodeInput: FC<Props> = ({
  className,
  maxLength = 4,
  secure = false,
  value = '',
  invalid,
  valid,
  type,
  name,
  id,
  onKeyDown,
  onBlur,
  ...props
}) => {
  const [position, setPosition] = useState<number>(0);
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<BaseInput>(null);

  const isMask = type === 'password' || secure;
  const [masked, setMasked] = useState<boolean>(isMask);

  const nextId = useMemo(() => id || `codeInput_${String(Date.now())}`, [id]);

  const { length } = value;

  const toggleMask = useCallback(
    () => setMasked((nextMasked) => !nextMasked),
    [],
  );

  const handlePositionChange = useCallback((nextPosition: number) => {
    setPosition(nextPosition);
  }, []);

  const handleItemClick = useCallback(
    (dirtyPosition: number) => {
      const maxPosition = Math.min(length, maxLength - 1);
      let nextPosition = dirtyPosition;

      if (nextPosition >= maxPosition) {
        nextPosition = maxPosition;
      }

      if (nextPosition !== position) {
        setPosition(nextPosition);
      }
    },
    [length, position, maxLength],
  );

  const handleInputFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleRootBlur = useCallback(() => {
    const { current } = inputRef;

    if (current) {
      current.clear();
    }
  }, []);

  const nextValue = String(value || '').substr(0, maxLength);
  const digits = nextValue.split('');

  const items: ReactNodeArray = [];

  for (let i = 0; i < maxLength; i += 1) {
    const itemFocused =
      focused && (i === position || (position === 0 && length === maxLength));

    let itemValue = digits[i] || EMPTY_VALUE_PLACEHOLDER;

    if (isMask && masked && itemValue !== EMPTY_VALUE_PLACEHOLDER) {
      itemValue = MASKED_VALUE_PLACEHOLDER;
    }

    items.push(
      <Item
        onClick={handleItemClick}
        focused={itemFocused}
        isInvalid={invalid}
        isValid={valid}
        position={i}
        htmlFor={nextId}
        key={i}
      >
        {itemValue}
      </Item>,
    );
  }

  return (
    <Root className={className} onBlur={handleRootBlur}>
      <Input
        {...props}
        ref={inputRef}
        inputMode="numeric"
        type={type}
        maxLength={maxLength}
        position={position}
        value={nextValue}
        onPositionChange={handlePositionChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        id={nextId}
      />
      <Items>
        {items}
        {isMask && <Button visible={!masked} onClick={toggleMask} />}
      </Items>
    </Root>
  );
};

const component = withFormValid(withFormControl('')(CodeInput));
export { component as CodeInput };
