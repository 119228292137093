import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { useFormGroup } from 'modules/common/ui';

import { Button } from './Documents.Button';
import { Root } from './Document.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент "Анкета-заявление". Отображается в самом низу формы. Имеет ссылку
 * на документ
 */
export const Document: FC<Props> = ({ ...props }) => {
  const { submit } = useFormGroup();

  const handleClick = useCallback(async () => {
    const [errors] = await submit();

    if (errors) {
      return;
    }

    /**
     * @todo Когда бэк допилит метод получения генерации Анкеты-Заявления, то
     * вставить его вызов сюда.
     */
    const href: string | undefined = undefined;

    if (href) {
      window.open(href, '_blank');
    }
  }, [submit]);

  return (
    <Root {...props}>
      Я даю свое согласие на то, что заполнив сведения в{' '}
      <Button variant="plain" disabled onClick={handleClick}>
        Анкете-заявлении
      </Button>{' '}
      на получение потребительского займа, используя SMS-код, я подтверждаю
      достоверность предоставленных мною сведений и считаю анкету подписанной со
      своей стороны.
    </Root>
  );
};
