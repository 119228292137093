import styled from '@emotion/styled';

/**
 * Текст последнего элемента списка хлебной крошки.
 */
export const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.687rem;
  line-height: 1rem;

  color: ${({ theme }) => theme.colors.light.text.secondary};

  white-space: nowrap;
`;
