import { FC, useEffect } from 'react';

import { Store } from '../../stores';

/**
 * Инициализирует глобальное хранилище авторизации пользователя. Все компоненты,
 * которые используют состояние авторизации, следует помещать внутри данного
 * компонента. В противном случае возможны ошибки, когда некая программная
 * сущность пытается обратиться к данным, которые ещё не были получены из
 * браузерного хранилища.
 */
export const Provider: FC = ({ children }) => {
  const store = Store.use();

  useEffect(() => {
    store.inititalize();
  });

  return <>{children}</>;
};
