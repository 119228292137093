import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { AmountHelper, DateHelper } from 'modules/common/values';
import { Button } from 'modules/common/ui';
import { PaymentType } from 'modules/repay';

import { Store } from '../../Store';
import { Type } from '../../Type';

import { Item } from '../Item';
import { Info } from '../Info';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Item>, 'heading' | 'body'>;

/**
 * Отображает блок погашения просрочки.
 */
const Overdue: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const amount = `${AmountHelper.formatDisplayFloat(
    store.model?.expirationPayment?.amount ?? 0,
  )} ${AmountHelper.getShortUnits()}`;

  console.log(store.model);
  const date = DateHelper.formatDisplay(
    store.model?.expirationPayment?.dateAt ?? new Date(),
  );

  const type = store.model?.type;

  const handleClick = useCallback(
    () =>
      store.navigateToPayment(
        type === Type.PDL && !store.isRestructured
          ? PaymentType.EARLY
          : PaymentType.EXPIRATION,
      ),
    [store, type],
  );

  return (
    <Item
      {...props}
      heading="Заём просрочен, оплатите!"
      variant="danger"
      body={
        <>
          <Info title="Дата просрочки" content={date} />
          <Info title="Сумма просрочки" content={amount} />
        </>
      }
    >
      <Button variant="filled" type="button" onClick={handleClick}>
        Оплатить просрочку
      </Button>
    </Item>
  );
};

const component = observer(Overdue);
export { component as Overdue };
