import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useEffect,
} from 'react';

import { Button, Announcement, LinkButton } from 'modules/common/ui';
import { LoanService as LoanMetricService } from 'modules/common/metrics';

import { Store } from '../../stores';

import success from './assets/success.svg';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Announcement>,
  'img' | 'alt' | 'heading'
>;

/**
 * Компонент отображающий "Займ успешно закрыт" на информационной странице платежей.
 */
export const SuccessFull: FC<Props> = ({ ...props }) => {
  const store = Store.use();
  const loanMetric = LoanMetricService.use();

  useEffect(() => {
    loanMetric.closed();
  }, [loanMetric]);

  const handleClick = useCallback(() => {
    store.goToAccount();
  }, [store]);

  return (
    <Announcement
      {...props}
      img={success}
      alt="Займ успешно закрыт"
      heading="Ура, Вы успешно закрыли заём!"
      subheading="Не ждите завтра — деньги могут понадобиться в любой момент!
        Загляните в Личный кабинет,
        чтобы узнать решение по новой заявке уже сегодня."
      footer={
        <>
          Настоящим выражаю своё согласие на{' '}
          <LinkButton
            href="https://apollon-zaym.ru/docs/07-soglasiye-na-obrabotku-obrabotke-personalnyh-dannyh-ooo-mkk-dzp-razvitie-7.pdf"
            target="_blank"
            rel="noopener noreferrer"
            variant="plain"
            color="info"
          >
            обработку персональных данных
          </LinkButton>{' '}
          и на запрос кредитного отчета в бюро кредитных историй
        </>
      }
    >
      <Button variant="filled" onClick={handleClick}>
        Узнать решение
      </Button>
    </Announcement>
  );
};
