import styled from '@emotion/styled';

/**
 * Заголовок.
 */
export const Heading = styled.div`
  color: ${({ theme }) => theme.colors.light.text.secondary};

  font-size: 0.6875rem;
`;
