import { ComponentPropsWithoutRef, FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../Store';

import { InferValidate } from './InferValidate';
import { List } from './List';

import { generateInitialValues } from './Documents.generateInitialValues';
import { generateSchema } from './Documents.generateSchema';
import { Root } from './Documents.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент отображающий список документов.
 */
const Documents: FC<Props> = ({ ...props }) => {
  const store = Store.use();
  const documents = store.documents || [];

  const names = useMemo(
    () => documents.map((document) => document.type),
    [documents],
  );

  const initialValues = useMemo(() => generateInitialValues(names), [names]);
  const schema = useMemo(() => generateSchema(names), [names]);

  const onUnregister = useCallback(() => {
    store.setValidate(undefined);
  }, [store]);

  const onRegister = useCallback(
    (validate: any) => {
      store.setValidate(validate);
    },
    [store],
  );

  if (store.documents == null) {
    throw new Error(
      `This component must be placed inside restructure.Provider`,
    );
  }

  return (
    <Root
      {...props}
      initialValues={initialValues}
      schema={schema}
      name="restructuring-documents"
    >
      <InferValidate onUnregister={onUnregister} onRegister={onRegister} />
      <List documents={documents} />
    </Root>
  );
};

const component = observer(Documents);
export { component as Documents };
