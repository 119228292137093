import { Helper } from '@devim-front/helper';

/**
 * Содержит методы для работы с масками ввода.
 */
export class MaskHelper extends Helper {
  /**
   * Форматирует указанный текст по переданной маске.
   * @param text Текст.
   * @param mask Маска.
   */
  public static format(text: string, mask: string) {
    const { length: textLength } = text;
    const { length: maskLength } = mask;

    let escaped: boolean = false;
    let result: string = '';

    for (let i = 0, j = 0; i < maskLength && j < textLength; ) {
      const maskChar = mask[i];
      const textChar = text[j];

      const special = maskChar === '0' || maskChar === 'a' || maskChar === '*';

      if (escaped && !special) {
        escaped = false;

        if (maskChar === textChar) {
          j += 1;
        }

        result += maskChar;
        i += 1;

        // eslint-disable-next-line no-continue
        continue;
      }

      if (maskChar === '\\') {
        escaped = true;
        i += 1;

        // eslint-disable-next-line no-continue
        continue;
      }

      let pattern: RegExp = /./;

      if (maskChar === '9') {
        pattern = /\d/;
      }

      if (maskChar === 'a') {
        pattern = /\w/;
      }

      if (pattern.test(textChar)) {
        result += textChar;
        i += 1;
      }

      j += 1;
    }

    return result;
  }

  /**
   * Возвращает `true`, если в указанном диапазоне символов маски есть
   * спец-символы.
   * @param mask Маска.
   * @param start Начальная позиция поиска.
   * @param length Количество символов относительно начала поиска.
   */
  public static isSpecialChars(
    mask: string,
    start: number = 0,
    length: number = mask.length,
  ) {
    const { length: maskLength } = mask;

    for (let i = start; i < maskLength && i < length; i += 1) {
      const char = mask.charAt(i);

      if (char === '\\') {
        i += 1;
        // eslint-disable-next-line no-continue
        continue;
      }

      if (char === '0' || char === '*' || char === 'a') {
        return true;
      }
    }

    return false;
  }
}
