import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент для отображение иконок в части подвала страницы.
 */
export const Icons = styled.div`
  grid-column: 2 / span 3;
  grid-row: 2;

  display: flex;

  @media ${lessThanContainer('medium')} {
    grid-column: 2 / span 3;

    flex-direction: column;
  }

  @media ${lessThanContainer('small')} {
    grid-column: auto;
    grid-row: auto;
  }
`;
