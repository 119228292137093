import { Service } from './Service';

import { Event } from '../types/Event';

/**
 * Сервис содержит методы для отправки событий метрики заявки на займ.
 */
export class ApplicationService extends Service {
  /**
   * Клиент создал заявку.
   */
  created() {
    this.send(Event.APPLICATION_CREATED);
  }

  /**
   * Клиент заполнил первый шаг анкеты.
   */
  firstStepSubmitted() {
    this.send(Event.APPLICATION_FIRST_STEP_SUBMITTED);
  }

  /**
   * Клиент заполнил второй шаг анкеты.
   */
  secondStepSubmitted() {
    this.send(Event.APPLICATION_SECOND_STEP_SUBMITTED);
  }

  /**
   * Клиент заполнил третий шаг анкеты.
   */
  thirdStepSubmitted() {
    this.send(Event.APPLICATION_THIRD_STEP_SUBMITTED);
  }

  /**
   * Клиенту одобрили заём. Когда пользователь оказывается на странице
   * подписания договора на займ, мы смотрим, есть ли флаг в localStorage.
   * Если есть - отправляем событие и удаляем флаг. Если нет - ничего не делаем.
   */
  approved() {
    const flag = localStorage.getItem(Event.APPLICATION_SIGNED);

    if (flag) {
      this.send(Event.APPLICATION_APPROVED);
      localStorage.removeItem(Event.APPLICATION_SIGNED);
    }
  }

  /**
   * Клиенту отказали в займе. Когда пользователь оказывается на странице
   * отказа в займе, мы смотрим, есть ли флаг в localStorage.
   * Если есть - отправляем событие и удаляем флаг. Если нет - ничего не делаем.
   */
  rejected() {
    const flag = localStorage.getItem(Event.APPLICATION_SIGNED);

    if (flag) {
      this.send(Event.APPLICATION_REJECTED);
      localStorage.removeItem(Event.APPLICATION_SIGNED);
    }
  }

  /**
   * Клиент подписал заявку на займ. После того, как пользователь подписал
   * заявку на займ, мы записываем в localStorage флаг,
   * означающий - "пользователь подписал заявку".
   */
  signed() {
    localStorage.setItem(Event.APPLICATION_SIGNED, 'true');

    this.send(Event.APPLICATION_SIGNED);
  }
}
