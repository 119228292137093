import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Указывает, что каретка должна быть повёрнута вверх.
   */
  active?: boolean;
};

/**
 * Корневой элемент компонента.
 */
export const Root = styled.svg<Props>`
  color: ${(p) => p.theme.color.info.main};

  transition: ${transition('transform')};
  transform: rotate(${(p) => (p.active ? 180 : 0)}deg);
`;
