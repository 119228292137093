import { FC, useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Page } from 'modules/common/routing';
import { Store } from 'modules/common/auth';
import { PageLoading } from 'modules/common/layout';

import { ResetContext } from 'modules/common/reset';
/**
 * Служебный компонент, который будучи подключён в DOM, прекращает авторизацию
 * и передаресовывает пользователя на главную.
 */
export const Logout: FC = () => {
  const router = useRouter();
  const store = Store.use();

  const value = useContext(ResetContext);

  const logout = useCallback(async () => {
    store.setTokens(undefined);
    await router.replace(Page.HOME);
    value.reset();
  }, [store, router, value]);

  useEffect(() => {
    logout();
  });

  return <PageLoading />;
};
