import styled from '@emotion/styled';

export const Root = styled.div`
  padding-left: 24px;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 10px;
    top: 7px;

    width: 4px;
    height: 4px;

    background: ${({ theme }) => theme.colors.light.text.overlay};
    border-radius: 2px;
  }
`;
