import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент калькулятора.
 */
export const Root = styled.div`
  padding-bottom: 35px;

  @media ${lessThanContainer('small')} {
    padding-bottom: 20px;
  }

  @media ${lessThanContainer('medium')} and (orientation: landscape) {
    padding-bottom: 5px;
  }
`;
