import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент калькулятора.
 */
export const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.light.background.primary};
  color: ${({ theme }) => theme.colors.light.text.primary};
  border: 1.26667px solid ${({ theme }) => theme.colors.light.border.hover};
  border-radius: 4.75rem;

  display: flex;

  @media ${lessThanContainer('small')} {
    border-radius: 1.25rem;

    display: block;
  }
`;
