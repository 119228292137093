import { LazyService } from '@devim-front/service';

import { Service as RoutingService } from 'modules/common/routing';
import { CompatService } from 'services/CompatService';
import { PaymentAction } from 'services/RpcService/types/PaymentAction';
import { PaymentMethodType } from 'services/RpcService/types/PaymentMethodType';
import { PaymentProvider } from 'services/RpcService/types/PaymentProvider';
import { ServiceType } from 'services/RpcService/types/ServiceType';

import { PaymentType } from '../types';

/**
 * Содержит методы для работы с оплатой.
 */
export class Service extends LazyService {
  /**
   * Ключ, по которому в сессии сохраняется тип оплаты займа.
   */
  private PAYMENT_TYPE_KEY = 'payment_type';

  /**
   * Ссылка на экземпляр сервиса API.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Экземпляр сервиса маршрутизатора.
   */
  private get routing() {
    return RoutingService.get(this);
  }

  /**
   * Возвращает тип платежа, который пользователь выбрал на странице займа.
   */
  public getPaymentType() {
    const value = window.localStorage.getItem(this.PAYMENT_TYPE_KEY);
    return value != null ? (value as PaymentType) : undefined;
  }

  /**
   * Задаёт тип платежа, который будет использоваться на странице
   */
  public setPaymentType(value?: PaymentType) {
    if (value != null) {
      window.localStorage.setItem(this.PAYMENT_TYPE_KEY, value);
    } else {
      window.localStorage.removeItem(this.PAYMENT_TYPE_KEY);
    }
  }

  /**
   * Метод загружает список доступных методов оплаты с сервера
   */
  public async getPaymentMethods(
    amount: number,
    serviceType = ServiceType.Loan,
  ) {
    const response = await this.rpc.paymentGetMethods(serviceType, amount);

    return response;
  }

  /**
   * Загружает метод оплаты с сервера.
   * @returns Действие, которое необходимо произвести для продолжения оплаты.
   */
  public async fetchPaymentMethod(
    amount: number,
    method: PaymentMethodType,
    provider: PaymentProvider,
    serviceType = ServiceType.Loan,
  ): Promise<PaymentAction> {
    const utm = this.routing.getFromSession();
    const result = await this.rpc.paymentGetInfo(
      provider,
      method,
      serviceType,
      amount,
      utm,
    );

    return result;
  }
}
