/**
 * Семейное положение.
 */
export enum UserMartialStatus {
  /**
   * Холост / не замужем.
   */
  Single = 'not_married',

  /**
   * Гражданский брак.
   */
  CivilMarriage = 'civil_marriage',

  /**
   * Женат / замужем.
   */
  Married = 'married',

  /**
   * В разводе.
   */
  Divorced = 'divorced',
}
