import { ComponentPropsWithoutRef, FC } from 'react';

import { Container, Nowrap } from 'modules/common/ui';
import { Page } from 'modules/common/routing';
import { DateHelper } from 'modules/common/values';

import { Text } from '../Text';

import { Root } from './Main.Root';
import { Content } from './Main.Content';
import { Item as MenuItem, LinkButton as MenuLinkButton } from './Menu';
import { Menu } from './Main.Menu';
import { Description } from './Main.Description';
import { Icons } from './Main.Icons';
// import { All } from './Main.All';
import { Apps } from './Main.Apps';

import { Icon } from './Icon';

// import Visa from './assets/VISA.svg';
// import Mir from './assets/MIR.svg';
// import MasterCard from './assets/MasterCard.svg';
// import Trustpilot from './assets/Trustpilot.svg';
// import Som from './assets/SOM.svg';
// import Age from './assets/Age.svg';
import AppStore from './assets/AppStore.svg';
import GooglePlay from './assets/GooglePlay.svg';
import AppGallery from './assets/AppGallery.svg';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'layout'>;

/**
 * Отображает часть подвала страницы.
 */
export const Main: FC<Props> = ({ ...props }) => {
  const year = DateHelper.getYear(DateHelper.CURRENT);

  return (
    <Root {...props}>
      <Container size="large" offset="small">
        <Content>
          <Menu>
            {/* <MenuItem>
              <MenuLinkButton href={Page.ABOUT}>О компании</MenuLinkButton>
            </MenuItem> */}
            <MenuItem>
              <MenuLinkButton href={Page.DOCUMENTS}>Документы</MenuLinkButton>
            </MenuItem>
          </Menu>
          <Description>
            <Text>
              © 2015-{year} Общество с ограниченной ответственностью
              «ДЗП-РАЗВИТИЕ 7» (сокращенно – ООО «ДЗП-РАЗВИТИЕ 7»)
            </Text>
            <Text>
              Сведения об Обществе с ограниченной ответственностью «ДЗП-Развитие 7» 
              (регистрационный номер записи в государственном реестре микрофинансовых организаций 
              001503140006969; адрес (место нахождения): 197374, <Nowrap>г. Санкт-Петербург</Nowrap>, Мебельная улица, дом 12, 
              корпус 1, лит. А, помещение 53-Н, офис  435; <Nowrap>ИНН 7838031546</Nowrap>; <Nowrap>ОГРН 1157847007702</Nowrap>) 
              из государственного реестра микрофинансовых организаций исключены на основании 
              заявления Общества с ограниченной ответственностью «ДЗП-Развитие 7» об исключении сведений 
              о нем из государственного реестра микрофинансовых организаций, в соответствии 
              с пунктом 11 части 1.1 статьи 7 и пунктом 8 части 4 статьи 14 Федерального закона от 02.07.2010 
              № 151-ФЗ «О микрофинансовой деятельности и микрофинансовых организациях» согласно приказа 
              Центрального Банка Российской Федерации от 04.08.2023 № ОД-1330.
            </Text>
            <Text>
              Адрес: 197374, г. Санкт-Петербург, ул. Мебельная, дом 12, корпус
              1, литера А, пом. 53-Н, оф. 435
            </Text>

            <Text>
              Телефон для связи:{' '}
              <a href="tel:+74996499059">
                <Nowrap>+7 (499) 649-90-59</Nowrap>
              </a>
            </Text>
            <Text>
              График работы контакт-центра: ежедневно: с 06:00 до 22:00 (по
              Москве).
            </Text>
            <Text>
              Служба поддержки клиентов:{' '}
              <a href="mailto:support@apollon-zaym.ru">
                <Nowrap>support@apollon-zaym.ru</Nowrap>
              </a>
            </Text>
            <Text>
              ООО «ДЗП-РАЗВИТИЕ 7» использует файлы «cookie» с целью
              персонализации сервисов и повышения удобства пользования
              веб-сайтом. «Cookie» представляют собой небольшие файлы,
              содержащие информацию о предыдущих посещениях веб-сайта. Если вы
              не хотите использовать файлы «cookie», измените настройки
              браузера.
            </Text>
          </Description>
          <Icons>
            {/* <All>
              <Icon src={Visa} alt="Visa" />
              <Icon src={Mir} alt="Mir" />
              <Icon src={MasterCard} alt="MasterCard" />
              <Icon src={Trustpilot} alt="Trustpilot" />
              <Icon src={Som} alt="Som" title="Член СРО с 13.03.2016" />
              <Icon src={Age} alt="18+" title="Только для совершеннолетних" />
            </All> */}
            <Apps>
              <Icon
                src={AppStore}
                alt="AppStore"
                href="https://apps.apple.com/cz/app/%D0%B0%D0%BF%D0%BE%D0%BB%D0%BB%D0%BE%D0%BD-%D0%B7%D0%B0%D0%B9%D0%BC%D1%8B-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1571329178"
              />
              <Icon
                src={GooglePlay}
                alt="GooglePlay"
                href="https://play.google.com/store/apps/details?id=com.apollon.android&hl=en&gl=KH"
              />
              <Icon
                src={AppGallery}
                alt="AppGallery"
                href="https://appgallery.huawei.com/#/app/C104501233"
              />
            </Apps>
          </Icons>
        </Content>
      </Container>
    </Root>
  );
};
