import { css } from '@emotion/react';

export const reset = css`
  *,
  *:before,
  *:after {
    background-repeat: no-repeat;
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;

    margin: 0;

    min-height: 100%;

    overflow-x: hidden;
  }

  #__next {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    min-height: 100%;
  }

  input,
  button {
    font-family: inherit;
    font-size: inherit;
  }
`;
