import styled from '@emotion/styled';

import { Form, lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент Form, задающий сетку grid.
 */
export const Root = styled(Form)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;

  @media ${lessThanContainer('small')} {
    grid-gap: 1rem;
  }
`;
