import { FC, ComponentPropsWithoutRef, useState, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Store as CoreStore } from 'modules/application/core';
import { Calculator, Store as CalculatorStore } from 'modules/calculator';
import { Store as StatementStore } from 'modules/application/statement';
import { SlideTransition } from 'modules/common/transition';

import { Root } from './ApplicationCalculator.Root';
import { Text } from './ApplicationCalculator.Text';
import { Root as CalculatorRoot } from './Calculator.Root';
import { Icon } from './Icon';

/**
 * Свойства компонента
 */
type Props = ComponentPropsWithoutRef<FC> & {
  /**
   * Номер шага, доступ к которому нужно проверять.
   */
  step?: number;
};

/**
 * Компонент калькулятора в форме заявки на заём.
 * Позволяет изменить сумму и срок заёма в процессе заполнения анкеты.
 */
const ApplicationCalculator: FC<Props> = ({ step, ...props }) => {
  const application = CoreStore.use();
  const { model, pending } = application;
  const calculator = CalculatorStore.use();
  const statement = StatementStore.use();

  const amount = model?.amount;
  const period = model?.period;

  const [isOpened, setIsOpened] = useState(amount === 0 && period === 0);

  const toggle = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const handleSubmit = useCallback(
    async (nextAmount: number, nextPeriod: number) => {
      await application.submitParams(nextAmount, nextPeriod);
      await statement.renew();
      setIsOpened(false);
    },
    [application, statement],
  );

  const setDefault =
    statement.model !== null && amount === 0 && period === 0 && step === 3;

  if (setDefault) {
    handleSubmit(calculator.amount, calculator.period);
  }

  return (
    <Root {...props} isOpened={isOpened}>
      {amount === 0 && period === 0 ? (
        <Text isOpened={isOpened} intro="Выберите параметры займа" />
      ) : (
        <Text
          isOpened={isOpened}
          icon={<Icon />}
          intro="Вы собираетесь взять"
          amount={amount}
          separator="на"
          period={period}
          onClick={toggle}
        />
      )}
      <SlideTransition visible={isOpened}>
        <CalculatorRoot>
          <Calculator
            pending={pending}
            amount={amount}
            period={period}
            buttonLabel="Сохранить"
            onSubmit={handleSubmit}
          />
        </CalculatorRoot>
      </SlideTransition>
    </Root>
  );
};

const component = observer(ApplicationCalculator);
export { component as ApplicationCalculator };
