import { Helper as Base } from '@devim-front/helper';
import { UserProfile } from 'services/RpcService/types/UserProfile';

/**
 * Содержит методы для работы со сведениями о пользователе.
 */
export class Helper extends Base {
  /**
   * Возвращает `true`, если все сведения о пользователе заполнены.
   * @param model Модель клиента.
   */
  public static isFull(model: UserProfile) {
    const { firstName, lastName, patronymicName, email } = model;

    return !!firstName && !!lastName && !!patronymicName && !!email;
  }
}
