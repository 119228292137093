import React, { Component } from 'react';
import { FormikProps } from 'formik';

import { FormGroupContext } from '../FormGroup';

/*
 * Свойства компонента.
 */
type Props = Pick<
  FormikProps<any>,
  'handleSubmit' | 'submitForm' | 'values' | 'errors'
> & {
  /**
   * Имя класса формы.
   */
  className?: string;

  /**
   * Сведения об автоподстановке в форму.
   */
  autoComplete?: string;

  /**
   * Уникальное(в пределах группы) имя формы.
   */
  name?: string;

  /**
   * Обрабатывает успешную отправку формы.
   * @param values Значения полей формы.
   */
  onSubmit?: (values: Record<any, any>) => void;

  /**
   * Обрабатывает ошибку валидации при отправке формы.
   * @param errors Коллекция ошибок валидации.
   */
  onErrors?: (errors: Record<any, string>) => void;
};

/*
 * Отображает корневой элемент формы.
 */
export class Root extends Component<Props> {
  /**
   * @inheritdoc
   */
  static contextType = FormGroupContext;

  /// @ts-ignore
  context!: React.ContextType<typeof FormGroupContext>;

  /**
   * @inheritdoc
   */
  componentDidMount() {
    const { register } = this.context;
    const { name } = this.props;

    if (!name) {
      return;
    }

    register(name, {
      submit: this.handleOuterSubmit,
    });
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    const { unregister } = this.context;
    const { name } = this.props;

    if (!name) {
      return;
    }

    unregister(name);
  }

  /**
   * Обработчик события программной отправки формы.
   */
  private handleOuterSubmit = async () => {
    const { submitForm, onSubmit } = this.props;

    await submitForm();

    const { values, errors } = this.props;

    const isError = Object.values(errors).length > 0;

    if (!isError && onSubmit) {
      onSubmit(values);
    }

    return [isError ? errors : null, isError ? null : values] as [
      Record<string, string> | null,
      Record<string, any> | null,
    ];
  };

  /**
   * @inheritdoc
   */
  render() {
    const { handleSubmit, children, className, autoComplete } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        autoComplete={autoComplete}
        className={className}
        noValidate
      >
        {children}
      </form>
    );
  }
}
