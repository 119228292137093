import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { Img } from './Announcement.Img';
import { Heading } from './Announcement.Heading';
import { SubHeading } from './Announcement.SubHeading';
import { Body } from './Announcement.Body';
import { Footer } from './Announcement.Footer';
import { Root } from './Announcement.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Путь к изображению
   */
  img: string;

  /**
   * Альтернативный текст изображения.
   */
  alt: string;

  /**
   * Заголовок.
   */
  heading: string;

  /**
   * Подзаголовок.
   */
  subheading?: ReactNode;

  /**
   * Подвал.
   */
  footer?: ReactNode;
};

/**
 * Контент объявления.
 */
export const Announcement: FC<Props> = ({
  img,
  alt,
  heading,
  subheading,
  footer,
  children,
  ...props
}) => (
  <Root {...props} size="small">
    <Img src={img} alt={alt} />
    <Heading>{heading}</Heading>
    {subheading && <SubHeading>{subheading}</SubHeading>}
    {children && <Body>{children}</Body>}
    {footer && <Footer>{footer}</Footer>}
  </Root>
);
