import * as Sentry from '@sentry/browser';
import { Helper as Base } from '@devim-front/helper';

/**
 * Содержит методы для работы с номерами телефонов.
 */
export class PhoneHelper extends Base {
  /**
   * Регулярное выражение для проверки номера телефона.
   */
  public static VALIDATION_REGEXP = /^\+7 \(9\d{2}\) \d{3}-\d{2}-\d{2}$/;

  /**
   * Регулярное выражение для проверки номера телефона контактного лица.
   */
  public static CONTACT_VALIDATION_REGEXP = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;

  /**
   * Обрабатывает значение телефонного номера и вырезает форматирование,
   * возвращая числовое значение.
   * @param phone Номер телефона.
   */
  public static parse(phone: string): string {
    const match = phone.match(/\d/g);

    if (match === null) {
      Sentry.captureException(
        new Error(`The value ${phone} cannot be parsed as a phone number`),
      );
      return '';
    }

    return match.join('');
  }

  /**
   * Обрабатывает значение телефонного номера и форматирует его согласно
   * шаблону.
   * @param phone Номер телефона.
   */
  public static format(phone: string): string {
    const match = phone.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match === null) {
      throw new Error(`The value ${phone} cannot be parsed as a phone number`);
    }

    const [, a, b, c, d, e] = match;
    return `+${a} (${b}) ${c}-${d}-${e}`;
  }
}
