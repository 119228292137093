import * as yup from 'yup';

import { Field } from '../types';

/**
 * Схема формы семейного положения.
 */
export const schema = yup.object().shape({
  /**
   * Семейное положение.
   */
  [Field.STATUS]: yup.string().required('Укажите ваше семейное положение'),

  /**
   * Количество членов семьи.
   */
  [Field.SIZE]: yup.string().required('Укажите количество членов вашей семьи.'),

  /**
   * Количество детей до 18-ти лет.
   */
  [Field.CHILDREN_COUNT]: yup
    .string()
    .required('Укажите количество ваших детей'),

  /**
   * Проживание с родителями.
   */
  [Field.IS_LIVING_WITH_PARENTS]: yup
    .boolean()
    .required('Выберите один из предложенных вариантов')
    .typeError('Выберите один из предложенных вариантов'),
});
