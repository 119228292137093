import { FC } from 'react';

import { Root } from './Preloader.Root';
import { Spinner } from './Preloader.Spinner';

/**
 * Отображает компонент индикации загрузки.
 */
export const Preloader: FC = () => (
  <Root>
    <Spinner size="large" />
  </Root>
);
