import React, {
  ChangeEvent,
  useCallback,
  useState,
  FC,
  ComponentPropsWithoutRef,
} from 'react';

import { Label } from './Input.Label';
import { Input as Base } from './Input.Input';
import { Root } from './Input.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'value' | 'onChange'
> & {
  /**
   * Подпись к полю ввода.
   */
  label: string;

  /**
   * Текущее значение поля ввода.
   */
  value: number;

  /**
   * Обрабатывает изменение значения поля ввода.
   */
  onChange: (value: number) => void;

  /**
   * Возвращает текст, который должен отображаться на поле ввода.
   *
   * @param value Значение поля ввода.
   */
  format: (value: number) => string;
};

/**
 * Отображает поле ввода с подписью на калькуляторе.
 */
export const Input: FC<Props> = ({
  className,
  format,
  label,
  name,
  onChange: outerOnChange,
  onFocus: outerOnFocus,
  onBlur: outerOnBlur,
  value: outerValue,
  ...props
}) => {
  const [innerValue, setInnerValue] = useState<string | undefined>(undefined);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { value } = target;

      if (/^\d*$/.test(value)) {
        setInnerValue(value);
      }
    },
    [setInnerValue],
  );

  const onFocus = useCallback(
    (event: any) => {
      const value = String(outerValue);
      setInnerValue(value);

      if (outerOnFocus) {
        outerOnFocus(event);
      }
    },
    [setInnerValue, outerOnFocus, outerValue],
  );

  const onBlur = useCallback(
    (event: any) => {
      const value = Number(innerValue);

      setInnerValue(undefined);
      outerOnChange(value);

      if (outerOnBlur) {
        outerOnBlur(event);
      }
    },
    [setInnerValue, innerValue, outerOnBlur, outerOnChange],
  );

  const value = innerValue == null ? format(outerValue) : innerValue;

  return (
    <Root className={className}>
      <Label htmlFor={name}>{label}</Label>
      <Base
        {...props}
        formNoValidate
        inputMode="numeric"
        type="text"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        name={name}
        id={name}
      />
    </Root>
  );
};
