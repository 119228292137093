import { BaseError } from './BaseError';

/**
 * Ошибка запроса к защищённому методу при отсутствии токенов аутентификации.
 */
export class UnauthorizedError extends BaseError {
  /**
   * Ответ внешнего сервера.
   */
  public readonly response: object;

  /**
   * Создаёт экземпляр ошибки данных ответа сервера.
   * @param response Данные ответа сервера.
   */
  constructor(response: any) {
    super('E_UNAUTHORIZED', 'Unauthorized');

    this.response = response;
  }
}
