import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { AmountHelper, DateHelper } from 'modules/common/values';
import { Button } from 'modules/common/ui';
import { PaymentType } from 'modules/repay';

import { Store } from '../../Store';

import { Item } from '../Item';
import { Info } from '../Info';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Item>, 'heading' | 'body'>;

/**
 * Отображает блок минимального платежа.
 */
const Minimal: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const amount = `${AmountHelper.formatDisplayFloat(
    store.model?.nextPayment?.amount ?? 0,
  )} ${AmountHelper.getShortUnits()}`;

  const date = DateHelper.yesterdayDisplay(
    store.model?.nextPayment?.dateAt ?? new Date(),
  );

  const handleClick = useCallback(
    () => store.navigateToPayment(PaymentType.NEXT),
    [store],
  );

  return (
    <Item
      {...props}
      heading="Минимальный платеж"
      variant={store.isEquator ? 'warning' : undefined}
      body={
        <>
          <Info title="Сумма платежа" content={amount} />
          <Info title="Дата платежа" content={date} />
        </>
      }
    >
      <Button variant="outlined" type="button" onClick={handleClick}>
        Внести платёж
      </Button>
    </Item>
  );
};

const component = observer(Minimal);
export { component as Minimal };
