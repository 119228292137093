import { createContext, FC, useState, useCallback, useMemo } from 'react';

/**
 * Значение контекста.
 */
type Value = {
  /**
   * Указывает, что модальное окно открыто.
   */
  isOpen: boolean;

  /**
   * Переключает значение свойства `expanded` на противоположное.
   */
  toggle: () => void;
};

/**
 * Контекст модального окна.
 */
export const Context = createContext<Value>({
  isOpen: false,
  toggle: () => {},
});

/**
 * Предоставляет контекст, в котором содержится состояния модального окна.
 */
export const Provider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const value: Value = useMemo(
    () => ({
      toggle,
      isOpen,
    }),
    [toggle, isOpen],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
