import styled from '@emotion/styled';

import { Caret as Base } from 'modules/common/ui';

/**
 * Добавляем компоненту Caret дополнительные стили.
 */
export const Caret = styled(Base)`
  width: 1rem;

  margin-left: 0.125rem;
`;
