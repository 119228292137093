import styled from '@emotion/styled';
import { ComponentPropsWithoutRef } from 'react';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'span'> & {
  /**
   * Тип темы сайта.
   */
  layout: 'screen' | 'default';
};

/**
 * Текстовый элемент меню шапки. Используется для дополнительных пунктов меню.
 */
export const Text = styled.span<Props>`
  position: relative;

  padding: 0.5rem 0;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  color: ${(props) => {
    return props.layout === 'screen'
      ? props.theme.colors.light.text.overlay
      : props.theme.colors.light.text.secondary;
  }};

  cursor: pointer;

  @media ${lessThanContainer('medium')} {
    display: none;
  }
`;
