export const MasterCard = () => (
  <svg
    width="27"
    height="16"
    viewBox="0 0 27 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.0179 12.9574V12.647H25.9357L25.8407 12.8603L25.7465 12.647H25.6639V12.9574H25.7221V12.7235L25.8112 12.9254H25.8713L25.9601 12.7228V12.9574H26.0183H26.0179ZM25.4972 12.9574V12.6999H25.6023V12.6477H25.334V12.6999H25.439V12.9574H25.4972Z"
      fill="#F79410"
    />
    <path
      d="M16.6903 14.2893H9.58594V1.71045H16.6903V14.2893Z"
      fill="#FF5F00"
    />
    <path
      d="M10.0353 7.99981C10.0339 6.7892 10.3123 5.5941 10.8496 4.50465C11.3869 3.41521 12.1689 2.45988 13.1368 1.71074C11.7083 0.600392 9.9396 -0.00252897 8.11818 7.97346e-06C3.63457 7.97346e-06 0 3.58146 0 7.99943C0 12.4182 3.63457 15.9996 8.11857 15.9996C10.0128 15.9996 11.7555 15.3604 13.1364 14.2893C12.1685 13.54 11.3865 12.5845 10.8493 11.495C10.312 10.4054 10.0337 9.21013 10.0353 7.99943"
      fill="#EB001B"
    />
    <path
      d="M26.2739 7.99981C26.2739 12.4185 22.6389 16 18.1549 16C16.3337 16.0024 14.5652 15.3996 13.1367 14.2896C15.0255 12.8246 16.2382 10.5516 16.2382 7.99981C16.2397 6.78919 15.9613 5.59405 15.424 4.50459C14.8868 3.41514 14.1046 2.45982 13.1367 1.71074C14.5653 0.60055 16.334 -0.0023503 18.1553 6.88577e-06C22.6393 6.88577e-06 26.2743 3.58146 26.2743 7.99943"
      fill="#F79E1B"
    />
  </svg>
);
