import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Badge.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Цвет фона элемента и контрастный текст
   */
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'info';
};

/**
 * Отображает элемент цветной метки с произвольным текстом.
 */
export const Badge: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>{children}</Root>
);
