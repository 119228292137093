import { createContext, useState, FC, useContext } from 'react';

/**
 * Контекст меню шапки.
 */
type Value = {
  /**
   * Состояние раскрытия меню шапки.
   */
  open: boolean;

  /**
   * Задаёт состояние раскрытия меню шапки.
   * @param open Состояние раскрытия меню шапки.
   */
  setOpen: (open: boolean) => void;
};

/**
 * Контекст меню шапки.
 */
export const MenuContext = createContext<Value>({
  open: false,
  setOpen: () => {},
});

/**
 * Объявляет контекст меню шапки.
 */
export const MenuProvider: FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const value: Value = { open, setOpen };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

/**
 * Возвращает контекст меню шапки.
 */
export const useMenu = () => useContext(MenuContext);
