import { ApplicationFormLegalSupport } from '../types/ApplicationFormLegalSupport';
import { ApplicationFormLoan } from '../types/ApplicationFormLoan';
import { ApplicationFormServicePaymentRefund } from '../types/ApplicationFormServicePaymentRefund';
import { ApplicationFormType } from '../types/ApplicationFormType';
import { ApplicationFormTypes } from '../types/ApplicationFormTypes';
import { UserContactPerson } from '../types/UserContactPerson';

/**
 * Преобразует ответ сервера в данные формы заявки.
 * @param formType Тип формы.
 * @param data Ответ сервера.
 */
export function transformApplicationForm<
  TFormType extends keyof ApplicationFormTypes,
>(formType: TFormType, data: any): ApplicationFormTypes[TFormType] {
  switch (formType) {
    case ApplicationFormType.LoanApplicationForm:
      return transformApplicationFormLoan(
        data,
      ) as ApplicationFormTypes[TFormType];

    case ApplicationFormType.LegalSupport:
      return transformApplicationFormLegalSupport(
        data,
      ) as ApplicationFormTypes[TFormType];

    case ApplicationFormType.ServicePaymentRefund:
      return transformApplicationFormServicePaymentRefund(
        data,
      ) as ApplicationFormTypes[TFormType];

    default:
      return undefined as never;
  }
}

/**
 * Преобразует ответ сервера в список контактных лиц.
 * @param data Ответ сервера.
 */
function transformContactPersons(data: any): UserContactPerson[] {
  return Array.from('123').reduce((acc, id) => {
    const firstName = data[`contact_first_name${id}`];
    const lastName = data[`contact_last_name${id}`];
    const patronymicName = data[`contact_patronymic${id}`];
    const phoneNumber = data[`contact_phone${id}`];
    const type = data[`contact_relation${id}`];

    const isEmpty = ![
      firstName,
      lastName,
      patronymicName,
      phoneNumber,
      type,
    ].some(Boolean);

    if (!isEmpty) {
      acc.push({
        firstName,
        lastName,
        patronymicName,
        phoneNumber,
        type,
      });
    }

    return acc;
  }, [] as UserContactPerson[]);
}

/**
 * Преобразует ответ сервера в данные формы заявки на заём.
 * @param data Ответ сервера.
 */
function transformApplicationFormLoan(data: any): ApplicationFormLoan {
  return {
    email: data.client_email,
    firstName: data.passport_first_name,
    lastName: data.passport_last_name,
    patronymicName: data.passport_patronymic,
    gender: data.passport_gender,
    registrationAddress: {
      city: data.address_city,
      street: data.address_street,
      house: data.address_house,
      apartment: data.address_apartment,
      region: data.address_country,
    },
    residenceAddress: {
      city: data.residence_address_city,
      street: data.residence_address_street,
      house: data.residence_address_house,
      apartment: data.residence_address_apartment,
      region: data.residence_address_country,
    },
    passportSeries: data.passport_series,
    passportNumber: data.passport_number,
    passportBirthPlace: data.passport_place_of_birth,
    passportBirthDate: data.passport_date_of_birth,
    passportDepartmentCode: data.passport_department_code,
    passportIssueDate: data.passport_issue_date,
    passportIssuer: data.passport_issued_by,
    innSnils: data.client_inn_snils,
    martialStatus: data.martial_status,
    familyMembersCount: data.martial_family_count,
    childrenCount: data.martial_children_count,
    isLivingWithParents: '1' === String(data.martial_live_with_parents),
    contactPersons: transformContactPersons(data),
    employment: {
      status: data.work_status,
      monthlySalary: data.work_salary,
      salaryDay: data.work_salary_day || undefined,
      organization: data.work_employer,
      address: data.work_address,
      position: data.work_position,
      phoneNumber: data.work_phone,
    },
    creditObligationsHasUnpaid:
      '1' === String(data.credit_obligations_has_unpaid),
    creditObligationsPaymentsPerMonth:
      data.credit_obligations_payments_per_month,
    creditObligationsAveragePaymentAmount:
      data.credit_obligations_average_payment_amount,
  };
}

/**
 * Преобразует ответ сервера в данные формы заявки на юридическую услугу.
 * @param data Ответ сервера.
 */
function transformApplicationFormLegalSupport(
  data: any,
): ApplicationFormLegalSupport {
  return {
    email: data.client_email,
    firstName: data.passport_first_name,
    lastName: data.passport_last_name,
    patronymicName: data.passport_patronymic,
    registrationAddress: {
      city: data.address_city,
      street: data.address_street,
      house: data.address_house,
      apartment: data.address_apartment,
      region: data.address_country,
    },
    passportSeries: data.passport_series,
    passportNumber: data.passport_number,
    passportBirthPlace: data.passport_place_of_birth,
    passportDepartmentCode: data.passport_department_code,
    passportIssueDate: data.passport_issue_date,
    passportIssuer: data.passport_issued_by,
    isLivingWithParents: '1' === String(data.martial_live_with_parents),
  };
}

/**
 * Преобразует ответ сервера в данные формы заявки на отказ от услуги.
 * @param data Ответ сервера.
 */
function transformApplicationFormServicePaymentRefund(
  data: any,
): ApplicationFormServicePaymentRefund {
  return {
    email: data.email,
    loanIDs: data.loan_ids,
    firstName: data.passport_first_name,
    lastName: data.passport_last_name,
    patronymicName: data.passport_patronymic,
    residenceAddress: {
      city: data.city,
      street: data.street,
      house: data.house,
      apartment: data.apartment,
    },
    passportSeries: data.passport_series,
    passportNumber: data.passport_number,
    passportDepartmentCode: data.passport_department_code,
    passportIssueDate: data.passport_issue_date,
    passportIssuer: data.passport_issued_by,

    bankName: data.bank_name,
    bankIdentificationCode: data.bank_bik,
    bankAccount: data.bank_account_number,
    bankRecipient: data.bank_client_full_name,
  };
}
