import { MaskType } from 'react-imask';
import styled from '@emotion/styled';
import {
  FC,
  ComponentProps,
  FocusEvent,
  ClipboardEvent,
  SyntheticEvent,
  useCallback,
  ReactNode,
  useState,
  ChangeEvent,
} from 'react';

import { MaskHelper } from 'modules/common/values';

import { prependAdornments } from '../../functions';
import { withFormControl, WithFormValidProps, withFormValid } from '../../hocs';

import { Spinner } from '../Spinner';

import { StartAdornment } from './TextInput.StartAdornment';
import { EndAdornment } from './TextInput.EndAdornment';
import { Input } from './TextInput.Input';
import { Root } from './TextInput.Root';

/**
 * Свойства компонента.
 */
type Props = WithFormValidProps<
  Omit<ComponentProps<typeof Input>, 'ref' | 'onChange' | 'mask'> & {
    /**
     * Маска поля ввода. Задаётся с помощью строки, содержащей спец-символы.
     * Подробнее об масках ввода можно прочитать в документации react-input-mask.
     * @see https://imask.js.org/guide.html
     */
    mask?: MaskType;

    /**
     * В это свойство можно поместить элемент, который должен быть отображен
     * внутри границ, но перед областью ввода текста.
     */
    startAdornment?: ReactNode;

    /**
     * В это свойство можно поместить элемент, который должен располагаться
     * внутри границ, но после области ввода текста.
     */
    endAdornment?: ReactNode;

    /**
     * Флаг, который указывает, что поле ввода должно выглядеть так, словно оно
     * в фокусе.
     */
    focused?: boolean;

    /**
     * Флаг, который указывает, что поле ввода должно выглядеть так, словно
     * на него наведён курсор.
     */
    hovered?: boolean;

    /**
     * Флаг, который указывает, что в `endAdornment` должен быть добавлена иконка
     * загрузки.
     */
    pending?: boolean;

    /**
     * Флаг, который указывает, что поле ввода должно быть заблокировано.
     */
    disabled?: boolean;

    /**
     * Флаг, который указывает, что поле ввода выглядит как обычно, но текст в
     * него вводить нельзя.
     */
    readOnly?: boolean;

    /**
     * Указывает, что в тексте следует удалять ведущие и закрывающие пробелы.
     */
    trim?: boolean;

    /**
     * Обработчик события изменения значения поля ввода.
     * @param value Новое значение.
     */
    onChange?: (value: string) => void;
  }
>;

/**
 * Отображает компонент поля ввода строки текста, которое поддерживает маски значений.
 */
const TextInput: FC<Props> = ({
  startAdornment,
  endAdornment: outerEndAdornment,
  mask = /.*/,
  className,
  invalid,
  valid,
  pending,
  disabled,
  readOnly,
  focused: outerFocused,
  hovered,
  onFocus,
  onBlur,
  onSelect,
  onPaste,
  onChange,
  onClick,
  name,
  overwrite = true,
  autofix = true,
  lazy = true,
  trim = false,
  ...props
}) => {
  const [innerFocused, setFocused] = useState<boolean>(false);

  const handleBlur = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      if (trim) {
        let { value } = event.target;
        value = value.trim();

        if (onChange) {
          onChange(value);
        }

        await Promise.resolve();
      }

      setFocused(false);

      if (onBlur) {
        onBlur(event);
      }
    },
    [onChange, onBlur, trim],
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus, setFocused],
  );

  const handleSelect = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (readOnly) {
        const input = event.target as HTMLInputElement;
        input.selectionStart = input.selectionEnd;
      }

      if (onSelect) {
        onSelect(event);
      }
    },
    [onSelect, readOnly],
  );

  const handlePaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      if (typeof mask !== 'string') {
        if (onPaste) {
          onPaste(event);
        }

        return;
      }

      const input = event.target as HTMLInputElement;

      const { selectionStart, selectionEnd, value: inputValue } = input;
      const text = event.clipboardData.getData('Text');

      const isCorrection =
        inputValue &&
        mask &&
        text &&
        selectionStart != null &&
        (selectionEnd === selectionStart || selectionEnd === mask.length) &&
        !MaskHelper.isSpecialChars(mask, 0, selectionStart);

      if (!isCorrection) {
        if (onPaste) {
          onPaste(event);
        }

        return;
      }

      event.preventDefault();

      const maskedText = MaskHelper.format(text, mask);

      if (onChange) {
        onChange(maskedText);
      }
    },
    [onPaste, onChange, mask],
  );

  let endAdornment: ReactNode = outerEndAdornment;

  if (pending) {
    endAdornment = prependAdornments(endAdornment, <Spinner />);
  }

  const focused = outerFocused != null ? outerFocused : innerFocused;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;

      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const handleAccept = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const hasMask = typeof mask !== 'undefined';

  return (
    <Root
      className={className}
      disabled={disabled}
      focused={focused}
      hovered={hovered}
      invalid={invalid}
      valid={valid}
      onClick={onClick}
    >
      {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
      <Input
        {...props}
        mask={mask!}
        placeholderChar="_"
        onPaste={handlePaste}
        onSelect={handleSelect}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        readOnly={readOnly}
        onChange={hasMask ? undefined : handleChange}
        onAccept={hasMask ? handleAccept : undefined}
        overwrite={overwrite}
        autofix={autofix}
        lazy={lazy}
      />
      {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
    </Root>
  );
};

const component = styled(withFormValid(withFormControl('')(TextInput)))``;
export { component as TextInput };
