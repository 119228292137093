import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Divider } from './Calculator.Divider';
import { Amount } from './Calculator.Amount';
import { Period } from './Calculator.Period';
import { Button } from './Calculator.Button';
import { Inputs } from './Calculator.Inputs';
import { Root } from './Calculator.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'onSubmit'> & {
  /**
   * Сумма займа
   */
  amount?: number;

  /**
   * Период займа
   */
  period?: number;

  /**
   * Текст кнопки калькулятора
   */
  buttonLabel?: string;

  /**
   * Указывает, что калькулятор находится в состоянии загрузки данных (то есть
   * сохранение заблокирована, и отображается индикатор загрузки).
   */
  pending?: boolean;

  /**
   * Обрабатывает подтверждение выбранных параметров.
   * @param amount Выбранная сумма займа.
   * @param period Выбранный срок займа.
   */
  onSubmit?: (amount: number, period: number) => void;
};

/**
 * Отображает калькулятор на сайте.
 */
export const Calculator: FC<Props> = ({
  pending,
  amount,
  period,
  buttonLabel,
  onSubmit,
  ...props
}) => (
  <Root {...props}>
    <Inputs>
      <Amount amount={amount} />
      <Divider />
      <Period period={period} />
    </Inputs>
    <Button
      disabled={pending}
      pending={pending}
      label={buttonLabel}
      onSubmit={onSubmit}
    />
  </Root>
);
