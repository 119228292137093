import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';

/**
 * Свойства элемента.
 */
type Props = {
  /**
   * Указывает, что область должна быть показана.
   */
  active?: boolean;
};

/**
 * Обёртка для содержимого выпадающей области.
 */
export const Wrapper = styled.div<Props>`
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;

  padding-top: 0.25rem;

  transition: ${transition('visibility', 'opacity')};
  visibility: hidden;
  opacity: 0;

  ${(p) =>
    p.active
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : null}
`;
