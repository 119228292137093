import styled from '@emotion/styled';

/**
 * Заглавие. Большой текст сверху.
 */
export const Heading = styled.span`
  grid-area: title;

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: left;

  color: ${({ theme }) => theme.color.text.main.main};
`;
