import { LazyStore, reactive } from '@devim-front/store';
import { action, observable } from 'mobx';

import { Page, Store as RoutingStore } from 'modules/common/routing';
import { Store as LoanStore } from 'modules/loan';
import { Store as AuthStore } from 'modules/common/auth';
import { PaymentMethod } from 'services/RpcService/types/PaymentMethod';

import { Service } from '../services';
import { PaymentType } from '../types';
import { PaymentProvider } from 'services/RpcService/types/PaymentProvider';
import { PaymentMethodType } from 'services/RpcService/types/PaymentMethodType';

/**
 * Хранилище состояния оплаты.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Массив методов оплаты
   */
  @observable
  public paymentMethods: PaymentMethod[] = [];

  /**
   * Ссылка на хранилище роутера.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Ссылка на сервис данного хранилища.
   */
  private get service() {
    return Service.get(this);
  }

  /**
   * Ссылка на хранилище займа.
   */
  private get loan() {
    return LoanStore.get(this);
  }

  /**
   * Ссылка на хранилище авторизации
   */
  private get auth() {
    return AuthStore.get(this);
  }

  /**
   * Задает массив методов оплаты
   */
  @action
  private async setPaymentMethods(methods: PaymentMethod[]) {
    this.paymentMethods = methods;
  }

  /**
   * @inheritdoc
   */
  public async fetchMethods() {
    const { model } = this.loan;
    const type = this.service.getPaymentType();

    let amount: number | undefined;

    if (type === PaymentType.NEXT) {
      amount = model?.nextPayment?.amount;
    }

    if (type === PaymentType.EARLY) {
      amount = model?.earlyPayment?.amount;
    }

    if (type === PaymentType.EXPIRATION) {
      amount = model?.expirationPayment?.amount;
    }

    if (typeof amount !== 'number') {
      return;
    }

    const methods = await this.service.getPaymentMethods(amount);

    await this.setPaymentMethods(methods);
  }

  /**
   * Осуществляет действие, которое необходимо произвести для продолжения оплаты.
   */
  @action
  public async pay(
    method: PaymentMethodType,
    provider = PaymentProvider.Tinkoff,
  ) {
    await this.loan.renew();

    const { model } = this.loan;
    const type = this.service.getPaymentType();

    let amount: number | undefined;

    if (type === PaymentType.NEXT) {
      amount = model?.nextPayment?.amount;
    }

    if (type === PaymentType.EARLY) {
      amount = model?.earlyPayment?.amount;
    }

    if (type === PaymentType.EXPIRATION) {
      amount = model?.expirationPayment?.amount;
    }

    if (typeof amount !== 'number') {
      return;
    }

    const { url } = await this.service.fetchPaymentMethod(
      amount,
      method,
      provider,
    );

    window.location.href = url;
  }

  /**
   * Вызывает переход на главную страницу ЛК.
   */
  public goToAccount = async () => {
    this.service.setPaymentType(undefined);
    if (this.auth.isNotAuthorized) {
      await this.routing.replace(Page.LOGIN);
      return;
    }
    await this.loan.renew();
    await this.routing.replace(Page.ACCOUNT);
  };
}
