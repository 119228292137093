import styled from '@emotion/styled';

export const Root = styled.div`
  margin-bottom: 20px;

  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;
