import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Fetchable } from '../../types';

/**
 * Свойства компонента.
 */
type Props = PropsWithChildren<{
  /**
   * Контект, который отображается вместо `children` до тех пор, пока
   * все экземпляры хранилища не будут готовы к использованию.
   */
  fallback?: ReactNode;

  /**
   * Массив экземпляров хранилищ, которые должны быть инициализированы при
   * подключении компонента в DOM и сброшены при его удалении.
   */
  stores: Fetchable[];
}>;

/**
 * Предоставляет механизм безопасной инициализации для экземпляров хранилищ,
 * реализующих интерфейс `Fetchable`.
 *
 * Когда провайдер подключается в DOM, у каждого экземпляра вызывается метод
 * `touch()`. Далее, до тех пор, пока флаги `ready` у всех них не станут
 * `true`, вместо содержимого компонента отображается `fallback`. Когда же все
 * данные загрузятся, компонент позволяет своему содержимому отобразиться.
 * И, наконец, когда провайдер удаляется из DOM, он вызывает метод `reset()`
 * у всех переданных экземпляров хранилища.
 */
const FetchableProvider: FC<Props> = ({
  fallback = null,
  stores,
  children,
}) => {
  useEffect(() => {
    stores.forEach((store) => store.touch());

    return () => {
      stores.forEach((store) => store.reset());
    };
  }, [stores]);

  const ready = stores.every((store) => store.ready);
  return ready ? <>{children}</> : <>{fallback}</>;
};

const component = observer(FetchableProvider);
export { component as FetchableProvider };
