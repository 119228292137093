import React, { HTMLProps, useCallback, FC, useState, ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

import { FileHelper } from 'modules/common/values';

import { CustomFile } from '../../types';
import { withFormControl, WithFormValidProps, withFormValid } from '../../hocs';

import { Root, RootProps } from './FileInput.Root';
import { Box } from './FileInput.Box';
import { Text } from './FileInput.Text';
import { Icon } from './FileInput.Icon';
import { EndAdornment } from './FileInput.EndAdornment';
import { StartAdornment } from './FileInput.StartAdornment';

/**
 * Свойства компонента.
 */
type Props = WithFormValidProps<
  Omit<HTMLProps<HTMLInputElement>, 'value' | 'ref' | 'onChange' | 'size'> &
    RootProps & {
      /**
       * Текущее значение компонента загрузки файлов.
       */
      value?: CustomFile;

      /**
       * Обработчик события изменения значения элемента.
       */
      onChange?: (value?: CustomFile) => void;

      /**
       * Иконка, отображаемая в поле компонента загрузки файлов.
       */
      icon?: ReactNode;

      /**
       * Устанавливает допустимые типы данных для загрузки.
       */
      accept?: string;

      /**
       * Максимальный размер файла для загрузки.
       */
      maxSize?: number;

      /**
       * В это свойство можно поместить элемент, который должен быть отображен
       * внутри границ, но перед областью добавления файла.
       */
      startAdornment?: ReactNode;

      /**
       * В это свойство можно поместить элемент, который должен располагаться
       * внутри границ, но после области добавления файла.
       */
      endAdornment?: ReactNode;
    }
>;

/**
 * Отображает поле ввода файлов.
 */
const FileInput: FC<Props> = ({
  startAdornment,
  endAdornment,
  className,
  accept,
  icon,
  placeholder,
  value,
  maxSize,
  focused: outerFocused,
  hovered,
  valid,
  invalid,
  disabled,
  onChange,
  onFocus,
  onBlur,
  id,
  ...props
}) => {
  const [innerFocused, setFocused] = useState<boolean>(false);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const onDrop = useCallback(
    async (files?: File[]) => {
      if (onChange == null) {
        return;
      }

      if (files == null || files[0] == null) {
        onChange(undefined);
        setFocused(false);
        return;
      }

      const file: CustomFile = files[0];

      const isValid =
        (maxSize == null || file.size <= maxSize) &&
        (accept == null || FileHelper.isMimeType(file, accept));

      if (isValid) {
        file.content = await FileHelper.getContent(file);
      }

      onChange(file);

      setFocused(false);
    },
    [onChange, maxSize, accept],
  );

  const onCancel = useCallback(() => {
    if (onChange) {
      onChange(undefined);
    }

    setFocused(false);
  }, [onChange]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onFileDialogCancel: onCancel,
    disabled,
  });

  const focused = outerFocused != null ? outerFocused : innerFocused;

  const text = (value && value.name) || placeholder;

  return (
    <Root
      {...getRootProps()}
      onFocus={handleFocus}
      onBlur={handleBlur}
      focused={focused}
      hovered={hovered}
      valid={valid}
      invalid={invalid}
      disabled={disabled}
      className={className}
    >
      {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
      <Box>
        {icon && <Icon>{icon}</Icon>}
        {text && <Text placeholder={value == null}>{text}</Text>}
        <input
          {...getInputProps()}
          {...props}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
        />
      </Box>
      {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
    </Root>
  );
};

const component = withFormValid(withFormControl(undefined)(FileInput));
export { component as FileInput };
