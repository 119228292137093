import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { Root } from './Section.Root';
import { Head } from './Section.Head';
import { Body } from './Section.Body';
import { Heading } from './Section.Heading';
import { Aside } from './Section.Aside';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'offset'> & {
  /**
   * Заголовок секции.
   */
  heading?: string;

  /**
   * Произвольный блок, который отображается справа от heading.
   */
  aside?: ReactNode;

  /**
   * Определяет отступ от предыдущей секции по вертикали.
   * @default medium
   */
  offset?: 'medium' | 'large';
};

/**
 * Секция компонентов в Application.
 */
export const Section: FC<Props> = ({
  heading,
  aside,
  children,
  offset = 'medium',
  ...props
}) => {
  const isHead = heading != null || aside != null;

  return (
    <Root {...props} offset={offset}>
      {isHead && (
        <Head>
          {heading && <Heading>{heading}</Heading>}
          {aside && <Aside>{aside}</Aside>}
        </Head>
      )}
      {isHead ? <Body>{children}</Body> : children}
    </Root>
  );
};
