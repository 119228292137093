import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

/**
 * Кнопка.
 */
export const Button = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;

  font-size: 0.8125rem;

  padding: 0.5rem;

  transition: ${transition('background-color', 'color')};

  outline: none;
`;
