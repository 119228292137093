import {
  FC,
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  FocusEvent,
} from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { TextInput, Helper } from 'modules/common/ui';

import { Field } from '../../../consts';
import { Store } from '../../../stores';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof TextInput> & {
  /**
   * Хранилище состояния.
   */
  store: Store;
};

/**
 * Отображает поле ввода кода подтверждения.
 */
const Input: FC<Props> = ({ onBlur, store, ...props }) => {
  const context = useFormikContext();

  const handleRequest = useCallback(() => {
    const self = window.document.getElementById(Field.CODE);

    if (self != null) {
      self.focus();
    }
  }, []);

  const handleFailure = useCallback(() => {
    context.setFieldValue(Field.CODE, '', false);
  }, [context]);

  const handlePrevent = useCallback(() => {
    context.setFieldTouched(Field.CODE, false, false);
    context.setFieldValue(Field.CODE, '', false);
    context.setFieldError(Field.CODE, '');
  }, [context]);

  const handleChange = useCallback(
    async (value: string) => {
      const code = value.replace(/\D/g, '');

      if (code.length < 4) {
        return;
      }

      await Promise.resolve();
      context.submitForm();
    },
    [context],
  );

  const handleBlur = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }

      await Promise.resolve();

      const { value } = event.target as HTMLInputElement;
      const code = value.replace(/\D/g, '');

      if (code.length < 4) {
        context.setFieldValue(Field.CODE, '', false);
      }
    },
    [onBlur, context],
  );

  useEffect(() => {
    store.setOnRequest(handleRequest);
    store.setOnFailure(handleFailure);
    store.setOnPrevent(handlePrevent);
  }, [store, handleRequest, handleFailure, handlePrevent]);

  const disabled = store.phone == null || store.pending || !store.codeRequested;
  const invalid = store.error != null ? true : undefined;
  const valid = store.error != null ? false : undefined;
  const focused = store.countdown > 0;

  const autoComplete = Helper.isOTPSupported() ? 'one-time-code' : 'off';

  return (
    <TextInput
      {...props}
      focused={focused}
      hovered={false}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      invalid={invalid}
      valid={valid}
      name={Field.CODE}
      id={Field.CODE}
      type="text"
      autoComplete={autoComplete}
      inputMode="numeric"
      mask="0000"
    />
  );
};

const component = observer(Input);
export { component as Input };
