import {
  FC,
  ContextType,
  ComponentPropsWithoutRef,
  useContext,
  useEffect,
} from 'react';

import { LayoutContext } from '../LayoutContext';

import { Inverse } from './Page.Inverse';
import { Root } from './Page.Root';

/**
 * Варианты глобальной разметки экрана.
 */
type Layout = ContextType<typeof LayoutContext>['layout'];

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Вариант глобальной разметки экрана. Если `default`, то страница
   * обычная. Если `screen`, то разметка у страницы как на главной.
   * @default `default`
   */
  layout?: Layout;
};

/**
 * Предоставляет обёртку для содержимого страницы сайта. Прижимает подвал
 * к низу страницы, и, кроме того, позволяет указать, какую глобальную
 * разметку использует страница: обычную или как на главной.
 */
export const Page: FC<Props> = ({ layout = 'default', ...props }) => {
  const { setLayout } = useContext(LayoutContext);
  useEffect(() => setLayout(layout));

  return (
    <>
      {layout === 'screen' && <Inverse />}
      <Root {...props} />
    </>
  );
};
