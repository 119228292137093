import { Service } from './Service';

import { Event } from '../types/Event';

/**
 * Сервис содержит методы для отправки событий метрики договора на заём.
 */
export class ContractService extends Service {
  /**
   * Клиент подписал договор.
   */
  signed() {
    this.send(Event.CONTRACT_SIGNED);
  }
}
