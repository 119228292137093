import styled from '@emotion/styled';

/**
 * Корневой элемент Documents.
 */
export const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
`;
