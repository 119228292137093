import * as SentryBrowser from '@sentry/browser';

type Listeners = Array<Function>;

/**
 * Позволяет подписать или отписать слушателей необработанных ошибок.
 * Для отлова необработанных ошибок используется сервис Sentry.io.
 */
export class Sentry {
  /**
   * Коллекция слушателей кастомных событий
   */
  private static listeners: Map<string, Listeners> = new Map();

  /**
   * Подписывает слушателя (listener) на событие (event)
   * @param type
   * @param listener
   */
  public static on(type: string, fn: (...args: any[]) => void): void {
    if (!type) {
      return;
    }

    const listeners: Listeners = this.listeners.get(type) || [];

    if (listeners.includes(fn)) {
      return;
    }

    listeners.push(fn);

    this.listeners.set(type, listeners);
  }

  /**
   * Отписывает слушателя (listener) от события определенного типа (type)
   * @param type
   * @param listener
   */
  public static off(type: string, fn: (...args: any[]) => void): void {
    const listeners: Listeners = this.listeners.get(type) || [];

    const index = listeners.indexOf(fn);

    if (index < 0) {
      return;
    }

    listeners.splice(index, 1);
    this.listeners.set(type, listeners);
  }

  /**
   * Распространяет событие указанного типа (type) среди слушателей
   * @param type Событие
   */
  public static emit(type: string, ...args: any[]) {
    const listeners: Listeners = this.listeners.get(type) || [];

    listeners.forEach((listener) => listener(args));
  }

  /**
   * Удаляет всех слушателей событий
   */
  public static dispose() {
    this.listeners.clear();
  }

  /**
   * Инициализирует библиотеку сервиса Sentry.io.
   */
  public static enable(enabled: boolean) {
    if (!enabled) {
      return;
    }

    const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

    if (!dsn) {
      return;
    }

    SentryBrowser.init({
      dsn,
      environment: process.env.NEXT_PUBLIC_ENV,
      beforeSend: (event, hint) => {
        if (event.level !== 'error') {
          return event;
        }

        const error = hint?.originalException;

        if (typeof error === 'object' && error !== null) {
          const payload = Object.keys(error).reduce(
            (result, key) => ({
              ...result,
              [key]: error[key as keyof typeof error],
            }),
            {} as Record<string, any>,
          );

          //eslint-disable-next-line no-param-reassign
          event.extra = {
            ...(event.extra || {}),
            ...payload,
          };
        }

        this.emit('error');

        return event;
      },
    });
  }
}
