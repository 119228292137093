import { FC, ComponentPropsWithoutRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';

/**
 * Свойства элемента.
 */
type Props = ComponentPropsWithoutRef<'button'> & {
  /**
   * Указывает, что данная опция должна быть подсвечена.
   */
  highlighted?: boolean;

  /**
   * Указывает, что данная опция - служебная.
   */
  service?: boolean;

  /**
   * Указывает, что данная опция - опция сброса значения.
   */
  reset?: boolean;
};

/**
 * Экранированный корневой элемент.
 */
const Escaped: FC<Props> = ({
  disabled,
  highlighted,
  service,
  reset,
  ...props
}) => <button {...props} />;

/**
 * Корневой элемент компонента.
 */
export const Root = styled(Escaped)`
  display: block;
  width: 100%;
  border: 0;

  cursor: pointer;
  outline: none;

  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-right: 1rem;
  padding-left: 1rem;

  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25;

  transition: ${transition('background-color', 'color')};
  background-color: transparent;
  color: inherit;

  :active {
    background-color: ${({ theme }) =>
      theme.colors.light.input.background.default};
  }

  ${(p) =>
    p.highlighted
      ? css`
          background-color: ${p.theme.colors.light.input.border.default};
        `
      : null}

  ${(p) =>
    p.reset
      ? css`
          color: ${p.theme.color.text.main.great};
        `
      : null}

  ${(p) =>
    p.disabled || p.service
      ? css`
          color: ${p.theme.color.text.main.greatest};
        `
      : null}

  ${(p) =>
    p.service
      ? css`
          background-color: transparent;

          cursor: default;

          :active {
            background-color: transparent;
          }
        `
      : null}
`;
