import { FC, useCallback } from 'react';

import { Root } from './Input.Root';
import { Label } from './Label';
import { Value } from './Value';
import { Range } from './Range';

export const Input: FC<{
  label: string;
  value: number;
  min: number;
  max: number;
  step: number;
  valuePrefix?: string;
  valueSuffix?: string;
  valueSeparator?: string;
  onChange: (value: any) => void;
}> = ({
  label,
  value,
  min,
  max,
  step,
  valueSeparator,
  valuePrefix,
  valueSuffix,
  onChange,
}) => {
  const submit = useCallback(
    (values: number[]) => {
      const [nextValue] = values;
      onChange(nextValue);
    },
    [onChange],
  );

  return (
    <Root>
      <Label>{label}</Label>
      <Value>
        {valuePrefix ? `${valuePrefix}${valueSeparator}` : null}
        {value}
        {valueSuffix ? `${valueSeparator}${valueSuffix}` : null}
      </Value>
      <Range
        values={[value]}
        min={min}
        max={max}
        step={step}
        onChange={submit}
      />
    </Root>
  );
};
