import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент кнопки закрытия меню.
 */
export const Root = styled.button`
  display: none;

  @media ${lessThanContainer('medium')} {
    display: inline-flex;
    align-items: center;

    margin-left: auto;
    margin-right: 1rem;

    min-height: 1.562rem;

    background-color: transparent;
    border: none;
  }
`;
