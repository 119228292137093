import styled from '@emotion/styled';

/**
 *  Боковое меню в подвале страницы.
 */
export const Menu = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
`;
