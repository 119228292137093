import styled from '@emotion/styled';
import { lessThanContainer } from '../../../common/ui';

export const Root = styled.div`
  color: #06d4fc;
  font-size: 15px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;

  @media ${lessThanContainer('small')} {
    font-size: 13px;
    line-height: 20px;
  }
`;
