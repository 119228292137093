import { ComponentPropsWithoutRef, FC } from 'react';

import { ArrowLeft } from 'modules/common/icons';

import { Root } from './Back.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'color' | 'variant'>;

/**
 * Отображает кнопку "Назад".
 */
export const Back: FC<Props> = ({ children, ...props }) => (
  <Root {...props} color="info" variant="plain">
    <ArrowLeft /> {children}
  </Root>
);
