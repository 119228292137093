/**
 * Пол.
 */
export enum UserGender {
  /**
   * Мужской.
   */
  Male = 'male',

  /**
   * Женский.
   */
  Female = 'female',

  /**
   * Пол неизвестен.
   */
  Unknown = 'unknown',
}
