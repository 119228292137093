import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';
import { Form as BaseForm } from 'modules/common/ui';

import { Store } from '../../../stores';

import { generateSchema } from './Form.generateSchema';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof BaseForm>, 'schema'>;

/**
 * Форма соглашений персональных данных.
 */
const Form: FC<Props> = ({ ...props }) => {
  const store = Store.use();
  const model = store.model || [];

  const personalInfoAgreements =
    model.find(
      ({ type }) => type === ApplicationAgreementType.PersonalDataProcessing,
    )?.children || [];

  const schema = useMemo(
    () => generateSchema(personalInfoAgreements),
    [personalInfoAgreements],
  );

  if (!personalInfoAgreements) {
    return null;
  }

  return <BaseForm {...props} schema={schema} />;
};

const component = observer(Form);
export { component as Form };
