/**
 * Статус заявки.
 */
export enum Status {
  /**
   * Заявка ещё не создана.
   */
  NONE = 'none',

  /**
   * Заявка находится в процессе заполнения.
   */
  FILLING = 'filling',

  /**
   * Заявка отправлена и находится на рассмотрении.
   */
  PENDING = 'pending',

  /**
   * Заявка была одобрена.
   */
  APPROVED = 'approved',

  /**
   * По данной заявке получен отказ.
   */
  REJECTED = 'rejected',
}
