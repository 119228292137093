import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { Root } from './Item.Root';
import { Heading } from './Item.Heading';
import { Body } from './Item.Body';
import { Footer } from './Item.Footer';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок платежа.
   */
  heading: string;

  /**
   * Содержание компонента.
   */
  body: ReactNode;

  /**
   * Вариант отображения границ.
   */
  variant?: 'default' | 'danger' | 'warning';
};

/**
 * Элемент выбора платежа.
 */
export const Item: FC<Props> = ({ heading, body, children, ...props }) => (
  <Root {...props}>
    <Heading>{heading}</Heading>
    <Body>{body}</Body>
    <Footer>{children}</Footer>
  </Root>
);
