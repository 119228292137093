import { Gender, Name } from '../types';

/**
 * Приводит строку в тип Name без type.
 * @param value Значение строки.
 * @param gender Привязка имени к полу.
 */
export function plainStringToName(
  value?: string,
  gender?: Gender,
): Omit<Name, 'type'> | undefined {
  if (!value) {
    return undefined;
  }

  return {
    value,
    gender: gender ?? 'UNKNOWN',
  };
}
