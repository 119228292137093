import styled from '@emotion/styled';

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
