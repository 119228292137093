/**
 * Количество детей.
 */
export enum UserChildrenCount {
  /**
   * Нет.
   */
  None = 'zero',

  /**
   * Один.
   */
  One = 'one',

  /**
   * Два.
   */
  Two = 'two',

  /**
   * Три.
   */
  Three = 'three',

  /**
   * Четыре.
   */
  Four = 'four',

  /**
   * Пять.
   */
  Five = 'five',

  /**
   * Больше пяти.
   */
  MoreThanFive = 'more_than_five',
}
