import { BankCard } from 'services/RpcService/types/BankCard';

export enum CardTypes {
  MIR = 'mir',
  AE = 'ae',
  VISA = 'visa',
  MC = 'mc',
  MAESTRO = 'maestro',
  UEK = 'uek',
}

/**
 * Возвращает дополнительный текст для кноки банковской карты.
 * @param model Модель карты.
 */
export const getExpDate = (model: BankCard) => {
  const { expiryMonth, expiryYear } = model;
  const month = `00${expiryMonth}`.slice(-2);

  return `${month}/${expiryYear}`;
};

export const getDigits = (model: BankCard) => {
  const { maskedNumber } = model;
  const digits = maskedNumber.slice(-4);

  return `* ${digits}`;
};

export const getCardType = (model: BankCard) => {
  const firstChar = model.maskedNumber.charAt(0);

  switch (firstChar) {
    case '2': {
      return CardTypes.MIR;
    }
    case '3': {
      return CardTypes.AE;
    }
    case '4': {
      return CardTypes.VISA;
    }
    default:
    case '5': {
      return CardTypes.MC;
    }
    case '6': {
      return CardTypes.MAESTRO;
    }
    case '7': {
      return CardTypes.UEK;
    }
  }
};
