import { LazyStore, reactive } from '@devim-front/store';
import { observable, action } from 'mobx';

import { Store as ClientStore } from 'modules/client';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';
import { applyFetchable } from 'modules/common/stores';
import { CompatService } from 'services/CompatService';
import { UserType } from 'services/RpcService/types/UserType';

/**
 * Хранилище для отслеживания ИНН.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Указывает на то, что в данный момент происходит обновление данных на
   * сервере.
   */
  @observable
  public pending: boolean = false;

  /**
   * Указыает на существование ИНН в базах.
   */
  @observable
  public isExists?: boolean = undefined;

  /**
   * Задаёт новое значение свойства `pending`.
   * @param pending Новое значение.
   */
  @action
  private setPending = (pending: boolean) => {
    this.pending = pending;
  };

  /**
   * Экземпляр сервиса RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Экземпляр хранилища клиента.
   */
  private get client() {
    return ClientStore.get(this);
  }

  /**
   * @inheritdoc
   */
  public async fetch() {
    this.setPending(true);

    let responce = false;

    if (this.client.model.type === UserType.Repeat) {
      responce = true;
    } else {
      try {
        responce = await this.rpc.clientCheckPassport();
      } catch (e) {
        if (RpcError.isType(e, RpcErrorCode.CheckPassportError)) {
          this.setPending(false);
        }
      }
    }

    return () => {
      this.setPending(false);
      this.isExists = responce;
    };
  }

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.pending = false;
    this.isExists = undefined;
  }
}
