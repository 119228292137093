import styled from '@emotion/styled';

/**
 * Компонент отображающий текст под заголовком баннера.
 */
export const Description = styled.p`
  margin: 1.25rem 0 0;

  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;

  color: ${({ theme }) => theme.colors.light.text.secondary};
`;
