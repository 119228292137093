import styled from '@emotion/styled';
import { FC, ComponentPropsWithoutRef } from 'react';
import { useFormikContext, getIn } from 'formik';

import { Base } from './Field.Base';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base> & {
  /**
   * Уникальное имя поля ввода.
   */
  name: string;

  /**
   * Выставляет приоритет отображения ошибок.
   */
  errorPriority?: 'inner' | 'outer';

  /**
   * Указывает, что ошибки, заданные в свойстве `error`, должны отображаться
   * так же, как и ошибки валидации (после отправки формы либо после того, как
   * пользователь "потрогает" поле ввода). Если указать `nativeErros={false}`,
   * то `error` будет отображаться всегда, вне зависимости от состояния формы.
   * @default `true`
   */
  nativeErrors?: boolean;
};

/**
 * Компонент отображающий название поля ввода.
 */
const Field: FC<Props> = ({
  errorPriority = 'inner',
  nativeErrors = true,
  error,
  name,
  ...props
}) => {
  const { touched, errors, submitCount } =
    useFormikContext<Record<string, any>>();

  const isTouched = getIn(touched, name, false);
  const isSubmitted = submitCount > 0;
  const outerError =
    !nativeErrors || isTouched || isSubmitted ? error : undefined;
  const innerError =
    isTouched || isSubmitted ? getIn(errors, name, undefined) : undefined;

  const errorMessage =
    errorPriority === 'inner'
      ? innerError || outerError
      : outerError || innerError;

  return <Base {...props} error={errorMessage} />;
};

const component = styled(Field)``;
export { component as Field };
