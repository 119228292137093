import { reactive } from '@devim-front/store';
import { action, observable, runInAction } from 'mobx';

import { Fetchable as IFetchable } from '../types';

/**
 * Название метода, который загружает данные и возвращает другую функцию,
 * обновляющую состояние хранилища на основе этих данных.
 */
export const FETCHABLE_FETCH = Symbol('Fetch');

/**
 * Название метода, который сбрасывает хранилище в состояние до инициализации.
 */
export const FETCHABLE_CLEAR = Symbol('Clear');

/**
 * Функция, которая обновляет состояние хранилища.
 */
type Apply = () => void;

/**
 * Хранилище, к которому применяется миксин.
 */
type TStore = new (...args: any[]) => {
  dispose(): void;

  /**
   * Загружает данные из внешнего источника, и возвращает функцию, которая
   * обновляет состояние хранилища на основе этих данных.
   */
  [FETCHABLE_FETCH]?(): Apply | Promise<Apply>;

  /**
   * Откатывает хранилище к состоянию до инициализации.
   */
  [FETCHABLE_CLEAR]?(): void;
};

/**
 * Возвращает класс расширенный класс хранилища, который умеет синхронно
 * или асинхронно загружать данные из внешних источников (сервера или, к
 * примеру, WebAPI) и инициализироваться с помощью этих данных. Также может
 * сбрасывать своё состояние к изначальному.
 * @param Target Расширяемый класс.
 */
export const applyFetchable = <T extends TStore>(Target: T) => {
  class Fetchable extends Target implements IFetchable {
    /**
     * Разрешает обещание `renewing`.
     */
    private renewingResolve?: () => void;

    /**
     * Количество запущенных в данный момент процессов `renew`.
     */
    private renewingCount: number = 0;

    /**
     * Уникальный идентификатор процесса инициализации хранилища.
     */
    private renewingId: number = 0;

    /**
     * Обещание, которое разрешится, когда все запущенные в данный момент
     * `renew` завершат свою работу.
     */
    private renewing?: Promise<void> = undefined;

    /**
     * Указывает, что хранилище было помечено к удалению, и, по завершению
     * загрузки данных, его состояние обновлять не нужно.
     */
    private disposed: boolean = false;

    /**
     * Указывает, что хранилище было очищено, и по завершению загрузки данных
     * его состояние обновлять не нужно.
     */
    private cleared: boolean = false;

    /**
     * @inheritdoc
     */
    @observable
    public ready: boolean = false;

    /**
     * Загружает данные из внешнего источника и возвращает функцию, которая
     * обновляет состояние хранилища на основе этих данных.
     * @virtual
     */
    protected fetch() {
      const fetch = this[FETCHABLE_FETCH];
      return fetch ? fetch.call(this) : () => {};
    }

    /**
     * Откатывает хранилище в состояние до инициализации.
     * @virtual
     */
    protected clear() {
      const clear = this[FETCHABLE_CLEAR];
      return clear ? clear.call(this) : undefined;
    }

    /**
     * Выполняет загрузку данных для хранилища, и, если эта загрузка - последняя
     * по счёту, обновляет его состояние.
     * @param id Идентификатор данного запроса данных.
     */
    private invoke = async (id: number) => {
      const apply = await this.fetch();

      const isApply = !this.disposed && !this.cleared && this.renewingId === id;

      if (isApply) {
        runInAction(() => {
          apply();
          this.ready = true;
        });
      }

      this.renewingCount -= 1;

      if (this.renewingCount > 0) {
        return;
      }

      const resolve = this.renewingResolve as () => void;

      this.renewingResolve = undefined;
      this.renewingId = 0;
      this.renewing = undefined;

      resolve();
    };

    /**
     * @inheritdoc
     */
    @action
    public renew = () => {
      if (this.disposed) {
        throw new Error(`Store has been disposed and cannot be renewed`);
      }

      if (this.renewing == null) {
        this.renewing = new Promise<void>((resolve) => {
          this.renewingResolve = resolve;
        });
      }

      this.renewingCount += 1;
      this.renewingId += 1;
      this.cleared = false;

      this.invoke(this.renewingId);

      return this.renewing;
    };

    /**
     * @inheritdoc
     */
    @action
    public touch = () => {
      if (this.disposed) {
        throw new Error(`Store has been disposed and cannot be touched`);
      }

      if (this.renewing) {
        return this.renewing;
      }

      if (this.ready) {
        return Promise.resolve();
      }

      return this.renew();
    };

    /**
     * @inheritdoc
     */
    @action
    public reset = () => {
      if (this.cleared || this.disposed) {
        return;
      }

      this.cleared = true;

      this.clear();
      this.ready = false;
    };

    /**
     * @inheritdoc
     */
    public dispose() {
      super.dispose();
      this.disposed = true;
    }
  }

  return reactive(Fetchable);
};
