import {
  Component,
  ComponentPropsWithoutRef,
  ReactNode,
  createRef,
} from 'react';

import { Wrapper } from './Dropdown.Wrapper';
import { Root } from './Dropdown.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Указывает, что выпадающая область должна быть показана.
   */
  active?: boolean;

  /**
   * Содержимое выпадающей области.
   */
  content: ReactNode;

  /**
   * Обрабатывает нажатие вне всплывающей области. Данное событие генерируется
   * только если всплывающая область активна.
   */
  onOuterClick?: (event: MouseEvent) => void;
};

/**
 * Отображает выпадающую область, содержащую список вариантов для выбора.
 * Надо отметить, что непосредственно содержимое области помещается не в
 * `children`, а в свойство `content`. В `children` должен находится элемент,
 * к которому относится данная область - поле ввода.
 */
export class Dropdown extends Component<Props> {
  /**
   * Ссылка на обёртку содержимого выпадающей области.
   */
  private rootRef = createRef<HTMLDivElement>();

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    window.addEventListener('click', this.handleClick);
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  /**
   * Обрабатывает любое нажатие мыши на странице.
   * @param event Событие.
   */
  private handleClick = (event: MouseEvent) => {
    const { onOuterClick, active } = this.props;
    const { current } = this.rootRef;

    if (!active || current == null) {
      return;
    }

    let node: HTMLElement | null = event.target as HTMLElement;

    while (node != null) {
      if (node === current) {
        return;
      }

      node = node.parentElement;
    }

    if (onOuterClick) {
      onOuterClick(event);
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { onOuterClick, content, children, active, ...props } = this.props;

    return (
      <Root {...props} ref={this.rootRef}>
        {children}
        <Wrapper active={active}>{content}</Wrapper>
      </Root>
    );
  }
}
