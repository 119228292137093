import Link from 'next/link';
import { Container } from 'modules/common/ui';
import { Page } from 'modules/common/routing';

import { Root } from './BannerV2.Root';
import { Root as Title } from './BannerV2.Title';
import { Root as Subtitle } from './BannerV2.Subtitle';
import { Root as Button } from './BannerV2.Link';

import { Store } from '../Store';
import { Helper } from '../Helper';

export const BannerV2 = ({ ...props }) => {
  const store = Store.use();

  const payment = Helper.formatPayment(
    store.model?.params.nearestPaymentAmountBefore,
    store.model?.params.minRestructuringPaymentAmount,
  );

  return (
    <Root {...props}>
      <Container>
        <Title>Радуйте себя, радуйте близких, а платёж - мы уменьшим.</Title>
        <Subtitle>
          Проведите праздники с удовольствием: сумму регулярного платежа можно
          снизить.
        </Subtitle>
        <Link href={Page.RESTRUCTURE} passHref>
          <Button>{payment} Подключить</Button>
        </Link>
      </Container>
    </Root>
  );
};
