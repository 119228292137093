import { ComponentPropsWithoutRef, FC, useCallback, useMemo } from 'react';

import { Field, Select, useFormValue } from 'modules/common/ui';
import { Service } from 'modules/common/daData';

import { Field as PassportField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Select>, 'name'> & {
  /**
   * Заголовок поля ввода.
   */
  label: string;

  /**
   * Имя поля ввода.
   */
  name: string;
};

/**
 * Отображает поле ввода органа, выдавшего паспорт.
 */
export const PassportUnit: FC<Props> = ({ label, name, ...props }) => {
  const daData = Service.use();

  const passportCode = useFormValue<string>(PassportField.CODE);

  const code = useMemo(() => passportCode || null, [passportCode]);

  const suggestPassportSource = useCallback(
    (value: string) => {
      if (!value) {
        return [];
      }

      return code == null
        ? daData.suggestPassportSource(value)
        : daData.suggestPassportSource(code);
    },
    [code, daData],
  );

  return (
    <Field label={label} name={name} htmlFor={name}>
      <Select
        {...props}
        id={name}
        name={name}
        values={suggestPassportSource}
        placeholder="Отделом УФМС России по гор. Москве"
        allowEmpty={code != null}
        minLength={code != null ? 0 : 4}
      />
    </Field>
  );
};
