import * as yup from 'yup';
import { Helper as Base } from '@devim-front/helper';

import { Model } from '../../statement';

/**
 * Содержит вспомогательные функции работы с механизмом шагов.
 */
export class StepHelper extends Base {
  /**
   * Определения схем для валидации модели, которая должна пребывать в указанном
   * состоянии для определения возможности перехода к определённому шагу.
   */
  private static SCHEMAS = {
    1: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
    }),

    2: yup.object().shape({
      passportNumber: yup.string().required(),
      passportDate: yup.string().required(),
      passportCode: yup.string().required(),
      passportUnit: yup.string().required(),
      birthPlace: yup.string().required(),
      birthDate: yup.string().required(),

      registrationAddress: yup
        .object()
        .shape({
          city: yup.string().required(),
          house: yup.string().required(),
        })
        .required(),

      isSameAddresses: yup.boolean().nullable(),

      livingAddress: yup.object().when('isSameAddresses', {
        is: false,
        then: yup.object().shape({
          city: yup.string().required(),
          house: yup.string().required(),
        }),
      }),

      isLivingWithParents: yup.boolean().required(),
      childrenCount: yup.string().required(),
      martialStatus: yup.string().required(),
      familySize: yup.string().required(),
    }),
  } as const;

  /**
   * Возвращает true, если модель является заполненной для указанного шага.
   * @param step Номер шага для проверки.
   * @param model Данные модели.
   */
  public static getStepCompleted(
    step: keyof typeof StepHelper['SCHEMAS'],
    model: Partial<Model>,
  ) {
    const schema = this.SCHEMAS[step];

    return schema.isValidSync(model);
  }

  /**
   * Вычисляет шаг на котором пользователь должен продолжить заполнение заявки.
   * @param model Данные модели.
   */
  public static getCurrentStep(model: Partial<Model>) {
    if (!this.getStepCompleted(1, model)) {
      return 1;
    }

    if (!this.getStepCompleted(2, model)) {
      return 2;
    }

    return 3;
  }
}
