import { LazyStore, reactive } from '@devim-front/store';
import { action, observable } from 'mobx';

import { Page, Store as RoutingStore } from 'modules/common/routing';
import { ApplicationService as ApplicationMetricService } from 'modules/common/metrics';
import { Store as StatementStore } from 'modules/application/statement';
import { Store as InnStore } from 'modules/inn';

import { StepHelper } from '../helpers';

/**
 * Хранилище отслеживания шагов заполнения анкеты.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Указывает на то, что в данный момент происходит обновление данных на
   * сервере.
   */
  @observable
  public pending: boolean = false;

  /**
   * Хранилище состояния роутера.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Экземпляр хранилища анкеты.
   */
  private get statement() {
    return StatementStore.get(this);
  }

  /**
   * Экземпляр хранилища данных ИНН.
   */
  private get inn() {
    return InnStore.get(this);
  }

  /**
   * Экземпляр сервиса метрик.
   */
  private get applicationMetric() {
    return ApplicationMetricService.get(this);
  }

  /**
   * Задаёт новое значение свойства `pending`.
   * @param pending Новое значение.
   */
  @action
  private setPending = (pending: boolean) => {
    this.pending = pending;
  };

  /**
   * Возвращает ссылку на страницу, представляющую указанный номер шага
   * заполнения заявки.
   * @param step Номер шага.
   */
  private getStepHref = (step: number) => {
    switch (step) {
      case 1: {
        return Page.APPLICATION_FIRST_STEP;
      }
      case 2: {
        return Page.APPLICATION_SECOND_STEP;
      }
      case 3: {
        return Page.APPLICATION_THIRD_STEP;
      }
      default: {
        throw new Error(`Unknown step ${step}`);
      }
    }
  };

  /**
   * Возвращает номер предыдущего шага заявки относительно указанного. Если
   * следующего шага нет или указанного номера шага не существует, метод
   * вернёт `undefined.`
   * @param step Номер шага.
   */
  private getPreviousStep = (step: number) => {
    return step <= 1 || step > 3 ? undefined : step - 1;
  };

  /**
   * Возвращает номер шага основываясь на существовании ИНН.
   * Если ИНН не существует, то переводим пользователя на 2-й шаг заявки.
   * @param step Номер шага.
   */
  private getStepBasedInn = async (step: number) => {
    if (step > 2) {
      await this.inn.renew();

      if (!this.inn.isExists) {
        step = 2;
      }
    }

    return step;
  };

  /**
   * Возвращает номер следующего шага заявки относительно указанного. Если
   * следующего шага нет или указанного номера шага не существует, метод
   * вернёт `undefined.`
   * @param step Номер шага.
   */
  private getNextStep = (step: number) => {
    return step < 1 || step >= 3 ? undefined : step + 1;
  };

  /**
   * Производит возврат к предыдущему шагу заполнения заявки.
   * @param step Текущий шаг заполнения заявки.
   */
  public back = (step: number) => {
    const previousStep = this.getPreviousStep(step);

    if (previousStep == null) {
      return;
    }

    const url = this.getStepHref(previousStep);
    this.routing.push(url);
  };

  /**
   * Обновляет текущую модель заявки переданной коллекцией значений, сохраняет
   * её на сервере, и, опционально, вызывает переход на следующий шаг
   * заполнения заявки.
   * @param values Коллекция свойств заявки, которые должны быть обновлены.
   * @param step Текущий номер шага заполнения заявки. Если указать этот
   * параметр, то по завершению обновления модели заявки пользователь
   * будет перенаправлен на следующий шаг. В случае, если указан последний
   * шаг, перенаправления не случится.
   */
  @action
  public submitStep = async (step: number) => {
    this.setPending(true);

    switch (step) {
      case 1:
        this.applicationMetric.firstStepSubmitted();
        break;

      case 2:
        this.applicationMetric.secondStepSubmitted();
        break;

      case 3:
        this.applicationMetric.thirdStepSubmitted();
        break;

      default:
        throw new Error(`Invalid step value: ${step}`);
    }

    await this.statement.submitValues();

    let nextStep = this.getNextStep(step);

    if (nextStep == null) {
      this.setPending(false);
      return;
    }

    nextStep = await this.getStepBasedInn(nextStep);

    const href = this.getStepHref(nextStep);
    await this.routing.push(href);

    this.setPending(false);
  };

  /**
   * Производит вычисление шага оформления заявки, на котором остановился
   * пользователь и производит перенаправление на соответствующую страницу
   * заполнения
   */
  public navigateToCurrentStep = async () => {
    await this.statement.renew();

    const { model } = this.statement;

    let step = model ? StepHelper.getCurrentStep(model) : 1;
    step = await this.getStepBasedInn(step);

    const url = this.getStepHref(step);

    await this.routing.replace(url);
  };
}
