import { Helper as Base } from '@devim-front/helper';

import { NumberHelper } from './NumberHelper';

/**
 * Содержит методы для работы со сроками займа. Срок займа представлен целым
 * количеством дней.
 */
export class PeriodHelper extends Base {
  /**
   * Возвращает единицы измерения указанного срока займа в нужной
   * грамматической форме.
   * @param period Срок займа в днях.
   */
  public static getUnits(period: number) {
    return NumberHelper.getPluralWord(period, 'день', 'дня', 'дней');
  }

  /**
   * Преобразует указанный срок займа в строку в человекопонятном формате
   * ("1 день", "365 дней" и тому подобное).
   * @param period Срок займа в днях.
   */
  public static formatDisplay(period: number) {
    return `${period} ${PeriodHelper.getUnits(period)}`;
  }
}
