import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './Icon.Root';

type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает иконку кнопки закрытия меню.
 */
export const Icon: FC<Props> = ({ ...props }) => (
  <Root
    width="10"
    height="10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Закрыть меню</title>
    <path
      d="M9.736.264a.9.9 0 010 1.272L6.273 5l3.463 3.464a.9.9 0 01.08 1.18l-.08.092a.9.9 0 01-1.272 0L5 6.273 1.536 9.736A.9.9 0 11.264 8.464L3.727 5 .264 1.536a.9.9 0 01-.08-1.18l.08-.092a.9.9 0 011.272 0L5 3.727 8.464.264a.9.9 0 011.272 0z"
      fill="#000515"
    />
  </Root>
);
