import React, { FC, ComponentProps, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PeriodHelper } from 'modules/common/values';

import { Store } from '../../../stores';

import { Input } from '../Input';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentProps<typeof Input>,
  'format' | 'value' | 'onChange' | 'label' | 'name'
> & {
  period?: number;
};

/**
 * Отображает поле ввода сркоа займа на калькуляторе.
 */
const Period: FC<Props> = ({ period, ...props }) => {
  const { period: storedPeriod, setPeriod } = Store.use();

  useEffect(() => {
    if (period) {
      setPeriod(period);
    }
  }, [period, setPeriod]);

  return (
    <Input
      {...props}
      label="Срок займа"
      name="periodField"
      value={storedPeriod}
      onChange={setPeriod}
      format={PeriodHelper.formatDisplay}
    />
  );
};

const component = observer(Period);
export { component as Period };
