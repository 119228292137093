import { FC, ComponentPropsWithoutRef } from 'react';

import { Form } from './Form';
import { Root } from './Confirmation.Root';
import { Note } from './Confirmation.Note';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает блок подписания договора через СМС.
 */
export const Confirmation: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Form />
    <Note />
  </Root>
);
