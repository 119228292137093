import styled from '@emotion/styled';

import { Adornment } from './FileInput.Adornment';

/**
 * Обёртка для дополнительного контента в начале поля ввода.
 */
export const StartAdornment = styled.div`
  ${Adornment}

  padding-left: 1.25rem;
`;
