import { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './Divider.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Компонент разделитель.
 */
export const Divider: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>{children}</Root>
);
