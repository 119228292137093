import styled from '@emotion/styled';

/**
 * Корневой элемент Document.
 */
export const Root = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;

  color: ${({ theme }) => theme.colors.light.text.secondary};
`;
