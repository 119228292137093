import { Theme } from '../types';

/**
 * Конфигурация темы сайта Аполлон-Займ.
 */
export const ApolloTheme: Theme = {
  colors: {
    light: {
      background: {
        primary: '#FFFFFF',
        secondary: '#F7F7F7',
      },
      text: {
        primary: '#000515',
        secondary: '#727272',
        tertiary: '#BABEC4',
        link: '#000515',
        disabled: '#D3D3D3',
        overlay: '#EFF2F7',
      },
      separator: '#DEDEE3',
      border: {
        default: '#F2F2F2',
        hover: '#DEDEE3',
      },
      input: {
        background: {
          default: '#F9F9F9',
          error: '#FFFAF9',
          disabled: '#F2F2F2',
        },
        border: {
          default: '#F2F2F2',
          focus: '#000515',
          hover: '#DEDEE3',
        },
      },
      button: {
        primary: {
          background: '#000515',
          text: '#FFFFFF',
        },
        overlay: {
          background: '#FFFFFF',
          text: '#000515',
        },
        disabled: {
          background: '#F2F2F2',
          text: '#D3D3D3',
        },
        outline: {
          border: '#000515',
          text: '#000515',
        },
      },
      accent: {
        error: '#F06033',
        warning: '#FFB36E',
        link: '#017EFE',
      },
    },
  },
  color: {
    primary: {
      main: '#000',
      dark: '#000',
      darker: '#000',
      darkest: '#000',
      light: '#333',
      lighter: '#666',
      lightest: '#999',
      contrast: '#fff',
      contrastAccent: '#47BCE8',
      contrastHidden: '#E0F1F8',
      great: '#333',
      greater: '#666',
      greatest: '#999',
    },
    secondary: {
      main: '#fff',
      dark: '#ccc',
      darker: '#999',
      darkest: '#666',
      light: '#fff',
      lighter: '#fff',
      lightest: '#fff',
      contrast: '#000',
      contrastAccent: '#000',
      contrastHidden: '#000',
      great: '#ccc',
      greater: '#999',
      greatest: '#666',
    },
    error: {
      main: '#e01f19',
      dark: '#b31914',
      darker: '#86130f',
      darkest: '#5a0c0a',
      light: '#e64c47',
      lighter: '#ec7975',
      lightest: '#f3a5a3',
      contrast: '#fff',
      contrastAccent: '#fff',
      contrastHidden: '#ccc',
      great: '#e64c47',
      greater: '#ec7975',
      greatest: '#f3a5a3',
    },
    success: {
      main: '#39b54a',
      dark: '#2e913b',
      darker: '#226d2c',
      darkest: '#17481e',
      light: '#61c46e',
      lighter: '#88d392',
      lightest: '#b0e1b7',
      contrast: '#fff',
      contrastAccent: '#fff',
      contrastHidden: '#ccc',
      great: '#61c46e',
      greater: '#88d392',
      greatest: '#b0e1b7',
    },
    warning: {
      main: '#F5984E',
      dark: '#b31914',
      darker: '#86130f',
      darkest: '#5a0c0a',
      light: '#e64c47',
      lighter: '#ec7975',
      lightest: '#f3a5a3',
      contrast: '#fff',
      contrastAccent: '#fff',
      contrastHidden: '#ccc',
      great: '#e64c47',
      greater: '#ec7975',
      greatest: '#f3a5a3',
    },
    info: {
      main: '#4364c0',
      dark: '#3c5aad',
      darker: '#36509a',
      darkest: '#2f4686',
      light: '#5674c6',
      lighter: '#6983cd',
      lightest: '#7b93d3',
      contrast: '#fff',
      contrastAccent: '#fff',
      contrastHidden: '#ccc',
      great: '#5674c6',
      greater: '#6983cd',
      greatest: '#7b93d3',
    },
    background: {
      main: {
        main: '#fff',
        dark: '#F5F6F6',
        darker: '#fff',
        darkest: '#fff',
        light: '#fff',
        lighter: '#fff',
        lightest: '#fff',
        great: '#fff',
        greater: '#F5F6F6',
        greatest: '#fff',
      },
      inverse: {
        main: '#000',
        dark: '#F5F6F6',
        darker: '#000',
        darkest: '#000',
        light: '#000',
        lighter: '#000',
        lightest: '#000',
        great: '#000',
        greater: '#F5F6F6',
        greatest: '#000',
      },
    },
    text: {
      main: {
        main: '#000',
        great: '#848484',
        greater: '#7d7d7d',
        greatest: '#ccc',
        accent: {
          main: '#47bCe8',
          great: '#6cc9ed',
          greater: '#91d7f1',
          greatest: '#b5e4f6',
        },
      },
      inverse: {
        main: '#ffffff',
        great: '#e6e6e6',
        greater: '#cccccc',
        greatest: '#b3b3b3',
        accent: {
          main: '#0b1f35',
          great: '#233549',
          greater: '#3c4c5d',
          greatest: '#546272',
        },
      },
    },
    border: {
      main: '#ccc',
      dark: '#a3a3a3',
      darker: '#7a7a7a',
      darkest: '#525252',
      light: '#d6d6d6',
      lighter: '#e0e0e0',
      lightest: '#ebebeb',
    },
  },
  container: {
    size: {
      large: 1280,
      medium: 980,
      small: 640,
      xSmall: 360,
    },
    offset: {
      default: 16,
      small: 8,
      large: 20,
      none: 0,
    },
  },
  pageContent: {
    offset: {
      default: 4,
      large: 5,
      extraLarge: 6.25,
      none: 0,
    },
  },
  transition: {
    duration: {
      default: 250,
    },
    fn: {
      default: 'ease-in-out',
      linear: 'linear',
    },
  },
  header: {
    size: {
      large: 5,
      medium: 3.375,
    },
  },
};
