import React, { ComponentPropsWithoutRef, FC } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../../../stores';
import { Spinner } from '../../../../common/ui';

import { Content } from './Button.Content';
import { Icon } from './Button.Icon';
import { Text } from './Button.Text';
import { Root } from './Button.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Root>,
  'children' | 'href' | 'onSubmit'
> & {
  /**
   * Текст, отображаемый на кнопке.
   */
  label?: string;

  /**
   * Индикатор состояния загрузки
   */
  pending?: boolean;

  /**
   * Обрабатывает сохранение параметров займа.
   * @param amount Выбранная сумма займа.
   * @param period Выбранный срок займа.
   */
  onSubmit?: (amount: number, period: number) => void;
};

/**
 * Отображает кнопку на калькуляторе.
 */
const Button: FC<Props> = ({ pending, onSubmit, ...props }) => {
  const store = Store.use();

  const { label } = props;
  const text = label || 'Получить бесплатно';

  const handleClick = () => {
    if (onSubmit) {
      onSubmit(store.amount, store.period);
    }
  };

  return (
    <Root {...props} onClick={handleClick}>
      <Content>
        {pending ? (
          <Spinner />
        ) : (
          <>
            <Text>{text}</Text>
            <Icon />
          </>
        )}
      </Content>
    </Root>
  );
};

const component = observer(Button);
export { component as Button };
