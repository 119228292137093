import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Spinner.Root';

/**
 * Свойства спиннера загрузки.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Root>,
  'theme' | 'as' | 'children'
>;

/**
 * Отображает вращающийся спиннер загрузки.
 */
export const Spinner: FC<Props> = ({ ...props }) => <Root {...props} />;
