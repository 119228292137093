import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Divider as Base } from './Divider';

/**
 * Разделитель пунктов меню шапки. Появляется в мобильной версии.
 */
export const Divider = styled(Base)`
  display: none;

  @media ${lessThanContainer('medium')} {
    display: block;
  }
`;
