import styled from '@emotion/styled';

import { Spinner as Base } from 'modules/common/ui';

/**
 * Элемент индикации загрузки.
 */
export const Spinner = styled(Base)`
  color: ${({ theme }) => theme.color.text.inverse.main};
`;
