import { LazyStore, reactive } from '@devim-front/store';
import { observable, action, computed } from 'mobx';

import { applyFetchable } from 'modules/common/stores';

import { CompatService } from 'services/CompatService';
import { OptionType } from 'services/RpcService/types/OptionType';
import { UserOption } from 'services/RpcService/types/UserOption';

/**
 * Модель опции.
 */
type Model = UserOption<OptionType.ServicePaymentRefund>;

/**
 * Хранилище состояния доступа для отказа от услуги.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Модель.
   */
  @observable
  public model?: Model = undefined;

  /**
   * Экземпляр сервиса RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Задаёт новое значение свойства `model`.
   * @param model Новое значение.
   */
  @action
  private setModel = (model?: Model) => {
    this.model = model;
  };

  /**
   * Можно ли подать заявку на отказ от услуги.
   * Теперь всегда показываем
   * @see https://jira.devim.team/browse/WL-2893
   */
  @computed
  public get isTrue(): boolean {
    if (!this.model) {
      return false;
    }

    return true;
  }

  /**
   * @inheritdoc
   */
  async fetch() {
    const options = await this.rpc.clientGetOptionsAvailable();
    const refundOption:
      | UserOption<OptionType.ServicePaymentRefund>
      | undefined = options.find(
      (o) => o.value?.type === OptionType.ServicePaymentRefund,
    );

    return () => {
      this.setModel(refundOption);
    };
  }

  /**
   * @inheritdoc
   */
  clear() {
    this.setModel(undefined);
  }
}
