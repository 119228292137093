import { Helper as Base } from '@devim-front/helper';

/**
 * Содержит методы для работы с паспортными данными.
 */
export class PassportHelper extends Base {
  /**
   * Регулярное выражение для проверки номера паспорта.
   */
  public static NUMBER_VALIDATION_REGEXP = /^\d{4}-\d{6}$/;

  /**
   * Регулярное выражение для проверки кода подразделения.
   */
  public static CODE_VALIDATION_REGEXP = /^\d{3}-\d{3}$/;

  /**
   * Обрабатывает значение номера паспорта и вырезает форматирование,
   * возвращая числовое значение.
   * @param number Номер паспорта.
   */
  public static parseNumber(number: string) {
    if (!this.NUMBER_VALIDATION_REGEXP.test(number)) {
      throw new Error(
        `The value ${number} cannot be parsed as a passport number`,
      );
    }

    return number.replace(/\D/g, '');
  }

  /**
   * Обрабатывает значение номера паспорта и форматирует его согласно
   * шаблону.
   * @param number Номер паспорта.
   */
  public static formatNumber(number: string): string {
    const match = number.match(/^(\d{4})(\d{6})$/);

    if (match === null) {
      throw new Error(
        `The value ${number} cannot be parsed as a passport number`,
      );
    }

    const [, a, b] = match;
    return `${a}-${b}`;
  }

  /**
   * Обрабатывает значение кода подразделения и вырезает форматирование,
   * возвращая числовое значение.
   * @param code Код подразделения.
   */
  public static parseCode(code: string) {
    if (!this.CODE_VALIDATION_REGEXP.test(code)) {
      throw new Error(`The value ${code} cannot be parsed as a passport code`);
    }

    return code.replace(/\D/g, '');
  }

  /**
   * Обрабатывает значение кода подразделения и форматирует его согласно
   * шаблону.
   * @param code Код подразделения.
   */
  public static formatCode(code: string): string {
    const match = code.match(/^(\d{3})-?(\d{3})$/);

    if (match === null) {
      throw new Error(`The value ${code} cannot be parsed as a passport code`);
    }

    const [, a, b] = match;
    return `${a}-${b}`;
  }
}
