import { ComponentType, Component, FocusEvent, createElement } from 'react';
import { getIn } from 'formik';

import { withForm, WithFormProps } from './withForm';

/**
 * Свойства, которые должен поддерживать целевой компонент.
 */
export type WithFormControlProps = {
  /**
   * Уникальное имя поля ввода.
   */
  name?: string;

  /**
   * Текущее значение поле ввода.
   */
  value?: any;

  /**
   * Функция, которая обрабатывает изменение значения компонента формы.
   *
   * @param value Новое значение компонента формы.
   */
  onChange?: (value?: any) => void;

  /**
   * Функция, которая обрабатывает событие потери фокуса полем ввода.
   *
   * @param event Событие потери фокуса.
   */
  onBlur?: (event: FocusEvent<any>) => void;
};

/**
 * Добавляет компоненту функции и свойства для работы с данными формы в которой
 * он находится.
 *
 * @param Target Целевой компонент.
 */
export const withFormControl =
  (baseDefaultValue: any) =>
  <TProps extends WithFormControlProps>(Target: ComponentType<TProps>) => {
    /**
     * Свойства внутреннего компонента.
     */
    type Props = TProps & WithFormControlProps & WithFormProps;

    /**
     * Добавляет свойства управляемого инпута дочернему компоненту.
     */
    class WithFormControl extends Component<Props> {
      /**
       * @inheritdoc
       */
      public static displayName = `WithFormInput(${
        Target.displayName || Target.name
      })`;

      /**
       * @inheritdoc
       */
      public componentDidMount() {
        const { form, name } = this.props;

        if (typeof name !== 'string' || !form) {
          return;
        }

        const { registerField, setFieldValue, initialValues } = form;
        const initialValue = getIn(initialValues, name);

        registerField(name, {});

        if (initialValue == null) {
          setFieldValue(name, baseDefaultValue, false);
        }
      }

      /**
       * @inheritdoc
       */
      public componentWillUnmount() {
        const { form, name } = this.props;

        if (typeof name !== 'string' || !form) {
          return;
        }

        const { unregisterField } = form;

        unregisterField(name);
      }

      /**
       * Функция, которая обрабатывает изменение значения компонента формы.
       *
       * @param newValue Новое значение компонента формы.
       */
      private handleChange = (newValue: any) => {
        const { form, name, onChange } = this.props;

        if (typeof name === 'string' && form) {
          const { setFieldValue } = form;

          /*
           * Необходимо преобразование значения в null т.к. Formik не осуществляет
           * вещание ошибок и валидацию при значении undefined.
           */
          const propagationValue = newValue === undefined ? null : newValue;

          setFieldValue(name, propagationValue, false);
        }

        if (onChange) {
          onChange(newValue);
        }
      };

      /**
       * Функция, которая обрабатывает событие потери фокуса полем ввода.
       *
       * @param event Событие потери фокуса.
       */
      private handleBlur = (event: FocusEvent<any>) => {
        const { name, form, onBlur } = this.props;

        if (typeof name === 'string' && form) {
          const { handleBlur } = form;

          handleBlur(event);
        }

        if (onBlur) {
          onBlur(event);
        }
      };

      /**
       * Отображает содержимое подключенного к форме компонента.
       */
      private renderConnected = () => {
        const {
          name,
          form: { getFieldProps },
          ...props
        } = this.props;
        const { handleChange, handleBlur } = this;

        let value: any;

        if (typeof name === 'string') {
          const fieldProps = getFieldProps({
            name,
            ...props,
          });

          value = fieldProps.value ?? baseDefaultValue;
        }

        return createElement(Target, {
          ...(props as TProps),
          name,
          value,
          onChange: handleChange,
          onBlur: handleBlur,
        });
      };

      /**
       * Отображает содержимое контролируемого компонента.
       */
      private renderControlled = () =>
        createElement(Target, {
          ...this.props,
        });

      /**
       * @inheritdoc
       */
      public render() {
        const { onChange, value } = this.props;

        const isControlled = value !== undefined && onChange !== undefined;

        if (isControlled) {
          return this.renderControlled();
        }

        return this.renderConnected();
      }
    }

    /**
     * Добавляет свойство form дочернему компоненту и возвращает подключенный
     * компонент, скрывая свойства, полученные из компонента высшего порядка.
     */
    return withForm<Props>(WithFormControl);
  };
