import { useCallback } from 'react';
import { observer } from 'mobx-react';

import { Store } from '../../../stores';
import { Input } from '../Input';
import { AmountHelper } from '../../../../common/values';

const Amount = () => {
  const { amount, setAmount } = Store.use();

  const onChange = useCallback(
    (value: number) => {
      const nextAmount = AmountHelper.create(value);
      setAmount(nextAmount);
    },
    [setAmount],
  );

  return (
    <Input
      value={AmountHelper.formatInteger(amount)}
      min={2000}
      step={1000}
      max={20000}
      label="Какая сумма вам требуется?"
      valuePrefix="₽"
      valueSeparator={' '}
      onChange={onChange}
    />
  );
};

const component = observer(Amount);
export { component as Amount };
