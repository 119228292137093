import styled from '@emotion/styled';

import { lessThanContainer, LinkButton as Base } from 'modules/common/ui';

/**
 * Элемент кнопки Личный кабинет.
 */
export const LinkButton = styled(Base)`
  @media ${lessThanContainer('medium')} {
    padding: 0.5rem 0.75rem;

    font-size: 0.687rem;
  }
`;
