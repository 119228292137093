import styled from '@emotion/styled';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Порядок отображения элементов Point / Children.
   */
  direction?: 'default' | 'reverse';
};

export const Text = styled.div<Props>`
  margin: ${(props) =>
    props.direction === 'reverse' ? '0 0.5rem 0 0' : '0 0 0 0.5rem'};
`;
