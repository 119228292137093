import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { transition } from 'modules/common/theme';
import { lessThanContainer } from 'modules/common/ui/functions';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Указывает, что данный элемент должен выглядеть так, будто он находится в
   * фокусе.
   */
  focused: boolean;

  /**
   * Указывает, что поле содержит ошибку.
   */
  isInvalid?: boolean;

  /**
   * Указывает, что поле прошло валидацию.
   */
  isValid?: boolean;
};

/**
 * Корневой элемент компонента.
 */
export const Root = styled.label<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  width: 4.375rem;
  height: 4.375rem;

  border-style: solid;
  border-width: 1.5px;
  border-radius: 0.75rem;
  box-sizing: border-box;

  transition: ${transition('border-color')};

  background-color: ${({ theme }) =>
    theme.colors.light.input.background.default};
  border-color: ${({ theme }) => theme.colors.light.input.border.default};
  box-shadow: none;

  user-select: none;

  ${({ isInvalid, theme }) =>
    isInvalid
      ? css`
          background-color: ${theme.colors.light.input.background.error};
          border-color: ${theme.colors.light.accent.error};
        `
      : null}

  ${({ isValid, theme }) =>
    isValid
      ? css`
          border-color: ${theme.colors.light.input.border.default};
        `
      : null}

  ${({ focused, theme }) =>
    focused
      ? css`
          background-color: ${theme.colors.light.input.background.default};
          border-color: ${theme.colors.light.input.border.focus} !important;
        `
      : null}
      

  :hover {
    border-color: ${({ theme }) => theme.colors.light.input.border.hover};
  }

  @media ${lessThanContainer('small')} {
    width: 3rem;
    height: 3rem;
  }
`;
