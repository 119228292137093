import { UnauthorizedError } from '../errors/UnauthorizedError';
import { Middleware } from './Middleware';

/**
 * Перехватывает HTTP-статус 401 и выбрасывает ошибку UnauthorizedError.
 */
export const unautorizedMiddleware: Middleware = async ({ response, json }) => {
  if (response.status !== 401) {
    return;
  }

  throw new UnauthorizedError(json);
};
