import { FC, ComponentPropsWithoutRef } from 'react';

import { Field, TextInput } from 'modules/common/ui';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof TextInput>;

/**
 * Отображает поле ввода номера телефона компании.
 */
export const CompanyPhone: FC<Props> = ({ ...props }) => {
  return (
    <Field
      htmlFor="phone"
      label="Номер телефона организации"
      name={IncomeField.EMPLOYER_PHONE}
    >
      <TextInput
        {...props}
        id="phone"
        name={IncomeField.EMPLOYER_PHONE}
        type="tel"
        inputMode="numeric"
        mask="+7 (000) 000-00-00"
        placeholder="+7 (900) 000-00-00"
      />
    </Field>
  );
};
