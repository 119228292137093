import styled from '@emotion/styled';

import {
  greaterThanContainer,
  lessThanContainer,
} from 'modules/common/ui/functions';
import { Field } from '../Field';

import { TextInput } from '../TextInput';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Ширина блока.
   */
  width: 'full' | 'medium';
};

/**
 * Позиционирование и стили внутри InputGroup.
 */
export const Init = styled.div<Props>`
  width: ${(p) => (p.width === 'medium' ? '30rem' : '100%')};

  display: flex;
  gap: 0.75rem;

  && > * {
    border-radius: 0.75rem;

    > * {
      border-radius: 0.75rem;
    }
  }

  @media ${greaterThanContainer('small')} {
    flex-direction: row;

    ${TextInput}, ${Field} {
      flex: 1;
    }
  }

  @media ${lessThanContainer('small')} {
    width: 100%;

    flex-direction: column;

    > *:first-of-type {
      margin-bottom: 1.5rem;
    }
  }
`;
