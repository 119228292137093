import { FC, PropsWithChildren, ReactNode } from 'react';
import { observer } from 'mobx-react';

import { Page, Redirect } from 'modules/common/routing';

import { Store } from '../../stores';

/**
 * Свойства компонента.
 */
type Props = PropsWithChildren<{
  /**
   * Элемент, который должен отображаться вместо содержимого компонента, пока
   * происходят проверки.
   */
  fallback?: ReactNode;
}>;

/**
 * Содержимое данного компонента будет показано лишь в том случае, когда
 * текущий пользователь не авторизован. Если же нет, пользователь будет
 * перенаправлен в личный кабинет.
 */
const NotAuthorizedOnly: FC<Props> = ({ fallback = null, children }) => {
  const store = Store.use();

  if (!store.initialized) {
    return <>{fallback}</>;
  }

  if (store.isNotAuthorized) {
    return <>{children}</>;
  }

  return <Redirect url={Page.ACCOUNT}>{fallback}</Redirect>;
};

const component = observer(NotAuthorizedOnly);
export { component as NotAuthorizedOnly };
