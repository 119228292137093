import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Определяет отступ между секциями.
   */
  offset: 'medium' | 'large';
};

/**
 * Корневой элемент Section.
 */
export const Root = styled.div<Props>`
  padding-top: ${(p) => (p.offset === 'large' ? '4rem' : '2rem')};

  :first-of-type {
    padding-top: 0rem;
  }

  @media ${lessThanContainer('small')} {
    padding-top: 3rem;
  }
`;
