import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui/functions';

/**
 * Отображает дочерний элемент, переданный в компонент.
 */
export const Body = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 2.25rem;

  @media ${lessThanContainer('small')} {
    margin-top: 1.75rem;
  }
`;
