import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент NavigationTop.
 */
export const Root = styled.nav`
  margin-bottom: 1.5rem;

  @media ${lessThanContainer('small')} {
    margin-bottom: 0.812rem;
  }
`;
