import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Компонент описания страницы.
 */
export const PageDescription = styled.p`
  margin: 1rem 0 1rem;

  max-width: 30rem;

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;

  color: ${({ theme }) => theme.colors.light.text.secondary};

  @media ${lessThanContainer('small')} {
    margin-bottom: 1.25rem;

    font-size: 0.812rem;
    line-height: 1.25rem;
  }
`;
