import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент задает стили для текста, вложенного в Checkbox.
 */
export const CheckboxText = styled.span`
  white-space: nowrap;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  @media ${lessThanContainer('small')} {
    display: none;
  }
`;
