import { FC, useCallback, useRef } from 'react';
import { useRouter } from 'next/router';

import { Store as LoanStore, Status as LoanStatus } from 'modules/loan';
import { Page, Redirect } from 'modules/common/routing';
import { PageLoading } from 'modules/common/layout';
import { Store as StepsStore } from 'modules/application/steps';
import { Status as ApplicationStatus } from 'modules/application/core';

import { Store } from '../../stores';

/**
 * Служебный компонент, который, будучи смонтирован в DOM, создаёт заявку на
 * заём, получая его параметры из адресной строки (`amount` и `period`). Если
 * же создать заявку по каким-либо причинам невозможно (например, есть у
 * пользователя есть уже активный заём), то перенаправляет клиента на
 * соответствующую страницу жизненного цикла.
 */
export const NewPage: FC = () => {
  const pending = useRef<boolean>(false);
  const router = useRouter();
  const loan = LoanStore.use();
  const store = Store.use();
  const steps = StepsStore.use();

  const submit = useCallback(
    async (amount: number, period: number) => {
      if (pending.current) {
        return;
      }

      pending.current = true;

      if (
        store.model?.status === ApplicationStatus.NONE ||
        store.model?.status === ApplicationStatus.FILLING
      ) {
        await store.submitParams(amount, period);
        await steps.navigateToCurrentStep();
      } else {
        router.replace(Page.ACCOUNT);
      }

      pending.current = false;
    },
    [store, steps, router],
  );

  if (loan.status == null) {
    throw new Error(`This component must be placed inside loan.Provider`);
  }

  if (loan.status !== LoanStatus.NONE) {
    return (
      <Redirect url={Page.ACCOUNT} replace>
        <PageLoading />
      </Redirect>
    );
  }

  const { query } = router;
  const { amount: amountText, period: periodText } = query;

  const amount = query.amount ? Number(amountText) : undefined;
  const period = query.period ? Number(periodText) : undefined;

  if (amount != null && period != null) {
    submit(amount, period);
    return <PageLoading />;
  }

  return <PageLoading />;
};
