import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента
 */
type Props = {
  isOpened: boolean;
};

/**
 * Стилизованая обёртка калькулятора в анкете
 */
export const Root = styled.div<Props>`
  @media ${lessThanContainer('small')} {
    margin-bottom: ${({ isOpened }) => (isOpened ? '4px' : '0')};
  }
`;
