import { LazyStore, reactive } from '@devim-front/store';
import { observable, action, runInAction } from 'mobx';
import { Sentry } from 'modules/common/sentry';
import {
  PassportHelper,
  PhoneHelper,
  SnilsHelper,
} from 'modules/common/values';
import { ApplicationFormType } from 'services/RpcService/types/ApplicationFormType';
import { UserGender } from 'services/RpcService/types/UserGender';
import {
  Service as DaDataService,
  CompanyName,
  Gender,
} from 'modules/common/daData';
import { Application } from 'services/RpcService/types/Application';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';
import { CompatService } from 'services/CompatService';

import { Model } from '../types';
import { AttachmentType } from 'services/RpcService/types/AttachmentType';

/**
 * Хранилище состояния процесса заполнения анкеты.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Заглушка файла для отображения в интерфейсе без содержимого.
   */
  private readonly STUB_FILE = new File([], 'Фотография', {
    lastModified: Date.now(),
    type: 'image/png',
  });

  /**
   * Указывает, инициализировано ли данное хранилище.
   */
  @observable
  public initialized: boolean = false;

  /**
   * Указывает на то, что в данный момент происходит обновление данных на
   * сервере.
   */
  @observable
  public pending: boolean = false;

  /**
   * Модель анкеты.
   */
  @observable
  public model?: Model = undefined;

  /**
   * Указывает на то, что в процессе обновлений данных произошла ошибка.
   */
  @observable
  public isError: boolean = false;

  /**
   * Ссылка на сервис DaData.
   */
  private get daData() {
    return DaDataService.get(this);
  }

  /**
   * Экземпляр сервиса RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Указывает, что в данный момент происходит скачивание данных анкеты с
   * сервера.
   */
  private renewing: boolean = false;

  /**
   * Задаёт новое значение свойства `pending`.
   * @param pending Новое значение.
   */
  @action
  private setPending = (pending: boolean) => {
    this.pending = pending;
  };

  /**
   * Задаёт новое значение свойства `model`.
   * @param model Новое значение.
   */
  @action
  private setModel = (model?: Model) => {
    this.model = model;
  };

  /**
   * Задаёт новое значение свойства `isError`.
   * @param isError Новое значение.
   */
  @action
  private setIsError = (isError: boolean) => {
    this.isError = isError;
  };

  /**
   * Вызывает принудительную загрузку данных анкеты с сервера.
   */
  @action
  public renew = async () => {
    if (this.renewing) {
      return;
    }

    this.renewing = true;

    const model = await this.getValues();
    this.setModel(model);

    this.renewing = false;
  };

  /**
   * Инициализирует экземпляр хранилища, если нужно.
   */
  @action
  public touch = async () => {
    if (this.initialized) {
      return;
    }

    await this.renew();

    runInAction(() => {
      this.initialized = true;
    });
  };

  /**
   * Производит отправку значений модели на сервер.
   */
  @action
  public submitValues = async () => {
    if (this.model == null) {
      throw new Error(`this.model is undefined`);
    }

    this.setPending(true);

    const values = this.model;

    await this.putValues(values);

    this.setPending(false);
  };

  /**
   * Обновляет значения указанных свойств анкеты.
   * @param values Коллекция новых значений.
   */
  @action
  public updateValues = async (values: Partial<Model>) => {
    if (this.model == null) {
      throw new Error(`this.model is undefined`);
    }

    this.setPending(true);

    const model: Model = {
      ...this.model,
      ...values,
    };

    this.setPending(false);
    this.setModel(model);
  };

  /**
   * Получает модель анкеты от сервера.
   */
  private async getValues(): Promise<Partial<Model>> {
    let response: Application<ApplicationFormType.LoanApplicationForm>;

    try {
      response = await this.rpc.applicationFormGetLast(
        ApplicationFormType.LoanApplicationForm,
      );
    } catch (error) {
      if (!RpcError.isType(error, RpcErrorCode.ApplicationNotExists)) {
        throw error;
      }

      return {};
    }

    const { fields } = response;

    let file: string | undefined = undefined;
    try {
      file = await this.rpc.clientGetAttachment(AttachmentType.PassportScan);
    } catch {}

    const employmentOrganization = fields.employment.organization
      ? ({
          hid: '_',
          name: fields.employment.organization,
        } as CompanyName)
      : undefined;

    const employmentAddress = fields.employment.address
      ? await this.daData.parseAddress(fields.employment.address)
      : undefined;

    const contactPersons = fields.contactPersons.map((contactPerson) => {
      const hasName =
        !!contactPerson.firstName ||
        !!contactPerson.lastName ||
        !!contactPerson.patronymicName;

      return {
        name: hasName
          ? {
              gender: 'UNKNOWN' as const,
              first: contactPerson.firstName,
              second: contactPerson.patronymicName,
              last: contactPerson.lastName,
            }
          : undefined,
        type: contactPerson.type,
        phone: contactPerson.phoneNumber,
      };
    });

    const partialModel: Partial<Model> = {
      contactPersons: contactPersons.length ? contactPersons : undefined,

      passportPhoto: file ? this.STUB_FILE : undefined,

      passportNumber:
        fields.passportSeries && fields.passportNumber
          ? PassportHelper.formatNumber(
              `${fields.passportSeries}${fields.passportNumber}`,
            )
          : undefined,
      passportCode: fields.passportDepartmentCode
        ? PassportHelper.formatCode(fields.passportDepartmentCode)
        : undefined,
      birthPlace: fields.passportBirthPlace,
      passportUnit: fields.passportIssuer,
      birthDate: fields.passportBirthDate,
      passportDate: fields.passportIssueDate,

      gender: fields.gender?.toUpperCase() as Gender,
      firstName: fields.firstName,
      secondName: fields.patronymicName,
      lastName: fields.lastName,
      email: fields.email,
      innSnils: fields.innSnils,

      registrationAddress: {
        id: '_',
        region: fields.registrationAddress.region,
        city: fields.registrationAddress.city,
        street: fields.registrationAddress.street,
        house: fields.registrationAddress.house,
        flat: fields.registrationAddress.apartment,
      },

      livingAddress: {
        id: '_',
        region: fields.residenceAddress.region,
        city: fields.residenceAddress.city,
        street: fields.residenceAddress.street,
        house: fields.residenceAddress.house,
        flat: fields.residenceAddress.apartment,
      },

      childrenCount: fields.childrenCount,
      familySize: fields.familyMembersCount,
      martialStatus: fields.martialStatus,
      isLivingWithParents: fields.isLivingWithParents,

      jobOrganization: employmentOrganization,
      jobAddress: employmentAddress,
      incomeType: fields.employment.status,
      jobPhone: fields.employment.phoneNumber,
      jobPosition: fields.employment.position,
      incomeDay:
        fields.employment.salaryDay === undefined
          ? undefined
          : String(fields.employment.salaryDay),
      incomeSize: fields.employment.monthlySalary,

      creditObligationsHasUnpaid: !fields.creditObligationsHasUnpaid
        ? false
        : fields.creditObligationsHasUnpaid,
      creditObligationsAveragePaymentAmount:
        !fields.creditObligationsAveragePaymentAmount
          ? 0
          : Number(fields.creditObligationsAveragePaymentAmount),
      creditObligationsPaymentsPerMonth:
        !fields.creditObligationsPaymentsPerMonth
          ? 0
          : Number(fields.creditObligationsPaymentsPerMonth),
    };

    const isSameAddresses = [
      partialModel.registrationAddress,
      partialModel.livingAddress,
    ]
      .map((address) => {
        if (!address) {
          return '';
        }

        return Object.values(address).join('|');
      })
      .every((v, _index, thisArray) => v === thisArray[0]);

    return {
      ...partialModel,
      isSameAddresses,
    };
  }

  /**
   * Обновляет указанные свойства модели заявки на сервере и возвращает
   * обновлённую модель.
   * @param values Новые значения свойств заявки.
   */
  private async putValues(values: Partial<Model>) {
    try {
      this.setIsError(false);
      await this.rpc.applicationFormCreateOrUpdate(
        ApplicationFormType.LoanApplicationForm,
        {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          patronymicName: values.secondName,
          gender:
            (values.gender?.toLowerCase() as UserGender) ?? UserGender.Unknown,
          registrationAddress: {
            region: values.registrationAddress?.region,
            city: values.registrationAddress?.city,
            street: values.registrationAddress?.street,
            house: values.registrationAddress?.house,
            apartment: values.registrationAddress?.flat,
          },
          residenceAddress: values.isSameAddresses
            ? {
                region: values.registrationAddress?.region,
                city: values.registrationAddress?.city,
                street: values.registrationAddress?.street,
                house: values.registrationAddress?.house,
                apartment: values.registrationAddress?.flat,
              }
            : {
                region: values.livingAddress?.region,
                city: values.livingAddress?.city,
                street: values.livingAddress?.street,
                house: values.livingAddress?.house,
                apartment: values.livingAddress?.flat,
              },
          passportSeries: values.passportNumber
            ? PassportHelper.parseNumber(values.passportNumber).substring(0, 4)
            : undefined,
          passportNumber: values.passportNumber
            ? PassportHelper.parseNumber(values.passportNumber).substring(4)
            : undefined,
          passportBirthPlace: values.birthPlace,
          passportBirthDate: values.birthDate,
          passportDepartmentCode: values.passportCode
            ? PassportHelper.parseCode(values.passportCode)
            : undefined,
          passportIssueDate: values.passportDate,
          passportIssuer: values.passportUnit,

          innSnils: values.innSnils
            ? SnilsHelper.parse(values.innSnils)
            : undefined,
          martialStatus: values.martialStatus,
          familyMembersCount: values.familySize,
          childrenCount: values.childrenCount,
          isLivingWithParents: values.isLivingWithParents,

          employment: {
            status: values.incomeType,
            monthlySalary: values.incomeSize,
            salaryDay:
              values.incomeDay === undefined
                ? undefined
                : Number(values.incomeDay),
            organization: values.jobOrganization?.name,
            address: values.jobAddress
              ? [
                  values.jobAddress.region,
                  values.jobAddress.city,
                  values.jobAddress.street,
                  values.jobAddress.house,
                  values.jobAddress.flat,
                ]
                  .filter(Boolean)
                  .join(', ')
              : undefined,
            phoneNumber: values.jobPhone
              ? PhoneHelper.parse(values.jobPhone)
              : undefined,
            position: values.jobPosition,
          },

          contactPersons:
            values.contactPersons?.map((contactPerson) => ({
              firstName: contactPerson.name?.first,
              lastName: contactPerson.name?.last,
              patronymicName: contactPerson.name?.second,
              phoneNumber: contactPerson.phone
                ? PhoneHelper.parse(contactPerson.phone)
                : undefined,
              type: contactPerson.type,
            })) ?? [],

          creditObligationsHasUnpaid: values.creditObligationsHasUnpaid,
          creditObligationsAveragePaymentAmount:
            values.creditObligationsHasUnpaid
              ? Number(values.creditObligationsAveragePaymentAmount)
              : 0,
          creditObligationsPaymentsPerMonth: values.creditObligationsHasUnpaid
            ? Number(values.creditObligationsPaymentsPerMonth)
            : 0,
        },
      );
    } catch (error) {
      this.setIsError(true);
      Sentry.emit('error');
    }

    if (values.passportPhoto == null || values.passportPhoto.content == null) {
      return;
    }

    await this.rpc.clientUploadAttachment(
      AttachmentType.PassportScan,
      values.passportPhoto.content,
    );

    /**
     * Замена файла на заглушку чтобы исключить повторную отправку вложения.
     */
    values.passportPhoto = this.STUB_FILE;
  }

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.initialized = false;
    this.pending = false;
    this.model = undefined;
    this.renewing = false;
  }
}
