/**
 * Тип погашения займа. Определяет каким образом будет происходить списание
 * средств после выбора метода оплаты.
 */
export enum PaymentType {
  /**
   * Полное погашение.
   */
  EARLY = 'early',

  /**
   * Погашение задолженности.
   */
  EXPIRATION = 'expiration',

  /**
   * Очередное погашение.
   */
  NEXT = 'next',

  /**
   * Произвольный платёж (оплата без авторизации для просрочки)
   */
  ANY = 'any',

  /**
   * Оплата юридической услуги.
   */
  LEGAL_SUPPORT = 'legalSupport',
}
