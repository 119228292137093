import styled from '@emotion/styled';
import { IMaskInput } from 'react-imask';

/**
 * Элемент непосредственно поля ввода.
 */
export const Input = styled(IMaskInput)`
  display: block;
  flex: 1 1 100%;

  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1rem;
  padding-left: 1rem;

  border-radius: 0.75rem;
  background-color: transparent;
  border: 0;

  font-family: inherit;
  font-size: 1rem;
  line-height: 3rem;

  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.colors.light.text.tertiary};
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.light.text.tertiary};
  }

  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.light.text.tertiary};
  }

  :focus {
    outline: none;
  }

  &[readonly] {
    user-select: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;
