import { Theme, css } from '@emotion/react';

/**
 * Генерирует глобальные стили, используя тему сайта.
 * @param theme Тема сайта.
 */
export const useTheme = (theme: Theme) => css`
  html {
    font-family: Montserrat, sans-serif;
    line-height: 1.25;
    font-size: 16px;
    color: ${theme.colors.light.text.primary};
    background: ${theme.colors.light.background.primary};
  }
`;
