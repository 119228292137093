import { BaseError } from './BaseError';

/**
 * Ошибка, означающая что сервер недоступен.
 */
export class ServiceUnavailableError extends BaseError {
  /**
   * Внутренняя ошибка.
   */
  public readonly innerError: unknown;

  /**
   * Создаёт экземпляр ошибки данных ответа сервера.
   * @param response Данные ответа сервера.
   */
  constructor(innerError: unknown) {
    super('E_SERVICE_UNAVAILABLE', 'Service unavailable');

    this.innerError = innerError;
  }
}
