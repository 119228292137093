import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Nowrap.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Элемент Nowrap оборачивает текст и отображает его одной строкой.
 */
export const Nowrap: FC<Props> = ({ children, ...props }) => (
  <Root {...props}>{children}</Root>
);
