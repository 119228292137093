import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import largeBackground from './assets/largeBackground.svg';
import mediumBackground from './assets/mediumBackground.svg';
import smallBackground from './assets/smallBackground.svg';

/**
 * Корневой элемент.
 */
export const Root = styled.div`
  padding: 1.875rem 1.562rem 3.25rem;

  background-image: url(${largeBackground});
  background-position: top center;
  background-size: cover;
  background-color: #fff6fb;

  border-radius: 0.5rem;

  @media ${lessThanContainer('medium')} {
    padding: 2.75rem 2rem;

    background-image: url(${mediumBackground});
  }

  @media ${lessThanContainer('small')} {
    padding: 2.75rem 1.5rem;

    background-image: url(${smallBackground});
  }
`;
