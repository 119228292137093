import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Input } from '../Input';

import { Store } from '../../../stores';

const Period = () => {
  const { period, setPeriod } = Store.use();

  const onChange = useCallback(
    (value: number) => {
      setPeriod(value);
    },
    [setPeriod],
  );

  return (
    <Input
      value={period}
      min={7}
      step={1}
      max={30}
      label="На какой срок?"
      valueSuffix="дней"
      valueSeparator={' '}
      onChange={(value) => onChange(value)}
    />
  );
};

const component = observer(Period);
export { component as Period };
