import {
  createContext,
  useState,
  FC,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import { Gender, NameType } from 'modules/common/daData';

/**
 * Значение контекста.
 */
type Value = {
  /**
   * Текущий пол.
   */
  gender: Gender;

  /**
   * Задаёт новый пол контекста.
   * @param type Тип имени.
   * @param gender Новое значение.
   */
  setGender: (type: NameType, gender: Gender) => void;

  /**
   * Сбрасывает состояние одной из составляющей имени.
   * В случае, если все типы потеряют значение, произойдёт сброс половой привязки.
   */
  resetType: (type: NameType) => void;
};

/**
 * Контекст половой привязки имён.
 */
const GenderContext = createContext<Value>({
  gender: 'UNKNOWN',
  setGender: () => {},
  resetType: () => {},
});

/**
 * Объявляет контекст половой привязки имён.
 */
export const GenderProvider: FC = ({ children }) => {
  const [values, setValues] = useState<Partial<Record<NameType, Gender>>>({});

  const handleSetGender = useCallback(
    (type: NameType, value: Gender) =>
      setValues((oldValues) => ({
        ...oldValues,
        [type]: value,
      })),
    [],
  );

  const resetType = useCallback(
    (type: NameType) =>
      setValues(({ [type]: removed, ...oldValues }) => ({
        ...oldValues,
      })),
    [],
  );

  const gender = useMemo<Gender>(() => {
    const { SURNAME, NAME, PATRONYMIC } = values;

    if (SURNAME && SURNAME !== 'UNKNOWN') {
      return values.SURNAME!;
    }

    if (NAME && NAME !== 'UNKNOWN') {
      return values.NAME!;
    }

    if (PATRONYMIC && PATRONYMIC !== 'UNKNOWN') {
      return values.PATRONYMIC!;
    }

    return 'UNKNOWN';
  }, [values]);

  const value = useMemo<Value>(
    () => ({
      gender,
      setGender: handleSetGender,
      resetType,
    }),
    [gender, handleSetGender, resetType],
  );

  return (
    <GenderContext.Provider value={value}>{children}</GenderContext.Provider>
  );
};

/**
 * Возвращает контекст половой привязки имён.
 */
export const useGender = () => useContext(GenderContext);
