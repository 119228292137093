import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Form as BaseForm } from 'modules/common/ui';

import { Store } from '../../../stores';

import { generateSchema } from './Form.generateSchema';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof BaseForm>, 'schema'>;

/**
 * Форма принятия соглашений при подписании заявки.
 */
const Form: FC<Props> = ({ ...props }) => {
  const store = Store.use();
  const models = store.model || [];

  const schema = useMemo(
    () => generateSchema(models, store.values),
    [models, store.values],
  );

  return <BaseForm {...props} schema={schema} />;
};

const component = observer(Form);
export { component as Form };
