import { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Store } from '../../stores';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'>;

/**
 * Компонент оборачивает в отдельный блок список соглашений.
 */
export const CheckboxesList: FC<Props> = ({ children, ...props }) => {
  const { values } = useFormikContext<any>();
  const { setPersonalInfoAgreements } = Store.use();

  useEffect(() => {
    setPersonalInfoAgreements(values);
  }, [values]);

  return <div {...props}>{children}</div>;
};
