import { ComponentPropsWithoutRef, FC } from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Store as AuthStore } from 'modules/common/auth';
import {
  Store as ApplicationStore,
  Status as ApplicationStatus,
} from 'modules/application/core';
import { Store as LoanStore } from 'modules/loan/Store';
import { Status as LoanStatus } from 'modules/loan/Status';
import { Page, Helper } from 'modules/common/routing';

import { useLayout } from '../../LayoutContext';

import { Root } from './Account.Root';
import { LinkButton } from './Account.LinkButton';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Элемент с кнопкой Account.
 */
const Account: FC<Props> = ({ ...props }) => {
  const { pathname } = useRouter();
  const { layout } = useLayout();
  const auth = AuthStore.use();
  const application = ApplicationStore.use();
  const loan = LoanStore.use();

  const isPublic = Helper.isPublicPage(pathname);
  const isOnboarding =
    auth.isAuthorized &&
    application.status === ApplicationStatus.NONE &&
    loan.status === LoanStatus.NONE;

  const color = layout === 'screen' ? 'secondary' : 'primary';
  let title = 'Выйти из Личного кабинета';
  let text = 'Выйти';
  let href = Page.LOGOUT;

  if (isPublic || auth.isNotAuthorized) {
    title = 'Перейти в Личный кабинет';
    text = 'Личный кабинет';
    href = Page.ACCOUNT;
  }

  if (isOnboarding) {
    title = 'Выйти из системы';
    text = 'Выйти';
    href = Page.LOGOUT;
  }

  return (
    <Root {...props}>
      <LinkButton
        href={href}
        color={color}
        variant="outlined"
        size="small"
        title={title}
      >
        {text}
      </LinkButton>
    </Root>
  );
};

const component = observer(Account);
export { component as Account };
