import styled from '@emotion/styled';

/**
 * Элемент Footer для компонентов информации.
 */
export const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.light.separator};

  padding: 3.75rem 0;
`;
