import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Field, FileInput } from 'modules/common/ui';

import { Icon } from './Icon';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof FileInput>, 'name'> & {
  /**
   * Имя поля ввода.
   */
  name: string;

  /**
   * Заголовок поля ввода.
   */
  label: string;
};

/**
 * Отображает поле загрузки фотографии паспорта.
 */
export const Photo: FC<Props> = ({ label, name, ...props }) => {
  return (
    <Field label={label} htmlFor={name} name={name}>
      <FileInput
        {...props}
        id={name}
        name={name}
        icon={<Icon />}
        placeholder="Добавьте фотографию разворота паспорта: второй и третьей страницы."
        maxSize={10 * 1024 * 1024}
        accept="image/*"
        capture="environment"
      />
    </Field>
  );
};
