import { FC, ComponentPropsWithoutRef, useCallback } from 'react';

import { Root } from './Item.Root';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'onClick'> & {
  /**
   * Позиция символа, который представляет данный компонент.
   */
  position: number;

  /**
   * Обрабатывает нажатие на данный элемент.
   * @param position Позиция символа, который представляет данный элемент.
   */
  onClick?: (position: number) => void;
};

/**
 * Отображает блок отдельного символа в поле ввода четырёхзначного кода.
 */
export const Item: FC<Props> = ({ onClick, position, ...props }) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(position);
    }
  }, [onClick, position]);

  return <Root {...props} onClick={handleClick} />;
};
