import styled from '@emotion/styled';

import { lessThanContainer, Button } from 'modules/common/ui';

/**
 * Подвал элемента Item.
 */
export const Footer = styled.div`
  margin-top: 1.5rem;

  @media ${lessThanContainer('small')} {
    ${Button} {
      width: 100%;
    }
  }
`;
