import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент создает сетку для вложенных кнопок с вариантами способов платежа.
 */
export const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @media ${lessThanContainer('small')} {
    grid-template-columns: 1fr;
  }
`;
