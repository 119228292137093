import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Состояние меню
   */
  open?: boolean;
};

/**
 * Корневой элемент шапки страницы.
 */
const Root = styled.div<Props>`
  @media ${lessThanContainer('medium')} {
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    z-index: 2;

    width: 100%;
    height: 100vh;

    background-color: ${({ theme }) => theme.colors.light.background.primary};

    overflow: hidden;

    transform: translate3d(-100%, 0, 0);
    visibility: hidden;

    ${(props) =>
      props.open
        ? css`
            left: 0;

            transform: translate3d(0, 0, 0);
            transition: all 0.4s ease-out 0s;
            visibility: visible;
          `
        : css``}
  }
`;

Root.defaultProps = {
  open: false,
};

export { Root };
