import { Themed } from '@emotion/react';

/**
 * Возвращает значение CSS-свойства `transition` для указанного списка свойств.
 * @param rules Список CSS-свойств, которые должны быть анимированы.
 */
export const transition =
  (...rules: string[]) =>
  (props: Themed<{}>) =>
    rules
      .map(
        (rule) =>
          `${rule} ${props.theme.transition.duration.default}ms ${props.theme.transition.fn.default}`,
      )
      .join(', ');
