import styled from '@emotion/styled';

/**
 * Корневой элемент компонента.
 */
export const Root = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.light.input.background.default};

  border: 1.5px solid ${({ theme }) => theme.colors.light.input.border.focus};

  border-radius: 0.75rem;
  overflow: hidden;

  max-height: 20rem;
`;
