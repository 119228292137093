import React, { ComponentType, FC } from 'react';
import { Themed } from '@emotion/react';
import styled from '@emotion/styled';

import { lessThanContainer } from '../functions';

/**
 * Свойства дочернего компонента.
 */
type InnerProps = {};

/**
 * Свойства итогового компонента.
 */
type OuterProps<P extends InnerProps> = P & {
  /**
   * Исходная ширина контейнера.
   * @default `large`
   */
  size?: 'large' | 'medium' | 'small' | 'full';

  /**
   * Ширина внутренних отступов от края контейнера до его содержимого.
   * @default `default`
   */
  offset?: 'default' | 'small' | 'large' | 'none';
};

/**
 * Внутренний отступ.
 */
const paddingX = (props: Themed<OuterProps<{}>>) => {
  switch (props.offset) {
    case 'default': {
      return props.theme.container.offset.default;
    }
    case 'small': {
      return props.theme.container.offset.small;
    }
    case 'large': {
      return props.theme.container.offset.large;
    }
    case 'none': {
      return props.theme.container.offset.none;
    }
    default: {
      return props.theme.container.offset.default;
    }
  }
};

/**
 * Возвращает максимальную ширину контейнера для большого экрана.
 */
const largeMaxWidth = ({ size = 'large', theme }: Themed<OuterProps<{}>>) => {
  switch (size) {
    case 'large': {
      return `${theme.container.size.large}px`;
    }
    case 'medium': {
      return `${theme.container.size.medium}px`;
    }
    case 'small': {
      return `${theme.container.size.small}px`;
    }
    case 'full': {
      return '100%';
    }
    default: {
      throw new Error(`Unexpected container size ${size}`);
    }
  }
};

/**
 * Возвращает максимальную ширину контейнера для среднего экрана.
 */
const mediumMaxWidth = ({ size = 'large', theme }: Themed<OuterProps<{}>>) => {
  switch (size) {
    case 'medium':
    case 'large': {
      return `${theme.container.size.medium}px`;
    }
    case 'small': {
      return `${theme.container.size.small}px`;
    }
    case 'full': {
      return '100%';
    }
    default: {
      throw new Error(`Unexpected container size ${size}`);
    }
  }
};

/**
 * Возвращает максимальную ширину контейнера для маленького экрана.
 */
const smallMaxWidth = ({ size = 'large', theme }: Themed<OuterProps<{}>>) => {
  switch (size) {
    case 'medium':
    case 'large':
    case 'small': {
      return `${theme.container.size.small}px`;
    }
    case 'full': {
      return '100%';
    }
    default: {
      throw new Error(`Unexpected container size ${size}`);
    }
  }
};

/**
 * Возвращает обёртку над дочерним компонентом, которая добавляет ему стили
 * и свойства контейнера.
 *
 * @param Target Дочерний компонент.
 */
export const withContainerStyle = <P extends InnerProps>(
  Target: ComponentType<P>,
) => {
  const Wrapper: FC<OuterProps<P>> = ({ size, offset, ...props }) => (
    <Target {...(props as P)} />
  );

  const WithContainerStyle = styled(Wrapper)`
    margin-right: auto;
    margin-left: auto;

    padding-right: ${paddingX}px;
    padding-left: ${paddingX}px;

    max-width: ${largeMaxWidth};
    width: 100%;

    @media ${lessThanContainer('large')} {
      max-width: ${mediumMaxWidth};
    }

    @media ${lessThanContainer('medium')} {
      max-width: ${smallMaxWidth};
    }

    @media ${lessThanContainer('small')} {
      max-width: 100%;
    }
  `;

  WithContainerStyle.displayName = `WithContainerStyle(${
    Target.displayName || Target.name
  })`;

  return WithContainerStyle;
};
