/**
 * Тип провайдера оплаты.
 */
export enum PaymentProvider {
  /**
   * Тинькофф банк.
   */
  Tinkoff = 'tinkoff',

  /**
   * Сервис YooMoney.
   */
  Yoomoney = 'yoomoney',
}
