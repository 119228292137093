import styled from '@emotion/styled';
import { ComponentPropsWithoutRef, FC } from 'react';

import { lessThanContainer, LinkButton as Link } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Link> & {
  /**
   * Тип темы сайта.
   */
  layout: 'screen' | 'default';
};

/**
 * Добавление props.
 */
const EscapedRoot: FC<Props> = ({ ...props }) => {
  return <Link {...props} />;
};

/**
 * Ссылка элемента меню шапки.
 */
export const LinkButton = styled(EscapedRoot)`
  display: inline;

  padding: 0.5rem 0;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  text-decoration: none;
  text-align: left;

  border-radius: 0;

  color: ${(props) => {
    return props.layout === 'screen'
      ? props.theme.colors.light.text.overlay
      : props.theme.colors.light.text.secondary;
  }};

  :hover {
    color: ${({ theme }) => theme.colors.light.text.tertiary};
  }

  @media ${lessThanContainer('medium')} {
    display: block;

    padding: 0.75rem 1.25rem;

    font-weight: normal;
    font-size: 0.812rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.light.text.primary};

    :hover {
      color: ${({ theme }) => theme.colors.light.text.primary};

      background-color: ${({ theme }) =>
        theme.colors.light.background.secondary};
    }
  }
`;
