import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Button } from './ParamsAlternative.Button';
import { Value } from '../Value';

/**
 * Корневой элемент.
 */
export const Root = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  grid-column: span 2;

  padding: calc(1.5rem - 1.5px);

  border-width: 2px;
  border-style: solid;
  border-radius: 0.25rem;

  background-color: ${({ theme }) => theme.colors.light.background.secondary};
  border-color: ${({ theme }) => theme.colors.light.button.outline.border};

  transition: ${'color'};

  text-align: left;
  cursor: pointer;
  outline: none;

  & > * {
    flex-shrink: 0;
  }

  :hover {
    ${Button} {
      background-color: ${({ theme }) =>
        theme.colors.light.button.primary.background};
      color: ${({ theme }) => theme.colors.light.button.primary.text};
    }
  }

  :active {
    box-shadow: none;
  }

  @media ${lessThanContainer('small')} {
    flex-direction: column;
    align-items: normal;

    grid-column: span 1;

    padding-top: 1rem;
    padding-bottom: 1rem;

    ${Value} + ${Value} {
      margin-top: 1rem;
    }

    ${Button} {
      margin-top: 1.5rem;
      align-self: center;
    }
  }
`;
