import { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Heading } from 'modules/common/ui';
import { LoanService as LoanMetricService } from 'modules/common/metrics';
import { PageLoading } from 'modules/common/layout';
import { Page as Url, Redirect } from 'modules/common/routing';
import { Store as RestructureStore } from 'modules/restructure';

import { Screen } from '../../restructure/Screen';
import { BannerV2 as Banner, Wrapper } from '../../restructure/BannerV2';
import { Section } from '../../payout/CardsPage/Section';
import { Cards } from '../../payout/CardsPage/Cards';

import { Store as LoanStore } from '../Store';
import { Status as LoanStatus } from '../Status';

import { Container } from './Dashboard.Container';
import { Payments } from './Dashboard.Payments';
import { Documents } from './Dashboard.Documents';
import { AlertBanner } from './Dashboard.AlertBanner';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Container>;

/**
 * Страница Дашборда.
 */
const Dashboard: FC<Props> = ({ ...props }) => {
  const store = LoanStore.use();
  const restructure = RestructureStore.use();
  const { isAvailable, isScreenShowed } = restructure;

  const loanMetric = LoanMetricService.use();

  useEffect(() => {
    loanMetric.issued();
  }, [loanMetric]);

  if (
    store.model.status === LoanStatus.SIGNING ||
    store.model.status === LoanStatus.SIGNED
  ) {
    return (
      <Redirect
        url={
          store.model.status === LoanStatus.SIGNING ? Url.CONTRACT : Url.PAYOUT
        }
        replace
      >
        <PageLoading />
      </Redirect>
    );
  }

  if (store.model.status === LoanStatus.NONE) {
    return (
      <Redirect url={Url.ACCOUNT}>
        <PageLoading />
      </Redirect>
    );
  }

  if (isAvailable && !isScreenShowed) {
    return <Screen />;
  }

  return restructure.isAvailable ? (
    <Wrapper>
      {restructure.isAvailable && <Banner />}
      <Container {...props} size="large">
        <Heading overhead={`Заём №${store.model?.number}`} />
        {store.isActionBlocked ? <AlertBanner /> : <Payments />}
        <Documents />
      </Container>
    </Wrapper>
  ) : (
    <Container {...props} size="large">
      <Heading overhead={`Заём №${store.model?.number}`} />
      {store.isActionBlocked ? <AlertBanner /> : <Payments />}
      <Documents />
      <Section heading="Банковские карты">
        <Cards isAside={false} noClick />
      </Section>
    </Container>
  );
};

const component = observer(Dashboard);
export { component as Dashboard };
