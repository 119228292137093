import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Обёртка для содержимого кнопки.
 */
export const Content = styled.span`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  width: 100%;

  @media ${lessThanContainer('small')} {
    padding-bottom: 0;
    padding-top: 0;
  }
`;
