import React, { FC, useEffect, useState } from 'react';

import { PageLoading } from 'modules/common/layout';
import { Page, Store as RouterStore } from 'modules/common/routing';

import { Service } from '../../services';
import { PaymentType } from '../../types';

import {
  SuccessFull,
  SuccessMinimal,
  SuccessAny,
  SuccessLegalSupport,
} from '..';

/**
 * Компонент отображающий успех операции оплаты.
 */
export const Success: FC = () => {
  const [paymentType, setPaymentType] = useState<PaymentType | undefined>();
  const service = Service.use();
  const router = RouterStore.use();

  useEffect(() => {
    if (!router || !service) {
      return;
    }

    const value = service.getPaymentType();

    if (value === null) {
      setTimeout(() => {
        router.replace(Page.ACCOUNT);
      });
      return;
    }

    setPaymentType(value);
  }, [router, service]);

  if (paymentType === undefined) {
    return <PageLoading />;
  }

  if (paymentType === PaymentType.EARLY) {
    return <SuccessFull />;
  }

  if (paymentType === PaymentType.ANY) {
    return <SuccessAny />;
  }

  if (paymentType === PaymentType.LEGAL_SUPPORT) {
    return <SuccessLegalSupport />;
  }

  return <SuccessMinimal />;
};
