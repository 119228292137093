import styled from '@emotion/styled';

import { Spinner } from '../../Spinner';

/**
 * Корневой элемент компонента.
 */
export const Root = styled(Spinner)`
  color: ${({ theme }) => theme.colors.light.text.secondary};
`;
