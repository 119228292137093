import {
  ComponentPropsWithoutRef,
  Component,
  createRef,
  KeyboardEvent,
  ChangeEvent,
} from 'react';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<'input'>,
  'maxLength' | 'value' | 'onChange'
> & {
  /**
   * Максимальная длина строки, которую можно ввести в данное поле ввода.
   */
  maxLength: number;

  /**
   * Текущая позиция редактируемого символа.
   */
  position: number;

  /**
   * Текущее значение поля ввода.
   */
  value: string;

  /**
   * Обрабатывает изменение значения поля ввода.
   * @param value Новое значение поля ввода.
   */
  onChange?: (value: string) => void;

  /**
   * Обрабатывает изменение позиции редактируемого символа.
   * @param position Новая позиция редактируемого символа.
   */
  onPositionChange?: (position: number) => void;

  /**
   * Обрабатывает неотфильтрованное событие фокуса на элементе.
   */
  onDirtyFocus?: () => void;

  /**
   * Обрабатывает неотфильтрованное событие потери элементом фокуса.
   */
  onDirtyBlur?: () => void;
};

/**
 * Поле ввода с частично управляемым фокусом.
 */
export class Input extends Component<Props> {
  /**
   * Ссылка на элемент поля ввода.
   */
  private ref = createRef<HTMLInputElement>();

  /**
   * Очищает поле ввода.
   */
  public clear = () => {
    const { onPositionChange, maxLength, position, value } = this.props;

    if (onPositionChange && position !== 0) {
      onPositionChange(0);
    }

    if (value.length === maxLength) {
      return;
    }

    const { current } = this.ref;

    if (current) {
      const { value: previousValue } = current;
      current.value = '';

      const changeEvent = new Event('input', { bubbles: true });

      // eslint-disable-next-line no-underscore-dangle
      const tracker = (current as any)._valueTracker;

      if (tracker) {
        tracker.setValue(previousValue);
      }

      current.dispatchEvent(changeEvent);
    }
  };

  /**
   * Обрабатывает нажатие клавиши клавиатуры.
   * @param event Событие.
   */
  private handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { onPositionChange, onKeyDown, maxLength, position, value } =
      this.props;

    const maxPosition = Math.min(maxLength - 1, value.length);
    const minPosition = 0;

    const { key } = event;

    if (key === 'ArrowRight') {
      event.preventDefault();

      if (onPositionChange && position + 1 <= maxPosition) {
        onPositionChange(position + 1);
      }

      return;
    }

    if (key === 'ArrowLeft') {
      event.preventDefault();

      if (onPositionChange && position - 1 >= minPosition) {
        onPositionChange(position - 1);
      }

      return;
    }

    if (key === 'Home') {
      event.preventDefault();

      if (onPositionChange && position !== minPosition) {
        onPositionChange(minPosition);
      }

      return;
    }

    if (key === 'End') {
      event.preventDefault();

      if (onPositionChange && position !== maxPosition) {
        onPositionChange(maxPosition);
      }

      return;
    }

    const whitelist = {
      numbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      keyboard: [
        'Backspace',
        'ArrowDown',
        'PageDown',
        'ArrowUp',
        'PageUp',
        'Delete',
        'Enter',
        'Tab',
      ],
    };

    if (
      !event.ctrlKey &&
      !whitelist.numbers.includes(key) &&
      !whitelist.keyboard.includes(key)
    ) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    if (value.length === maxLength && whitelist.numbers.includes(key)) {
      this.handleChange(value.substr(0, position));
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  /**
   * Обрабатывает изменение текущего значения поля ввода.
   * @param event Событие.
   */
  private handleChange = (event: string | ChangeEvent<HTMLInputElement>) => {
    const { onPositionChange, onChange, position, maxLength } = this.props;
    let value;

    if (typeof event === 'string') {
      value = event;
    } else {
      event.preventDefault();
      value = event.target.value;
    }

    if (value && !/^\d+$/.test(value)) {
      return;
    }

    let nextValue = value.substr(0, maxLength);

    if (nextValue.length - 1 !== position) {
      nextValue = value.substr(0, position + 1);
    }

    const nextPosition = nextValue.length < maxLength ? nextValue.length : 0;

    if (typeof event === 'object') {
      // eslint-disable-next-line no-param-reassign
      event.target.value = nextValue;
    }

    if (onChange) {
      onChange(nextValue);
    }

    if (onPositionChange && position !== nextPosition) {
      onPositionChange(nextPosition);
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
      onPositionChange,
      onKeyDown,
      onChange,
      maxLength,
      position,
      value,
      ...props
    } = this.props;

    const maxPosition = Math.min(maxLength - 1, value.length);
    const minPosition = 0;

    if (position < minPosition || position > maxPosition) {
      throw new Error(
        `The position ${position} must be between 0 and ${maxPosition}`,
      );
    }

    const { current } = this.ref;

    if (current != null) {
      current.selectionStart = position;
      current.selectionEnd = position;
    }

    return (
      <input
        {...props}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleChange}
        maxLength={maxLength}
        value={value}
        ref={this.ref}
      />
    );
  }
}
