import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент ряда деталей.
 */
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
  margin-bottom: 5px;

  @media ${lessThanContainer('small')} {
    justify-content: space-between;
  }

  @media ${lessThanContainer('medium')} and (orientation: landscape) {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
`;
