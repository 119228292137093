import { Helper as Base } from '@devim-front/helper';

import { Status as LoanStatus } from 'modules/loan/Status';
import { Page } from 'modules/common/routing';

/**
 * Содержит утилиты для работы с Header.
 */
export class Helper extends Base {
  /**
   * Преобразует заголовок и ссылку для логотипа в зависимости от состояния
   * авторизации и состояния займа пользователя.
   * @param isAuthorized Указывает, что текущий пользователь авторизован
   * в системе.
   * @param status Статус текущего займа пользователя.
   */
  public static logoRouting(isAuthorized: boolean, status?: string) {
    let title = 'Перейти на главную страницу';
    let href = Page.HOME;
    const isLoanActive = isAuthorized && status === LoanStatus.ACTIVE;

    if (isAuthorized) {
      title = 'Перейти в Личный кабинет';
      href = Page.ACCOUNT;
    }

    return { title, href, isLoanActive };
  }
}
