import { FC, ComponentPropsWithoutRef } from 'react';
import { observer } from 'mobx-react';

import { DateHelper } from 'modules/common/values';
import { Nowrap } from 'modules/common/ui/components/Nowrap';
import { Store } from '../Store';
import { Helper } from '../Helper';

import { Item } from './Item';

import { Root } from './Params.Root';
import { Section } from './Params.Section';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает параметры изменения платежа на странице подписания реструктуризации.
 */
const Params: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const date = DateHelper.formatDisplay(
    store.model?.params.nearestPaymentDate ?? new Date(),
  );

  const payment = Helper.formatPayment(
    store.model?.params.nearestPaymentAmountBefore,
    store.model?.params.minRestructuringPaymentAmount,
  );

  return (
    <Root {...props}>
      <Section>
        <Item title="Сниженный платеж">
          <Nowrap>{payment}</Nowrap>
        </Item>
      </Section>
      <Section>
        <Item title="Дата платежа">{date}</Item>
      </Section>
    </Root>
  );
};

const component = observer(Params);
export { component as Params };
