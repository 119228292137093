import { ComponentPropsWithoutRef, FC } from 'react';

import {
  PersonalInfo,
  Provider as PersonalInfoProvider,
} from '../PersonalInfo';

import { Form as Base } from './Form/Form';
import { GroupCheckbox } from './GroupCheckbox';
import { Expandable } from './Expandable';
import { Provider } from './Context';
import { Toggle } from './Toggle';
import { List } from './List';

import { ToggleContainer } from './Form.ToggleContainer';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Base>, 'schema'>;

/**
 * Компонент содержит блок чекбоксов "Я согласен со всеми условиями".
 * По нажатию на текст "всеми условиями" раскрывается вложенный список
 * чекбоксов
 */
export const Form: FC<Props> = ({ ...props }) => (
  <Base {...props}>
    <Provider>
      <PersonalInfoProvider>
        <GroupCheckbox>
          <span>Я согласен со </span>
          <Toggle>всеми условиями</Toggle>
        </GroupCheckbox>
        <Expandable>
          <ToggleContainer>
            <List />
          </ToggleContainer>
        </Expandable>
        <PersonalInfo />
      </PersonalInfoProvider>
    </Provider>
  </Base>
);
