import { FC, ComponentPropsWithoutRef } from 'react';

import { LinkButton } from './Item.LinkButton';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof LinkButton> & {
  /**
   * Название документа.
   */
  children: string;

  /**
   * Путь к документу.
   */
  href: string;
};

/**
 * Отображает компонент с документом.
 */
export const Item: FC<Props> = ({ children, href, ...props }) => {
  const title = `Открыть или скачать документ «${children}»`;

  return (
    <LinkButton
      {...props}
      href={href}
      title={title}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </LinkButton>
  );
};
