import { ComponentPropsWithoutRef, FC } from 'react';

import { withButtonStyle } from '../../hocs';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'button'>;

/**
 * Отображает кнопку.
 */
const Button: FC<Props> = ({ ...props }) => <button {...props} />;

const component = withButtonStyle(Button);
export { component as Button };
