import styled from '@emotion/styled';
import { Checkbox as BaseCheckbox } from './PersonalInfo.Checkbox';
import { CheckboxesList as BaseCheckboxesList } from './PersonalInfo.CheckboxesList';

export const Content = styled.div`
  position: relative;
  max-width: 400px;
`;

export const CheckboxesList = styled(BaseCheckboxesList)`
  position: relative;
  overflow-y: auto;
  max-height: 300px;
  margin-bottom: 20px;
`;

export const Checkbox = styled(BaseCheckbox)`
  margin-bottom: 10px;
`;
