import { FC, HTMLProps } from 'react';
import BaseLink from 'next/link';

/**
 * Свойство компонента
 */
type Props = Omit<HTMLProps<HTMLAnchorElement>, 'href'> & {
  /**
   * Обязательный параметр для ссылки
   */
  href: string;
};

/**
 * Компонент, который отрисовывает Anchor внутри next/link
 */
export const Link: FC<Props> = ({ href, ...props }) => {
  return (
    <BaseLink href={href} passHref>
      <a {...props} />
    </BaseLink>
  );
};
