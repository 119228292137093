import React, {
  FC,
  Children,
  ReactNode,
  ComponentPropsWithoutRef,
} from 'react';

import { withFormValid, WithFormValidProps } from '../../hocs';

import { Root, Text } from './Point.Root';
import { Input } from './Point.Input';
import { Checkbox } from './Point.Checkbox';
import { Icon } from './Point.Icon';
import { Radio } from './Point.Radio';

/**
 * Свойства компонента.
 */
type Props = WithFormValidProps<
  Omit<ComponentPropsWithoutRef<typeof Input>, 'type' | 'children'> & {
    /**
     * Указывает, к какому типу принадлежит компонент.
     */
    type?: 'checkbox' | 'radio';

    /**
     * Текстовый блок, который отображается рядом с иконкой.
     */
    children: ReactNode;

    /**
     * Порядок отображения элементов Point / Children.
     */
    direction?: 'default' | 'reverse';

    /**
     * Вариант отображения элемента.
     */
    variant?: 'default' | 'solid';
  }
>;

/**
 * Базовый компонент для отображения флажка с блоком текса.
 */
const Point: FC<Props> = ({
  type = 'checkbox',
  className,
  direction,
  children,
  variant,
  invalid,
  valid,
  id,
  ...props
}) => {
  const isChildren = Boolean(children) && Children.count(children) > 0;

  return (
    <Root htmlFor={id} direction={direction} className={className}>
      <Input {...props} type={type} id={id} />
      {type === 'checkbox' ? (
        <Checkbox isInvalid={invalid} isValid={valid} variant={variant}>
          <Icon />
        </Checkbox>
      ) : (
        <Radio isInvalid={invalid} isValid={valid} />
      )}
      {isChildren && <Text direction={direction}>{children}</Text>}
    </Root>
  );
};

const component = withFormValid(Point);
export { component as Point };
