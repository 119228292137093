export const Visa = () => (
  <svg
    width="37"
    height="11"
    viewBox="0 0 37 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0696 0.165831L9.22007 10.8342H6.04693L3.6521 2.32161C3.53236 1.82412 3.35275 1.60301 2.93366 1.38191C2.21521 1.05025 1.0178 0.663317 0 0.497488L0.0598696 0.165831H5.14887C5.80744 0.165831 6.40615 0.552765 6.52589 1.27136L7.78317 7.46231L10.8964 0.221106H14.0696V0.165831ZM26.4628 7.35176C26.4628 4.53266 22.2718 4.36684 22.2718 3.15076C22.2718 2.76382 22.6909 2.37688 23.5291 2.26633C23.9482 2.21105 25.1456 2.15578 26.4628 2.76382L27.0016 0.497488C26.2832 0.276383 25.3851 0 24.1877 0C21.254 0 19.1586 1.43719 19.1586 3.53769C19.1586 5.08543 20.6553 5.91457 21.7929 6.41206C22.9304 6.90955 23.3495 7.24121 23.3495 7.73869C23.3495 8.45729 22.4515 8.78894 21.5534 8.78894C20.0566 8.78894 19.1586 8.40201 18.5 8.12563L17.9612 10.4472C18.6796 10.7236 19.9369 11 21.254 11C24.3673 11 26.4029 9.56281 26.4628 7.35176ZM34.246 10.8342H37L34.6052 0.165831H32.0307C31.432 0.165831 30.9531 0.49749 30.7735 0.939701L26.2832 10.8342H29.3964L29.9951 9.23116H33.8269L34.246 10.8342ZM30.8932 7.07538L32.4498 3.09548L33.3479 7.07538H30.8932ZM18.3204 0.165831L15.8657 10.8342H12.8722L15.3269 0.165831H18.3204Z"
      fill="#131132"
    />
  </svg>
);
