import styled from '@emotion/styled';

/**
 * Корневой элемент калькулятора.
 */
export const Label = styled.p`
  margin: 0;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 18px;
  opacity: 0.7;
`;
