import { Themed } from '@emotion/react';
import styled from '@emotion/styled';

/**
 * Свойства компонента.
 */
export type Props = {
  /**
   * Цвет блока.
   */
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'info';

  /**
   * Высота блока.
   * @default `1rem`
   */
  height?: number | string;

  /**
   * Ширина блока.
   * @default `100%`
   */
  width?: number | string;
};

/**
 * Цвет фона элемента.
 */
const backgroundColor = (props: Themed<Props>) => {
  switch (props.color) {
    case 'primary': {
      return props.theme.color.primary.main;
    }
    case 'secondary': {
      return props.theme.color.secondary.main;
    }
    case 'success': {
      return props.theme.color.success.main;
    }
    case 'warning': {
      return props.theme.color.warning.main;
    }
    case 'error': {
      return props.theme.color.error.main;
    }
    case 'info': {
      return props.theme.color.info.main;
    }
    default: {
      return 'transparent';
    }
  }
};

/**
 * Цвет текста элемента.
 */
const textColor = (props: Themed<Props>) => {
  switch (props.color) {
    case 'primary': {
      return props.theme.color.primary.contrast;
    }
    case 'secondary': {
      return props.theme.color.secondary.contrast;
    }
    case 'success': {
      return props.theme.color.success.contrast;
    }
    case 'warning': {
      return props.theme.color.warning.contrast;
    }
    case 'error': {
      return props.theme.color.error.contrast;
    }
    case 'info': {
      return props.theme.color.info.contrast;
    }
    default: {
      return 'inherit';
    }
  }
};

/**
 * Высота блока.
 */
const height = (props: Themed<Props>) => {
  const value = props.height ?? '1rem';
  return typeof value === 'string' ? value : `${value}px`;
};

/**
 * Ширина блока.
 */
const width = (props: Themed<Props>) => {
  const value = props.width ?? '100%';
  return typeof value === 'string' ? value : `${value}px`;
};

/**
 * Отображает цветной блок, который является заглушкой для ещё не реализованных
 * блоков во время вёрстки.
 */
export const Root = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundColor};
  color: ${textColor};

  height: ${height};
  width: ${width};
`;
