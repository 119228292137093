import styled from '@emotion/styled';

/**
 * Обертка для компонента загрузки файла.
 */
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  flex: 1 1 auto;

  padding: 1.25rem;

  max-width: 100%;
`;
