import styled from '@emotion/styled';

import { Input as Base } from './Input';

/**
 * Скрытый инпут компонента ввода четырёхзначного кода.
 */
export const Input = styled(Base)`
  position: absolute;
  top: 100%;
  left: 0;

  height: 0;

  opacity: 0.001;
  border: 0;

  display: block;

  outline: none !important;
`;
