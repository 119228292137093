import { Global as Base, useTheme as useBaseTheme } from '@emotion/react';
import { FC } from 'react';

import { fonts } from './Global.fonts';
import { reset } from './Global.reset';
import { useTheme } from './Global.useTheme';

/**
 * Подключает на страницу глобальные стили приложения.
 */
export const Global: FC = () => {
  const theme = useTheme(useBaseTheme());

  return (
    <>
      <Base styles={fonts} />
      <Base styles={reset} />
      <Base styles={theme} />
    </>
  );
};
