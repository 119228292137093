import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Icon as Base } from './Icon';

/**
 * Иконка на кнопке.
 */
export const Icon = styled(Base)`
  margin-left: auto;

  display: block;

  height: 2.25rem;
  width: 2.25rem;

  @media ${lessThanContainer('small')} {
    height: 2rem;
    width: 2rem;
  }
`;
