import styled from '@emotion/styled';

import { Adornment } from './TextInput.Adornment';

/**
 * Обёртка для дополнительного контента в начале поля ввода.
 */
export const StartAdornment = styled.div`
  ${Adornment}

  padding-left: 1rem;
`;
