import { Helper as Base } from '@devim-front/helper';

import { DateHelper } from 'modules/common/values';

/**
 * Содержит методы для валидации формы паспортных данных.
 */
export class SchemaHelper extends Base {
  /**
   * Метод, который возвращает сообщение об ошибке,
   * если указан неверный формат даты или дата не существует.
   * @param value Строка.
   */
  private static getDateError(value: string) {
    const isInvalidFormat = !DateHelper.validateDisplayFormat(value);

    if (isInvalidFormat) {
      return 'Неверный формат даты';
    }

    const isInvalidValue = !DateHelper.validateDisplayValue(value);

    if (isInvalidValue) {
      return 'Указанная дата не существует';
    }

    return false;
  }

  /**
   * Метод, валидирующий поле ввода даты рождения пользователя.
   * @param value Строка с датой рождения пользователя.
   */
  public static validateBirthDate(value: string) {
    if (this.getDateError(value)) {
      return this.getDateError(value);
    }

    const date = DateHelper.parseDisplay(value);
    const age = DateHelper.getYearsBetween(date, DateHelper.CURRENT);

    if (DateHelper.isBefore(DateHelper.CURRENT, date)) {
      return 'Указана неверная дата рождения';
    }

    if (age < 18) {
      return 'Пользователь не может быть несовершеннолетним';
    }

    if (age >= 100) {
      return 'Возраст пользователя не может превышать 100 лет';
    }

    return undefined;
  }

  /**
   *Метод, валидирующий поле ввода даты выдачи паспорта пользователя.
   * @param value Строка с датой выдачи паспорта.
   */
  public static validatePassportDate(value?: string) {
    if (!value) {
      return false;
    }

    if (this.getDateError(value)) {
      return this.getDateError(value);
    }

    const date = DateHelper.parseDisplay(value);

    const isFuture = DateHelper.isBefore(DateHelper.CURRENT, date);

    if (isFuture) {
      return 'Указана неверная дата выдачи';
    }

    return undefined;
  }

  /**
   *Метод, валидирующий поле ввода даты на актуальность паспорта.
   * @param value Строка с датой выдачи паспорта.
   * @param birthDate Строка с датой рождения пользователя.
   */
  public static validatePassportExpiration(value: string, birthDate: string) {
    const date = DateHelper.parseDisplay(value);
    const birth = DateHelper.parseDisplay(birthDate);
    const age = DateHelper.getYearsBetween(birth, DateHelper.CURRENT);

    let minDate = DateHelper.addYears(birth, 45);

    if (age < 45) {
      minDate = DateHelper.addYears(birth, 20);
    }

    if (age < 20) {
      minDate = DateHelper.addYears(birth, 14);
    }

    const isExpired = DateHelper.isBefore(date, minDate);

    if (isExpired) {
      return 'Срок действия паспорта истёк';
    }

    return undefined;
  }

  /**
   *Метод, валидирующий поле ввода серии и номера паспорта.
   * @param value Строка.
   */
  public static validatePassportNumber(value?: string) {
    if (!value) {
      return false;
    }

    const match = value.match(/^(\d{4})-(\d{6})$/);

    if (match == null) {
      return false;
    }

    const serial = Number(match[1]);
    const code = Number(match[2]);

    const isInvalidValue = serial < 100 || code < 101;

    return !isInvalidValue;
  }

  /**
   *Метод, валидирующий поле ввода СНИЛС.
   * @param value Строка.
   */
  public static validateSnils(value?: string) {
    if (!value) {
      return true;
    }
    const match = value.match(/^(\d{3}-\d{3}-\d{3})\s(\d{2})$/);

    if (match == null) {
      return false;
    }

    const digits = match[1].replace(/\D/g, '');
    const checksum = Number(match[2]);

    const { length } = digits;

    let sum: number = 0;

    for (let i = 0; i < length; i += 1) {
      sum += Number(digits[i]) * (9 - i);
    }

    sum %= 101;

    if (sum === 100) {
      sum = 0;
    }

    if (sum !== checksum) {
      return false;
    }

    return true;
  }
}
