/**
 * Названия полей ввода данных о доходе.
 */
export enum Field {
  EMPLOYMENT = 'employment',
  WAGE = 'wage',
  EMPLOYER = 'employer',
  EMPLOYER_ADDRESS = 'employerAddress',
  EMPLOYER_PHONE = 'employerPhone',
  EMPLOYMENT_POSITION = 'employmentPosition',
  SALARY_DAY = 'salaryDay',
}
