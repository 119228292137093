import { ComponentPropsWithoutRef, FC, useCallback } from 'react';

import { Field, Select } from 'modules/common/ui';
import { Service } from 'modules/common/daData';
import { CompanyName as CompanyType } from 'modules/common/daData/types';

import { Field as IncomeField } from '../../types';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Select>;

/**
 * Отображает поле ввода для поиска названия организации.
 */
export const CompanyName: FC<Props> = ({ ...props }) => {
  const daData = Service.use();

  const suggestCompanyName = useCallback(
    (value: string) => (value === '' ? [] : daData.suggestCompanyName(value)),
    [daData],
  );

  const handleFormat = useCallback((item) => [item.name].join(''), []);

  const handleUnique = useCallback((value: CompanyType) => value.hid, []);

  const fallback = useCallback((search: string) => {
    const model: CompanyType = {
      name: search,
      hid: '_',
    };

    return model;
  }, []);

  return (
    <Field label="Место работы" name={IncomeField.EMPLOYER} htmlFor="company">
      <Select
        {...props}
        id="company"
        name={IncomeField.EMPLOYER}
        values={suggestCompanyName}
        format={handleFormat}
        unique={handleUnique}
        placeholder="ООО “ИНТЕДИКО”"
        fallback={fallback}
        trim
      />
    </Field>
  );
};
