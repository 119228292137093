import { FC, useCallback } from 'react';

import { BankCard } from 'services/RpcService/types/BankCard';

import { Store } from '../../Store';

import { getExpDate, getDigits, getCardType } from './Card.Utils';
import { Root } from './Card.Root';
import { Logo } from './Card.Logo';
import { Info } from './Card.Info';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Модель карты, которую отображает данный компонент.
   */
  model: BankCard;
  /**
   * Не обрабатывать клик
   */
  noClick?: boolean;
};

/**
 * Отображает отдельную банковскую карту.
 */
export const Card: FC<Props> = ({ model, noClick }) => {
  const store = Store.use();

  const handleClick = useCallback(() => {
    store.payout(model.id);
  }, [store, model]);

  const expDate = getExpDate(model);
  const digits = getDigits(model);
  const type = getCardType(model);

  return (
    <Root onClick={noClick ? undefined : handleClick}>
      <Logo type={type} />
      <Info>
        <div>{digits}</div>
        <div>{expDate}</div>
      </Info>
    </Root>
  );
};
