import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Page, Redirect } from 'modules/common/routing';
import { PageLoading } from 'modules/common/layout';
import { Store as ClientStore } from 'modules/client';
import { Store as LoanStore, Status } from 'modules/loan';

import { Store as ApplicationStore } from '../../stores';
import { Status as ApplicationStatus } from '../../types';

/**
 * Инициализирует хранилище состояния заявки на заём.
 */
const Provider: FC = ({ children }) => {
  const applicationStore = ApplicationStore.use();
  const clientStore = ClientStore.use();
  const loanStore = LoanStore.use();

  useEffect(() => {
    clientStore.touch();
    applicationStore.touch();
    loanStore.touch();
  });

  if (!applicationStore.ready || !clientStore.ready || !loanStore.ready) {
    return <PageLoading />;
  }

  if (
    loanStore.status !== Status.NONE ||
    applicationStore.status === ApplicationStatus.PENDING
  ) {
    return (
      <Redirect url={Page.ACCOUNT} replace>
        <PageLoading />
      </Redirect>
    );
  }

  return <>{children}</>;
};

const component = observer(Provider);
export { component as Provider };
