/**
 * Продукт, которым является заём, делится на несколько типов.
 * Продукты, являющиеся типом SMART или MINI имеют только свойство полного погашения,
 * без минимального платежа (т.к не имеют графика платежа) и относятся к PDL типу.
 * Остальные продукты относятся к INSTALLMENT и имеют свойство частичного погашения займа.
 */
export enum Type {
  /**
   * Продукты, с отсутствующим свойством "Минимальный платёж".
   *
   * P.S.
   * Времена меняются, теперь может быть минимальный платеж, если
   * клиент использовал услугу реструктуризации
   */
  PDL = 'PDL',

  /**
   * Продукты, с возможностью частичного погашения займа, с помощью "Минимальной оплаты".
   */
  INSTALLMENT = 'INSTALLMENT',
}
