import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';
import { transition } from 'modules/common/theme';

/**
 * Свойства компонента
 */
type Props = {
  isOpened: boolean;
};

/**
 * Стилизованая обёртка для иконки
 */
export const Root = styled.div<Props>`
  margin-left: 11px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: -2px;

  & svg {
    transform-origin: 50% 50%;
    transition: ${transition('transform')};
    transform: ${(props) =>
      props.isOpened ? 'rotate(-180deg)' : 'rotate(0deg)'};
  }

  @media ${lessThanContainer('small')} {
    position: absolute;
    right: 0;
  }
`;
