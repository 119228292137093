import styled from '@emotion/styled';

import { Adornment } from './TextInput.Adornment';

/**
 * Обёртка для дополнительного контента в конце поля ввода.
 */
export const EndAdornment = styled.div`
  ${Adornment}

  padding-right: 1rem;
`;
