import styled from '@emotion/styled';

/**
 * Компонент для отображение текста в подвале страницы.
 */
export const Text = styled.p`
  margin: 1.25rem 0 0;

  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;

  :first-of-type {
    margin-top: 0;
  }
`;
