import styled from '@emotion/styled';

/**
 * Иконка.
 */
export const Icon = styled.span`
  grid-area: icon;

  display: flex;
  align-items: center;

  height: 100%;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  margin-left: 1rem;
`;
