import { Helper } from '@devim-front/helper';
import { CustomFile } from '../../ui';

/**
 * Содержит методы для работы с файлами.
 */
export class FileHelper extends Helper {
  /**
   * Возвращает `true`, если указанный файл имеет переданный MIME-type.
   * @param file Файл.
   * @param mimeType MIME-тип.
   */
  public static isMimeType(file: CustomFile, mimeType: string) {
    const pattern = new RegExp(mimeType.replace('*', '.*'));
    return pattern.test(file.type) || this.isHeic(file.path);
  }

  /**
   * Возвращает `true`, если указанный файл имеет расширение HEIC.
   * @param path Путь к файлу
   */
  public static isHeic(path?: string): boolean {
    if (!path) return false;

    const extArr = path.split('.');
    if (extArr.length <= 1) return false;

    const ext = extArr.pop();
    return ext ? ext.toLowerCase() === 'heic' : false;
  }

  /**
   * Возвращает содержимое файла в виде base64-строки.
   * @param file Файл.
   */
  public static getContent(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
}
