/**
 * Названия полей ввода данных.
 */
export enum Field {
  CREDIT_OBLIGATIONS_HAS_UNPAID = 'creditObligationsHasUnpaid',

  CREDIT_OBLIGATIONS_PAYMENTS_PER_MONTH = 'creditObligationsPaymentsPerMonth',

  CREDIT_OBLIGATIONS_AVERAGE_PAYMENT_AMOUNT = 'creditObligationsAveragePaymentAmount',
}
