import { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { InferType } from 'yup';

import { Form as Base, FormItem } from 'modules/application/common';
import { Store } from 'modules/application/statement';
import { UserEmploymentStatus } from 'services/RpcService/types/UserEmploymentStatus';
import { UserMonthlySalary } from 'services/RpcService/types/UserMonthlySalary';

import { schema } from '../../schemas';
import { Field as IncomeField } from '../../types';

import { WorkStatus } from '../WorkStatus';
import { MonthlyIncome } from '../MonthlyIncome';
import { Payment } from '../Payment';
import { CompanyName } from '../CompanyName';
import { CompanyAddress } from '../CompanyAddress';
import { WhenEmployed } from '../WhenEmployed';
import { Position } from '../Position';
import { CompanyPhone } from '../CompanyPhone';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Base>,
  'onSubmit' | 'schema' | 'initialValues'
>;

/**
 * Отображает форму "Информация о доходе".
 */
export const Form: FC<Props> = ({ ...props }) => {
  const store = Store.use();

  const handleSubmit = useCallback(
    (form: InferType<typeof schema>) => {
      store.updateValues({
        jobOrganization: form.employer as any,
        jobAddress: form.employerAddress as any,
        jobPhone:
          form.employment === UserEmploymentStatus.Employed
            ? form.employerPhone || undefined
            : undefined,
        jobPosition: form.employmentPosition || undefined,
        incomeType: form.employment as UserEmploymentStatus,
        incomeDay: form.salaryDay || undefined,
        incomeSize: form.wage as UserMonthlySalary,
      });
    },
    [store],
  );

  const initialValues = {
    [IncomeField.EMPLOYER]: store.model?.jobOrganization,
    [IncomeField.EMPLOYER_ADDRESS]: store.model?.jobAddress,
    [IncomeField.EMPLOYER_PHONE]: store.model?.jobPhone,
    [IncomeField.EMPLOYER]: store.model?.jobOrganization,
    [IncomeField.EMPLOYMENT_POSITION]: store.model?.jobPosition,
    [IncomeField.EMPLOYMENT]: store.model?.incomeType,
    [IncomeField.SALARY_DAY]: store.model?.incomeDay,
    [IncomeField.WAGE]: store.model?.incomeSize,
  };

  return (
    <Base
      {...props}
      onSubmit={handleSubmit}
      schema={schema}
      initialValues={initialValues}
    >
      <FormItem>
        <WorkStatus />
      </FormItem>
      <FormItem>
        <MonthlyIncome />
      </FormItem>
      <WhenEmployed>
        <FormItem>
          <CompanyName />
        </FormItem>
        <FormItem>
          <CompanyAddress />
        </FormItem>
        <FormItem>
          <Position />
        </FormItem>
        <FormItem>
          <CompanyPhone />
        </FormItem>
        <FormItem>
          <Payment />
        </FormItem>
      </WhenEmployed>
    </Base>
  );
};
