import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент обертки заголовка страницы.
 */
export const Root = styled.div`
  padding: 2rem 0;

  background-color: ${({ theme }) => theme.colors.light.background.secondary};

  @media ${lessThanContainer('small')} {
    padding: 1.25rem 0;
  }
`;
