import { CustomError } from '@devim-front/error';

/**
 * Предотвращает запрос СМС с кодом подтверждения или отправку этого кода на
 * сервера.
 */
export class Prevent extends CustomError {
  /**
   * Причина отмены операции. Отображается как ошибка на форме поля ввода кода.
   */
  public readonly reason?: string;

  /**
   * Создаёт экземпляр ошибки.
   * @param reason Причина отмены операции. Отобразиться как ошибка на форме
   * ввода кода.
   */
  public constructor(reason?: string) {
    super('ConfirmationError', 'Preventing a code request');

    this.reason = reason;
  }
}
