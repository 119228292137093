import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';

import { Store } from '../../stores';

/**
 * Отображает экран загрузки вместо своего содержимого до тех пор, пока не
 * загрузится список соглашений.
 */
const Provider: FC = ({ children }) => {
  const store = Store.use();

  useEffect(() => {
    store.touch();
  }, [store]);

  if (store.model == null) {
    return <PageLoading />;
  }

  return <>{children}</>;
};

const component = observer(Provider);
export { component as Provider };
