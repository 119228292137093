import * as yup from 'yup';

import { ApplicationAgreement } from 'services/RpcService/types/ApplicationAgreement';
import { ApplicationAgreementType } from 'services/RpcService/types/ApplicationAgreementType';

/**
 * Генерирует схему валидации на основе списка моделей соглашений.
 * @param models Список моделей соглашений.
 * @param values Значения, выбранные пользователем ранее.
 */
export const generateSchema = (
  models: ApplicationAgreement[],
  values?: Record<ApplicationAgreementType, boolean>,
) => {
  let shape: Record<string, any> = {};

  const { length } = models;

  for (let i = 0; i < length; i += 1) {
    const model = models[i];

    let initialValue = model.isCheckedByDefault;

    if (values) {
      initialValue = values[model.type] ?? false;
    }

    let item = yup.boolean().default(initialValue);

    if (model.required) {
      item = item.required().isTrue();
    }

    shape = {
      ...shape,
      [model.type]: item,
    };
  }

  return yup.object().shape(shape);
};
