import { FreeStore, reactive } from '@devim-front/store';
import { action, observable, runInAction } from 'mobx';

import { applyFetchable } from 'modules/common/stores';
import { RequestCode, ConfirmCode } from '../types';
import { Prevent, LimitExceededError, InvalidCodeError } from '../errors';
import { ErrorType } from '../consts';

const CODE_REQUESTED_KEY = 'confirmationCodeRequested';
const COUNTDOWN_KEY = 'confirmationCountdown';

/**
 * Хранилище состояния процесса подтверждения операции с помощью СМС.
 */
@reactive
export class Store extends applyFetchable(FreeStore) {
  /**
   * Функция, которая запрашивает СМС с кодом подтверждения у API.
   */
  private requestCodeFn?: RequestCode;

  /**
   * Функция, которая отправляет код подтверждения серверу.
   */
  private confirmCodeFn?: ConfirmCode;

  /**
   * Выполняется, когда запрос на получение кода подтверждения успешно
   * завершился.
   */
  private onRequestFn?: () => void;

  /**
   * Выполняется, когда во время отправки запросов произошла ошибка.
   */
  private onFailureFn?: () => void;

  /**
   * Выполняется, когда любой из запросов отменяется с помощью исключения
   * `Prevent`.
   */
  private onPreventFn?: () => void;

  /**
   * Таймаут обратного отсчёта секунд.
   */
  private timeout: any = undefined;

  /**
   * Номер телефона, на который отправляется код подтверждения.
   */
  @observable
  public phone?: string;

  /**
   * Указывает, что код подтверждения уже высылался на телефон пользователя.
   */
  @observable
  public codeRequested: boolean = false;

  /**
   * Количество секунд, которое осталось до повторной возможности отправки
   * кода подтверждения.
   */
  @observable
  public countdown: number = 0;

  /**
   * Указывает, что в данный момент происходит отправка данных на сервер.
   */
  @observable
  public pending: boolean = false;

  /**
   * Ошибка, которая произошла во время последней операции.
   */
  @observable
  public error?: string = undefined;

  /**
   * Задаёт новое значение свойству `phone`
   * @param phone Телефон
   */
  @action
  private setPhone = (phone?: string) => {
    this.phone = phone;
  };

  /**
   * Задаёт новое значение свойству `codeRequested`.
   * @param value Новое значение.
   */
  @action
  private setCodeRequested = (value: boolean) => {
    this.codeRequested = value;

    if (value) {
      window.localStorage.setItem(CODE_REQUESTED_KEY, String(true));
    } else {
      window.localStorage.removeItem(CODE_REQUESTED_KEY);
    }
  };

  /**
   * Задаёт новое значение свойству `countdown`.
   * @param value Новое значение.
   */
  @action
  private setCountdown = (value: number) => {
    this.countdown = value;

    if (this.countdown === 0) {
      window.localStorage.removeItem(COUNTDOWN_KEY);
      return;
    }

    window.localStorage.setItem(COUNTDOWN_KEY, String(this.countdown));
  };

  /**
   * Присваивает новое значение флагу `pending`.
   * @param value Новое значение.
   */
  @action
  private setPending = (value: boolean) => {
    this.pending = value;
  };

  /**
   * Присваивает новое значение свойству `error`.
   * @param value Новое значение.
   */
  @action
  private setError = (value?: string) => {
    this.error = value;
  };

  /**
   * Задаёт обработчик успеха запроса кода подтверждения.
   * @param onRequest Обработчик.
   */
  public setOnRequest = (onRequest: () => void) => {
    this.onRequestFn = onRequest;
  };

  /**
   * Задаёт обработчик ошибки во время любого запроса.
   * @param onFailure Обработчик.
   */
  public setOnFailure = (onFailure: () => void) => {
    this.onFailureFn = onFailure;
  };

  /**
   * Задаёт обработчик отмены любого запроса с помощью исключения `Prevent`.
   * @param onPrevent Обработчик.
   */
  public setOnPrevent = (onPrevent: () => void) => {
    this.onPreventFn = onPrevent;
  };

  /**
   * Устанавливает параметры, используемые при инициализации стора.
   * @param phone Номер телефона, на который отправляется код подтверждения.
   * @param requestCode Функция, с помощью которой у сервера запрашивается
   * код подтверждения.
   * @param confirmCode Функция, с помощью полученный код подтверждения
   * отправляется на сервер.
   */
  public setParams = (
    phone: string,
    requestCode: RequestCode,
    confirmCode: ConfirmCode,
  ) => {
    this.requestCodeFn = requestCode;
    this.confirmCodeFn = confirmCode;
    this.setPhone(phone);
  };

  /**
   * @inheritdoc
   */
  public async fetch() {
    let { timeout } = this;
    const codeRequested = Boolean(
      window.localStorage.getItem(CODE_REQUESTED_KEY),
    );
    const countdown = Number(window.localStorage.getItem(COUNTDOWN_KEY) || 0);

    if (timeout) {
      clearTimeout(this.timeout);
    }

    if (countdown) {
      timeout = setTimeout(this.nextCountdown, 1000);
    }

    return () => {
      this.setCodeRequested(codeRequested);
      this.setCountdown(countdown);
      this.timeout = timeout;
    };
  }

  /**
   * @inheritdoc
   */
  public clear() {
    clearTimeout(this.timeout);
    this.timeout = undefined;
    this.setPhone(undefined);
    this.setPending(false);
    this.setError(undefined);
    this.setCodeRequested(false);
    this.setCountdown(0);
  }

  /**
   * Обрабатывает очередную итерацию обратного отсчёта.
   */
  @action
  private nextCountdown = () => {
    this.setCountdown(this.countdown - 1);

    if (this.countdown > 0) {
      this.timeout = setTimeout(this.nextCountdown, 1000);
    }
  };

  /**
   * Запускает обратный отсчёт секунд до получения возможности запросить код
   * подтверждения повторно.
   */
  @action
  private startCountdown = () => {
    this.setCountdown(60);
    this.timeout = setTimeout(this.nextCountdown, 1000);
  };

  /**
   * Останавливает обратный отсчёт секунд.
   */
  @action
  private stopCountdown = () => {
    this.setCountdown(0);

    clearTimeout(this.timeout);
    this.timeout = undefined;
  };

  /**
   * Вызывает запрос кода подтверждения у сервера.
   */
  @action
  public requestCode = async () => {
    if (this.phone == null || this.requestCodeFn == null) {
      throw new Error(`A store is not initialized`);
    }

    const { codeRequested: previousCodeRequested } = this;

    this.setCodeRequested(false);
    this.setPending(true);
    this.setError(undefined);

    try {
      await this.requestCodeFn(this.phone);
    } catch (error) {
      if (error instanceof LimitExceededError) {
        runInAction(() => {
          this.setError(ErrorType.REQUEST_LIMIT_EXCEEDED);
          this.setPending(false);
        });

        if (this.onFailureFn) {
          this.onFailureFn();
        }

        return;
      }

      if (error instanceof Prevent) {
        const { reason } = error;

        runInAction(() => {
          this.setCodeRequested(previousCodeRequested);
          this.setPending(false);
          this.setError(reason);
        });

        if (this.onPreventFn) {
          this.onPreventFn();
        }

        return;
      }

      throw error;
    }

    runInAction(() => {
      this.setCodeRequested(true);
      this.setPending(false);
      this.startCountdown();
    });

    if (this.onRequestFn) {
      this.onRequestFn();
    }
  };

  /**
   * Вызывает подтверждение указанного кода у сервера.
   * @param code Код подтверждения.
   */
  @action
  public confirmCode = async (code: string) => {
    if (this.phone == null || this.confirmCodeFn == null) {
      throw new Error('A store is not initialized');
    }

    this.setError(undefined);
    this.setPending(true);

    try {
      await this.confirmCodeFn(code, this.phone);
    } catch (error) {
      if (error instanceof LimitExceededError) {
        runInAction(() => {
          this.setError(ErrorType.CONFIRM_LIMIT_EXCEEDED);
          this.setPending(false);
        });

        if (this.onFailureFn) {
          this.onFailureFn();
        }

        return;
      }

      if (error instanceof InvalidCodeError) {
        runInAction(() => {
          this.setError(ErrorType.INVALID_CODE);
          this.setPending(false);
        });

        if (this.onFailureFn) {
          this.onFailureFn();
        }

        return;
      }

      if (error instanceof Prevent) {
        const { reason } = error;

        runInAction(() => {
          this.setPending(false);
          this.setError(reason);
        });

        if (this.onPreventFn) {
          this.onPreventFn();
        }

        return;
      }

      throw error;
    }

    runInAction(() => {
      this.setCodeRequested(false);
      this.stopCountdown();
      this.setPending(false);
    });
  };
}
