import { ComponentPropsWithoutRef, FC } from 'react';

import { LinkButton } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof LinkButton>,
  'color' | 'variant'
>;

/**
 * Отображает кнопку "Назад".
 */
export const Back: FC<Props> = ({ children, ...props }) => (
  <LinkButton {...props} color="primary" variant="outlined">
    {children}
  </LinkButton>
);
