import { LazyStore, reactive } from '@devim-front/store';
import { observable, computed, action } from 'mobx';
import { applyFetchable } from 'modules/common/stores';
import { CompatService } from 'services/CompatService';
import { UserProfile } from 'services/RpcService/types/UserProfile';

/**
 * Хранилище состояния сведений о пользователе.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Модель клиента. Если свойство равно `undefined`, то модель ещё не была
   * получена с сервера.
   */
  @observable
  private unsafeModel?: UserProfile = undefined;

  /**
   * Ссылка на сервис RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Модель клиента. Если она ещё не загружена, то обращение к этому
   * свойству вызовет исключение.
   */
  @computed
  public get model() {
    if (this.unsafeModel == null) {
      throw new Error(
        `client.Store is not ready. Please use touch method before usage`,
      );
    }

    return this.unsafeModel;
  }

  /**
   * Присваивает свойству `model` новое значение.
   * @param model Новое значение.
   */
  @action
  private setModel = (model?: UserProfile) => {
    this.unsafeModel = model;
  };

  /**
   * @inheritdoc
   */
  async fetch() {
    const model = await this.rpc.clientGetProfile();

    return () => {
      this.setModel(model);
    };
  }

  /**
   * @inheritdoc
   */
  clear() {
    this.setModel(undefined);
  }
}
