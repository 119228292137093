import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './Item.Root';
import { LinkButton } from './Item.LinkButton';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Номер позиции элемента. Должен начинаться с позиции 2, так как первую
   * позицию занимает элемент главной страницы.
   * Нужен для задания `itemprop="position" content="2"`
   */
  position: string;

  /**
   * Ссылка элемента.
   */
  href: string;
};

/**
 * Единица элемента хлебной крошки.
 */
export const Item: FC<Props> = ({ position, href, children, ...props }) => {
  return (
    <Root
      {...props}
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
    >
      <LinkButton itemProp="item" href={href}>
        <span itemProp="name">{children}</span>
      </LinkButton>
      <meta itemProp="position" content={position} />
    </Root>
  );
};
