import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент кнопки открытия меню .
 */
export const Root = styled.button`
  display: none;

  margin-right: 0.5rem;
  padding-left: 0;

  background-color: transparent;
  border: none;

  @media ${lessThanContainer('medium')} {
    display: inline-block;
  }
`;
