import { LazyStore, reactive } from '@devim-front/store';
import { NextRouter } from 'next/router';
import { observable, action } from 'mobx';

/**
 * Хранилище состояния маршрутизатора.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Экземпляр маршрутизатора, полученного из контекста Next.js.
   */
  private router: NextRouter = undefined!;

  /**
   * Если в данный момент происходит откат истории методом `backTo`, свойство
   * содержит адрес, до которого следует откатиться. В противном случае -
   * `undefined`.
   */
  @observable
  public backUrl?: string = undefined;

  /**
   * Регистрирует экземпляр маршрутизатора из контекста Next.js.
   * @param router Экземпляр маршрутизатора.
   */
  public setRouter = (router: NextRouter) => {
    this.router = router;
  };

  /**
   * Производит переход на заданный адрес и помещает операцию в историю.
   * @param url Маршрут для перехода.
   * @param as Опциональный декоратор адреса, отображаемый в браузере.
   * @param options Параметры выполнения перехода.
   */
  public push: NextRouter['push'] = (url, as?, options?) =>
    this.router.push(url, as, options);

  /**
   * Производит переход на заданный адрес и заменяет последнюю операцию в
   * истории.
   * @param url Маршрут для перехода.
   * @param as Опциональный декоратор адреса, отображаемый в браузере.
   * @param options Параметры выполнения перехода.
   */
  public replace: NextRouter['replace'] = (url, as?, options?) => {
    return this.router.replace(url, as, options);
  };

  /**
   * Вызывает переход на предыдущую страницу истории.
   */
  public back: NextRouter['back'] = () => {
    return this.router.back();
  };

  /**
   * Задаёт новое значение свойству `backUrl`.
   * @param value Новое значение.
   */
  @action
  public setBackUrl = (value?: string) => {
    this.backUrl = value;
  };

  /**
   * Вызывает множественные переходы назад по истории браузера до тех пор,
   * пока текущий адрес страницы не совпадёт с указанным.
   * @param url Адрес страницы.
   */
  public backTo = (url: string) => {
    this.setBackUrl(url);
  };

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.setBackUrl(undefined);
  }
}
