/**
 * Тип вложения.
 */
export enum AttachmentType {
  /**
   * Скан паспорта.
   */
  PassportScan = 'passport_scan',

  /**
   * Фотография для идентификации пользователя.
   */
  IdentificationPhoto = 'identification_photo',

  /**
   * Документ, отправляемый при отказе от услуги.
   */
  ServiceRefundApplication = 'refund_service_application',
}
