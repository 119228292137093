import styled from '@emotion/styled';

/**
 * Корневой компонент единицы хлебной крошки.
 */
export const Root = styled.li`
  position: relative;

  padding-right: 2.25rem;

  color: ${({ theme }) => theme.colors.light.text.secondary};

  :after {
    content: '—';

    position: absolute;

    top: 0.09rem;
    right: 0.625rem;

    color: inherit;
  }
`;
