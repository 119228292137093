import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заглавие варианта платежа.
 */
export const Title = styled.div`
  font-size: 0.75rem;

  color: ${({ theme }) => theme.colors.light.text.secondary};

  @media ${lessThanContainer('small')} {
    flex: 1;

    margin-right: 0.5rem;
  }
`;
