import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает кнопку с текстом в Item.
 */
export const Button = styled.a`
  display: block;
  flex: 1 1 auto;

  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: left;

  padding-left: 0.75rem;
  color: ${({ theme }) => theme.colors.light.text.primary};

  @media ${lessThanContainer('small')} {
    font-size: 0.68rem;
    line-height: 1rem;
  }
`;
