import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Заголовок поля ввода.
 */
export const Label = styled.label`
  display: block;
  width: 100%;

  font-weight: 300;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  user-select: none;
  cursor: text;

  @media ${lessThanContainer('small')} {
    font-size: 0.75rem;
  }
`;
