import { LazyStore, reactive } from '@devim-front/store';
import { observable, action, computed } from 'mobx';

import { ApplicationService as ApplicationMetricService } from 'modules/common/metrics';
import { applyFetchable } from 'modules/common/stores';

import { Model, Status } from '../types';
import { Store as StatementStore } from '../../statement/stores';
import { CompatService } from 'services/CompatService';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';
import { LoanApplication } from 'services/RpcService/types/LoanApplication';
import { ApplicationStatus } from 'services/RpcService/types/ApplicationStatus';
import { DateHelper } from 'modules/common/values';
import { Service as RoutingService } from 'modules/common/routing';

/**
 * Хранилище состояния заявки.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Указывает на то, что в данный момент происходит обновление данных на
   * сервере.
   */
  @observable
  public pending: boolean = false;

  /**
   * Модель текущей заявки.
   */
  @observable
  public model?: Model = undefined;

  /**
   * Экземпляр сервиса RPC API.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Экземпляр сервиса маршрутизатора.
   */
  private get routing() {
    return RoutingService.get(this);
  }

  /**
   * Хранилище заявки.
   */
  private get statementStore() {
    return StatementStore.get(this);
  }

  /**
   * Экземпляр сервиса метрик.
   */
  private get applicationMetric() {
    return ApplicationMetricService.get(this);
  }

  /**
   * Текущий статус заявки. Если заявка ещё не получена с сервера, свойство
   * равно `undefined`.
   */
  @computed
  public get status() {
    return this.model?.status;
  }

  /**
   * Задаёт новое значение свойства `pending`.
   * @param pending Новое значение.
   */
  @action
  private setPending = (pending: boolean) => {
    this.pending = pending;
  };

  /**
   * Задаёт новое значение свойства `model`.
   * @param model Новое значение.
   */
  @action
  private setModel = (model?: Model) => {
    this.model = model;
  };

  /**
   * Преобразует данные заявки на заём в модель.
   */
  private parseModel = (loanApplication?: LoanApplication): Model => {
    let status: Status = Status.NONE;

    switch (loanApplication?.status) {
      case ApplicationStatus.FillingRequired:
        status = Status.FILLING;
        break;

      case ApplicationStatus.InitialCheck:
      case ApplicationStatus.DecisionRequired:
      case ApplicationStatus.Processing:
        status = Status.PENDING;
        break;

      case ApplicationStatus.Rejected:
      case ApplicationStatus.Cancelled:
        status = Status.REJECTED;
        break;

      case ApplicationStatus.Approved:
        status = Status.APPROVED;
        break;

      default:
        status = Status.NONE;
        break;
    }

    return {
      creationDate: loanApplication?.createdAt ?? DateHelper.CURRENT,
      expiredAtDate: loanApplication?.expiresAt,
      status,
      amount: loanApplication?.amount ?? 0,
      period: loanApplication?.period ?? 0,
    };
  };

  /**
   * @inheritdoc
   */
  public async fetch() {
    let loanApplication: LoanApplication | undefined;

    try {
      loanApplication = await this.rpc.loanApplicationGetActive();
    } catch (error) {
      if (RpcError.isType(error, RpcErrorCode.LoanApplicationNotExists)) {
      } else {
        throw error;
      }
    }

    const model = this.parseModel(loanApplication);

    await this.statementStore.touch();

    return () => {
      this.setModel(model);
    };
  }

  /**
   * Сохраняет на сервере базовые параметры займа (`amount`, `period`).
   * @param amount Сумма займа.
   * @param period Срок займа.
   */
  @action
  public submitParams = async (amount: number, period: number) => {
    this.setPending(true);

    const utm = this.routing.getFromSession();

    const loanApplication = await this.rpc.loanApplicationCreateOrUpdate(
      period,
      amount,
      utm,
    );
    let model = this.parseModel(loanApplication);

    if (this.model != null) {
      model = {
        ...this.model,
        ...model,
      };
    }

    this.applicationMetric.created();

    this.setModel(model);
    this.setPending(false);
  };

  /**
   * @inheritdoc
   */
  public clear() {
    this.pending = false;
    this.model = undefined;
  }
}
