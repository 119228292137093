import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент списока хлебной крошки.
 */
export const Root = styled.ol`
  display: flex;

  margin: 0 0 1.5rem;
  padding: 0;

  list-style: none;

  @media ${lessThanContainer('small')} {
    margin-bottom: 1rem;
  }
`;
