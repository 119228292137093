import { LazyStore, reactive } from '@devim-front/store';
import { observable, action } from 'mobx';

import { Sentry } from 'modules/common/sentry';
import {
  Page,
  Store as RoutingStore,
  Service as RoutingService,
} from 'modules/common/routing';
import { Store as LoanStore } from 'modules/loan';
import { CompatService } from 'services/CompatService';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';

/**
 * Хранилище состояния процесса выведения денег.
 */
@reactive
export class Store extends LazyStore {
  /**
   * Указывает, что в данный момент следует показывать экран загрузки.
   */
  @observable
  public loading: boolean = false;

  /**
   * Ссылка на роутер.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Экземпляр сервиса маршрутизатора.
   */
  private get routingService() {
    return RoutingService.get(this);
  }

  /**
   * Ссылка на хранилище займа.
   */
  private get loan() {
    return LoanStore.get(this);
  }

  /**
   * Ссылка на сервис RPC.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Задаёт новое значение свойству `loading`.
   */
  @action
  private setLoading = (value: boolean) => {
    this.loading = value;
  };

  /**
   * Указывает совершить вывод денег на карту с переданным идентификатором.
   * @param id Идентификатор карты.
   */
  @action
  public payout = async (id: number) => {
    this.setLoading(true);

    try {
      const utm = this.routingService.getFromSession();

      await this.rpc.paymentPayout(id, utm);
      await this.loan.renew();
      await this.routing.push(Page.ACCOUNT);
      this.setLoading(false);
    } catch (error) {
      this.setLoading(false);

      if (RpcError.isType(error, RpcErrorCode.PayoutCardNotAllowed)) {
        return;
      }

      if (RpcError.isType(error)) {
        Sentry.emit('error', { code: error.code });
      }
    }
  };

  /**
   * Вызывает переход на платёжный терминал для привязки новой карты.
   */
  @action
  public addCard = async () => {
    this.setLoading(true);

    try {
      const bindMethod = await this.rpc.paymentBindCard();
      window.location.href = bindMethod.meta.url;
    } catch (error) {
      this.setLoading(false);

      if (RpcError.isType(error)) {
        const { code } = error;
        Sentry.emit('error', { code });
      }
    }
  };

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  @action
  public dispose() {
    this.setLoading(false);
  }
}
