import { FC, HTMLProps } from 'react';
import BaseLink from 'next/link';

import { withButtonStyle } from '../../hocs';

/**
 * Свойство компонента
 */
type Props = Omit<HTMLProps<HTMLAnchorElement>, 'href' | 'size'> & {
  /**
   * Обязательный параметр для ссылки
   */
  href: string;
};

/**
 * Компонент, который отрисовывает Anchor внутри next/link
 */
const LinkButton: FC<Props> = ({ href, ...props }) => {
  return (
    <BaseLink href={href} passHref>
      <a {...props} />
    </BaseLink>
  );
};

const component = withButtonStyle(LinkButton);
export { component as LinkButton };
