import { Global as Base, css, useTheme, keyframes } from '@emotion/react';
import { FC } from 'react';

import { Type } from '../../consts';

/**
 * Анимация.
 */
const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

/**
 * Добавляет на страницу глобальные стили для анимации появления / исчезнования.
 */
export const FadeTransitionStyle: FC = () => {
  const theme = useTheme();

  return (
    <Base
      styles={css`
        .${Type.FADE}-enter {
          animation: ${animation} ${theme.transition.duration.default}ms
            ease-in-out forwards;
        }

        .${Type.FADE}-leave {
          animation: ${animation} ${theme.transition.duration.default}ms
            ease-in-out forwards reverse;
        }
      `}
    />
  );
};
