/**
 * Название свойства формы ввода контактных данных.
 */
export enum Field {
  EMAIL = 'email',
  FIRST_NAME = 'name',
  LAST_NAME = 'surname',
  SECOND_NAME = 'patronymic',
  GENDER = 'gender',
}
