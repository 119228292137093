import { FC } from 'react';
import { Button, Announcement, Nowrap } from 'modules/common/ui';

import errorImg from './assets/error.svg';

type Props = {
  onClick: () => void;
};

export const Alert: FC<Props> = ({ onClick }) => (
  <Announcement
    img={errorImg}
    alt="Обратите внимание"
    heading="Обратите внимание"
    subheading="Проверьте, пожалуйста, корректность заполнения Ваших персональных данных. И направьте запрос повторно"
    footer={
      <>
        Если проблема не решилась в течение 30 минут, обратитесь, пожалуйста, с
        вопросом в службу поддержки клиентов:{' '}
        <Nowrap>
          <a href="tel:+74996499059" style={{ color: '#848484' }}>
            +7 (499) 649-90-59
          </a>
        </Nowrap>
      </>
    }
  >
    <Button onClick={onClick} variant="filled">
      Попробовать снова
    </Button>
  </Announcement>
);
