import { useEffect } from 'react';

import { usePreloader } from './usePreloader';

/**
 * Добавляет текущий компонент к общему количеству ожидания функций подгрузки и
 * удаляет его при демонтировании.
 */
export const usePreloaderTask = () => {
  const { addAwaiter, removeAwaiter } = usePreloader();

  useEffect(() => {
    addAwaiter();

    return removeAwaiter;
  });
};
