import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';
import { Store as RouterStore, Page } from 'modules/common/routing';
import {
  Store as ApplicationStore,
  Status as ApplicationStatus,
} from 'modules/application/core';

import { Store } from '../../stores';

/**
 * Инициализирует хранилище состояния заявки на заём.
 */
const Provider: FC = ({ children }) => {
  const store = Store.use();
  const applicationStore = ApplicationStore.use();
  const router = RouterStore.use();

  useEffect(() => {
    if (
      applicationStore.status !== ApplicationStatus.REJECTED &&
      applicationStore.model?.status !== ApplicationStatus.NONE
    ) {
      store.touch();
    } else {
      router.replace(Page.ACCOUNT);
    }
  }, [store, applicationStore, router]);

  if (store.model == null || !store.initialized) {
    return <PageLoading />;
  }

  return <>{children}</>;
};

const component = observer(Provider);
export { component as Provider };
