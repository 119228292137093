import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';
import { Page, Redirect } from 'modules/common/routing';
import { Store } from '../../Store';

/**
 * Проверяет, может ли текущий пользователь просматривать эту страницу и, если
 * нет, перенаправляет его на второй шаг заявки.
 */
const Access: FC = ({ children }) => {
  const store = Store.use();

  useEffect(() => {
    store.touch();
  }, []);

  if (store.isExists === undefined) {
    return <PageLoading />;
  }

  if (store.isExists === false) {
    return (
      <Redirect url={Page.APPLICATION_SECOND_STEP} replace>
        <PageLoading />
      </Redirect>
    );
  }

  return <>{children}</>;
};

const component = observer(Access);
export { component as Access };
