import styled from '@emotion/styled';
import { FC } from 'react';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Ширина ячейки объекта
   */
  width?: 'full' | 'half';

  /**
   * Горизонтальное выравнивание контента.
   */
  horizontalAlign?: 'left' | 'center';
};

/**
 * Экранирование от лишних props
 */
const EscapedRoot: FC<Props> = ({ width, horizontalAlign, ...props }) => {
  return <div {...props} />;
};

/**
 * Корневой элемент для позиционирования FormItem.
 */
export const Root = styled(EscapedRoot)`
  grid-column: ${({ width }) => (width === 'half' ? 'span 1' : 'span 2')};

  justify-self: ${({ horizontalAlign }) =>
    horizontalAlign === 'center' ? 'center' : 'initial'};

  @media ${lessThanContainer('small')} {
    grid-column: span 2;
  }
`;
