import { ComponentPropsWithoutRef, FC } from 'react';

import { TextInput } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof TextInput>;

/**
 * Отображает поле ввода email.
 */
export const EmailInput: FC<Props> = ({ ...props }) => (
  <TextInput {...props} trim />
);
