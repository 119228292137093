import { ComponentPropsWithoutRef, FC, useContext } from 'react';
import { Button } from 'modules/common/ui';

import { Context } from '../../PersonalInfo';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Button>;

/**
 * Компонент открытия модального окна со списком дополнительных соглашений
 * о персональных данных.
 */
export const ButtonPersonalData: FC<Props> = ({ children, ...props }) => {
  const { toggle } = useContext(Context);

  return (
    <Button {...props} onClick={toggle}>
      {children}
    </Button>
  );
};
