import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Heading.Root';
import { OverHead } from './Heading.OverHead';
import { Main } from './Heading.Main';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Надзаголовок
   */
  overhead?: string;

  /**
   * Заголовок
   */
  children?: string;
};

/**
 * Компонент заголовка.
 */
export const Heading: FC<Props> = ({ overhead, children, ...props }) => (
  <Root {...props}>
    {overhead && <OverHead>{overhead}</OverHead>}
    {children && <Main>{children}</Main>}
  </Root>
);
