import { Pool, Provider as ServiceProvider } from '@devim-front/service';
import { createContext, FC, useContext, useMemo } from 'react';

/**
 * Контекст каркаса сброса состояний.
 */
type Value = {
  /**
   * Пул сервисов.
   */
  pool: Pool | undefined;

  /**
   * Задаёт функцию сброса состояний.
   */
  reset: () => void;
};

/**
 * Контекст.
 */
export const ResetContext = createContext<Value>({
  pool: undefined,
  reset: () => {},
});

/**
 * Объявляет контект глобальной разметки экрана.
 */
export const PoolProvider: FC = ({ children }) => {
  const value = useMemo<Value>(() => {
    const pool = new Pool();
    const reset = () => {
      pool.dispose();
    };
    return { pool, reset };
  }, []);

  return (
    <ResetContext.Provider value={value}>
      <ServiceProvider pool={value.pool}>{children}</ServiceProvider>
    </ResetContext.Provider>
  );
};

/**
 * Возвращает контект глобальной разметки экрана.
 */
export const useLayout = () => useContext(ResetContext);
