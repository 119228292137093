import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент объекта с информацией о платеже.
 */
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.light.background.secondary};

  padding-left: 1.5rem;
  padding-right: 1.5rem;

  padding: 1.5rem;

  border-radius: 0.5rem;

  @media ${lessThanContainer('small')} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;
