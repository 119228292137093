import { CustomError } from '@devim-front/error';

/**
 * Выбрасывается, если пользователь превысил лимит либо количества запросов
 * кода подтверждения, либо количество попыток отправить этот код.
 */
export class LimitExceededError extends CustomError {
  /**
   * @inheritdoc
   */
  public constructor() {
    super('LimitExceededError', 'Limit exceeded');
  }
}
