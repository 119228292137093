import { Component, PropsWithChildren } from 'react';

import { Sentry } from 'modules/common/sentry';
import { Button, Announcement, Nowrap } from 'modules/common/ui';
import { Page, PageContent } from 'modules/common/layout';

import errorImg from './assets/error.svg';

/**
 * Типы пропов и стейта
 */
type Props = PropsWithChildren<{}>;

type State = {
  hasError: boolean;
  errorCode: number | null;
};

/**
 * Компонент подписывается на необработанные ошибки и показывает
 * блок страницы с ошибкой при их наличии. А также являет собой
 * реализацию ErrorBoundary.
 */
export class ErrorBoundary extends Component<Props, State> {
  /**
   * @inheritdoc
   */
  public state: State = {
    hasError: false,
    errorCode: null,
  };

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    Sentry.on('error', this.setHasError);
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    Sentry.off('error', this.setHasError);
  }

  /**
   * @inheritdoc
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * Обработчик кнопки перезагрузки страницы
   */
  private handleClick = () => {
    document.location.reload();
  };

  /**
   * Устанавливает значение стейта hasError в true
   */
  private setHasError = (args: any[]) => {
    let code;
    if (args.length) {
      const [arg] = args;
      code = arg.code;
    }
    this.setState({ hasError: true, errorCode: code || null });
  };

  /**
   * Возвращает сообщение об ошибке
   */
  private getErrorMessage = () => {
    const { errorCode } = this.state;
    switch (errorCode) {
      case -320700:
        return 'Уважаемый клиент, предлагаем Вам воспользоваться другой банковской картой для получения займа.';
      default:
        return 'Во время выполнения операции произошла ошибка. Приносим свои извинения, наши специалисты уже работают над этой проблемой.';
    }
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { children } = this.props;
    const { hasError } = this.state;
    const errorMessage = this.getErrorMessage();

    return hasError ? (
      <Page>
        <PageContent height="full">
          <Announcement
            img={errorImg}
            alt="Произошла ошибка"
            heading="Произошла ошибка"
            subheading={errorMessage}
            footer={
              <>
                Если проблема не решилась в течение 30 минут, обратитесь,
                пожалуйста, с вопросом в службу поддержки клиентов:{' '}
                <Nowrap>
                  <a href="tel:+74996499059" style={{ color: '#848484' }}>
                    +7 (499) 649-90-59
                  </a>
                </Nowrap>
              </>
            }
          >
            <Button onClick={this.handleClick} variant="filled">
              Попробовать снова
            </Button>
          </Announcement>
        </PageContent>
      </Page>
    ) : (
      <>{children}</>
    );
  }
}
