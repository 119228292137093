import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useEffect,
} from 'react';

import { Button, Announcement } from 'modules/common/ui';
import { LoanService as LoanMetricService } from 'modules/common/metrics';

import { Store } from '../../stores';

import success from './assets/success.png';

/**
 * Свойства компонента.
 */
type Props = Omit<
  ComponentPropsWithoutRef<typeof Announcement>,
  'img' | 'alt' | 'heading'
>;

/**
 * Компонент отображающий "Мин. платёж оплачен" на информационной странице платежей.
 */
export const SuccessAny: FC<Props> = ({ ...props }) => {
  const store = Store.use();
  const loanMetric = LoanMetricService.use();

  useEffect(() => {
    loanMetric.paid();
  }, [loanMetric]);

  const handleClick = useCallback(() => {
    store.goToAccount();
  }, [store]);

  return (
    <Announcement
      {...props}
      img={success}
      alt="Платёж принят"
      heading="Ваш платёж принят!"
    >
      <Button variant="filled" onClick={handleClick}>
        Личный кабинет
      </Button>
    </Announcement>
  );
};
