import { ComponentPropsWithoutRef, FC } from 'react';

import { AmountHelper, PercentHelper } from 'modules/common/values';

import { Root } from './ParamsAlternative.Root';
import { Button } from './ParamsAlternative.Button';
import { Value } from '../Value';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Значение для суммы займа.
   */
  amount?: number;

  /**
   * Заголовок для блока с суммой займа.
   */
  amountTitle?: string;

  /**
   * Значение для процентной ставки.
   */
  rate?: number;

  /**
   * Заголовок для блока с процентной ставкой.
   */
  rateTitle?: string;
};

/**
 * Блок с альтернативными условиями займа.
 */
export const ParamsAlternative: FC<Props> = ({
  amount,
  amountTitle = 'Сумма займа',
  rate,
  rateTitle = 'Ставка, в день',
  ...props
}) => {
  let amountText: string = '';
  let rateText: string = '';

  if (amount != null) {
    const text = AmountHelper.formatDisplayFloat(amount);
    const unit = AmountHelper.getShortUnits();
    amountText = `${text} ${unit}`;
  }

  if (rate != null) {
    const text = PercentHelper.formatDisplayFloat(rate);
    const unit = PercentHelper.getShortUnits();
    rateText = `${text} ${unit}`;
  }

  return (
    <Root {...props}>
      <Value title={amountTitle}>{amountText}</Value>
      <Value title={rateTitle}>{rateText}</Value>
      <Button>Выбрать</Button>
    </Root>
  );
};
