import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

import { Item } from './Menu.Item';

/**
 * Корневой элемент меню шапки.
 */
export const Root = styled.ul`
  display: flex;

  margin: 0 0 0 3rem;
  padding: 0;

  list-style: none;

  ${Item}:first-of-type {
    margin-left: 0;
  }

  @media ${lessThanContainer('medium')} {
    flex-direction: column;

    margin-top: 1.5rem;
    margin-left: 0;
  }
`;
