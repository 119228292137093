import styled from '@emotion/styled';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Тип темы сайта
   */
  layout: 'screen' | 'default';
};

/**
 * Корневой элемент подвала страницы.
 */
export const Root = styled.footer<Props>`
  flex: 0 0 auto;

  font-size: 0.75rem;

  color: ${({ theme }) => theme.colors.light.text.primary};
`;
