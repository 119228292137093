import {
  createContext,
  FC,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

/**
 * Значение дочерней формы.
 */
type FormChildHandle = {
  /**
   * Обработчик отправки формы.
   */
  submit: () => Promise<[Record<any, string> | null, Record<any, any> | null]>;
};

/**
 * Значение контекста.
 */
type Value = {
  /**
   * Регистрирует внутреннюю форму.
   * @param name Уникальное имя формы.
   * @param handler Обработчик отправки формы.
   */
  register: (name: string, handle: FormChildHandle) => void;

  /**
   * Удаляет зарегистрированную форму.
   * @param name Уникальное имя формы.
   */
  unregister: (name: string) => void;

  /**
   * Вызывает отправку всех дочерних форм.
   */
  submit: () => Promise<
    [
      Record<string, Record<string, string>> | null,
      Record<string, Record<string, any>> | null,
    ]
  >;

  /**
   * Указывает, что в данный момент происходит асинхронная валидация элементов
   * формы.
   */
  pending: boolean;
};

/**
 * Контекст формы высшего порядка.
 */
export const FormGroupContext = createContext<Value>({
  register: () => {},
  unregister: () => {},
  submit: () => Promise.resolve([{}, null]),
  pending: false,
});

/**
 * Свойства провайдера.
 */
type Props = {
  /**
   * Обрабатывает успешную отправку форм.
   * @param values Значения полей форм.
   */
  onSubmit?: (values: Record<string, Record<any, any>>) => void;

  /**
   * Обрабатывает ошибку валидации при отправке форм.
   * @param errors Коллекция ошибок валидации.
   */
  onErrors?: (errors: Record<string, Record<any, string>>) => void;
};

/**
 * Объявляет контекст формы высшего порядка.
 */
export const FormGroup: FC<Props> = ({ children, onErrors, onSubmit }) => {
  const [handles, setHandles] = useState<Record<string, FormChildHandle>>({});
  const [pending, setPending] = useState(false);

  const handleRegister = useCallback(
    (name: string, handle: FormChildHandle) =>
      setHandles((oldHandles) => ({
        ...oldHandles,
        [name]: handle,
      })),
    [],
  );

  const handleUnregister = useCallback(
    (name: string) =>
      setHandles(({ [name]: remove, ...oldHandles }) => oldHandles),
    [],
  );

  const handleSubmit = useCallback(async (): Promise<
    [
      Record<string, Record<string, string>> | null,
      Record<string, Record<string, any>> | null,
    ]
  > => {
    const errorsTree: Record<string, Record<any, string>> = {};
    const valuesTree: Record<string, Record<any, any>> = {};

    setPending(true);

    let isError = false;

    await Promise.all(
      Object.keys(handles).map(async (name) => {
        const { submit } = handles[name];

        const [errors, values] = await submit();

        if (errors) {
          errorsTree[name] = errors;
          isError = true;
        }

        if (values) {
          valuesTree[name] = values;
        }
      }),
    );

    if (isError && onErrors) {
      onErrors(errorsTree);
    }

    if (!isError && onSubmit) {
      await onSubmit(valuesTree);
    }

    setPending(false);

    return [isError ? errorsTree : null, !isError ? valuesTree : null];
  }, [handles, onErrors, onSubmit]);

  const value = useMemo<Value>(
    () => ({
      register: handleRegister,
      unregister: handleUnregister,
      submit: handleSubmit,
      pending,
    }),
    [handleRegister, handleUnregister, handleSubmit, pending],
  );

  return (
    <FormGroupContext.Provider value={value}>
      {children}
    </FormGroupContext.Provider>
  );
};

/**
 * Предоставляет доступ к контексту формы высшего порядка.
 */
export const useFormGroup = () => useContext(FormGroupContext);
