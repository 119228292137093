import styled from '@emotion/styled';

/**
 * Заголовок InputGroup
 */
export const Label = styled.div`
  color: ${({ theme }) => theme.colors.light.text.secondary};
  font-size: 0.75rem;

  margin-bottom: 0.125rem;
`;
