import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Params.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Параметр, отвечающий за отображение внутреннего блока.
   * Если выбрано "change", то показывается кнопка "Изменить условия".
   * Если выбрано "alternative", то блок с альтернативными условиями.
   */
  variant?: 'change' | 'alternative';
};

/**
 * Корневой компонент параметров займа.
 */
export const Params: FC<Props> = (props) => <Root {...props} />;
