import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Root } from './List.Root';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Список хлебной крошки.
 */
export const List: FC<Props> = ({ children, ...props }) => {
  return (
    <Root {...props} itemScope itemType="https://schema.org/BreadcrumbList">
      {children}
    </Root>
  );
};
