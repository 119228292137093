import Router from 'next/router';
import { createContext, Component } from 'react';

import { FadeTransition } from 'modules/common/transition';

import { Preloader } from '../Preloader';

/**
 * Значения контекста.
 */
type Value = {
  addAwaiter: () => void;
  removeAwaiter: () => void;
};

/**
 * Контекст отслеживания событий подгрузки данных.
 */
export const PreloaderContext = createContext({
  addAwaiter: () => {},
  removeAwaiter: () => {},
});

/**
 * Состояние компонента.
 */
type State = {
  /**
   * Количество элементов системы, ожидающих загрузку данных.
   */
  pendingAwaiters: number;
};

/**
 * Добавляет в дерево компонентов контекст отслеживания подгрузки данных.
 */
export class PreloaderContextProvider extends Component<{}, State> {
  /**
   * @inheritdoc
   */
  public state: State = {
    pendingAwaiters: 0,
  };

  /**
   * Значение контекста.
   */
  private value: Value = {
    addAwaiter: () => this.handleAddAwaiter(),
    removeAwaiter: () => this.handleRemoveAwaiter(),
  };

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    Router.events.on('routeChangeStart', this.handleAddAwaiter);
    Router.events.on('routeChangeComplete', this.handleRemoveAwaiter);
    Router.events.on('routeChangeError', this.handleRemoveAwaiter);
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount() {
    Router.events.off('routeChangeStart', this.handleAddAwaiter);
    Router.events.off('routeChangeComplete', this.handleRemoveAwaiter);
    Router.events.off('routeChangeError', this.handleRemoveAwaiter);
  }

  /**
   * Обрабатывает событие добавления компонента, находящегося в стадии загрузки.
   */
  private handleAddAwaiter = () =>
    this.setState(({ pendingAwaiters }) => ({
      pendingAwaiters: pendingAwaiters + 1,
    }));

  /**
   * Обрабатывает событие удаления компонента, находящегося в стадии загрузки.
   */
  private handleRemoveAwaiter = () =>
    this.setState(({ pendingAwaiters }) => ({
      pendingAwaiters: pendingAwaiters === 1 ? 0 : pendingAwaiters - 1,
    }));

  /**
   * @inheritdoc
   */
  render() {
    const { children } = this.props;
    const { pendingAwaiters } = this.state;

    const isPending = pendingAwaiters > 0;

    return (
      <PreloaderContext.Provider value={this.value}>
        <FadeTransition in={isPending}>
          <Preloader />
        </FadeTransition>
        {children}
      </PreloaderContext.Provider>
    );
  }
}
