import styled from '@emotion/styled';

/**
 * Надзаголовок.
 */
export const OverHead = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  padding-bottom: 0.5rem;
`;
