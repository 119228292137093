import { ComponentType, FC } from 'react';
import { useFormikContext, getIn } from 'formik';

/**
 * Свойства дочернего компонента.
 */
type InnerProps = {
  /**
   * Уникальное имя поля ввода в рамках формы.
   */
  name?: string;

  /**
   * Указывает на то, что поле ввода было провалидировано и не прошло
   * валидацию. Если свойство равно `undefined`, то валидация поля ввода
   * ещё не проводилась.
   */
  invalid?: boolean;

  /**
   * Указывает, что поле ввода успешно прошло валидацию. Если свойство равно
   * `undefined`, то валидация поля ввода ещё не проводилась.
   */
  valid?: boolean;
};

/**
 * Свойства итогового компонента.
 */
type OuterProps<P extends InnerProps> = P;

/**
 * Дополняет коллекцию свойств поля ввода свойствами, которые отражают состояние
 * валидации его значения.
 */
export type WithFormValidProps<P extends {}> = Omit<P, keyof InnerProps> &
  InnerProps;

/**
 * Возвращает обёртку над указанным компонентом поля ввода. Она извлекает
 * состояние валидации данного поля ввода из контекста формы и пробрасывает
 * в указанный компонент значения свойств `invalid` и `valid`.
 * @param Target Компонент поля ввода.
 */
export const withFormValid = <P extends InnerProps>(
  Target: ComponentType<P>,
) => {
  const WithFormValid: FC<OuterProps<P>> = ({
    invalid: propsInvalid,
    valid: propsValid,
    name,
    ...props
  }) => {
    const formContext = useFormikContext<Record<string, any>>();

    let invalid = propsInvalid;
    let valid = propsValid;

    if (typeof name === 'string') {
      const touched = getIn(formContext.touched, name);
      const value = getIn(formContext.values, name);
      const error = getIn(formContext.errors, name);

      const isSubmitted = formContext.submitCount > 0;
      const isTouched = Boolean(touched);
      const isValue = value != null && value !== '';
      const isError = Boolean(error);

      const isShowed = isSubmitted || isTouched;

      invalid = invalid ?? (isShowed ? isError : undefined);
      valid = valid ?? (isShowed && isValue ? !isError : undefined);
    }

    return (
      <Target {...(props as P)} name={name} invalid={invalid} valid={valid} />
    );
  };

  WithFormValid.displayName = `WithFormValid
  (${Target.displayName || Target.name})`;

  return WithFormValid;
};
