import styled from '@emotion/styled';

import { transition } from 'modules/common/theme';

import { Icon as Base } from './Icon';

export const Icon = styled(Base)`
  position: relative;
  z-index: 1;

  transition: ${transition('visibility', 'opacity')};

  visibility: hidden;
  opacity: 0;

  color: ${({ theme }) => theme.colors.light.input.border.focus};
`;
