import styled from '@emotion/styled';

/**
 * Описание.
 */
export const Text = styled.span`
  grid-area: description;

  font-size: 0.75rem;
  text-align: left;

  color: ${({ theme }) => theme.colors.light.text.secondary};
`;
