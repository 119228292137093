import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Тело элемента Item.
 */
export const Body = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 1rem;

  @media ${lessThanContainer('small')} {
    flex-direction: column;
  }
`;
