import { defaultMiddleware } from './defaultMiddleware';
import { BadResponseError } from './errors/BadResponseError';
import { ServiceUnavailableError } from './errors/ServiceUnavailableError';
import { Middleware, MiddlewareContext } from './middleware/Middleware';
import { AuthTokens } from './types/AuthTokens';

/**
 * Идентификатор блокировки обновления токенов сессии.
 */
const REFRESH_TOKENS_LOCK = 'REFRESH_TOKENS';

/**
 * Связь событий с типом данных, которые вещаются этим событием.
 */
interface BaseServiceEventMap {
  /**
   * Событие автоматического обновления токенов сервисом.
   */
  refreshToken: AuthTokens;

  /**
   * Событие возникновения ошибки.
   */
  error: unknown;
}

/**
 * Тип события, которое вещает сервис.
 */
type BaseServiceEvent = keyof BaseServiceEventMap;

/**
 * Обработчик события, которое вещает сервис согласно типу.
 */
type BaseServiceEventHandler<TEvent extends BaseServiceEvent> = (
  /**
   * Данные события.
   */
  data: BaseServiceEventMap[TEvent],
) => void;

/**
 * Параметры инициализации сервиса.
 */
export type BaseServiceOptions = {
  /**
   * Коллекция промежуточных обработчиков данных между транспортом и методом.
   */
  middleware?: Middleware[];

  /**
   * Дополнительные заголовки.
   */
  headers?: Headers;

  /**
   * Инфморация об обновлении токена аутентификации.
   */
  refreshTokenMethod: {
    /**
     * Метод JSON RPC для вызова.
     */
    method: string;

    /**
     * Функция подготовки данных для отправки на сервер.
     * @param refreshToken Токен обновления сессии.
     */
    dataMapper: (refreshToken: string) => Record<string, string>;

    /**
     * Функция получения токенов из ответа сервера.
     * @param response Ответ сервера в формате JSON.
     */
    resultMapper: (response: any) => AuthTokens;

    /**
     * Функция получения даты обновления JWT из его публичной части.
     * @param jwtPublic Публичные данные JWT.
     */
    getExpiration: (jwtPublic: unknown) => Date | undefined;
  };
};

/**
 * Сервис, который предоставляет методы для взаимодействия с JSON RPC.
 */
export abstract class BaseService {
  /**
   * URL конечной точки RPC API.
   */
  private readonly apiRoot: string;

  /**
   * Параметры инициализации сервиса.
   */
  private options: Readonly<BaseServiceOptions>;

  /**
   * Хранилище подписок на события сервиса.
   */
  private subscriptions: Readonly<Record<string, Function[]>> = {};

  /**
   * Токены сессии.
   */
  private session: Readonly<AuthTokens | undefined>;

  /**
   * Карта глобальных блокировок.
   */
  private locks: Readonly<Record<string, Promise<any>>> = {};

  /**
   * Уникальный идентификатор запроса. Требуется для передачи в RPC API.
   */
  private requestID: number = 0;

  /**
   * Коллекция промежуточных обработчиков данных между транспортом и методом.
   */
  private get middleware(): Middleware[] {
    return [...defaultMiddleware, ...(this.options.middleware ?? [])];
  }

  /**
   * Создаёт экземпляр сервиса взаимодействия с JSON RPC.
   * @param apiRoot URL конечной точки.
   * @param options Параметры сервиса RPC.
   * @param session Токены аутентификации и обновления сессии.
   */
  constructor(
    apiRoot: string,
    options: BaseServiceOptions,
    session?: AuthTokens,
  ) {
    this.apiRoot = apiRoot;
    this.session = session;
    this.options = options;
  }

  /**
   * Подписывает слушателя (listener) на событие (event)
   * @param event Тип события.
   * @param listener Функция слушателя.
   */
  public on<TEvent extends BaseServiceEvent>(
    event: TEvent,
    handler: BaseServiceEventHandler<TEvent>,
  ) {
    if (!this.subscriptions[event]) {
      this.subscriptions = {
        ...this.subscriptions,
        [event]: [],
      };
    }

    this.subscriptions[event].push(handler);
  }

  /**
   * Отписывает слушателя (listener) от события определенного типа (event)
   * @param event Тип события.
   * @param listener Функция слушателя.
   */
  public off<TEvent extends BaseServiceEvent>(
    event: TEvent,
    handler: BaseServiceEventHandler<TEvent>,
  ) {
    if (!this.subscriptions[event]) {
      return;
    }

    const { [event]: listeners, ...subscriptions } = this.subscriptions;

    this.subscriptions = {
      ...subscriptions,
      [event]: listeners.filter((listener) => listener !== handler),
    };
  }

  /**
   * Задаёт опции базового сервиса.
   * @param options Опции базового сервиса.
   */
  public setOptions(options: BaseServiceOptions) {
    this.options = options;
  }

  /**
   * Задаёт токены сессии.
   */
  public async authorize(session: AuthTokens): Promise<void> {
    await this.setLock(REFRESH_TOKENS_LOCK, async () => {});
    this.session = session;
  }

  /**
   * Завершает сессию, удаляя токены.
   */
  public async deauthorize() {
    await this.setLock(REFRESH_TOKENS_LOCK, async () => {});
    this.session = undefined;
  }

  /**
   * Генерирует новый идентификатор запроса.
   */
  private generateRequestID() {
    this.requestID += 1;

    return this.requestID;
  }

  /**
   * Расшифровывает публичную часть JWT.
   * @param token Токен для расшифровки.
   */
  private parseJwt(token: string): unknown {
    try {
      const [, base64Url] = token.split('.');
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      return undefined;
    }
  }

  /**
   * Вызывает указанынй метод RPC с переданными параметрами и дополнительными
   * заголовками. Возвращает результат выполнения метода.
   * @param method Метод RPC.
   * @param params Передаваемые данные.
   * @param ignoreRenewal Отключение функции обновления токенов.
   */
  protected async request<TResult = any>(
    method: string,
    params: Record<string, any> = {},
    ignoreRenewal = false,
  ) {
    try {
      const requestHeaders = new Headers(this.options.headers);

      requestHeaders.set('Content-Type', 'application/json');
      requestHeaders.set('Accept', 'application/json');

      if (!ignoreRenewal) {
        const session = await this.setLock(REFRESH_TOKENS_LOCK, () =>
          this.checkAndRefreshTokens(),
        );

        if (session) {
          requestHeaders.set('Authorization', `Bearer ${session.accessToken}`);
        }
      }

      const requestInit: RequestInit = {
        headers: requestHeaders,
        method: 'post',
        body: JSON.stringify({
          method,
          params,
          id: this.generateRequestID(),
          jsonrpc: '2.0',
        }),
      };

      let response: Response;
      try {
        response = await fetch(this.apiRoot, requestInit);
      } catch (e) {
        throw new ServiceUnavailableError(e);
      }

      let json: any;
      try {
        json = await response.json();
      } catch (e) {
        throw new BadResponseError(e);
      }

      const middlewareContext: MiddlewareContext = {
        request: requestInit,
        response,
        json,
        lock: (lockID, handler) => this.setLock(lockID, handler),
      };

      for (const middleware of this.middleware) {
        await middleware(middlewareContext);
      }

      const { result } = json;

      return result as TResult;
    } catch (error) {
      this.emit('error', error);
      throw error;
    }
  }

  /**
   * Проверяет TTL токена сессии, блокирует выполнение дальнейших запросов,
   * и возвращает обновлённые токены сессии.
   */
  private async checkAndRefreshTokens() {
    const { method, dataMapper, resultMapper, getExpiration } =
      this.options.refreshTokenMethod;

    if (!this.session) {
      return undefined;
    }

    const { accessToken, refreshToken } = this.session;

    const accessJwtPublic = this.parseJwt(accessToken);
    const refreshJwtPublic = this.parseJwt(refreshToken);
    const expiration =
      getExpiration(accessJwtPublic) ?? getExpiration(refreshJwtPublic);
    const now = new Date();

    if (expiration && expiration < now && undefined !== refreshToken) {
      const response = await this.request<AuthTokens>(
        method,
        dataMapper(refreshToken),
        true,
      );

      this.session = resultMapper(response);
      this.emit('refreshToken', this.session);

      return this.session;
    }

    return this.session;
  }

  /**
   * Устанавливает глобальную блокировку вызовов по заданному идентификатору.
   * @param lockID Идентификатор блокировки.
   * @param handler Ожидаемый обработчик, заверешение которого продолжит выполнение запроса.
   */
  private async setLock<THandlerValue = unknown>(
    lockID: string,
    handler: () => Promise<THandlerValue>,
  ): Promise<THandlerValue> {
    if (!(lockID in this.locks)) {
      const awaiter = handler();

      this.locks = {
        ...this.locks,
        [lockID]: awaiter,
      };

      // Сброс блокировки происходит при завершении первого вызова.
      let result: THandlerValue;
      try {
        result = await awaiter;
      } catch (e) {
        throw e;
      } finally {
        const { [lockID]: oldLock, ...restLocks } = this.locks;

        this.locks = restLocks;
      }

      return result;
    }

    return this.locks[lockID];
  }

  /**
   * Производит вещание события с соответствующим набором данных.
   * @param event Тип события.
   * @param data Данные события.
   */
  private emit<TEvent extends BaseServiceEvent>(
    event: TEvent,
    data: BaseServiceEventMap[TEvent],
  ) {
    const handlers = this.subscriptions[event] ?? [];

    handlers.forEach((handler) => handler(data));
  }
}
