import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент значения детали калькулятора.
 */
export const Value = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-left: 30px;

  @media ${lessThanContainer('medium')} and (orientation: landscape) {
    font-size: 20px;
    line-height: 24px;
  }
`;
