import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент отображающий вариант платежа.
 */
export const Root = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;

  :first-of-type {
    margin-right: 0.5rem;
  }

  @media ${lessThanContainer('small')} {
    flex: 1;
    flex-direction: row;
    margin-bottom: 1rem;

    :first-of-type {
      margin-right: 0;
    }
    :last-of-type {
      margin-bottom: 0;
    }
  }
`;
