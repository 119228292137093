import { Helper as Base } from '@devim-front/helper';

import { Query } from '../types';
import { Page } from '../consts';

/**
 * Содержит методы для работы с URL.
 */
export class Helper extends Base {
  /**
   * Преобразует коллекцию параметров запроса в строку (без ведущего '?').
   * @param query Коллекция параметров запроса.
   */
  public static stringifyQuery(query: Query) {
    let result = '';

    const fields = Object.keys(query);
    const { length } = fields;

    for (let i = 0; i < length; i += 1) {
      const field = fields[i];
      const value = query[field];

      const title = encodeURIComponent(field);

      if (Array.isArray(value)) {
        const { length: itemsCount } = value;

        for (let j = 0; j < itemsCount; j += 1) {
          const separator = i === 0 && j === 0 ? '' : '&';
          result += `${separator}${title}[]`;

          const item = value[j] ? encodeURIComponent(value[j]) : undefined;

          if (item) {
            result += `=${item}`;
          }
        }
      } else {
        const separator = i > 0 ? '&' : '';
        result += `${separator}${title}`;

        const item = value ? encodeURIComponent(value) : undefined;

        if (item) {
          result += `=${encodeURIComponent(item)}`;
        }
      }
    }

    return result;
  }

  /**
   * Обединяет путь и коллекцию параметров запроса в URL.
   * @param pathname Путь в адресе.
   * @param query Коллекция значений запроса в адресе.
   * @returns Строка.
   */
  public static combineUrl(pathname: string, query: Query = {}) {
    let result = pathname;

    const search = this.stringifyQuery(query);

    if (search) {
      result += `?${search}`;
    }

    return result;
  }

  /**
   * Проверяет, является ли страница публичной.
   * @param pathname Страница.
   */
  public static isPublicPage(pathname: string) {
    const pages: string[] = [Page.HOME, Page.ABOUT, Page.DOCUMENTS];
    return pages.includes(pathname);
  }
}
