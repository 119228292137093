import styled from '@emotion/styled';

/**
 * Подвал страницы Repay.
 */
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin-top: 2rem;
  padding-top: 2rem;

  border-top: 1px solid ${({ theme }) => theme.colors.light.separator};
`;
