export enum Direction {
  Right = 'to right',
  Left = 'to left',
  Down = 'to bottom',
  Up = 'to top',
}

export interface ITrackBackground {
  min: number;
  max: number;
  values: number[];
  colors: string[];
  direction?: Direction;
}

export function getTrackBackground({
  values,
  colors,
  min,
  max,
  direction,
}: ITrackBackground) {
  // sort values ascending
  const progress = values
    .slice(0)
    .sort((a, b) => a - b)
    .map((value) => ((value - min) / (max - min)) * 100);

  const middle = progress.reduce(
    (acc, point, index) =>
      `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    '',
  );
  return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${
    colors[colors.length - 1]
  } 100%)`;
}

export const getLastPaymentDate = (period: number) => {
  const date = new Date(Date.now());
  date.setDate(date.getDate() + period + 1);
  const day = date.getUTCDate();
  const displayDay = day < 10 ? `0${day}` : day;
  const month = date.getUTCMonth() + 1;
  const displayMonth = month < 10 ? `0${month}` : month;

  return `${displayDay}.${displayMonth}`;
};
