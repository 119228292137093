import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент Params.
 */
export const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;

  margin-bottom: 1.25rem;

  @media ${lessThanContainer('small')} {
    grid-template-columns: 1fr;
  }
`;
