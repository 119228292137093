import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент Step.
 */
export const Root = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.light.text.secondary};

  @media ${lessThanContainer('small')} {
    font-size: 0.75rem;
  }
`;
