import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Элемент отображающий информацию о варианте платежа.
 */
export const Content = styled.div`
  font-size: 1rem;
  font-weight: bold;

  color: ${({ theme }) => theme.colors.light.text.primary};

  margin-top: 0.5rem;

  @media ${lessThanContainer('small')} {
    font-size: 0.75rem;

    margin-top: 0rem;
  }
`;
