import { ComponentPropsWithoutRef, FC } from 'react';

import { withContainerStyle } from '../../hocs';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<'div'>;

/**
 * Отображает компонент контейнера, который имеет фиксированный размер и центрует своё содержимое.
 */
const Container: FC<Props> = ({ ...props }) => <div {...props} />;

const component = withContainerStyle(Container);
export { component as Container };
