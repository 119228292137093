import { PaymentData } from '../types/PaymentData';
import { transformLoan } from './transformLoan';

/**
 * Преобразует ответ сервера в данные об оплате займа.
 * @param data Ответ сервера.
 */
export function transformPaymentData(data: any): PaymentData {
  return {
    ...transformLoan(data),
    source: data.source,
    token: data.token,
  };
}
