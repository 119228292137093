import { CustomError } from '@devim-front/error';

/**
 * Вызывается, когда пользователь ввёл неверный код подтверждения операции.
 */
export class InvalidCodeError extends CustomError {
  /**
   * @inheritdoc
   */
  public constructor() {
    super('ConfirmationError', 'Invalid confirmation code');
  }
}
