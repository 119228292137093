import { ComponentPropsWithoutRef, FC } from 'react';

import { Container } from 'modules/common/ui';

import { useLayout } from '../LayoutContext';

import { Root } from './Header.Root';
import { Nav } from './Header.Nav';
import { Toggler } from './Toggler';
import { Logo } from './Logo';
import { SideNav } from './SideNav';
import { Menu } from './Menu';
import { Account } from './Account';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Root>, 'layout'>;

/**
 * Отображение шапки на странице.
 */
export const Header: FC<Props> = ({ ...props }) => {
  const { layout } = useLayout();

  return (
    <Root {...props} layout={layout}>
      <Container>
        <Nav>
          <Toggler />
          <Logo />
          <SideNav>
            <Menu />
          </SideNav>
          <Account />
        </Nav>
      </Container>
    </Root>
  );
};
