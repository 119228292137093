import styled from '@emotion/styled';
import {
  Component,
  ComponentPropsWithoutRef,
  KeyboardEvent,
  FocusEvent,
  createRef,
} from 'react';

import { appendAdornments } from '../../functions';
import { withFormControl, WithFormValidProps, withFormValid } from '../../hocs';

import { Dropdown } from './Dropdown';
import { Options } from './Options';
import { Spinner } from './Spinner';
import { Group } from './Group';
import { Caret } from './Caret';
import { Input } from './Input';

/**
 * Свойства компонента списка опций.
 */
type OptionsProps = ComponentPropsWithoutRef<typeof Options>;

/**
 * Свойства компонента.
 */
type Props = WithFormValidProps<
  Omit<
    ComponentPropsWithoutRef<typeof Input>,
    'value' | 'onChange' | 'onBlur' | 'defaultValue' | 'autoComplete'
  > & {
    /**
     * Выбранное в данный момент значение.
     */
    value?: any;

    /**
     * Указывает, что перед списком вариантов для выбора у поля должен быть
     * один служебный вариант, сбрасывающий содержимое поля ввода. В данное
     * свойство можно передать текст этого пункта или просто флаг наличия
     * такого варианта.
     */
    reset?: boolean | string;

    /**
     * Обрабатывает событие выбора нового значения.
     * @param value Новое значение или `undefined`, если содержимое поля ввода
     * было очищено.
     */
    onChange?: (value?: any) => void;

    /**
     * Обрабатывает событие потери компонентом фокуса.
     */
    onBlur?: (event: FocusEvent<any>) => void;

    /**
     * Список значений, которые доступны для выбора в данном списке, или
     * функция, которая его возвращает.
     */
    values?: OptionsProps['values'];

    /**
     * Функция, которая на основе переданного значения генерирует содержимое
     * элемента соответствующего ему элемента выпадающего списка.
     * @param value Значение.
     */
    format?: OptionsProps['format'];

    /**
     * Функция, которая на основе переданного ей значения генерирует его
     * уникальный ключ. Этот ключ используется для сравнения значений и для
     * их индексации в списке вариантов.
     * @param value Значение.
     */
    unique?: OptionsProps['unique'];

    /**
     * Указывает, что для пустой поисковой строки функцию загрузки вариантов
     * вызвать тоже следует.
     * @default false
     */
    allowEmpty?: OptionsProps['allowEmpty'];

    /**
     * Задаёт минимальную длину поисковой строки, после которой начинается
     * загрузка вариантов.
     * @default 3
     */
    minLength?: OptionsProps['minLength'];

    /**
     * Указывает, что когда пользователь не выбрал ни один из вариантов, но
     * при этом ввёл в поле ввода какой-либо текст, следует попытаться найти
     * среди вариантов наиболее подходящий и выбрать его. В противном случае,
     * для выбора значения под такой случай будет использоваться значение
     * свойства `fallback`.
     *
     * @default false
     */
    valuesOnly?: boolean;

    /**
     * Если пользователь не выбрал ни один из вариантов, но при этом ввёл в поле
     * ввода какой-то текст, нужно решить, что с этим текстом делать. Если
     * флаг `valuesOnly` выставлен, то компонент попробует найти среди доступных
     * вариантов наиболее подходящий, и выбрать его. Если же флаг не задан,
     * то вызывается данная функция.
     *
     * Она принимает на вход текущее содержимое поле ввода и возвращает
     * значение, которому этот текст должен соответствовать.
     *
     * @default `(search) => search`
     */
    fallback?: 'clear' | ((search: string) => any);
  }
>;

/**
 * Список значений выпадающего списка по умолчанию.
 */
const defaultValues: any[] = [];

/**
 * Функция, которая генерирует текст элемента выпадающего списка по
 * умолчанию.
 * @param value Значение.
 */
const defaultFormat = (value: any) => String(value);

/**
 * Функция, которая генерирует уникальный ключ значения по умолчанию.
 * @param value Значение.
 */
const defaultUnique = (value: any) => String(value);

/**
 * Функция, которая преобразует текст, введённый в поле ввода, в текущее
 * значение компонента.
 */
const defaultFallback = (search: string) => search;

/**
 * Состояние компонента.
 */
type State = {
  /**
   * Указывает, должно ли в данный момент быть видимо всплывающее меню.
   */
  isDropdown: boolean;

  /**
   * Указывает, что в данный момент происходит асинхронная генерация списка
   * вариантов.
   */
  isPending: boolean;

  /**
   * Текущее значение поля ввода текста.
   */
  search: string;
};

/**
 * Отображает выпадающий список с возможностью динамической генерации
 * вариантов.
 */
class Select extends Component<Props, State> {
  /**
   * Ссылка на элемент списка вариантов для выбора.
   */
  private optionsRef = createRef<Options>();

  /**
   * Ссылка на корневой элемент группы.
   */
  private groupRef = createRef<Group>();

  /**
   * @inheritdoc
   */
  public state: State = {
    isDropdown: false,
    isPending: false,
    search: '',
  };

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    const { value, format = defaultFormat } = this.props;

    if (value != null) {
      const search = value != null ? format(value) : '';
      this.setState({ search });
    }
  }

  /**
   * Обрабатывает изменение состояния процесса асинхронной генерации
   * списка вариантов.
   * @param isPending Значение флага.
   */
  private handlePendingChange = (isPending: boolean) => {
    this.setState({ isPending });
  };

  /**
   * Обрабатывает изменение значения текстового поля.
   * @param value Значение поля ввода.
   */
  private handleInputChange = (value: string) => {
    this.setState({ search: value });

    if (value) {
      return;
    }

    this.setState({ isDropdown: true });

    const { onChange } = this.props;

    if (onChange) {
      onChange(undefined);
    }
  };

  /**
   * Обрабатывает нажатие на поле ввода.
   */
  private handleInputClick = () => {
    const { isDropdown } = this.state;
    const { readOnly } = this.props;

    if (readOnly) {
      this.setState({ isDropdown: !isDropdown });
      return;
    }

    if (!isDropdown) {
      this.setState({ isDropdown: true });
    }
  };

  /**
   * Обрабатывает событие нажатия на клавишу в поле ввода текста.
   * @param event Событие.
   */
  private handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { shiftKey, key } = event;
    const { isDropdown } = this.state;
    const { readOnly } = this.props;

    if (shiftKey) {
      return;
    }

    if (!isDropdown) {
      const whitelist = [
        'ArrowRight',
        'ArrowLeft',
        'ArrowDown',
        'ArrowUp',
        'Home',
        'End',
        'PageDown',
        'PageUp',
        readOnly && ' ',
        'Enter',
      ].filter(Boolean);

      if (whitelist.includes(key)) {
        event.preventDefault();
        this.setState({ isDropdown: true });
      }

      return;
    }

    const { current } = this.optionsRef;

    if (current == null) {
      throw new Error(`Expected "this.optionsRef.current" to be defined`);
    }

    if (current.pending) {
      const whitelist = ['Enter', readOnly && ' '].filter(Boolean);

      if (whitelist.includes(key)) {
        event.preventDefault();
        this.setState({ isDropdown: false });
      }

      return;
    }

    switch (key) {
      case ' ': {
        if (readOnly) {
          event.preventDefault();
          current.choose();
        }
        break;
      }
      case 'Enter': {
        event.preventDefault();
        current.choose();
        break;
      }
      case 'ArrowRight': {
        if (readOnly) {
          event.preventDefault();
          current.highlightLast();
        }
        break;
      }
      case 'ArrowLeft': {
        if (readOnly) {
          event.preventDefault();
          current.highlightFirst();
        }
        break;
      }
      case 'Home': {
        if (readOnly) {
          event.preventDefault();
          current.highlightFirst();
        }
        break;
      }
      case 'End': {
        if (readOnly) {
          event.preventDefault();
          current.highlightLast();
        }
        break;
      }
      case 'ArrowDown': {
        event.preventDefault();
        current.highlightNext();
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        current.highlightPrevious();
        break;
      }
      case 'PageDown': {
        event.preventDefault();
        current.highlightLast();
        break;
      }
      case 'PageUp': {
        event.preventDefault();
        current.highlightFirst();
        break;
      }
      default: {
        break;
      }
    }
  };

  /**
   * Обрабатывает событие нажатия любой клавиши мыши вне выпадающей области,
   * пока она активна.
   * @param event Событие.
   */
  private handleOuterClick = (event: MouseEvent) => {
    event.preventDefault();
    this.setState({ isDropdown: false });
  };

  /**
   * Обрабатывает выбор одного из предложенных значений.
   * @param value Новое значение.
   */
  private handleOptionsChange = (value?: any) => {
    const { onChange, format = defaultFormat } = this.props;

    this.setState({ isDropdown: false });

    if (onChange) {
      onChange(value);
    }

    const search = value != null ? format(value) : '';
    this.setState({ search });

    const { current } = this.groupRef;

    if (current) {
      current.focusInput();
    }
  };

  /**
   * Обрабатывает фокус на группе элементов компонента.
   */
  private handleGroupFocus = () => {
    const { format = defaultFormat, value } = this.props;

    const search = value != null ? format(value) : '';
    this.setState({ search });
  };

  /**
   * Обрабатывает потерю фокуса всей группой элементов.
   * event Событие потери фокуса.
   */
  private handleGroupBlur = async (event: FocusEvent<any>) => {
    this.setState({ isDropdown: false });

    const {
      onChange,
      onBlur,
      valuesOnly = false,
      fallback = defaultFallback,
      format = defaultFormat,
      readOnly,
      value,
    } = this.props;

    const change = async (nextValue: any) => {
      const nextSearch = nextValue != null ? format(nextValue) : '';
      this.setState({ search: nextSearch });

      if (onChange) {
        onChange(nextValue ?? undefined);
        await Promise.resolve();
      }
    };

    const blur = () => {
      if (onBlur) {
        onBlur(event);
      }
    };

    if (readOnly) {
      blur();
      return;
    }

    const propsSearch = value != null ? format(value) : '';
    const { search: stateSearch } = this.state;

    if (propsSearch === stateSearch) {
      blur();
      return;
    }

    if (valuesOnly) {
      const { current } = this.optionsRef;

      if (current == null) {
        throw new Error(`Expected "this.optionsRef.current" to be defined`);
      }

      const nextValue = current.pending
        ? undefined
        : current.getDefault(stateSearch);

      await change(nextValue);

      blur();
      return;
    }

    const nextValue = fallback === 'clear' ? undefined : fallback(stateSearch);
    await change(nextValue);

    blur();
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { isDropdown, isPending, search } = this.state;
    const {
      onKeyDown,
      onChange,
      onBlur,
      fallback,
      format = defaultFormat,
      unique = defaultUnique,
      values = defaultValues,
      allowEmpty = false,
      valuesOnly,
      minLength = 4,
      endAdornment,
      placeholder,
      className,
      disabled,
      readOnly,
      value,
      reset,
      ...props
    } = this.props;

    const isFinalDropdown = !disabled && isDropdown;

    const options = (
      <Options
        onPendingChange={this.handlePendingChange}
        onChange={this.handleOptionsChange}
        allowValues={isFinalDropdown}
        allowEmpty={allowEmpty}
        minLength={minLength}
        values={values}
        search={search}
        format={format}
        unique={unique}
        value={value}
        reset={reset}
        ref={this.optionsRef}
      />
    );

    let adornment = endAdornment;

    if (isPending && isFinalDropdown) {
      adornment = appendAdornments(adornment, <Spinner />);
    }

    if (readOnly) {
      adornment = appendAdornments(
        adornment,
        <Caret active={isFinalDropdown} />,
      );
    }

    let nextPlaceholder = placeholder;

    if (readOnly && nextPlaceholder == null) {
      nextPlaceholder = 'Выберите вариант';
    }

    let inputValue: string = search;

    if (readOnly) {
      inputValue = value != null ? format(value) : '';
    }

    return (
      <Group
        onFocus={this.handleGroupFocus}
        onBlur={this.handleGroupBlur}
        className={className}
        ref={this.groupRef}
      >
        <Dropdown
          onOuterClick={this.handleOuterClick}
          active={isFinalDropdown}
          content={options}
        >
          <Input
            {...props}
            autoComplete="off"
            onKeyDown={this.handleInputKeyDown}
            onChange={this.handleInputChange}
            onClick={this.handleInputClick}
            endAdornment={adornment}
            placeholder={nextPlaceholder}
            readOnly={readOnly}
            disabled={disabled}
            value={inputValue}
          />
        </Dropdown>
      </Group>
    );
  }
}

const component = styled(withFormValid(withFormControl(undefined)(Select)))``;
export { component as Select };
