import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { PageLoading } from 'modules/common/layout';
import { Store as RestructureStore } from 'modules/restructure';
import { CardsStore } from '../../payout/CardsStore';

import { Store } from '../Store';

/**
 * Отображает своё содержимое только тогда, когда сведения о займе будут
 * загружены с сервера. До тех пор отображает компонент загрузки страницы
 * `common.layout.PageLoading`.
 */
const Provider: FC = ({ children }) => {
  const store = Store.use();
  const restructure = RestructureStore.use();
  const cardsStore = CardsStore.use();

  useEffect(() => {
    store.touch();
    restructure.touch();
    cardsStore.touch();
  });

  if (!store.ready || !restructure.ready || !cardsStore.ready) {
    return <PageLoading />;
  }

  return <>{children}</>;
};

const component = observer(Provider);
export { component as Provider };
