/**
 * Содержит методы для работы с UI
 */
export class Helper {
  public static isOTPSupported(): boolean {
    const agent = window.navigator.userAgent;
    const start = agent.indexOf('OS ');

    if (
      (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
      start > -1
    ) {
      return Number(agent.substr(start + 3, 3).replace('_', '.')) >= 12;
    }
    return false;
  }
}
