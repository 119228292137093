import {
  FC,
  ComponentPropsWithoutRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';

import { FormGroup } from 'modules/common/ui';
import { Store as InnStore, Alert } from 'modules/inn';

import { Store } from '../../stores';

import { NavigationTop } from './NavigationTop';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof FormGroup> & {
  /**
   * Номер шага заявки.
   */
  step: number;
};

/**
 * Объединяет все вложенные формы в группу и добавляет под них кнопки
 * навигации по шагам заявки.
 */
const StepForms: FC<Props> = ({ onSubmit, children, step, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const store = Store.use();
  const { isExists, pending } = InnStore.use();

  useEffect(() => {
    if (!isExists && !pending) {
      setIsOpen(true);
    }
  }, [isExists, pending]);

  const handleSubmit = useCallback(
    async (forms: any) => {
      if (store.pending) {
        return;
      }

      await store.submitStep(step);

      if (onSubmit) {
        onSubmit(forms);
      }
    },
    [onSubmit, store, step],
  );

  return (
    <FormGroup {...props} onSubmit={handleSubmit}>
      {isExists === false && isOpen ? (
        <Alert onClick={() => setIsOpen(false)} />
      ) : (
        <>
          <NavigationTop step={step} />
          {children}
        </>
      )}
    </FormGroup>
  );
};

const component = observer(StepForms);
export { component as StepForms };
