import styled from '@emotion/styled';

import { PageContent as Base } from 'modules/common/layout/components/PageContent';

/**
 * Компонент обёртки основного содержимого шага заявки. Добавляется на 2 и 3
 * шаге заявки. Содержит отступы согласно дизайну.
 */
export const PageContent = styled(Base)`
  padding-top: 1.25rem;
`;
