import styled from '@emotion/styled';
import { Container as Element } from 'modules/common/ui';

/**
 * Контейнер страницы дашборд.
 */
export const Container = styled(Element)`
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
`;
