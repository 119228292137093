import styled from '@emotion/styled';

/**
 * Корневой элемент Info.
 */
export const Root = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 4rem;
`;
