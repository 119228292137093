import { LazyStore, reactive } from '@devim-front/store';
import { observable, computed, action } from 'mobx';

import { LimitExceededError, InvalidCodeError } from 'modules/confirmation';
import { Page, Store as RoutingStore } from 'modules/common/routing';
import { Prevent } from 'modules/confirmation';
import { applyFetchable } from 'modules/common/stores';
import { CompatService } from 'services/CompatService';
import { Service as RoutingService } from 'modules/common/routing';
import { OptionType } from 'services/RpcService/types/OptionType';
import { Option } from 'services/RpcService/types/Option';
import { RpcError } from 'services/RpcService/errors/RpcError';
import { RpcErrorCode } from 'services/RpcService/types/RpcErrorCode';
import { Document } from 'services/RpcService/types/Document';

/**
 * Хранилище состояния подписания договора на реструктуризацию.
 */
@reactive
export class Store extends applyFetchable(LazyStore) {
  /**
   * Модель реструктуризации или undefined,
   * если текущему пользователю реструктуризация недоступна.
   */
  @observable
  public model?: Option<OptionType.Restructuring> = undefined;

  /**
   * Список документов, если текущему пользователю доступна реструктуризация.
   */
  @observable
  public documents: Document[] = [];

  /**
   * Показывался ли промо-экран юзеру
   */
  @observable
  public isScreenShowed: Boolean = false;

  /**
   * Функция, которая вызывает валидацию формы согласия с документами.
   */
  private validate?: () => Promise<boolean> = undefined;

  /**
   * Указывает, доступна ли текущему пользователю реструктуризация.
   */
  @computed
  public get isAvailable() {
    return Boolean(this.model);
  }

  /**
   * Экземпляр сервиса RPC API.
   */
  private get rpc() {
    return CompatService.get(this).rpcService;
  }

  /**
   * Экземпляр сервиса маршрутизатора.
   */
  private get routingService() {
    return RoutingService.get(this);
  }

  /**
   * Ссылка на экземпляр сервиса маршрутизации.
   */
  private get routing() {
    return RoutingStore.get(this);
  }

  /**
   * Перенаправляет пользователя на страницу займа.
   */
  public async navigateToLoan() {
    await this.routing.push(Page.LOAN);
  }

  /**
   * Метод для загрузки данных модели.
   */
  protected async fetch() {
    let documents: Document[] = [];
    let restructuring: Option<OptionType.Restructuring> | undefined;

    try {
      const options = await this.rpc.loanOptionGetAvailable();
      restructuring = options.find(
        (option) => option.type === OptionType.Restructuring,
      );
    } catch (error) {
      if (RpcError.isType(error, RpcErrorCode.LoanOptionSmsLimitExceeded)) {
        throw new LimitExceededError();
      }
    }

    if (restructuring) {
      try {
        documents = await this.rpc.loanOptionChoose(restructuring.id);
      } catch (error) {
        if (RpcError.isType(error, RpcErrorCode.LoanSmsLimitExceeded)) {
          throw new LimitExceededError();
        }

        throw error;
      }
    }

    const isScreenShowed = Boolean(
      localStorage.getItem('restructure_screen_showed'),
    );

    return () => {
      this.model = restructuring;
      this.documents = documents;
      this.isScreenShowed = isScreenShowed;
    };
  }

  /**
   * Задаёт новое значение свойству `validate`.
   * @param validate Функция валидации формы согласия.
   */
  public setValidate = (validate?: () => Promise<boolean>) => {
    this.validate = validate;
  };

  /**
   * Скрывает экран и записывает это в LS
   */
  @action
  public hideScreen = () => {
    localStorage.setItem('restructure_screen_showed', 'true');
    this.isScreenShowed = false;

    this.reset();
    this.renew();
  };

  /**
   * Запрашивает у сервера код подтверждения для договора реструктуризации.
   */
  @action
  public requestCode = async () => {
    if (this.validate == null) {
      throw new Error(`this.validate is undefined`);
    }

    const isValid = await this.validate();

    if (!isValid) {
      throw new Prevent('Требуется согласие с обязательными полями договора');
    }

    if (this.model) {
      try {
        await this.rpc.loanOptionSendConfirmationCode(this.model.id);
      } catch (error) {
        if (RpcError.isType(error, RpcErrorCode.LoanOptionSmsLimitExceeded)) {
          throw new LimitExceededError();
        }

        throw error;
      }
    }
  };

  /**
   * Подписывает договор на реструктуризацию, отправляя код подтверждения
   * и перенаправляет пользователя на страницу займа.
   * @param code Код подтверждения.
   */
  @action
  public confirmCode = async (code: string) => {
    if (this.validate == null) {
      return;
    }

    const isValid = await this.validate();

    if (!isValid) {
      throw new Prevent('Требуется согласие с обязательными полями договора');
    }

    if (this.model) {
      const utm = this.routingService.getFromSession();

      try {
        await this.rpc.loanOptionConfirmSignCode(this.model.id, code, utm);
      } catch (error) {
        if (RpcError.isType(error, RpcErrorCode.LoanOptionSignLimitExceeded)) {
          throw new LimitExceededError();
        }

        if (
          RpcError.isType(error, RpcErrorCode.LoanOptionInvalidConfirmationCode)
        ) {
          throw new InvalidCodeError();
        }

        throw error;
      }

      await this.navigateToLoan();
    }
  };

  /**
   * Освобождает все занятые экземпляром сервиса ресурсы, подготавливая его к
   * удалению.
   */
  protected clear() {
    this.model = undefined;
    this.documents = [];
    this.validate = undefined;
  }
}
