import { ReactNode } from 'react';

/**
 * Вставляет в начало adornments ещё один элемент.
 * @param adornments значение свойства startAdornment или endAdornment.
 * @param node элемент, который должен быть добавлен в начало.
 */
export const prependAdornments = (adornments: ReactNode, node: ReactNode) => {
  if (!adornments) {
    return <>{node}</>;
  }

  return (
    <>
      {node}
      {adornments}
    </>
  );
};
