import styled from '@emotion/styled';

/**
 * Контейнер раскрываемого списка соглашений.
 */
export const ToggleContainer = styled.div`
  padding: 1.25rem;

  display: grid;
  grid-auto-flow: row;
  gap: 1rem 0;
`;
