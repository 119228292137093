import { FC } from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { Helper } from '../../helpers';
import { Store } from '../../stores';

/**
 * Запрещает подлкючение в DOM своего содержимого, пока идёт процесс отката
 * браузерной истории методом `Store.get().backTo()`.
 */
const BackToProvider: FC = ({ children }) => {
  const router = useRouter();
  const store = Store.use();

  if (store.backUrl == null) {
    return <>{children}</>;
  }

  const { pathname, query } = router;
  const currentUrl = Helper.combineUrl(pathname, query);

  if (currentUrl !== store.backUrl) {
    router.back();
    return null;
  }

  store.setBackUrl(undefined);
  return <>{children}</>;
};

const component = observer(BackToProvider);
export { component as BackToProvider };
