import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.div`
  margin-bottom: 8px;
  padding: 0 12px;

  text-align: center;
  font-size: 16px;
  line-height: 20px;

  @media ${lessThanContainer('small')} {
    font-size: 15px;
    line-height: 24px;
  }
`;
