import { Service } from './Service';

import { Event } from '../types/Event';

/**
 * Сервис содержит методы для отправки событий метрики заявления для отказа от
 * услуги.
 */
export class RefusalOfServiceService extends Service {
  /**
   * Клиент перешел на заполнение заявления для отказа от услуги.
   */
  form() {
    this.send(Event.REFUSAL_OF_SERVICE_FORM);
  }

  /**
   * Клиент успешно отправил заявления для отказа от услуги.
   */
  success() {
    this.send(Event.REFUSAL_OF_SERVICE_SUCCESS);
  }
}
