import React, { FC, ComponentProps, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

/**
 * Свойства родительского компонента.
 */
type BaseProperties = ComponentProps<typeof CSSTransition>;

/**
 * Свойства компонента.
 */
type Properties = Omit<BaseProperties, 'timeout' | 'classNames'> & {
  /**
   * Таймаут анимации.
   */
  timeout?: BaseProperties['timeout'];

  /**
   * Имя класса с анимацией для появления содержимого.
   */
  enter?: string;

  /**
   * Имя класса с анимацией для исчезновения содержимого.
   */
  leave?: string;
};

/**
 * Добавляет анимацию появления и ухода для вложенного содержимого.
 */
export const Transition: FC<Properties> = ({
  timeout: outerTimeout,
  enter = 'enter',
  leave = 'leave',
  ...props
}) => {
  const classNames = useMemo(
    () => ({
      enterActive: enter,
      exitActive: leave,
    }),
    [enter, leave],
  );

  const theme = useTheme();

  const timeout = useMemo(
    () =>
      outerTimeout != null ? outerTimeout : theme.transition.duration.default,
    [outerTimeout, theme],
  );

  return (
    <CSSTransition
      timeout={timeout}
      mountOnEnter
      unmountOnExit
      classNames={classNames}
      {...props}
    />
  );
};
